import { Component, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';
import { ActivatedRoute } from '../../../../node_modules/@angular/router';
import { LoginService } from '../../_handler/login.service';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.css']
})
export class LandingComponent implements OnInit {
  estadomenu = 1;
  preg1 = 1;
  preg2 = 1;
  preg3 = 1;
  preg4 = 1;
  mobile: any;

  acordion1 = true;
  acordion2 = false;
  acordion3 = false;
  acordion4 = false;
  acordion5 = false;

  preguntaFr1 = true;
  preguntaFr2 = true;
  preguntaFr3 = true;
  preguntaFr4 = true;
  preguntaFr5 = true;

  URL_LOGIN: string = environment.URL_LOGIN;
  constructor(public route: ActivatedRoute, public loginService: LoginService) { }

  ngOnInit(): void {
    sessionStorage.setItem('ruta', JSON.stringify(false));
    this.mobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    setTimeout(() => {
      this.resizeTop();
      // location.reload(true);
      this.reponsive();
    }, 10);
    this.move(1);

  }

  goSection() {
    const section = 'seccion';
    $('html,body').animate({
      scrollTop: $('#' + section).offset().top
    }, 1000).end();
  }

  move(estado) {
    if (estado === 1) {
      this.preg1 = 2;
      this.preg2 = 1;
      this.preg3 = 1;
      this.preg4 = 1;
    } else if (estado === 2) {
      this.preg1 = 1;
      this.preg2 = 2;
      this.preg3 = 1;
      this.preg4 = 1;
    } else if (estado === 3) {
      this.preg1 = 1;
      this.preg2 = 1;
      this.preg3 = 2;
      this.preg4 = 1;
    } else if (estado === 4) {
      this.preg1 = 1;
      this.preg2 = 1;
      this.preg3 = 1;
      this.preg4 = 2;
    }

  }

  reponsive() {
    if (this.mobile) {
      const elm1 = <HTMLElement>document.getElementById('header');
      const elm2 = <HTMLElement>document.getElementById('oficina');
      if (elm1 || elm2) {
        elm1.style.height = '360px';
        elm2.style.fontSize = '1.8em';
      }

    }
  }
  resizeTop() {
    if (this.mobile) {
      const elm = <HTMLElement>document.getElementById('textoHome');
      if (elm != null) {
        // elm.style.fontSize = '1.5em';
        // elm.style.marginLeft = '-80px';
        // elm.style.paddingRight = '130px';
        // elm.style.marginTop = '-116px';
      }
      const imgIndex = <HTMLElement>document.getElementById('imagenIndex');
      if (imgIndex != null) {
        // imgIndex.style.marginTop = '-271px';
        // imgIndex.style.width = '65%';
      }
    }

  }


  onTabClose(event) {
    //console.log('c');
    if (event.index || event.index === 0) {
      this.acordion1 = false;
      this.acordion2 = false;
      this.acordion3 = false;
      this.acordion4 = false;
      this.acordion5 = false;
    }
  }

  onTabOpen(event) {
    //console.log('a');
    if (event) {

      if (event.index === 0) {
        this.acordion1 = true;
        this.acordion2 = false;
        this.acordion3 = false;
        this.acordion4 = false;

      } else if (event.index === 1) {
        this.acordion1 = false;
        this.acordion2 = true;
        this.acordion3 = false;
        this.acordion4 = false;

      } else if (event.index === 2) {
        this.acordion1 = false;
        this.acordion2 = false;
        this.acordion3 = true;
        this.acordion4 = false;

      } else if (event.index === 3) {
        this.acordion1 = false;
        this.acordion2 = false;
        this.acordion3 = false;
        this.acordion4 = true;
      }

    }
  }
}


