/**
 * intl-latam-ec-form-secure
 * Api to secure Application
 *
 * OpenAPI spec version: 1.2.1
 * Contact: cgudino@kruger.com.ec
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import {
    HttpClient, HttpHeaders, HttpParams,
    HttpResponse, HttpEvent
} from '@angular/common/http';
import { CustomHttpUrlEncodingCodec } from '../encoder';

import { Observable } from 'rxjs/Observable';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { Reqcode } from '../model-seguridad/reqcode';

import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';


@Injectable()
export class AccessService {
    @BlockUI() blockUI: NgBlockUI;
    protected basePath = environment.URL_API_SECURE;
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional() @Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * get access token
     * access token
     * @param body Request code
     * @param idToken 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public intlLatamEcSecureGetTokenClient(body: Reqcode, idToken?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public intlLatamEcSecureGetTokenClient(body: Reqcode, idToken?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public intlLatamEcSecureGetTokenClient(body: Reqcode, idToken?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public intlLatamEcSecureGetTokenClient(body: Reqcode, idToken?: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
        this.blockUI.start();
        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling intlLatamEcSecureGetTokenClient.');
        }


        let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (idToken !== undefined && idToken !== null) {
            queryParameters = queryParameters.set('idToken', <any>idToken);
        }

        let headers = this.defaultHeaders;

        // authentication (basic-authorizator) required
        // if (this.configuration.apiKeys["Unused"]) {
        headers = headers.set('clientid', "TG9naW5Qb3J0YWw=");
        // }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.basePath}/access`,
            body,
            {
                params: queryParameters,
                //withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map( response => {
                this.blockUI.stop();
                return response
           } )
          );
    }

}
