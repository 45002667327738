/**
 * intl-latam-ec-portal-documents
 * No description
 *
 * OpenAPI spec version: 1.0.0
 * Contact: you@your-company.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { CatalogModel } from './catalog';


/**
 * Débito automatico, requerido si el campo isAutomaticDebit es verdadero.
 */
export class FigurePersonAutomaticDebitModel { 
    financialEntity?: CatalogModel;
    /**
     * Tipo de cuenta.
     */
    accountType?: string;
    /**
     * Número de cuenta.
     */
    accountNumber?: string;
    /**
     * Titular de la cuenta.
     */
    accountHolder?: string;
    /**
     * Número de identificación.
     */
    numberIdentification?: string;

    constructor(){
        this.financialEntity = new CatalogModel();
        this.accountType = null;
        this.accountNumber = null;
        this.accountHolder = null;
        this.numberIdentification = null;
    }
}
