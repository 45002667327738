import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';

import { SelectItem } from 'primeng/primeng';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/components/common/messageservice';
import { Properties } from '../../../../../general/properties';
import { FormsService } from '../../../../../../_services';

import { Util } from '../../../../../general/util';
import { FormModel } from 'src/app/_model/documents/form';
import { PersonalReferencePersonModel } from 'src/app/_model/documents/personalReferencePerson';
import { BankReferenceModel } from 'src/app/_model/documents/bankReference';
import { CommercialReferenceModel } from 'src/app/_model/documents/commercialReference';
import { EconomicInformationModel, CatalogModel, WorkModel, DocumentModel } from 'src/app/_model/documents/models';
import { CatalogService } from 'src/app/_services/api_v1/catalogs.service';
import { DocumentsService } from 'src/app/_services/api_v1/documents.service';
import { SharedCatalogueService } from '../../../shared-catalogue.service';

@Component({
    selector: 'app-datos-financieros-co',
    templateUrl: './datosFinancieros.html',
    styleUrls: ['./datosFinancieros.css']
})

export class DatosFinancierosComponent implements OnInit {

    @Output() changed = new EventEmitter<boolean>();
    @ViewChild('form') public userFrm: NgForm;

    properties: Properties = new Properties();
    es: any = this.properties.es;
    titulo: string = "Información financiera";
    subtitulo: string = "Diligencia los siguientes campos. Conocer tu información financiera es importante para nosotros."
    // Generales
    pais: any;
    idFormulario: any;

    showFilter: Boolean = true;

    // Catalogos
    tipoActividadSelectItem: any[];
    tipoEstadoCivilSelectItem: any[];
    tipoCiuuSelectItem: any[];
    tipoOcupacionesSelectItem: any[];
    tipoProfesionSelectItem: any[];
    tipoProductoelectItem: any[];
    tipoRelacionSelectItem: any[];
    tipoCargoSelectItem: any[];

    monthlyIcomeSelectItem: any;
    // Modelos
    nuevoFormulario: DocumentModel = new DocumentModel();;
    formulario = new FormModel();
    //formulario.data= new FormModel(); 
    respuestas: SelectItem[];
    countriesSelectItem: SelectItem[] = [];
    provinceSelectItem: SelectItem[] = [];
    citySelectItem: SelectItem[] = [];
    chargesSelectItem: SelectItem[] = [];

    // Referencias
    refPersonales = new Array<PersonalReferencePersonModel>();
    refBancarias = new Array<BankReferenceModel>();

    refComerciales = new Array<CommercialReferenceModel>();

    // Variables
    mostrarSeccion: number;
    sumInsured: number;
    mostrarReferencias: number;
    step: any;
    botonAgregar: boolean;
    botonAgregarBank: boolean;

    loading: boolean;
    mostrarCargo: boolean = false;
    mostrarCargoAux: boolean = false;
    signoMoneda: string;

    // variables para el conteo de campos dilegenciados
    mobile: any;
    bndValidHeaderLabor = false;
    bndValidHeaderEconomic = false;
    bndValidHeaderReference = false;
    listSections = [];

    listInputsLabor = [];
    bndCamposLabor = false;
    countLabor = 0;
    listInputsEconomic = [];
    bndCamposEconomic = false;
    countEconomic = 0;
    listInputsReference = [];
    bndCamposReference = false;
    countReference = 0;
    mostrarOtraRelacionRef: boolean = false;
    mostrarDescripcion = false;
    util: Util = new Util();
    idMonthlyN: number;
    economicInformation = new EconomicInformationModel();
    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private messageService: MessageService,
        public formService: FormsService,
        private catalogService: CatalogService,
        private _documentsService: DocumentsService,
        public _sharedCatalogue: SharedCatalogueService,
    ) {
        this.countriesSelectItem.push({ label: this.properties.labelSelecioneCombo, value: '' });
        this.provinceSelectItem.push({ label: this.properties.labelSelecioneCombo, value: '' });
        this.citySelectItem.push({ label: this.properties.labelSelecioneCombo, value: '' });
        this.respuestas = this.properties.respuestas;
        this.listSections = ['labor', 'economic', 'reference'];
    }

    ngOnInit(): void {
        this.mobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
        const id = this.route.snapshot.paramMap.get('idFormulario');
        const country = this.route.snapshot.paramMap.get('idCountry');
        this.pais = country;
        this.idFormulario = id;
        this.signoMoneda = '';
        this.cargarDatos();
    }

    cargarDatos() {
        this.cargarTipoCiuu();
        // this.cargarOcupaciones();
        // this.cargarProfeciones();
        // this.cargarProductos();
        // this.cargarTipoCargo();
        this.cargarTipoActividad();
        // this.cargarTipoRelacion();
        this.getCountries();
        // this.getMonthlyIncomeN();
        // se mueve consultar formulario a la respuesta de pais
        //await this.consultarFormulario();
    }
    onTabOpen(e) {

        const index = e.index;
        switch (index) {
            case 0:
                this.bndValidHeaderLabor = true;
                break;
            case 1:
                this.bndValidHeaderEconomic = true;
                break;
            case 2:
                this.bndValidHeaderReference = true;
                break;
            default:
                break;
        }
    }

    onTabClose(e) {
        const index = e.index;
        this.mobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
        switch (index) {
            case 0:
                this.bndValidHeaderLabor = false;
                break;
            case 1:
                this.bndValidHeaderEconomic = false;
                break;
            case 2:
                this.bndValidHeaderReference = false;
                break;
            default:
                break;
        }
    }

    ingresosMensFormat() {
        this.economicInformation.monthlyIncomeDescription = this.util.formatoMoney(this.economicInformation.monthlyIncomeDescription);
    }

    egresosMensFormat() {
        this.economicInformation.monthlyExpenses = this.util.formatoMoney(this.economicInformation.monthlyExpenses);
    }

    totalActivosFormat() {
        this.economicInformation.actives = this.util.formatoMoney(this.economicInformation.actives);
    }

    totalPasivosFormat() {
        this.economicInformation.pasives = this.util.formatoMoney(this.economicInformation.pasives);
    }

    otrosIngresosFormat() {
        this.economicInformation.otherIncome = this.util.formatoMoney(this.economicInformation.otherIncome);
    }

    consultarFormulario() {
        this.loading = true;
        this._documentsService.getDocumentsByDateIdByCountry(this.idFormulario, this.pais)
            .subscribe((resp: any) => {
                this.nuevoFormulario = resp;
                this.mostrarCargo = false;
                this.mostrarOtraRelacionRef = false;

                this.economicInformation = resp.data.economicInformation;
                if (this.economicInformation == null) {
                    this.economicInformation = new EconomicInformationModel();
                }
                if (this.economicInformation.monthlyIncome != null) {
                    this.idMonthlyN = this.economicInformation.monthlyIncome.id;
                } else {
                    this.economicInformation.monthlyIncome = new CatalogModel();
                }


                if (resp.data.work == null) {
                    resp.data.work = new WorkModel();
                    // this.entity.work.phoneNumber.description = '';
                }
                this.step = resp.data.step;
                this.sumInsured = resp.data.sumInsured;
                if (this.sumInsured > 200000) {
                    this.mostrarReferencias = 1;
                }
                else {
                    this.mostrarReferencias = 0;
                }
                if (resp.data.personalReferences == null) {
                    let refPersonalesAux = new PersonalReferencePersonModel();
                    this.refPersonales.push(refPersonalesAux);
                }
                else {
                    this.refPersonales = resp.data.personalReferences;
                    this.refPersonales.forEach(x => {
                        if (x.otherType) {
                            x.showOtherType = true;
                        }
                    })
                }
                if (this.refPersonales.length >= 2) {
                    this.botonAgregar = true;
                } else {
                    this.botonAgregar = false;
                }
                if (resp.data.bankReferences == null) {
                    let bankReferencesAuxA = new BankReferenceModel();
                    // let bankReferencesAuxB = new EntityBankReferencesModel();
                    this.refBancarias.push(bankReferencesAuxA);
                    // this.refBancarias.push(bankReferencesAuxB);
                }
                else {
                    this.refBancarias = resp.data.bankReferences;
                }
                if (resp.data.commercialReferences == null) {
                    let refComercialesAuxA = new CommercialReferenceModel();
                    let refComercialesAuxB = new CommercialReferenceModel();
                    this.refComerciales.push(refComercialesAuxA);
                    this.refComerciales.push(refComercialesAuxB);
                }
                else {
                    this.refComerciales = resp.data.commercialReferences;
                }

                if (resp.data.work.economicActivity && resp.data.work.economicActivity != null) {
                    const economicActivity = resp.data.work.economicActivity.id;
                    if (economicActivity === 3 || economicActivity === 4 || economicActivity === 7) {
                        this.mostrarSeccion = 0;
                    } else if (economicActivity === 2 || economicActivity === 6 || economicActivity === 8) {
                        this.mostrarSeccion = 1;
                    } else if (economicActivity === 1 || economicActivity === 5 || economicActivity === 9 || economicActivity === 10) {
                        this.mostrarSeccion = 2;
                    }
                    // Asalariado, Independiente, Socio
                    if (resp.data.work.economicActivity && resp.data.work.economicActivity != null &&
                        resp.data.work.economicActivity.id == 4 || resp.data.work.economicActivity.id == 5 ||
                        resp.data.work.economicActivity.id == 6) {
                        this.mostrarCargo = true;
                        this.mostrarCargoAux = true;
                    }
                    else {
                        this.mostrarCargo = false;
                        this.mostrarCargoAux = false;
                    }
                    if (resp.data.work.economicActivity.id == 10) {
                        this.mostrarDescripcion = true;
                    }

                } else {
                    this.mostrarSeccion = 0;
                }


                try {


                    if (resp.data.work.placeAddressWork.place.country) {
                        this.getProvinceByCountry(resp.data.work.placeAddressWork.place.country)
                    }
                    if (resp.data.work.placeAddressWork.place.province) {
                        this.getCityByProvince(resp.data.work.placeAddressWork.place.province, null)
                    }


                } catch (error) {

                }

                this.formulario = resp.data;
                if (this.formulario.work && this.formulario.work.economicActivity) {
                    this.onChangeMainActivity(this.formulario.work.economicActivity);
                }
                setTimeout(() => {
                    this.validacionSection(this.userFrm, this.listSections);
                    this.loading = false;
                }, 1000);
                if (this.mobile) {
                    for (let index = 0; index < 2; index++) {
                        let elm = <HTMLElement>document.getElementById('countAccordion' + index);
                        elm.style.display = 'none';
                    }
                    let card = <HTMLElement>document.getElementById('cardIntermediarios');
                    card.style.paddingLeft = '10px';
                    card.style.paddingRight = '10px';
                }
            },
                (err: any) => {
                    console.log(err);
                    this.loading = true;
                });
    }

    onChangeMainActivity(eventV) {
        this.validateInput(this.userFrm, 'labor');
        if (eventV) {
            let event = eventV.id
            if (event === 3 || event === 4 || event === 7 || event == null) {
                this.mostrarSeccion = 0;
            } else if (event === 2 || event === 6 || event === 8) {
                this.mostrarSeccion = 1;
            } else if (event === 1 || event === 5 || event === 9 || event === 10) {
                this.mostrarSeccion = 2;
            }
            if ([1,2,5,6,8,9,10].includes(event)) {
                this.showFilter = false;
            } else {
                this.showFilter = true;
            }
            if (event === 10) {
                this.mostrarDescripcion = true;
            } else {
                this.mostrarDescripcion = false;
            }
        }

    }

    validacionSection(form: NgForm, listSections: Array<String>) {
        const catalog = new CatalogModel();
        this.countLabor = 0;
        this.listInputsLabor = [];
        this.countEconomic = 0;
        this.listInputsEconomic = [];
        this.countReference = 0;
        this.listInputsReference = [];

        const controls = form.controls;
        Object.keys(controls).forEach(input => {
            const nameInput = input.split('_');
            listSections.forEach(section => {
                if (nameInput[1] === section) {
                    switch (section) {
                        case 'labor':
                            this.listInputsLabor.push(input);
                            if (form.controls[input].value !== '') {
                                if (form.controls[input].value !== 0) {
                                    if (form.controls[input].value !== null && form.controls[input].value !== undefined) {
                                        if (form.controls[input].value.id !== null && form.controls[input].value.id !== '') {
                                            this.countLabor++;
                                        }
                                    }
                                }
                            }
                            break;
                        case 'economic':
                            this.listInputsEconomic.push(input);
                            if (form.controls[input].value !== '' || form.controls[input].status == 'VALID') {
                                if (form.controls[input].value !== 0 || form.controls['ingresosMensuales_economic']) {
                                    if (form.controls[input].value !== null || form.controls[input].status == 'VALID') {
                                        if (form.controls[input].value !== undefined || form.controls[input].status == 'VALID') {
                                            this.countEconomic++;
                                        }
                                    }
                                }
                            }
                            break;
                        case 'reference':
                            this.listInputsReference.push(input);
                            if (form.controls[input].value !== '') {
                                if (form.controls[input].value !== 0) {
                                    if (form.controls[input].value !== null && form.controls[input].value !== undefined) {
                                        if (form.controls[input].value.id !== null && form.controls[input].value.id !== '') {
                                            this.countReference++;
                                        }
                                    }
                                }
                            }
                            break;
                        default:
                            break;
                    }
                }
                if (this.countLabor === this.listInputsLabor.length) {
                    this.bndCamposLabor = true;
                } else {
                    this.bndCamposLabor = false;
                }
                if (this.countEconomic === this.listInputsEconomic.length) {
                    this.bndCamposEconomic = true;
                } else {
                    this.bndCamposEconomic = false;
                }
                if (this.countReference === this.listInputsReference.length) {
                    this.bndCamposReference = true;
                } else {
                    this.bndCamposReference = false;
                }
            });
        });
    }

    validateInput(form: NgForm, key) {
        setTimeout(() => {
            switch (key) {
                case 'labor':
                    this.countLabor = 0;
                    this.listInputsLabor = [];
                    break;
                case 'economic':
                    this.countEconomic = 0;
                    this.listInputsEconomic = [];
                    break;
                case 'reference':
                    this.countReference = 0;
                    this.listInputsReference = [];
                    break;
                default:
                    break;
            }

            const controls = form.controls;
            Object.keys(controls).forEach(input => {
                const nameInput = input.split('_');
                if (nameInput[1] === key) {
                    switch (key) {
                        case 'labor':
                            this.listInputsLabor.push(input);
                            if (form.controls[input].value !== '') {
                                if (form.controls[input].value !== 0) {
                                    if (form.controls[input].value !== null && form.controls[input].value !== undefined) {
                                        if (form.controls[input].value.id !== null && form.controls[input].value.id !== '') {
                                            this.countLabor++;
                                        }
                                    }
                                }
                            }
                            break;
                        case 'economic':
                            this.listInputsEconomic.push(input);
                            if (form.controls[input].value !== '' || form.controls[input].status == 'VALID') {
                                if (form.controls[input].value !== 0 || form.controls['ingresosMensuales_economic']) {
                                    if (form.controls[input].value !== null || form.controls[input].status == 'VALID') {
                                        if (form.controls[input].value !== undefined || form.controls[input].status == 'VALID') {
                                            this.countEconomic++;
                                        }
                                    }
                                }
                            }
                            break;
                        case 'reference':
                            this.listInputsReference.push(input);
                            if (form.controls[input].value !== '') {
                                if (form.controls[input].value !== 0) {
                                    if (form.controls[input].value !== null && form.controls[input].value !== undefined) {
                                        if (form.controls[input].value.id !== null && form.controls[input].value.id !== '') {
                                            this.countReference++;
                                        }
                                    }
                                }
                            }
                            break;
                        default:
                            break;
                    }
                }
                if (this.countLabor === this.listInputsLabor.length) {
                    this.bndCamposLabor = true;
                } else {
                    this.bndCamposLabor = false;
                }
                if (this.countEconomic === this.listInputsEconomic.length) {
                    this.bndCamposEconomic = true;
                } else {
                    this.bndCamposEconomic = false;
                }
                if (this.countReference === this.listInputsReference.length) {
                    this.bndCamposReference = true;
                } else {
                    this.bndCamposReference = false;
                }
            });
        }, 1000);
    }

    cargarTipoRelacion() {
        this.catalogService.getCatalogs('relationship', this.pais)
            .subscribe((resp: any) => {
                this.tipoRelacionSelectItem = [];
                this.tipoRelacionSelectItem.push({ 'code': '', 'description': this.properties.labelSelecioneCombo, 'id': '' });
                resp.forEach(element => {
                    this.tipoRelacionSelectItem.push(element)
                });
            },
                (err: any) => console.log(err),
                () => {
                });
    }

    cargarTipoActividad() {
        this.catalogService.getCatalogs(this.properties.economicActivityPN, this.pais)
            .subscribe((resp: any) => {
                this.tipoActividadSelectItem = [];
                this.tipoActividadSelectItem.push({ 'code': '', 'description': this.properties.labelSelecioneCombo, 'id': '' });
                resp.forEach(element => {
                    this.tipoActividadSelectItem.push(element)
                });
            },
                (err: any) => console.log(err),
                () => {
                });
    }

    // Solo para colombia
    cargarTipoCiuu() {
        this.catalogService.getCatalogs('ciuu', 'CO')
            .subscribe((resp: any) => {
                this.tipoCiuuSelectItem = [];
                this.tipoCiuuSelectItem.push({ 'value': null, 'label': this.properties.labelSelecioneCombo });
                resp.forEach(element => {
                    this.tipoCiuuSelectItem.push({ 'value': element, 'label': element.id + " - " + element.description })
                });
            },
                (err: any) => console.log(err),
                () => {
                });
    }

    cargarTipoCargo() {
        this.catalogService.getCatalogs('charges', 'CO')
            .subscribe((resp: any) => {
                this.tipoCargoSelectItem = [];
                this.tipoCargoSelectItem.push({ 'value': null, 'label': this.properties.labelSelecioneCombo });
                resp.forEach(element => {
                    this.tipoCargoSelectItem.push({ 'value': element, 'label': element.description })
                });
            },
                (err: any) => console.log(err),
                () => {
                });
    }



    cargarOcupaciones() {
        this.catalogService.getCatalogs('occupations', 'CO')
            .subscribe((resp: any) => {
                this.tipoOcupacionesSelectItem = [];
                this.tipoOcupacionesSelectItem.push({ 'code': '', 'description': this.properties.labelSelecioneCombo, 'id': '' });
                resp.forEach(element => {
                    this.tipoOcupacionesSelectItem.push(element)
                });
            },
                (err: any) => console.log(err),
                () => {
                });
    }

    cargarProfeciones() {
        this.catalogService.getCatalogs('professions', 'CO')
            .subscribe((resp: any) => {
                this.tipoProfesionSelectItem = [];
                this.tipoProfesionSelectItem.push({ 'code': '', 'description': this.properties.labelSelecioneCombo, 'id': '' });
                resp.forEach(element => {
                    this.tipoProfesionSelectItem.push(element)
                });
            },
                (err: any) => console.log(err),
                () => {
                });
    }

    cargarProductos() {
        this.catalogService.getCatalogs('productType', 'CO')
            .subscribe((resp: any) => {
                this.tipoProductoelectItem = [];
                this.tipoProductoelectItem.push({ 'code': '', 'description': this.properties.labelSelecioneCombo, 'id': '' });
                resp.forEach(element => {
                    this.tipoProductoelectItem.push(element)
                });
            },
                (err: any) => console.log(err),
                () => {
                });
    }

    mostrarOtro(event) {
        let relacion = event;
    }

    actualizarFormulario(myForm: NgForm) {
        const listControls = Object.keys(myForm.controls);
        const sizeControls = listControls.length;
        let i = 0;
        let count = 0;
        listControls.forEach(control => {
            if (myForm.valid &&
                (myForm.controls[control].value != null && myForm.controls[control].value !== undefined)) {
                if (myForm.controls[control].value.id === '' && control !== 'ciuu_labor' && control !== 'producto_labor'
                    && control !== 'ocupacion_labor' && control !== 'profesion_labor'
                    && control !== 'fuenteIngresos_labor') {
                    count++;
                }
                i++;
            } else {
                i++;
                const sectionNoValidate = control.split('_')[1];
                if (!myForm.controls[control].valid) {
                    count++;
                } else if (control !== 'ciuu_labor' && control !== 'producto_labor'
                    && control !== 'ocupacion_labor' && control !== 'profesion_labor'
                    && control !== 'fuenteIngresos_labor' && sectionNoValidate !== 'reference') {
                    if ((myForm.controls[control].value == null || myForm.controls[control].value === undefined)) {
                        count++;
                    } else if (myForm.controls[control].value.id === '') {
                        count++;
                    }
                }
            }
            if (sizeControls === i) {
                if (count === 0) {
                    this.loading = true;
                    this.armarFormulario();
                    this._documentsService.updateDocument(this.nuevoFormulario).
                        subscribe((resp: any) => {
                            this.messageService.add({ severity: 'success', summary: '', detail: this.properties.msgSuccessForm });
                            this.loading = false;
                            this.changed.emit(true);
                        },
                            (err: any) => {
                                this.messageService.add({ severity: 'error', summary: '', detail: this.properties.msgErrorForm });
                                this.loading = true;
                            },
                            () => {
                            });
                } else {
                    this.messageService.add({
                        severity: 'error', summary: '', detail: "El formulario aun tiene " + count +
                            " Error(es) revise las alertas en cada campo"
                    });
                    this.loading = false;
                }
            }
        });
    }

    armarFormulario() {

        if (this.idMonthlyN != undefined && this.idMonthlyN != null) {

            this.monthlyIcomeSelectItem.forEach(description => {
                if (description.value === this.idMonthlyN) {
                    const model = {
                        description: description.label,
                        id: description.value,
                        code: null
                    }
                    this.economicInformation.monthlyIncome = model;
                }
            }
            )
        }
        this.formulario.step = this.step

        this.formulario.personalReferences = this.refPersonales;
        this.formulario.bankReferences = this.refBancarias;
        this.formulario.commercialReferences = this.refComerciales;
        this.formulario.economicInformation = this.economicInformation;
        // if (this.formulario.work) {
        //     if (this.formulario.work.economicActivity) {
        //         console.log(this.formulario.work.economicActivity);
        //         let value = this.formulario.work.economicActivity;
        //         let label = null;
        //         this.tipoMainActivitySelectItem.forEach(x => {
        //             if (x.value == value)
        //                 label = x.label;
        //         })
        //         let objetoEconicActivity = new CatalogModel;
        //         objetoEconicActivity.id = +value;
        //         objetoEconicActivity.description = label;
        //        this.formulario.work.economicActivity = objetoEconicActivity;
        //     }
        // }

        this.nuevoFormulario.data = this.formulario;
        let paso = 2;
        if (paso <= this.step) {
            paso = this.step;
        } else {
            this.step = paso;
        }
        this.formulario.step = this.step
    }

    agregar() {
        let referencia = new PersonalReferencePersonModel();
        this.refPersonales.push(referencia);
        if (this.refPersonales.length >= 2) {
            this.botonAgregar = true;
        } else {
            this.botonAgregar = false;
        }
        this.validateInput(this.userFrm, 'reference');
    }
    agregarBanco() {
        let referencia = new BankReferenceModel();
        this.refBancarias.push(referencia);
        if (this.refBancarias.length >= 2) {
            this.botonAgregarBank = true;

        } else {
            this.botonAgregarBank = false;
        }
        this.validateInput(this.userFrm, 'reference');
    }
    getMonthlyIncomeN() {
        this.catalogService.getCatalogs('monthlyIncomeN', this.pais)
            .subscribe((resp: any) => {
                this.monthlyIcomeSelectItem = [];
                this.monthlyIcomeSelectItem.push({ label: this.properties.labelSelecioneCombo, value: null });
                resp.forEach(element => {
                    const catalog = {
                        label: element.description,
                        value: element.id
                    };
                    this.monthlyIcomeSelectItem.push(catalog);
                });
            },
                (err: any) => console.log(err),
                () => {
                });
    }

    async getCountries() {
        this.catalogService.getCatalogs(this.properties.countries, this.pais)
            .subscribe((resp: any) => {
                this.countriesSelectItem = [];
                this.countriesSelectItem.push({ label: this.properties.labelSelecioneCombo, value: '' });
                resp.forEach(element => {
                    const catalog = {
                        label: element.description,
                        value: element
                    };
                    this.countriesSelectItem.push(catalog);
                });
                this.consultarFormulario();
            },
                (err: any) => console.log(err),
                () => {
                });
    }


    getProvinceByCountry(pais, index?) {
        if (pais) {
            let idPais = pais.id
            if (idPais === this.properties.codCatalogEc || idPais === this.properties.codCatalogCo) {
                this.catalogService.getCatalogs(this.properties.province, this.pais, idPais)
                    .subscribe((resp: any) => {
                        this.provinceSelectItem = [];
                        this.provinceSelectItem.push({ label: this.properties.labelSelecioneCombo, value: '' });
                        resp.forEach(element => {
                            const catalog = {
                                label: element.description,
                                value: element
                            };
                            this.provinceSelectItem.push(catalog);
                        });
                    },
                        (err: any) => console.log(err),
                        () => {
                        });
            }
            // CONTROL CAMBIOS 
            //entity.work.address.geographicLocation.country.description 
        }
        this.validateInput(this.userFrm, 'labor');
    }

    validateplaceAddressWork(pais) {
        let idPais = pais.id
        if (idPais !== this.properties.codCatalogEc && idPais !== this.properties.codCatalogCo) {
            this.formulario.work.placeAddressWork.place.city = null
            this.formulario.work.placeAddressWork.place.province = null
        }
    }

    getCityByProvince(province, index) {

        if (province)
            this.catalogService.getCatalogs(this.properties.city, this.pais, province.id).subscribe((resp: any) => {
                this.citySelectItem = [];
                this.citySelectItem.push({ label: this.properties.labelSelecioneCombo, value: '' });
                resp.forEach(element => {
                    const catalog = {
                        label: element.description,
                        value: element
                    };
                    this.citySelectItem.push(catalog);
                });
            },
                (err: any) => console.log(err),
                () => {
                });

        this.validateInput(this.userFrm, 'labor');
    }

    updateDescriptionCity(idCity, index) {

        this.validateInput(this.userFrm, 'labor');
    }

    eventoTipoActividad(event) {

        // Independiente = 6
        this.mostrarCargo = false;
        this.mostrarCargoAux = false;

        if (event.id == 4 || event.id == 5) {
            this.mostrarCargo = true;
            this.mostrarCargoAux = true;
        }
        else {
            this.mostrarCargo = false;

        }
        if (event.id == 6) {
            this.mostrarCargo = true;
            this.mostrarCargoAux = false;
        }


        this.validateInput(this.userFrm, 'labor');
    }

    eventoOtraRelacion(event) {

        if (event.relationship.id == 11) {
            event.showOtherType = true;
        }
        else {
            event.showOtherType = false;
        }
        this.validateInput(this.userFrm, 'reference');
    }

    eliminarItem(index) {

        for (var i = 0; i < this.refPersonales.length; i++) {
            if (i == index) {

                this.refPersonales.splice(i, 1)
            }
        }
        if (this.refPersonales.length >= 2) {
            this.botonAgregar = true;
        } else {
            this.botonAgregar = false;
        }
    }

    eliminarItemBank(index) {

        for (var i = 0; i < this.refBancarias.length; i++) {
            if (i == index) {

                this.refBancarias.splice(i, 1)
            }
        }
        if (this.refBancarias.length >= 2) {
            this.botonAgregarBank = true;

        } else {
            this.botonAgregarBank = false;
        }
    }

}
