/**
 * intl-latam-ec-portal-documents
 * No description
 *
 * OpenAPI spec version: 1.0.0
 * Contact: you@your-company.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { CatalogModel } from './catalog';
import { LocationModel } from './location';
import { PersonFormContactsModel } from './personFormContacts';
import { PersonFormDocumentModel } from './personFormDocument';
import { PlaceAddressInformationModel } from './placeAddressInformation';
import { PersonFormPepModel } from './personFormPep';


export class PersonFormModel { 
    id?: number;
    firstName?: string;
    secondLastName?: string;
    lastName?: string;
    secondName?: string;
    gender: CatalogModel;
    found?: false;
    civilStatus: CatalogModel;
    /**
     * Fecha de naciemiento.
     */
    birthDate: Date;
    birthPlace: LocationModel;
    /**
     * Nacionalidades.
     */
    nationalities: Array<CatalogModel>;
    nationality: any;
    contacts?: PersonFormContactsModel;
    placeAddressResidence?: PlaceAddressInformationModel;
    document?: PersonFormDocumentModel;
    /**
     * ¿Está sujeto a obligaciones tributarias en otro país o grupo de países? aplica solo Colombia.
     */
    hasTaxObligationsOtherCountries?: boolean;
    /**
     * Paises de obligaciones tributarias, aplica solo Colombia.
     */
    countriesTaxObligations?: Array<CatalogModel>;
    /**
     * Es persona PEP.
     */
    isPep?: boolean;
    pep?: PersonFormPepModel;

    constructor(){

        this.id = null;
        this.firstName = null;
        this.secondLastName = null;
        this.lastName = null;
        this.secondName = null;
        //this.gender = new CatalogModel();
        this.gender = null;
        this.civilStatus = new CatalogModel();
        this.birthDate = null;
        this.found = false;
        this.birthPlace = new LocationModel();
        this.nationalities = new Array<CatalogModel>();
        this.nationality = null;
        this.contacts = new PersonFormContactsModel();
        this.placeAddressResidence = new PlaceAddressInformationModel();
        this.document =  new  PersonFormDocumentModel();
        this.hasTaxObligationsOtherCountries = false;
        this.countriesTaxObligations = new Array<CatalogModel>();
        this.pep = new PersonFormPepModel();
        this.isPep = false;
    }
}
