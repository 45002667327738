
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { CommonModule } from '@angular/common';
//UTILITARIOS.
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import {
  InputTextModule, DialogModule, DataTableModule, SharedModule, ButtonModule, GrowlModule, DropdownModule,
  MessagesModule, FileUploadModule, ConfirmDialogModule, InputTextareaModule,
  AutoCompleteModule, TabViewModule, PanelModule, CalendarModule, ConfirmationService, CheckboxModule, FieldsetModule,
  RadioButtonModule, MultiSelectModule, MessageModule
} from 'primeng/primeng';
import { KeyFilterModule } from 'primeng/keyfilter';
import { SelectButtonModule } from 'primeng/selectbutton';
import { InputMaskModule } from 'primeng/inputmask';
// Import ngx-barcode module
import { TableModule } from 'primeng/table';
// import { LoadingComponent } from '../../general/loading/loading.component';
import { BlockUIModule } from 'primeng/blockui';
import { ProgressSpinnerModule } from 'primeng/primeng';
import { SolicitudesComponent } from './solicitudes.component';

@NgModule({
  declarations: [
    SolicitudesComponent

  ],
  imports: [
    TableModule,
    CommonModule,
    FormsModule,
    // ModuloGeneral,
    HttpModule,
    BrowserAnimationsModule,
    InputTextModule,
    DialogModule,
    DataTableModule,
    SharedModule,
    ButtonModule,
    GrowlModule,
    DropdownModule,
    MessagesModule,
    MessageModule,
    FileUploadModule,
    ConfirmDialogModule,
    InputTextareaModule,
    TabViewModule,
    PanelModule,
    CalendarModule,
    CheckboxModule,
    FieldsetModule,
    RadioButtonModule,
    KeyFilterModule,
    MultiSelectModule,
    AutoCompleteModule,
    BlockUIModule,
    ProgressSpinnerModule,
    SelectButtonModule,
    InputMaskModule

  ],
  providers: [
  ],
  bootstrap: [],
  exports: [
    SolicitudesComponent
  ]

})
export class SolicitudesModule { }
