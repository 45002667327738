/**
 * intl-latam-ec-milf-linkingform
 * Manage the information of the linking form.
 *
 * OpenAPI spec version: 2.1.0
 * Contact: kruger@kruger.com.ec
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Entity } from './entity';
import { Evicertia } from './evicertia';
import { FormFigures } from './formFigures';
import { FormUrl } from './formUrl';
import { Person } from './person';
import { Vehicle } from './vehicle';


export interface Form { 
    id?: string;
    submissions?: string;
    step?: number;
    country: Form.CountryEnum;
    application: Form.ApplicationEnum;
    riskControlPerson?: boolean;
    riskControlSpouse?: boolean;
    formStatus?: Form.FormStatusEnum;
    idParentForm?: string;
    effectiveDate?: Date;
    evicertia?: Evicertia;
    vehicle?: Vehicle;
    manager: Person;
    broker: Person;
    url?: FormUrl;
    sumInsured?: number;
    entityType: Form.EntityTypeEnum;
    entity?: Entity;
    files?: Array<any>;
    isContractingBeneficiaryInsured?: boolean;
    figures?: Array<FormFigures>;
    trueInformationStatement?: boolean;
    authorizationPersonalData?: boolean;
}
export namespace Form {
    export type CountryEnum = 'EC' | 'CO';
    export const CountryEnum = {
        EC: 'EC' as CountryEnum,
        CO: 'CO' as CountryEnum
    };
    export type ApplicationEnum = 'FRESHSERVICE' | 'UNQORK' | 'FORM';
    export const ApplicationEnum = {
        FRESHSERVICE: 'FRESHSERVICE' as ApplicationEnum,
        UNQORK: 'UNQORK' as ApplicationEnum,
        FORM: 'FORM' as ApplicationEnum
    };
    export type FormStatusEnum = 'PENDING' | 'ACCEPTED' | 'FINALIZED' | 'LOCKED' | 'EXPIRED' | 'REJECT';
    export const FormStatusEnum = {
        PENDING: 'PENDING' as FormStatusEnum,
        ACCEPTED: 'ACCEPTED' as FormStatusEnum,
        FINALIZED: 'FINALIZED' as FormStatusEnum,
        LOCKED: 'LOCKED' as FormStatusEnum,
        EXPIRED: 'EXPIRED' as FormStatusEnum,
        REJECT: 'REJECT' as FormStatusEnum
    };
    export type EntityTypeEnum = 'N' | 'J';
    export const EntityTypeEnum = {
        N: 'N' as EntityTypeEnum,
        J: 'J' as EntityTypeEnum
    };
}
