import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { ReqcodeModel } from '../../_model/security/reqcode';
import { BreadcrumbService } from '../../breadcrumb.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { LoginService } from '../../_handler/login.service';
import { Router, ActivatedRoute } from '../../../../node_modules/@angular/router';
import { MenusService } from '../../_services';
import { USER_MENUS, PATH_PORTAL } from '../../_shared/var.constant';
import { ApiResponse } from '../../_model/form/apiResponse';
import { delay } from '../../../../node_modules/@types/q';
import { Title } from '@angular/platform-browser';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  constructor(private breadcrumbService: BreadcrumbService, private route: ActivatedRoute, private menuService: MenusService,
    private router: Router,
    private loginService: LoginService) { }
  respMenu: any;
  menucache = [];
  menuuser: any;
  menus = [];
  submenus = [];
  modelToMenu: any[];
  modelCode: ReqcodeModel;
  code: string;
  images: any;
  mobile: any;
  gallerHorizontal: 100;
  gallerVertical: 100;
  dispositivo = 1;
  clickImage = true;
  urlVideo = "";
  urlAcademy = environment.URL_ACADEMY;
  urlMark = "https://marketinglibertyandes.com/login?utm_source=ECU_INT_BANNER&utm_medium=OFICINAENLINEA&utm_campaign=LA_ESPUMA_MIMKT&utm_term=JUN_2023";
  helper = new JwtHelperService();

  ngOnInit() {
    this.urlVideo = this.urlAcademy;
    this.cargarBreadCrum();
    const sendTermsConditions = JSON.parse(sessionStorage.getItem('sendTermsConditions'));
    if (sendTermsConditions && sendTermsConditions == true) {
      this.router.navigate(['/termCondiciones']);
    }
    // this.cargarImagenes();
    this.mobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    setTimeout(() => {
      this.responsive();
      this.cargarImagenes();
    }, 300);
    // this.menuService.intlLatamEcFormSecureGetMenusForUser("bguamanzara@ec.krugercorp.com").subscribe((resp: ApiResponse) => {
    //   var respdata = resp.data == null ? [] : resp.data;
    //   this.processArray(respdata);
    // });

  }


  // async processArray(array) {
  //   var menusEditable = [];
  //   for (const item of array) {
  //     menusEditable.push(await this.consultar(item))
  //   }
  //   let securityMenus = [];
  //   securityMenus = menusEditable.filter(data => data.routerLink != undefined).map(data => "/"+data.routerLink)
  //   console.log("security:", securityMenus)
  //   let result = [];
  //   result = menusEditable.filter(data => data.parentId == null).sort(function (a, b) {
  //     if (a.sort > b.sort) {
  //       return 1;
  //     }
  //     if (a.sort < b.sort) {
  //       return -1;
  //     }
  //     return 0;
  //   });

  //   for (var menu of result) {
  //     let resultB = [];
  //     resultB = menusEditable.filter(submenus => submenus.parentId === menu.id).sort(function (a, b) {
  //       if (a.sort > b.sort) {
  //         return 1;
  //       }
  //       if (a.sort < b.sort) {
  //         return -1;
  //       }
  //       return 0;
  //     });
  //     if (resultB.length > 0) {
  //       menu['items'] = resultB;
  //     }
  //   }
  //   sessionStorage.setItem(USER_MENUS, JSON.stringify(result))
  //   sessionStorage.setItem(PATH_PORTAL, JSON.stringify(securityMenus))
  // }

  // consultar(item) {
  //   return this.menuService.intlLatamEcFormSecureGetSpecificMenu(item).map(resp => {
  //     let menuItem = resp.data[0];
  //     let menu = {
  //       id: menuItem.id,
  //       label: menuItem.description,
  //       icon: menuItem.path,
  //       parentId: menuItem.parentId,
  //       sort: menuItem.sort
  //     };
  //     if (menuItem.url !== undefined && menuItem.url !== '') {
  //       menu['routerLink'] = [menuItem.url];
  //     }
  //     return menu;
  //   }).toPromise();
  // }

  cargarBreadCrum() {
    this.breadcrumbService.setItems([
      { label: 'Inicio', routerLink: ['/home'] },
    ]);
  }
  cargarImagenes() {
    if (this.mobile) {
      this.images = [];
      this.images.push({ source: 'assets/layout/images/bannerOfiLinJulWebMobile1.jpg', alt: 'Banner', title: '' });
      this.images.push({ source: 'assets/layout/images/bannerOfiLinJulWebMobile2.jpg', alt: 'Banner', title: '' });
      this.images.push({ source: 'assets/layout/images/bannerOfiLinJulWebMobile3.jpg', alt: 'Banner', title: '' });
      this.images.push({ source: 'assets/layout/images/bannerOfiLinJulWebMobile4.jpg', alt: 'Banner', title: '' });
      this.images.push({ source: 'assets/layout/images/academiaLibertyMobile.jpg', alt: 'Banner', title: '' });
      this.images.push({ source: 'assets/layout/images/bannerOfiLinMobileRedir.jpg', alt: 'Banner', title: '' });
      this.images.push({ source: 'assets/layout/images/Home-Page-mob-2.jpg', alt: 'Banner' });
    } else {
      this.images = [];
      this.images.push({ source: 'assets/layout/images/bannerOfiLin1JulWeb.jpg', alt: 'Banner', title: '' });
      this.images.push({ source: 'assets/layout/images/bannerOfiLin2JulWebNew.jpg', alt: 'Banner', title: '' });
      this.images.push({ source: 'assets/layout/images/bannerOfiLin3JulWeb.jpg', alt: 'Banner', title: '' });
      this.images.push({ source: 'assets/layout/images/bannerOfiLin4JulWeb.jpg', alt: 'Banner', title: '' });
      this.images.push({ source: 'assets/layout/images/academiaLibertyDesktop.jpg', alt: 'Banner', title: '' });
      this.images.push({ source: 'assets/layout/images/bannerOfiLinDesktopRedir.jpg', alt: 'Banner', title: '' });
      this.images.push({ source: 'assets/layout/images/Home-Page-2.jpg', alt: 'Banner', title: '' });
    }
  }

  changeImage(image) {
    if (image.index == 4 || image.index == 5) {
      setTimeout(() => {
        this.clickImage = true;
        if (image.index == 4) {
          this.urlVideo = this.urlAcademy;
        } else {
          this.urlVideo = this.urlMark;
        }
      }, 600);
    } else {
      this.clickImage = false;
    }
  }

  clickImageBanner() {
    if (this.clickImage) {
      window.open(this.urlVideo, '_blank');
    }
  }

  responsive() {
    if (this.mobile) {
      this.dispositivo = 0;
      const elm4 = <HTMLElement>document.getElementById('contenedorImg');
      elm4.style.width = '100%';
      // elm4.style.marginTop = '-8.2%';



    } else {
      this.dispositivo = 1;
    }

  }
}
