/**
 * intl-latam-ec-milf-linkingform
 * Manage the information of the linking form.
 *
 * OpenAPI spec version: 2.1.0
 * Contact: kruger@kruger.com.ec
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Address } from './address';
import { Catalog } from './catalog';
import { Contact } from './contact';
import { Document } from './document';
import { EntityCountries } from './entityCountries';
import { GeographicLocation } from './geographicLocation';


export interface Person { 
    id?: number;
    firstName: string;
    secondName?: string;
    lastName: string;
    secondLastName?: string;
    document?: Document;
    civilStatus?: Catalog;
    gender?: Person.GenderEnum;
    nationality?: Catalog;
    nationalities?: Array<EntityCountries>;
    birthDate?: Date;
    birthPlace?: GeographicLocation;
    address?: Address;
    phoneNumber?: Contact;
    cellPhoneNumber?: Contact;
    email?: Contact;
}
export namespace Person {
    export type GenderEnum = 'M' | 'F';
    export const GenderEnum = {
        M: 'M' as GenderEnum,
        F: 'F' as GenderEnum
    };
}
