/**
 * intl-latam-ec-portal-documents
 * No description
 *
 * OpenAPI spec version: 1.0.0
 * Contact: you@your-company.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { PersonPolicyContactsModel } from './personPolicyContacts';


export class PersonPolicyModel { 
    firstName?: string;
    lastName?: string;
    secondLastName?: string;
    id?: number;
    secondName?: string;
    contacts?: PersonPolicyContactsModel;

    constructor(){
        this.firstName = null;
        this.lastName = null;
        this.secondLastName = null;
        this.id = null;
        this.secondName = null;
        this.contacts = new PersonPolicyContactsModel();
    }
}
