

export class UserRolModel {
    docType?: string;
    name?: string;
    id?: string;
    rolesId?: Array<string>;
    userId?: string;
    termsUseDate?:String;
    /**
     * 1 active 0 inactive
     */
    userStatus?: number;

    constructor() {
        this.docType = "user_rol";
        this.name = null;
        this.id = null;
        this.rolesId = null;
        this.userId = null;
        this.userStatus = null;
        this.termsUseDate = null;
    }
}