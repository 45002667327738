/**
 * intl-latam-ec-portal-documents
 * No description
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { ContactModel } from './contact';


export class CommercialReferenceContactsModel { 
    phoneNumber?: ContactModel;
     constructor(){
         this.phoneNumber= new ContactModel;
     }
}
