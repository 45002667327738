import { CatalogModel } from './catalog';
import { PersonFormContactsModel } from './personFormContacts';

export class ContratantePersona  {

    firstName: any;
    secondName: any;
    lastName: any;
    secondLastName: any;
    civilStatus: CatalogModel;
    birthDate: any;
    foundContract?: boolean;
    contacts: PersonFormContactsModel;
    gender: any;
    

    constructor(){
        
        this.firstName = null;
        this.secondName = null;
        this.lastName = null;
        this.secondLastName = null;
        this.civilStatus = null;
        this.foundContract = false;
        this.birthDate = new Date();
        this.civilStatus = new CatalogModel();
        this.contacts = new PersonFormContactsModel();

    }

}