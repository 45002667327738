export const ACCOUNT_CONST = {
    TITLE: 'Estados de cuenta',
    SUBTITLE1: 'Descarga el estado de cuenta de ',
    SUBTITLE1_1: 'un solo cliente.',
    SUBTITLE2: 'Genera el estado de cuenta de ',
    SUBTITLE2_1: 'todos tus clientes.',
    DOCUMENTS: [
        { name: 'Descargar excel', flag: 'excel.svg' },
        { name: 'Descargar PDF', flag: 'pdf.svg' },
    ],
    ISWALLETBROKER: 1,
    ISNOTWALLETBROKER: 0,
    MSG: {
        ERR: {
            SUMMARY: 'Lo sentimos',
            SEVERITY: 'error',
        },
        SUC: {
            DETAIL: 'No se ha encontrado el usuario',
            SUMMARY: 'Descarga completa',
            SEVERITY: 'success',
        },
        USER_NOT_FOUND: 'No se ha encontrado el usuario',
        SERVER_ERR: 'Error en el servidor, por favor contáctese con el Administrador',
    },
    REPORTS: {
        PDF: 'data:application/octet-stream;base64,',
        EXCEL: 'data:application/octet-stream;base64,',
        PDF_EXT: '.pdf',
        EXCEL_EXT: '.xlsx',
    }
};
