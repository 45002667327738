import { Observable } from 'rxjs/Observable';

import { Injectable } from '@angular/core';
import { UrlServices } from '../url.services';
import { GenericService, METHODS } from '../services.util';

/**
 * UserService manages our current user
 */
@Injectable()
export class CatalogService {

  urlServices: UrlServices = new UrlServices();


  constructor(public _genericService: GenericService) {
  }

  getCatalogs(name: string, countryId: string, parentId?): Observable<any> {
    let codeParenteId = '';
    if (parentId) {
      codeParenteId = '&parentId=' + parentId;
    }
    return this._genericService.genericCallServices(METHODS.GET,this.urlServices.getUrlCatalogs(name,countryId,codeParenteId), null, null, null, false);
  }

  getCatalogsBasic(name: string, countryId: string, parentId?): Observable<any> {
    let codeParenteId = '';
    if (parentId) {
      codeParenteId = '&parentId=' + parentId;
    }
    return this._genericService.genericCallServices(METHODS.GET,
      `${this.urlServices.domainCatalogs}/${name}/basic?country=${countryId}${codeParenteId}&idToken=52fa1bff326ed4d5e05e7f9f23f1ea50`);
  }

}
