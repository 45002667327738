/**
 * intl-latam-ec-portal-documents
 * No description
 *
 * OpenAPI spec version: 1.0.0
 * Contact: you@your-company.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { CatalogModel } from './catalog';


export class FinancialMovementsProductosModel { 
    /**
     * Tipo de producto.
     */
    type: string;
    currency?: CatalogModel;
    country?: CatalogModel;
    city?: CatalogModel;
    province?: CatalogModel;
    /**
     * Nombre de la entidad.
     */
    entity?: string;
    /**
     * Monto.
     */
    amount?: number;
    /**
     * Identificación o número del producto.
     */
    productId?: string;

    constructor(){
        this.type = null;
        this.currency = null;
        this.country = new CatalogModel();
        this.city = new CatalogModel();
        this.province= new CatalogModel();
        this.entity = null;
        this.amount = null;
        this.productId = null;
    }
}
