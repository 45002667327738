/**
 * intl-latam-ec-portal-documents
 * No description
 *
 * OpenAPI spec version: 1.0.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { PEPAdministratorPersonPepModel } from './pEPAdministratorPersonPep';
import { ShareholderPersonDocumentModel } from './shareholderPersonDocument';


export class PEPAdministratorPersonModel {
    document: ShareholderPersonDocumentModel;
    firstName?: string;
    secondName?: string;
    lastName?: string;
    secondLastName?: string;
    businessName?: string;
    pep?: PEPAdministratorPersonPepModel;
    constructor() {
        this.document = new ShareholderPersonDocumentModel();
        this.pep = new PEPAdministratorPersonPepModel();
    }
}
