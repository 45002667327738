// CORE-ANGULAR
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { CommonModule } from '@angular/common';
// UTILITARIOS.
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
    InputTextModule, DialogModule, DataTableModule, SharedModule, ButtonModule, GrowlModule, DropdownModule,
    MessagesModule, FileUploadModule, ConfirmDialogModule, InputTextareaModule,
    AutoCompleteModule,
    TabViewModule, PanelModule, CalendarModule, CheckboxModule, FieldsetModule,
    RadioButtonModule, MultiSelectModule, BlockUIModule, TabMenuModule, AccordionModule
} from 'primeng/primeng';
import { KeyFilterModule } from 'primeng/keyfilter';
import { InputMaskModule } from 'primeng/inputmask';
// Import ngx-barcode module
import { TableModule } from 'primeng/table';
import { StepsModule } from 'primeng/steps';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
// Formulario

import { NegociosComponent } from './negocios.component';
import { AutosComponent } from './autos/autos.component';
import { HogarComponent } from './hogar/hogar.component';
import { SearchPortalComponent } from './searchPortal/home.component';

@NgModule({
    declarations: [
        NegociosComponent,
        AutosComponent,
        HogarComponent,
        SearchPortalComponent
    ],
    imports: [
        TableModule,
        CommonModule,
        FormsModule,
        HttpModule,
        BrowserAnimationsModule,
        InputTextModule,
        DialogModule,
        DataTableModule,
        SharedModule,
        ButtonModule,
        GrowlModule,
        DropdownModule,
        MessagesModule,
        FileUploadModule,
        ConfirmDialogModule,
        InputTextareaModule,
        TabViewModule,
        PanelModule,
        CalendarModule,
        CheckboxModule,
        FieldsetModule,
        RadioButtonModule,
        KeyFilterModule,
        MultiSelectModule,
        AutoCompleteModule,
        InputMaskModule,
        StepsModule,
        ProgressSpinnerModule,
        BlockUIModule,
        TabMenuModule,
      TabViewModule,
      AccordionModule

    ],
    providers: [
    ],
    bootstrap: [],
    exports: [

    ]

})
export class NegociosModule { }
