/**
 * intl-latam-ec-portal-documents
 * No description
 *
 * OpenAPI spec version: 1.0.0
 * Contact: you@your-company.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
export class DocumentModel {
    /**
     * Nombre de la aplicación desde donde viene el documento.
     */
    application: any;
    /**
     * Fecha ID para ordenamiento.
     */
    dateId: Date;
    /**
     * País del requerimiento.
     */
    country: any;
    /**
     * Tipo de requerimiento Poliza, Formulario
     */
    requestType: any;
    status: any;
    evicertia?: any;
    data: any;

    constructor() {
        this.application = null;
        this.dateId = new Date();
        this.country = null;
        this.requestType = null;
        this.status = null;
        this.evicertia = null;
        this.data = null;
    }
}
