import { Component, OnInit, Output, EventEmitter, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';

import { SelectItem } from 'primeng/primeng';
import { ActivatedRoute } from '@angular/router';
import { MessageService } from 'primeng/components/common/messageservice';
import { Properties } from '../../../../../general/properties';
import { Util } from '../../../../../general/util';
import {
    DocumentModel, FormModel, Company, EconomicInformationModel, PersonFormModel,
    CatalogModel, ContactModel, SpousePersonFormModel, LocationModel, PlaceAddressInformationModel
} from 'src/app/_model/documents/models';
import { FormsService } from 'src/app/_services/api_v1/forms.service';
import { PEPAdministratorPersonModel } from 'src/app/_model/documents/pEPAdministratorPerson';
import { ShareholderPersonModel } from 'src/app/_model/documents/shareholderPerson';
import { CatalogService } from 'src/app/_services/api_v1/catalogs.service';
import { DocumentsService } from 'src/app/_services/api_v1/documents.service';
import { SharedCatalogueService } from '../../../shared-catalogue.service';
import { ComercialInformationModel } from 'src/app/_model/documents/comercialInfo';
import { ShareholdersOfShareholder } from 'src/app/_model/documents/shareholdersOfshareholder';
import { OtherShareholder } from 'src/app/_model/documents/otherShareholder';

@Component({
    selector: 'app-info-financiera-ec',
    templateUrl: './infoFinanciera.html',
    styleUrls: ['./infoFinanciera.css']
})

export class InfoFinancieraComponent implements OnInit {

    @Output() changed = new EventEmitter<boolean>();
    @ViewChild('form') public userFrm: NgForm;

    properties: Properties = new Properties();
    util: Util = new Util();
    es: any = this.properties.es;
    titulo = 'Información Legal y Financiera';
    subtitulo = 'Diligencia los siguientes campos. Conocer tu información financiera es importante para nosotros.';
    // Generales
    pais: string;
    idFormulario: string;
    fullNameLegalRepresentative = null;

    // Modelos
    respuestas: SelectItem[];
    tipoPersona: SelectItem[];
    document: DocumentModel;
    newDocument: DocumentModel;
    form: FormModel;
    company: Company;
    economicInformation: EconomicInformationModel;
    legalRepresentative: PersonFormModel;
    spouseLegalRepresentative: SpousePersonFormModel;
    existAdministratorsPep = false
    // Catalogos
    tipoCiuuSelectItem: any[];
    economicActivitySelectItem: any[];
    tipoDocumentoSelectItem: any[];
    monthlyIcomeSelectItemJ: SelectItem[];
    companyTypeSelectItem: SelectItem[] = [];
    ciiuTypeSelectItem: SelectItem[] = [];
    documentTypeSelectItem: SelectItem[] = [];
    countriesSelectItem: SelectItem[] = [];
    countriesAdressSelectItem: SelectItem[] = [];
    provinceSelectItem: SelectItem[] = [];
    provinceAdressSelectItem: SelectItem[] = [];
    citySelectItem: SelectItem[] = [];
    cityAdressSelectItem: SelectItem[] = [];
    countriesObligationTaxSelectItem: any[] = [];
    civilStatusSelectItem: SelectItem[] = [];
    typeInvestmentySelectItem: SelectItem[] = [];



    selectCountry = true;
    selectAdressCountry = true;
    listCountries: any;
    administrators: Array<PEPAdministratorPersonModel>;
    shareholders: Array<ShareholderPersonModel>;
    comercialInformation: ComercialInformationModel;
    listInputsComercialInformation = [];
    countComercialInformation = 0;
    bndCamposEconomicInfo = false;

    // banderas
    otherCompany = false;
    otherActivity = false;
    gender = this.properties.generos;
    civilStatus = false;
    step: any;
    loading: boolean;

    // variables conteo campos diligenciados
    listSections = [];
    bndValidHeaderCompany = false;
    listInputsCompany = [];
    bndCamposCompany = false;
    countCompany = 0;
    bndValidHeaderEconomic = false;
    bndValidHeaderComercialInfo = false;
    listInputsEconomic = [];
    bndCamposEconomic = false;
    countEconomic = 0;
    bndValidHeaderLegalRepresentative = false;
    listInputsLegalRepresentative = [];
    bndCamposLegalRepresentative = false;
    countLegalRepresentative = 0;
    bndValidHeaderShareholder = false;
    listInputsShareholder = [];
    bndCamposShareholder = false;
    countShareholder = 0;
    bndValidHeaderAdministrator = false;
    listInputsAdministrator = [];
    bndCamposAdministrator = false;
    countAdministrator = 0;
    mobile: any;

    mostrarAddCountry: boolean;
    signoMoneda: string;

    today = new Date();
    yesterday = new Date();
    nextDate = new Date();

    mostrarPaises = false;
    selectedPaises: string[] = [];
    disabledAgregarPaises = false;
    paisesSelectItem: any[];


    menorEdad = false;
    idMonthly: number;

    dateAux = new Date();
    // porcentajeMax: number = 100;
    // porcentajeMin: number = 1;
    verificaPorcentaje: number = 0;
    dateEntry: any;


    copiaListaPaises: any;
    tipoCargoSelectItem: any[];

    countAccionistas: number = 0;
    countAdministradores: number = 0;

    constructor(
        private route: ActivatedRoute,
        private messageService: MessageService,
        public formService: FormsService,
        private catalogService: CatalogService,
        private _documentsService: DocumentsService,
        public _sharedCatalogue: SharedCatalogueService
    ) {
        this.document = new DocumentModel();
        this.newDocument = new DocumentModel();
        this.company = new Company();
        this.economicInformation = new EconomicInformationModel();
        this.legalRepresentative = new PersonFormModel();
        this.spouseLegalRepresentative = new SpousePersonFormModel();
        this.comercialInformation = new ComercialInformationModel();
        this.administrators = [];
        this.shareholders = [];
        this.respuestas = this.properties.respuestas;
        this.tipoPersona = this.properties.tipoPersona;
        this.companyTypeSelectItem.push({ label: this.properties.labelSelecioneCombo, value: '' });
        this.ciiuTypeSelectItem.push({ label: this.properties.labelSelecioneCombo, value: '' });
        this.documentTypeSelectItem.push({ label: this.properties.labelSelecioneCombo, value: '' });
        this.countriesSelectItem.push({ label: this.properties.labelSelecioneCombo, value: '' });
        this.typeInvestmentySelectItem.push({ label: this.properties.labelSelecioneCombo, value: '' },
            { label: 'Nacional', value: 'Nacional' }, { label: 'Extranjera', value: 'Extranjera' });
        this.countriesAdressSelectItem.push({ label: this.properties.labelSelecioneCombo, value: '' });
        this.provinceSelectItem.push({ label: this.properties.labelSelecioneCombo, value: '' });
        this.provinceAdressSelectItem.push({ label: this.properties.labelSelecioneCombo, value: '' });
        this.citySelectItem.push({ label: this.properties.labelSelecioneCombo, value: '' });
        this.cityAdressSelectItem.push({ label: this.properties.labelSelecioneCombo, value: '' });
        this.countriesObligationTaxSelectItem.push({ label: this.properties.labelSelecioneCombo, value: '' });
        this.civilStatusSelectItem.push({ label: this.properties.labelSelecioneCombo, value: '' });
        this.listSections = ['company', 'economic','comercialInfo', 'legalRepresentative', 'shareholder', 'administrator'];
        this.yesterday = new Date(this.today.getTime() - 24 * 60 * 60 * 1000);

    }

    ngOnInit(): void {
        this.mobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
        const id = this.route.snapshot.paramMap.get('idFormulario');
        const country = this.route.snapshot.paramMap.get('idCountry');
        this.pais = country;
        this.idFormulario = id.toString();
        this.getCivilStatus();
        this.signoMoneda = '$';
        this.getCountries();
        this.getDocumentType();
        //Se mueve consultar formulario despues de consultar los paises
        //this.consultFormulario();
        this.getPaises();
        this.getMonthlyIncome();
    }

    verificarEdad() {
        this.dateAux = new Date();
        if (this.legalRepresentative.birthDate) {
            let year = this.legalRepresentative.birthDate.getFullYear();
            if (year == this.dateAux.getFullYear()) {
                this.legalRepresentative.birthDate.setFullYear(1900);
                this.dateAux = this.legalRepresentative.birthDate;
                this.legalRepresentative.birthDate = this.dateAux;
            }
            const datePipe = new Date(this.legalRepresentative.birthDate).getTime();
            if (this.legalRepresentative.birthDate) {
                const timerDiff = Math.abs(Date.now() - datePipe);
                if (timerDiff > 568023793803) {
                    this.menorEdad = false;
                } else {
                    this.menorEdad = true;
                }
            }
        } else {
            this.menorEdad = false;

        }

    }

    ingresosMensFormat() {
        this.economicInformation.monthlyIncome.description = this.util.formatoMoney(this.economicInformation.monthlyIncome.description);
        // }
    }
    otrosIngresosFormat() {
        this.economicInformation.otherIncome = this.util.formatoMoney(this.economicInformation.otherIncome);
    }
    egresosMensFormat() {
        this.economicInformation.monthlyExpenses = this.util.formatoMoney(this.economicInformation.monthlyExpenses);
    }

    totalActivosFormat() {
        this.economicInformation.actives = this.util.formatoMoney(this.economicInformation.actives);
    }
    totalPasivosFormat() {
        this.economicInformation.pasives = this.util.formatoMoney(this.economicInformation.pasives);
    }

    onTabOpen(e) {
        let index = e.index;
        switch (index) {
            case 0:
                this.bndValidHeaderEconomic = true;
                break;
            case 1:
                this.bndValidHeaderComercialInfo = true;
                break;
            case 2:
                this.bndValidHeaderLegalRepresentative = true;
                break;
            case 3:
                this.bndValidHeaderShareholder = true;
                break;
            default:
                break;
        }
    }

    onTabClose(e) {
        this.mobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
        let index = e.index;
        switch (index) { 
            case 0:
                this.bndValidHeaderEconomic = false;
                break;
            case 1:
                this.bndValidHeaderComercialInfo = false;
                break;
            case 2:
                this.bndValidHeaderLegalRepresentative = false;
                break;
            case 3:
                this.bndValidHeaderShareholder = false;
                break;
            default:
                break;
        }
    }

    validacionSection(form: NgForm, listSections: Array<String>) {
        this.countCompany = 0;
        this.listInputsCompany = [];
        this.countEconomic = 0;
        this.listInputsEconomic = [];
        this.countLegalRepresentative = 0;
        this.listInputsLegalRepresentative = [];
        this.countComercialInformation = 0;
        this.listInputsComercialInformation = [];
        const controls = form.controls;
        Object.keys(controls).forEach(input => {
            const nameInput = input.split('_');
            listSections.forEach(section => {
                if (nameInput[1] === section) {
                    switch (section) {
                        case 'company':
                            this.listInputsCompany.push(input);
                            if (form.controls[input].value !== '') {
                                if (form.controls[input].value !== 0) {
                                    if (form.controls[input].value !== null) {
                                        if (form.controls[input].value !== undefined) {
                                            if (form.controls[input].value.id !== null) {
                                                this.countCompany++;
                                            }
                                        }
                                    }
                                }
                            }
                            break;
                        case 'economic':
                            this.listInputsEconomic.push(input);
                            if (form.controls[input].value !== '') {
                                if (form.controls[input].value !== null) {
                                    if (form.controls[input].value !== undefined) {
                                        if (form.controls[input].value.id !== null) {
                                            this.countEconomic++;
                                        }
                                    }
                                }
                            }
                            break;
                        case 'comercialInfo':
                            this.listInputsComercialInformation.push(input);
                            if (form.controls[input].value != '') {
                                if (form.controls[input].value !== null) {
                                    if (form.controls[input].value !== undefined) {
                                        if (form.controls[input].value.id !== null) {
                                            this.countComercialInformation++;
                                        }
                                    }
                                }

                            }
                            break;
                        case 'legalRepresentative':
                            this.listInputsLegalRepresentative.push(input);
                            if (form.controls[input].value !== '') {
                                if (form.controls[input].value !== 0) {
                                    if (form.controls[input].value !== null) {
                                        if (form.controls[input].value !== undefined) {
                                            if (form.controls[input].value.id !== null) {
                                                this.countLegalRepresentative++;
                                            }
                                        }
                                    }
                                }
                            }
                            break;
                        case 'shareholder':
                            this.listInputsShareholder.push(input);
                            if (form.controls[input].status == 'VALID')
                                this.countShareholder++;
                            break;
                        case 'administrator':
                            this.listInputsAdministrator.push(input);
                            if (form.controls[input].status == 'VALID')
                                this.countAdministrator++;
                            break;
                        default:
                            break;
                    }
                }
                if (this.countCompany === this.listInputsCompany.length) {
                    this.bndCamposCompany = true;
                } else {
                    this.bndCamposCompany = false;
                }
                if (this.countEconomic === this.listInputsEconomic.length) {
                    this.bndCamposEconomic = true;
                } else {
                    this.bndCamposEconomic = false;
                }
                if (this.countComercialInformation === this.listInputsComercialInformation.length) {
                    this.bndCamposEconomicInfo = true;
                } else {
                    this.bndCamposEconomicInfo = false;
                }
                if (this.countLegalRepresentative === this.listInputsLegalRepresentative.length) {
                    this.bndCamposLegalRepresentative = true;
                } else {
                    this.bndCamposLegalRepresentative = false;
                }
                if (this.countShareholder === this.listInputsShareholder.length) {
                    this.bndCamposShareholder = true;
                } else {
                    this.bndCamposShareholder = false;
                }
                if (this.countAdministrator === this.listInputsAdministrator.length) {
                    this.bndCamposAdministrator = true;
                } else {
                    this.bndCamposAdministrator = false;
                }
            });
        });
    }

    validateInput(form: NgForm, key) {
        setTimeout(() => {
            switch (key) {
                case 'company':
                    this.countCompany = 0;
                    this.listInputsCompany = [];
                    break;
                case 'economic':
                    this.countEconomic = 0;
                    this.listInputsEconomic = [];
                    break;
                case 'comercialInfo':
                    this.countComercialInformation = 0;
                    this.listInputsComercialInformation = [];
                    break;
                case 'legalRepresentative':
                    this.countLegalRepresentative = 0;
                    this.listInputsLegalRepresentative = [];
                    break;
                case 'shareholder':
                    this.countShareholder = 0;
                    this.listInputsShareholder = [];
                    break;
                case 'administrator':
                    this.countAdministrator = 0;
                    this.listInputsAdministrator = [];
                    break;
                default:
                    break;
            }
            const controls = form.controls;
            Object.keys(controls).forEach(input => {
                const nameInput = input.split('_');
                if (nameInput[1] === key) {
                    switch (key) {
                        case 'company':
                            this.listInputsCompany.push(input);
                            if (form.controls[input].value !== '') {
                                if (form.controls[input].value !== 0) {
                                    if (form.controls[input].value !== null) {
                                        if (form.controls[input].value !== undefined) {
                                            if (form.controls[input].value.id !== null) {
                                                this.countCompany++;
                                            }
                                        }
                                    }
                                }
                            }
                            break;
                        case 'economic':
                            this.listInputsEconomic.push(input);
                            if (form.controls[input].value !== '') {
                                if (form.controls[input].value !== null) {
                                    if (form.controls[input].value !== undefined) {
                                        if (form.controls[input].value.id !== null) {
                                            this.countEconomic++;
                                        }
                                    }
                                }
                            }
                            break;
                        case 'comercialInfo':
                            this.listInputsComercialInformation.push(input);
                            if (form.controls[input].value != '') {
                                if (form.controls[input].value !== null) {
                                    if (form.controls[input].value !== undefined) {
                                        if (form.controls[input].value.id !== null) {
                                            this.countComercialInformation++;
                                        }
                                    }
                                }

                            }
                            break;
                        case 'legalRepresentative':
                            this.listInputsLegalRepresentative.push(input);
                            // console.log('input'+ input, form.controls[input]);
                            if (form.controls[input].value !== '') {
                                if (form.controls[input].value !== 0) {
                                    if (form.controls[input].value !== null) {
                                        if (form.controls[input].value !== undefined) {
                                            if (form.controls[input].value.id !== null) {
                                                if (input === 'cellphone_legalRepresentative' || input === 'phone_legalRepresentative') {
                                                    let lenghInput = null;
                                                    lenghInput = form.controls[input].value.charAt(form.controls[input].value.length - 1);
                                                    if (lenghInput !== '_') {
                                                        this.countLegalRepresentative++;
                                                    }
                                                } else {
                                                    this.countLegalRepresentative++;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                            break;
                        case 'shareholder':
                            this.listInputsShareholder.push(input);
                            if (form.controls[input].status == 'VALID')
                                this.countShareholder++;
                            break;
                        case 'administrator':
                            this.listInputsAdministrator.push(input);
                            if (form.controls[input].status == 'VALID')
                                this.countAdministrator++;
                            break;
                        default:
                            break;
                    }
                }
                if (this.countCompany === this.listInputsCompany.length) {
                    this.bndCamposCompany = true;
                } else {
                    this.bndCamposCompany = false;
                }
                if (this.countEconomic === this.listInputsEconomic.length) {
                    this.bndCamposEconomic = true;
                } else {
                    this.bndCamposEconomic = false;
                }
                if (this.countComercialInformation === this.listInputsComercialInformation.length) {
                    this.bndCamposEconomicInfo = true;
                } else {
                    this.bndCamposEconomicInfo = false;
                }
                if (this.countLegalRepresentative === this.listInputsLegalRepresentative.length) {
                    this.bndCamposLegalRepresentative = true;
                } else {
                    this.bndCamposLegalRepresentative = false;
                }
                if (this.countShareholder === this.listInputsShareholder.length) {
                    this.bndCamposShareholder = true;
                } else {
                    this.bndCamposShareholder = false;
                }
                if (this.countAdministrator === this.listInputsAdministrator.length) {
                    this.bndCamposAdministrator = true;
                } else {
                    this.bndCamposAdministrator = false;
                }
            });
        }, 1000);
    }

    consultFormulario() {
        this.loading = true;
        this._documentsService.getDocumentsByDateIdByCountry(this.idFormulario, this.pais).subscribe((resp: DocumentModel) => {
            this.document = resp;

            if (resp.data === '') {
                this.form = new FormModel();
            }
            this.step = resp.data.step;
            this.existAdministratorsPep = resp.data.existAdministratorsPep;
            if (resp.data.company == null) {
                resp.data.company = new Company()
                this.company = new Company()
            } else {
                this.company = resp.data.company;
            }
            if (resp.data.company.type != null) {
                this.company.type = resp.data.company.type;
            }
            if (resp.data.company.ciuu != null) {
                this.company.ciuu = resp.data.company.ciuu;
            }
            if (resp.data.company.contacts.email != null) {
                this.company.contacts.email = resp.data.company.contacts.email;
            } else {
                this.company.contacts.email = new ContactModel();
            }
            if (resp.data.company.economicActivity != null) {
                this.company.economicActivity = resp.data.company.economicActivity;

            } else {
                this.company.economicActivity = null;
            }
            if (resp.data.economicInformation !== undefined) {
                this.economicInformation = resp.data.economicInformation;
                if (resp.data.economicInformation.monthlyIncome != null) {
                    this.idMonthly = resp.data.economicInformation.monthlyIncome.id;
                    this.economicInformation.monthlyIncome = resp.data.economicInformation.monthlyIncome;
                } else {
                    this.economicInformation.monthlyIncome = new CatalogModel();
                }



            } else {

                resp.data.economicInformation = new EconomicInformationModel();
                this.economicInformation = new EconomicInformationModel();
            }
            if (resp.data.comercialInformation == null) {
                this.comercialInformation = new ComercialInformationModel();
            } else {
                this.comercialInformation.typePoliza = resp.data.comercialInformation.typePoliza;
                this.comercialInformation.sumAsegurada = resp.data.comercialInformation.sumAsegurada;
            }
            if (resp.data.economicInformation.monthlyIncome != null) {
                this.economicInformation.monthlyIncome = resp.data.economicInformation.monthlyIncome;
            } else {
                this.economicInformation.monthlyIncome = new CatalogModel();
            }
            if (this.economicInformation === undefined) {
                this.economicInformation = new EconomicInformationModel();
            }
            if (resp.data.person !== undefined && resp.data.person != null) {
                if (!resp.data.person.birthPlace) {
                    resp.data.person.birthPlace = new LocationModel();
                }
                if (!resp.data.person.placeAddressResidence) {
                    resp.data.person.placeAddressResidence = new PlaceAddressInformationModel();
                }
                if (resp.data.person.countriesTaxObligations != null) {
                    this.listCountries = resp.data.person.countriesTaxObligations;
                    this.disabledAgregarPaises = true;
                    this.mostrarPaises = true;
                }
                this.legalRepresentative = resp.data.person;
                this.legalRepresentative.birthDate = new Date(this.legalRepresentative.birthDate);
            } else {
                this.legalRepresentative = new PersonFormModel();
                this.legalRepresentative.birthDate = null;
            }
            if (resp.data.spouse !== undefined && resp.data.spouse != null) {
                this.spouseLegalRepresentative = resp.data.spouse;
            } else {
                this.spouseLegalRepresentative = new SpousePersonFormModel();
            }

            if (resp.data.shareholders != null) {
                this.shareholders = resp.data.shareholders;
                this.countAccionistas = this.shareholders.length;
            } else {
                this.shareholders = new Array<ShareholderPersonModel>();
                this.countAccionistas = 0;
                this.addShareholder();
            }

            if (resp.data.administrators !== undefined || resp.data.administrators != null) {
                this.administrators = resp.data.administrators;
                this.countAdministradores = this.administrators.length;
            } else {
                this.administrators = [];
                this.countAdministradores = 0;
            }
            if (this.administrators) {
                this.administrators.forEach(administrator => {
                    if (administrator.pep.dateEntry)
                        administrator.pep.dateEntry = new Date(administrator.pep.dateEntry);
                    if (administrator.pep.dateUnlinked)
                        administrator.pep.dateUnlinked = new Date(administrator.pep.dateUnlinked);

                });
            }
            this.form = this.document.data;
            this.validateCivilStatus(this.legalRepresentative.civilStatus.id);
            setTimeout(() => {
                this.loading = true;
                if (this.document.country === this.properties.countryEc || this.document.country === this.properties.countryCo) {

                    if (this.legalRepresentative.birthPlace.country && (this.legalRepresentative.birthPlace.country.id === this.properties.codCatalogCo ||
                        this.legalRepresentative.birthPlace.country.id === this.properties.codCatalogEc)) {
                        this.getProvinceByCountry(this.legalRepresentative.birthPlace.country, this.properties.birth);
                        this.selectCountry = false;
                    }
                    if (this.legalRepresentative.placeAddressResidence.place.country && (this.legalRepresentative.placeAddressResidence.place.country.id === this.properties.codCatalogCo ||
                        this.legalRepresentative.placeAddressResidence.place.country.id === this.properties.codCatalogEc)) {
                        this.getProvinceByCountry(this.legalRepresentative.placeAddressResidence.place.country,
                            this.properties.address);
                        this.selectAdressCountry = false;
                    }
                }
                this.loading = false;
            }, 1000);
            setTimeout(() => {
                this.validacionSection(this.userFrm, this.listSections);
                this.loading = false;
            }, 1000);
            if (this.mobile) {
                for (let index = 0; index < 3; index++) {
                    const elm = <HTMLElement>document.getElementById('countAccordion' + index + 1);
                    elm.style.display = 'none';
                }
                const card = <HTMLElement>document.getElementById('cardIntermediarios');
                card.style.paddingLeft = '10px';
                card.style.paddingRight = '10px';
            }
        },
            (err: any) => {
                this.loading = true;
            });

    }

    verifyForm() {
        let valR = true;
        if (!this.economicInformation.monthlyExpenses.includes('$')) {
            this.economicInformation.monthlyExpenses = null;
            valR = false;
        }
        if (!this.economicInformation.actives.includes('$')) {
            this.economicInformation.actives = null;
            valR = false;
        }
        if (!this.economicInformation.pasives.includes('$')) {
            this.economicInformation.pasives = null;
            valR = false;
        }
        if (this.economicInformation.haveOtherIncome && !this.economicInformation.otherIncome.includes('$')) {
            this.economicInformation.otherIncome = null;
            valR = false;
        }
        return valR
    }

    updateForm(myForm: NgForm) {
        if (!this.verifyForm()) {
            return
        }
        if (myForm.valid) {
            this.loading = true;

            this.validarPorcentaje()
            if (this.verificaPorcentaje == 0) {
                this.prepareForm();
                this._documentsService.updateDocument(this.newDocument).
                    subscribe((resp: any) => {
                        this.messageService.add({ severity: 'success', summary: '', detail: this.properties.msgSuccessForm });
                        this.loading = false;
                        this.changed.emit(true);
                    },
                        (err: any) => {
                            this.messageService.add({ severity: 'error', summary: '', detail: this.properties.msgErrorForm });
                            this.loading = true;
                        },
                        () => {
                        });
            }
            else {
                this.messageService.add({
                    severity: 'error', summary: '', detail: 'Verifique que el total del porcentaje de los accionistas no exceda el  100%'
                });
            }

        } else {
            let count = 0;
            Object.keys(myForm.controls).forEach(element => {
                if (!myForm.controls[element].valid) {
                    count++;
                } else if (myForm.controls[element].value === 0) {
                    count++;
                }
            });
            this.messageService.add({
                severity: 'error', summary: '', detail: 'El formulario aun tiene ' + count +
                    ' Error(es) revise las alertas en cada campo'
            });
        }
    }

    prepareForm() {

        if (this.idMonthly !== undefined && this.idMonthly != null) {

            this.monthlyIcomeSelectItemJ.forEach(description => {
                if (description.value === this.idMonthly) {
                    const model = {
                        description: description.label,
                        id: description.value,
                        code: null
                    };
                    this.economicInformation.monthlyIncome = model;
                }
            });
        }

        //console.log('Esta es la fecha', this.legalRepresentative.birthDate);
        if (this.legalRepresentative.birthDate) {
            let dateX = new Date(1970, 0, 1, 0, 0, 0);
            // let datez = new Date('1970-01-01T00:00:00.000Z');
            let datez = new Date(0);
            let dateY = new Date(this.legalRepresentative.birthDate);
            let day = dateY.getDate();
            let mont = dateY.getMonth();
            let year = dateY.getFullYear();

            // console.log('Esta es la fecha fail', dateX)
            // console.log('Esta es la fecha separada', day, '*', mont, '*', year);

        }

        this.newDocument = this.document;
        this.newDocument.data = this.form;
        this.newDocument.data.company = this.company;
        this.newDocument.data.person = this.legalRepresentative;
        this.newDocument.data.comercialInformation = this.comercialInformation;

        if (this.civilStatus) {
            this.newDocument.data.spouse = this.spouseLegalRepresentative;
        }

        this.newDocument.data.person.countriesTaxObligations = this.listCountries;
        if (this.newDocument.data.existAdministratorsPep && this.newDocument.data.existAdministratorsPep == true) {
            this.newDocument.data.administrators = this.administrators;
        } else {
            this.newDocument.data.administrators = [];
        }
        this.newDocument.data.shareholders = this.shareholders;
        this.newDocument.data.economicInformation = this.economicInformation;
        let paso = 2;
        if (paso <= this.step) {
            paso = this.step;
        } else {
            this.step = paso;
        }
        this.newDocument.data.step = this.step;

    }

    validateCivilStatus(id) {
        if (this.legalRepresentative != null) {
            if (this.legalRepresentative != null) {
                if (this.legalRepresentative.civilStatus != null) {
                    if (id === 2 || id === 3) {
                        this.civilStatus = true;
                    } else {
                        this.civilStatus = false;
                    }
                }
            }
        }
        this.validateInput(this.userFrm, 'legalRepresentative');
    }

    getPaises() {
        this.catalogService.getCatalogs(this.properties.countries, this.pais)
            .subscribe((resp: any) => {
                this.paisesSelectItem = [];
                //this.paisesSelectItem.push({ 'code': '', 'description': this.properties.labelSelecioneCombo, 'id': '' });
                resp.forEach(element => {
                    this.paisesSelectItem.push(element);
                });

            },
                (err: any) => console.log(err),
                () => {
                });
    }

    getMonthlyIncome() {
        this.catalogService.getCatalogs('monthlyIncomeJ', this.pais)
            .subscribe((resp: any) => {
                this.monthlyIcomeSelectItemJ = [];
                this.monthlyIcomeSelectItemJ.push({ label: this.properties.labelSelecioneCombo, value: null });
                resp.forEach(element => {
                    const catalog = {
                        label: element.description,
                        value: element.id
                    };
                    this.monthlyIcomeSelectItemJ.push(catalog);
                });
            },
                (err: any) => console.log(err),
                () => {
                });
    }

    getCivilStatus() {
        this.catalogService.getCatalogs(this.properties.civilStatus, this.pais)
            .subscribe((resp: any) => {
                this.civilStatusSelectItem = [];
                this.civilStatusSelectItem.push({ label: this.properties.labelSelecioneCombo, value: '' });
                resp.forEach(element => {
                    const catalog = {
                        label: element.description,
                        value: element
                    };
                    this.civilStatusSelectItem.push(catalog);
                });
            },
                (err: any) => console.log(err),
                () => {
                });
    }

    validateOtherCompany(event) {
        //console.log(event);

        if (event) {
            if (event.description === 'OTRO') {
                this.otherCompany = true;
            } else {
                this.otherCompany = false;
            }
        }

        this.validateInput(this.userFrm, 'company');
    }

    validateOtherActivity(event) {

        if (event) {
            if (event.label === 'OTRO') {
                this.otherActivity = true;
            } else {
                this.otherActivity = false;
            }
        }

        this.validateInput(this.userFrm, 'company');
    }

    addAdministratorPep() {
        const adminstrator = new PEPAdministratorPersonModel();
        this.administrators.push(adminstrator);
        this.countAdministradores = ++this.countAdministradores;
        this.validateInput(this.userFrm, 'administrator');
    }

    addShareholder() {
        const shareholder = new ShareholderPersonModel();
        this.shareholders.push(shareholder);
        this.countAccionistas = ++this.countAccionistas;
        this.validateInput(this.userFrm, 'shareholder');
    }
    addShareholderOf(index) {
        const shareholder = new ShareholdersOfShareholder();
        this.shareholders[index].shareholders.push(shareholder);
        this.validateInput(this.userFrm, 'shareholder');
    }
    addShareholderOfShareholders(index, indexShareholders) {
        console.log(index, indexShareholders);
        const shareholder = new OtherShareholder();
        this.shareholders[index].shareholders[indexShareholders].shareholders.push(shareholder);
        console.log(this.shareholders[index].shareholders[indexShareholders].shareholders)
        this.validateInput(this.userFrm, 'shareholder');
    }

    addCountry() {
        this.mostrarPaises = true;
        this.validateInput(this.userFrm, 'legalRepresentative');
    }


    getCountries() {
        this.catalogService.getCatalogs(this.properties.countries, this.pais)
            .subscribe((resp: any) => {
                this.countriesSelectItem = [];
                this.countriesAdressSelectItem = [];
                this.countriesSelectItem.push({ label: this.properties.labelSelecioneCombo, value: '' });
                this.countriesAdressSelectItem.push({ label: this.properties.labelSelecioneCombo, value: '' });
                resp.forEach(element => {
                    const catalog = {
                        label: element.description,
                        value: element
                    };
                    this.countriesSelectItem.push(catalog);
                    this.countriesAdressSelectItem.push(catalog);
                });
                this.consultFormulario();
            },
                (err: any) => console.log(err),
                () => {
                });
    }

    getProvinceByCountry(Pais, type) {

        if (Pais) {
            let idPais = Pais.id
            if (idPais === this.properties.codCatalogEc || idPais === this.properties.codCatalogCo) {
                this.catalogService.getCatalogs(this.properties.province, this.pais, idPais)
                    .subscribe((resp: any) => {
                        if (type === this.properties.birth) {
                            this.provinceSelectItem = [];
                            this.provinceSelectItem.push({ label: this.properties.labelSelecioneCombo, value: '' });
                            //this.legalRepresentative.birthPlace.country.description = descriptionCountry;
                            resp.forEach(element => {
                                const catalog = {
                                    label: element.description,
                                    value: element
                                };
                                this.provinceSelectItem.push(catalog);
                            });
                            this.selectCountry = false;
                            if (this.legalRepresentative.birthPlace.province && this.legalRepresentative.birthPlace.province.id) {
                                this.getCityByProvince(this.legalRepresentative.birthPlace.province, type);
                            }
                        } else {
                            this.provinceAdressSelectItem = [];
                            this.provinceAdressSelectItem.push({ label: this.properties.labelSelecioneCombo, value: '' });
                            //this.legalRepresentative.placeAddressResidence.place.country.description = descriptionCountry;
                            resp.forEach(element => {
                                const catalog = {
                                    label: element.description,
                                    value: element
                                };
                                this.provinceAdressSelectItem.push(catalog);
                            });
                            this.selectAdressCountry = false;
                            if (this.legalRepresentative.placeAddressResidence.place.province && this.legalRepresentative.placeAddressResidence.place.province.id) {
                                this.getCityByProvince(this.legalRepresentative.placeAddressResidence.place.province, type);
                            }
                        }
                    },
                        (err: any) => console.log(err),
                        () => {
                        });
            } else {
                if (type === this.properties.birth) {
                    this.selectCountry = true;
                    this.provinceSelectItem = [];
                    this.citySelectItem = [];
                    this.legalRepresentative.birthPlace.province = null
                    this.legalRepresentative.birthPlace.city = null
                    //this.legalRepresentative.birthPlace.country.id = idPais;
                } else {
                    this.selectAdressCountry = true;
                    this.provinceAdressSelectItem = [];
                    this.cityAdressSelectItem = [];
                    this.legalRepresentative.placeAddressResidence.place.province = null
                    this.legalRepresentative.placeAddressResidence.place.city = null
                    //this.legalRepresentative.placeAddressResidence.place.country.id = idPais;
                }
            }
        }

        this.validateInput(this.userFrm, 'legalRepresentative');
    }

    getCityByProvince(Province, type) {
        if (Province)
            this.catalogService.getCatalogs(this.properties.city, this.pais, Province.id).subscribe((resp: any) => {
                let idProvince = Province.id
                if (type === this.properties.birth) {
                    this.citySelectItem = [];
                    this.citySelectItem.push({ label: this.properties.labelSelecioneCombo, value: '' });
                    resp.forEach(element => {
                        const catalog = {
                            label: element.description,
                            value: element
                        };
                        this.citySelectItem.push(catalog);

                    });
                } else {
                    this.cityAdressSelectItem = [];
                    this.cityAdressSelectItem.push({ label: this.properties.labelSelecioneCombo, value: '' });
                    resp.forEach(element => {
                        const catalog = {
                            label: element.description,
                            value: element
                        };
                        this.cityAdressSelectItem.push(catalog);

                    });
                }
            },
                (err: any) => console.log(err),
                () => {
                });
        this.validateInput(this.userFrm, 'legalRepresentative');

    }

    updateDescriptionCity(city, type) {


        this.validateInput(this.userFrm, 'legalRepresentative');
    }

    updateDateRepresentante(event) {
        this.dateAux = new Date();
        if (event) {
            let year = event.getFullYear();
            if (year == this.dateAux.getFullYear()) {
                this.legalRepresentative.birthDate.setFullYear(1900);
                this.dateAux = this.legalRepresentative.birthDate;
                this.legalRepresentative.birthDate = this.dateAux;
                //console.log(this.legalRepresentative.birthDate);
            }
        }
        this.validateInput(this.userFrm, 'legalRepresentative');

    }

    getDocumentType() {
        this.catalogService.getCatalogs(this.properties.documentType, this.pais)
            .subscribe((resp: any) => {
                this.documentTypeSelectItem = [];
                this.documentTypeSelectItem.push({ label: this.properties.labelSelecioneCombo, value: '' });
                resp.forEach(element => {
                    const catalog = {
                        label: element.description,
                        value: element
                    };
                    this.documentTypeSelectItem.push(catalog);
                });
            },
                (err: any) => console.log(err),
                () => {
                });
    }


    eliminarPais(index) {
        this.copiaListaPaises = this.listCountries;
        for (let i = 0; i < this.copiaListaPaises.length; i++) {
            if (i === index) {
                this.copiaListaPaises.splice(i, 1);
                this.listCountries = this.copiaListaPaises;
                //console.log(this.listCountries);

            }
        }

    }


    eliminarAccionista(index) {
        for (let i = 0; i < this.shareholders.length; i++) {
            if (i === index) {
                this.shareholders.splice(i, 1);
            }
        }
        this.validateInput(this.userFrm, 'shareholder');
        this.countAccionistas = this.countAccionistas - 1;
    }

    eliminarAccionistas(data, i) {
        if (data != "J") {
            this.shareholders[i].shareholders = []
        }
    }

    eliminarAccionistasOf(index, indexOf) {
        for (let i = 0; i < this.shareholders[index].shareholders.length; i++) {
            if (i === indexOf) {
                this.shareholders[index].shareholders.splice(i, 1);
            }
        }
        this.validateInput(this.userFrm, 'shareholder');
    }

    eliminarAccionistasOthers(data, i, iOf) {
        if (data != "J") {
            this.shareholders[i].shareholders[iOf].shareholders = []
        }
    }

    eliminarAccionistasOfOthers(index, indexOf, indexOfShare) {

        for (let i = 0; i < this.shareholders[index].shareholders[indexOf].shareholders.length; i++) {
            if (i === indexOfShare) {
                this.shareholders[index].shareholders[indexOf].shareholders.splice(i, 1);
            }
        }
        this.validateInput(this.userFrm, 'shareholder');
    }

    eliminarAdministrador(index) {
        for (let i = 0; i < this.administrators.length; i++) {
            if (i === index) {
                this.administrators.splice(i, 1);
            }
        }
        this.validateInput(this.userFrm, 'administrator');
        if (this.existAdministratorsPep && this.administrators.length == 0) {
            this.existAdministratorsPep = false;
        }
        this.countAdministradores = this.countAdministradores - 1;
    }


    eliminarlistCountries(index) {
        for (let i = 0; i < this.listCountries.length; i++) {
            if (i === index) {
                this.listCountries.splice(i, 1);
            }
        }
    }


    cambioFecha(date) {
        this.nextDate = new Date(date.getTime() + 24 * 60 * 60 * 1000);
    }

    armarPaises(event) {
        this.validateInput(this.userFrm, 'legalRepresentative');
        this.listCountries = event;
    }


    addAdministradores(existAdministratorsPep) {
        if (existAdministratorsPep) {
            if (this.administrators.length == 0) {
                this.addAdministratorPep();
            }
        }
    }



    validarPorcentaje() {

        let total = 0;
        this.verificaPorcentaje = 0;
        this.shareholders.forEach(x => {
            total = Number(x.percentageParticipation) + total;
        })
        if (total > 100 && this.shareholders.length > 1) {
            this.verificaPorcentaje = 1;
        }

    }



}
