/**
 * intl-latam-ec-portal-documents
 * No description
 *
 * OpenAPI spec version: 1.0.0
 * Contact: you@your-company.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { CatalogModel } from './catalog';


export class FinancialMovementsClaimsModel { 
    /**
     * Año
     */
    year?: number;
    branch?: CatalogModel;
    result?: CatalogModel;
    resultDescription;
    /**
     * Compañia reclamo.
     */
    company?: string;
    /**
     * Valor del reclamo.
     */
    value?: number;

    constructor(){
        this.year = null;
        this.branch = null;
        this.result = null;
        this.company = null;
        this.value = null;
    }
}
