import { LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpModule } from '@angular/http';
// import { NgxSoapModule } from 'ngx-soap';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LocationStrategy, HashLocationStrategy, registerLocaleData, PathLocationStrategy, DatePipe } from '@angular/common';
import { AppRoutes } from './app.routes';

import { AccordionModule } from 'primeng/accordion';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { CarouselModule } from 'primeng/carousel';
import { ChartModule } from 'primeng/chart';
import { CheckboxModule } from 'primeng/checkbox';
import { ChipsModule } from 'primeng/chips';
import { CodeHighlighterModule } from 'primeng/codehighlighter';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ColorPickerModule } from 'primeng/colorpicker';
import { ContextMenuModule } from 'primeng/contextmenu';
import { DataViewModule } from 'primeng/dataview';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { EditorModule } from 'primeng/editor';
import { FieldsetModule } from 'primeng/fieldset';
import { FileUploadModule } from 'primeng/fileupload';
import { GalleriaModule } from 'primeng/galleria';
import { GrowlModule } from 'primeng/growl';
import { InplaceModule } from 'primeng/inplace';
import { InputMaskModule } from 'primeng/inputmask';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { LightboxModule } from 'primeng/lightbox';
import { ListboxModule } from 'primeng/listbox';
import { MegaMenuModule } from 'primeng/megamenu';
import { MenuModule } from 'primeng/menu';
import { MenubarModule } from 'primeng/menubar';
import { MessagesModule } from 'primeng/messages';
import { MultiSelectModule } from 'primeng/multiselect';
import { OrderListModule } from 'primeng/orderlist';
import { OrganizationChartModule } from 'primeng/organizationchart';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { PaginatorModule } from 'primeng/paginator';
import { PanelModule } from 'primeng/panel';
import { PanelMenuModule } from 'primeng/panelmenu';
import { PasswordModule } from 'primeng/password';
import { PickListModule } from 'primeng/picklist';
import { ProgressBarModule } from 'primeng/progressbar';
import { RadioButtonModule } from 'primeng/radiobutton';
import { RatingModule } from 'primeng/rating';
import { ScheduleModule } from 'primeng/schedule';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { SelectButtonModule } from 'primeng/selectbutton';
import { SlideMenuModule } from 'primeng/slidemenu';
import { SliderModule } from 'primeng/slider';
import { SpinnerModule } from 'primeng/spinner';
import { SplitButtonModule } from 'primeng/splitbutton';
import { StepsModule } from 'primeng/steps';
import { TabMenuModule } from 'primeng/tabmenu';
import { TableModule } from 'primeng/table';
import { TabViewModule } from 'primeng/tabview';
import { TerminalModule } from 'primeng/terminal';
import { TieredMenuModule } from 'primeng/tieredmenu';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { ToolbarModule } from 'primeng/toolbar';
import { TooltipModule } from 'primeng/tooltip';
import { TreeModule } from 'primeng/tree';
import { TreeTableModule } from 'primeng/treetable';

import { AppComponent } from './app.component';
import { AppRightPanelComponent } from './app.rightpanel.component';
import { AppMenuComponent, AppSubMenuComponent } from './app.menu.component';
import { AppBreadcrumbComponent } from './app.breadcrumb.component';
import { BreadcrumbService } from './breadcrumb.service';
import { MessageService } from 'primeng/components/common/messageservice';
import { ConfirmationService } from 'primeng/api';

//import { BlockUIModule } from 'primeng/blockui';

import { GestionFormularioModule } from './liberty/gestionFormulario/gestionFormulario.module';

// Liberty
import { UsuariosModule } from './liberty/seguridad/usuarios/usuarios.module';
import { GruposModule } from './liberty/seguridad/grupos/grupos.module';
import { ReglasModule } from './liberty/seguridad/reglas/reglas.module';
import { MenusModule } from './liberty/seguridad/menus/menus.module';
import { RolesModule } from './liberty/seguridad/roles/roles.module';
import { SociosModule } from './liberty/seguridad/socios/socios.module';
import { FormularioModule } from './liberty/formulario/formulario.module';
import { RegistroModule } from './liberty/formulario/registro/registro.module';
import { ApiModule, APIS, BASE_PATH } from './_services';
import { PoliciesComponentEc } from './liberty/policies/policiesEc/policies.component';
import { PoliciesComponentCo } from './liberty/policies/policiesCo/policies.component';
import { TermsComponentCo } from './liberty/terms/termsCo/terms.component';
import { TermsComponentEc } from './liberty/terms/termsEc/terms.component';
import { NegociosModule } from './liberty/negocios/negocios.module';
import { HomeComponent } from './liberty/home/home.component';
import { IncentivosModule } from './liberty/mis-negocios/incentivos/incentivos.module';
import { ProgressSpinnerModule } from 'primeng/primeng';
import { PolizaComponent } from './liberty/polizas/poliza.component';
import { PolizaRevisionComponent } from './liberty/polizas/poliza-revision/poliza-revision.component';
import { LandingComponent } from './liberty/lading/landing.component';
import { Not403Component } from './liberty/not403/not403.component';
import { LoginComponent } from './liberty/login/login.component';
import { AuthorizationService } from './_handler/authorization.service';
import { ReportePolizasComponent } from './liberty/reportes/reporte-polizas/reporte-polizas.component';
import { TermCondicionesComponent } from './liberty/terminosCondiciones/termCondiciones.component';
import { SolicitudesModule } from './liberty/solicitudes/solicitudes.module';
import { CuentaModule } from './liberty/cuenta/cuenta.module';
// Control
import { TramitesComponent } from './liberty/tramites/tramites.component';
import { PolizaReviewComponent } from './liberty/tramites/poliza-review/poliza-review.component';
import { FormularioRevisionComponent } from './liberty/tramites/formulario-revision/formulario-revision.component';
import { CapitalizadoPipe } from './pipes/capitalizado.pipe';
import { BlockUIModule } from 'ng-block-ui';
import { ReporteFormularioComponent } from './liberty/reportes/reporte-formulario/reporte-formulario.component';
import { SolicitudesEndComponent } from './liberty/solicitudesEnd/solicitudesEnd.component';
import { BpmModule } from './liberty/bpm/bpm.module';
import { RenovationsModule } from './liberty/renovations/renovations.module';
import { AutoInspectionsModule } from './liberty/autoInspections/autoInspections.module';
import { AppTopBarComponent } from './app.topbar.component';
import { NotificationService } from './notification.service';
import { AcademyModule } from './liberty/academiaLib/academia.module';
import { MisSolicitudesModule } from './liberty/misSolicitudes/mis-solicitudes.module';
import localeEsCo from '@angular/common/locales/es-CO';
import { LoadingComponent } from './liberty/general/loading/loading.component';
import { InspectionsCModule } from './liberty/autoInspections/inspectionsCreated/inspections-c.module';
import { AccesoComponent } from './liberty/formulario/acceso/acceso.component';
import { AccesoComponentEc } from './liberty/formulario/acceso/accesoEc/acceso.component';
import { AccesoComponentCo } from './liberty/formulario/acceso/accesoCo/acceso.component';
import { CotizadorComponent } from './liberty/cotizadorRedirect/cotizador.component';
registerLocaleData(localeEsCo, 'es-Co');


@NgModule({
    imports: [
        BrowserModule,
        FormsModule,
        ReactiveFormsModule,
        AppRoutes,
        HttpClientModule,
        HttpModule,
        BrowserAnimationsModule,
        AccordionModule,
        AutoCompleteModule,
        BreadcrumbModule,
        ButtonModule,
        CalendarModule,
        CarouselModule,
        ChartModule,
        CheckboxModule,
        ChipsModule,
        CodeHighlighterModule,
        ConfirmDialogModule,
        ColorPickerModule,
        ContextMenuModule,
        DataViewModule,
        DialogModule,
        DropdownModule,
        EditorModule,
        FieldsetModule,
        FileUploadModule,
        GalleriaModule,
        GrowlModule,
        InplaceModule,
        InputMaskModule,
        InputSwitchModule,
        InputTextModule,
        InputTextareaModule,
        LightboxModule,
        ListboxModule,
        MegaMenuModule,
        MenuModule,
        MenubarModule,
        MessagesModule,
        MultiSelectModule,
        OrderListModule,
        OrganizationChartModule,
        OverlayPanelModule,
        PaginatorModule,
        PanelModule,
        PanelMenuModule,
        PasswordModule,
        PickListModule,
        ProgressBarModule,
        RadioButtonModule,
        RatingModule,
        ScheduleModule,
        ScrollPanelModule,
        SelectButtonModule,
        SlideMenuModule,
        SliderModule,
        SpinnerModule,
        SplitButtonModule,
        StepsModule,
        TableModule,
        TabMenuModule,
        TabViewModule,
        TerminalModule,
        TieredMenuModule,
        ToggleButtonModule,
        ToolbarModule,
        TooltipModule,
        TreeModule,
        TreeTableModule,
        GestionFormularioModule,
        ApiModule,
        ProgressSpinnerModule,
        // PerfilesModule,
        FormularioModule,
        IncentivosModule,
        NegociosModule,
        UsuariosModule,
        GruposModule,
        ReglasModule,
        MenusModule,
        RolesModule,
        SociosModule,
        FormularioModule,
        GalleriaModule,
        BlockUIModule.forRoot({
            template: LoadingComponent
        }),
        SolicitudesModule,
        CuentaModule, 
        BpmModule,
        AcademyModule,
        MisSolicitudesModule,
        RenovationsModule,
        AutoInspectionsModule,
        InspectionsCModule,
        RegistroModule,
    ],
    declarations: [
        AppComponent,
        AppRightPanelComponent,
        AppMenuComponent,
        AppSubMenuComponent,
        AppBreadcrumbComponent,
        PoliciesComponentEc,
        PoliciesComponentCo,
        TermsComponentCo,
        TermsComponentEc,
        TermCondicionesComponent,
        HomeComponent,
        PolizaComponent,
        PolizaRevisionComponent,
        LandingComponent,
        Not403Component,
        LoginComponent,
        ReportePolizasComponent,
        TramitesComponent,
        PolizaReviewComponent,
        FormularioRevisionComponent,
        CapitalizadoPipe,
        ReporteFormularioComponent,
        SolicitudesEndComponent,
        AppTopBarComponent,
        LoadingComponent,
        AccesoComponent,
        AccesoComponentEc,
        AccesoComponentCo,
        CotizadorComponent
    ],
    entryComponents: [LoadingComponent],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthorizationService,
            multi: true
        },
        APIS,
        { provide: LocationStrategy, useClass: HashLocationStrategy },
        ApiModule,
        { provide: LOCALE_ID, useValue: 'es-Co' },
        BreadcrumbService, MessageService, ConfirmationService, NotificationService, DatePipe
    ],
    bootstrap: [AppComponent],
})
export class AppModule { }