//angular
import { Component, OnInit, ElementRef, AfterViewChecked } from '@angular/core';
//liberty-properties
import { Properties } from '../../../general/properties';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/components/common/messageservice';
import { FormsService } from '../../../../_services';
import { ApiResponse } from '../../../../_services/model-formulario/modelsForm';
import { DocumentsService } from 'src/app/_services/api_v1/documents.service';
import { TOKE_NAME, TOKE_NAME_OAUTH, CURRENT_FORM_OAUTH } from 'src/app/_shared/var.constant';
import { MenuItem } from 'primeng/api';
import { environment } from '../../../../../environments/environment';

@Component({
    selector: 'acceso-co',
    templateUrl: './acceso.html',
    styleUrls: ['../acceso.css']
})

export class AccesoComponentCo implements OnInit, AfterViewChecked {

    properties: Properties = new Properties();
    data: any;
    tituloAcceso: string = 'Para continuar debemos validar tu identidad';
    subTituloAcceso: string = 'Te enviaremos un código de seguridad al siguiente número';
    items: MenuItem[];
    showPass = false;
    // Variables
    loading: boolean;
    bandera: number = 0;
    banderaBloqueado: number = 0;
    numeroAcceso: number;
    numeroIngresado: number = 0;
    pin: string = '';
    displayPinF: boolean = false;
    contadorIntentos: any = null;
    validacion: number = 0;
    cellPhoneNumber: any;
    estadoFormulario: any;
    estadoFormularioAux: any;
    pinRestoreUrl = environment.PIN_RESTORE;
    pinHaventUrl = environment.PIN_HAVENT;
    tokeNameOauth;
    currentFormOauth;
    pinProgress = 0;
    pinTries = 2;
    //General
    pais: any;
    idFormulario: any;

    // Modelos
    nuevoFormulario: any;
    formulario: any;

    // Tipo Persona
    entityType: any;
    maximoIntentos = 3;

    fechaVigencia: any;
    fechaActual = new Date();

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private messageService: MessageService,
        public formService: FormsService,
        private _documentsService: DocumentsService,
        private elem: ElementRef
    ) { }

    ngOnInit(): void {
        let id = this.route.snapshot.paramMap.get('idFormulario');
        let country = this.route.snapshot.paramMap.get('idCountry');
        this.idFormulario = id;
        this.pais = country;
        // this.consultarFormulario(country, id);
        this.getLinkingForm();
        this.items = [
            { label: 'Solicitar Código SMS' },
            { label: 'Ingresa código SMS' },
            { label: 'Ingresa PIN' }
        ];
    }

    openPinUrl(type: number) {
        this.displayPinF = false;
        switch(type) {
            case 0:
                window.open(environment.PIN_HAVENT, '_blank')
            break;
            case 1:
                window.open(environment.PIN_RESTORE, '_blank')
            break;
        }
    }

    keyPressNumbers(event) {
        var charCode = (event.which) ? event.which : event.keyCode;
        // Only Numbers 0-9
        if ((charCode < 48 || charCode > 57)) {
            event.preventDefault();
            return false;
        } else {
            return true;
        }
    }

    changePinView() {
        const pinInput = <HTMLElement>document.getElementById('pinInput');
        if (!this.showPass) {
            pinInput.style['-webkit-text-security'] = 'none';
        } else {
            pinInput.style['-webkit-text-security'] = 'disc';
        }
        this.showPass = !this.showPass;

    }

    ngAfterViewChecked() {
        this.stepsCompleted();
    }

    stepsCompleted() {
        const elm1 = this.elem.nativeElement.querySelectorAll('.ui-steps-item');
        for (let i = 0; i <= this.bandera - 1; i++) {
            elm1[i].classList.remove('ui-state-disabled');
            elm1[i].classList.add('ui-state-highlight');
        }
    }

    getLinkingForm() {
        // let user = JSON.parse(sessionStorage.getItem('access_token'));
        this._documentsService.events(this.idFormulario, this.pais, "GET_PERSON_URL").subscribe((document: any) => {
            if (document && document != null) {
                if (document.status == 'LOCKED' || this.fechaVigencia < this.fechaActual) {
                    this.router.navigate(['/formulario/caducado']);
                } else {
                    if (document.status == 'PENDING') {
                        if (document.data && document.data != null) {
                            this.nuevoFormulario = null;
                            this.formulario = document.data;
                            if (this.formulario.personType === 'J') {
                                this.cellPhoneNumber = this.formulario.company.contacts.cellPhoneNumber.description;
                            } else if (this.formulario.personType === 'N') {
                                this.cellPhoneNumber = this.formulario.person.contacts.cellPhoneNumber.description;
                            }
                            this.estadoFormulario = document.status;
                            if (document.sms.locked) {
                                this.router.navigate(['/formulario/caducado']);
                                this.banderaBloqueado = 1;
                            } else {
                                this.banderaBloqueado = 0;
                            }
                            this.loading = false;
                        } else {
                            this.pais == 'EC' ? this.router.navigate(['/formulario/finalizado']) : this.router.navigate(['/formularioCo/finalizadosCo/CO']);
                        }
                    } else {
                        this.pais == 'EC' ? this.router.navigate(['/formulario/finalizado']) : this.router.navigate(['/formularioCo/finalizadosCo/CO']);
                    }

                }
            }
        }, err => {

            this.loading = true;
        });

    }

    enviarCodigo() {
        this.loading = true;
        this.armarFormularioEnvioSms();
        this._documentsService.events(this.idFormulario, this.pais, 'SUBMIT_SMS_FORM_CODE').subscribe((resp: ApiResponse) => {
            this.bandera = 1;
            this.tituloAcceso = 'Ingrese el código de verificación';
            this.subTituloAcceso = 'Hemos enviado un código de 6 dígitos al número seleccionado';
            //this.numeroAcceso = Number(atob(resp.data[0]));
            this.pinProgress = 50;
            this.loading = false;
        },
            (err: any) => {
                this.messageService.add({ severity: 'error', summary: '', detail: '¡No se actualizó correctamente su número!' });
                this.loading = true;
            });
    }

    pinValidation() {
        let documentNumber;
        let documentType;
        if (this.formulario.personType === 'N') {
            documentNumber = this.formulario.person.document.documentNumber;
            documentType = this.formulario.person.document.type.id;
        } else {
            documentNumber = this.formulario.company.document.documentNumber;
            documentType = this.formulario.company.document.type.id;
        }
        this._documentsService.logingForm(this.idFormulario, this.pais, {pin: this.pin, documentNumber, documentType}).subscribe((resp: any) => {
            if (resp) {
                this.messageService.add({ severity: 'success', summary: 'Exito', detail: '¡Código PIN correcto!' });
                sessionStorage.setItem(TOKE_NAME_OAUTH, this.tokeNameOauth);
                sessionStorage.setItem(CURRENT_FORM_OAUTH, this.currentFormOauth);
                this.irRegistro();
            } else {
                this.pin = "";
                if (this.pinTries < 1) {
                    this.displayPinF = true;
                    this.pinTries = 2;
                } else {
                    this.messageService.add({ severity: 'error', summary: 'Acceso', detail: `Código PIN incorrecto.\nTienes ${this.pinTries} intentos más.` });
                    this.pinTries--;
                }
            }
        })
    }

    validarAcceso() {
        let code = {
            "code": btoa(this.numeroIngresado + "")
        }
        this._documentsService.logingForm(this.idFormulario, this.pais, code).subscribe((resp: any) => {

            if (resp.access_token) {
                this.tokeNameOauth = JSON.stringify(resp);
                this.currentFormOauth = this.idFormulario + "&" + this.pais;
                this.messageService.add({ severity: 'success', summary: 'Exito', detail: '¡Código correcto!' });
                this.tituloAcceso = 'Ingresa tu código PIN';
                this.subTituloAcceso = 'Ingresa, crea o restablece tu PIN de seguridad';
                this.cellPhoneNumber = '';
                this.bandera = 2;
                this.pinProgress = 100;
            } else {
                let intentos = JSON.parse(resp)
                if (intentos && intentos.intentosFallidos) {
                    this.contadorIntentos = this.maximoIntentos - intentos.intentosFallidos
                    this.messageService.add({ severity: 'error', summary: 'Acceso', detail: 'Código incorrecto tienes' + ' ' + this.contadorIntentos + ' ' + 'intentos más' });
                }
            }

        },
            (err: any) => {
                this.router.navigate(['/formulario/caducado']);
                this.banderaBloqueado = 1;
                this.messageService.add({ severity: 'error', summary: '', detail: '¡Ha bloquedo su formulario!' });
                this.loading = true;
            }
        );
    }


    irRegistro() {
        this.router.navigate(['formulario/registro', this.idFormulario, this.pais]);
    }


    bloquearFormulario() {
        this.armarFormulario()
        this.formService.intlLatamEcMilfUpdateLinkingForm(this.idFormulario, this.nuevoFormulario).subscribe((resp: ApiResponse) => {

        },
            (err: any) => {
                this.messageService.add({ severity: 'error', summary: '', detail: '¡No se actualizó correctamente su formulario!' });
            },
            () => {
            });
    }

    armarFormulario() {
        this.estadoFormularioAux = "LOCKED";
        this.formulario.formStatus = this.estadoFormularioAux;
        this.nuevoFormulario = this.formulario
    }

    armarFormularioEnvioSms() {
        //  this.formulario.url.attempt = this.contadorIntentos;
        this.nuevoFormulario = this.formulario;
    }

    guardarIntentos() {
        this.armarIntentos()
        this.formService.intlLatamEcMilfUpdateLinkingForm(this.idFormulario, this.nuevoFormulario).subscribe((resp: ApiResponse) => {
        },
            (err: any) => {
            },
            () => {
            });
    }

    armarIntentos() {
        this.formulario.url.attempt = this.contadorIntentos;
        this.nuevoFormulario = this.formulario
    }

    test(e: any) {
        //console.log("Entro al test: ", e);
    }

}