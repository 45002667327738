import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { Configuration } from './configuration';
import { HttpClient } from '@angular/common/http';


import { CatalogsService } from './api/catalogs.service';
import { NotificationsService } from './api/notifications.service';
import { ReportsService } from './api/reports.service';
import { DefaultService } from './api/default.service';
import { FormsService } from './api_v1/forms.service';
import { FilesService } from './api_v1/files.service';
import { StateAccountService } from './api_v1/state-account.service';

@NgModule({
    imports: [],
    declarations: [],
    exports: [],
    providers: [
        CatalogsService,
        FilesService,
        FormsService,
        NotificationsService,
        ReportsService,
        DefaultService,
        StateAccountService,
    ]
})
export class ApiModule {
    public static forRoot(configurationFactory: () => Configuration): ModuleWithProviders {
        return {
            ngModule: ApiModule,
            providers: [{ provide: Configuration, useFactory: configurationFactory }]
        };
    }

    constructor(@Optional() @SkipSelf() parentModule: ApiModule,
        @Optional() http: HttpClient) {
        if (parentModule) {
            throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
        }
        if (!http) {
            throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
                'See also https://github.com/angular/angular/issues/20575');
        }
    }
}
