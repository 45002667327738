/**
 * intl-latam-ec-portal-documents
 * No description
 *
 * OpenAPI spec version: 1.0.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { CatalogModel } from './catalog';
import { ShareholderPersonDocumentModel } from './shareholderPersonDocument';
import { ShareholdersOfShareholder } from './shareholdersOfshareholder';


export class ShareholderPersonModel {
    document: ShareholderPersonDocumentModel;
    firstName?: string;
    secondName?: string;
    lastName?: string;
    secondLastName?: string;
    businessName?: string;
    capital?:string;
    /**
     * Porcentaje de participación.
     */
    percentageParticipation?: number;
    nameRepresentShareholder?: string;
    nationality?: CatalogModel;
    /**
     * Es PEP, aplica para Colombia.
     */
    isPEP: boolean;
    /**
     * ¿Está obligado a declaración tributaria en otro país o grupo de países?, aplica para Colombia.
     */
    isSubjectTaxObligationsAnotherCountry: boolean;
    typeInvestment?: string;
    /**
     * Especificación de la declaración tributaria, aplica si isSubjectTaxObligationsAnotherCountry es verdadero y es Colombia.
     */
    descriptionTaxDeclaration?: string;

    shareholders?:Array<ShareholdersOfShareholder>;

    constructor() {
        this.document = new ShareholderPersonDocumentModel();
        this.nationality = new CatalogModel();
        this.shareholders = new Array<ShareholdersOfShareholder>();
    }
}
