//Core-angular
import { NgModule } from '@angular/core';
import { InspectionsCComponent } from './inspections-c.component';
import { CommonModule } from '@angular/common';
import { ButtonModule } from 'primeng/primeng';
//Components

@NgModule({
  declarations: [
    InspectionsCComponent
  ],
  imports: [
    CommonModule,
    ButtonModule
  ],
  providers: [
  ],
  bootstrap: [],
  exports: [
    InspectionsCComponent
  ]

})
export class InspectionsCModule { }
