/**
 * intl-latam-ec-portal-documents
 * No description
 *
 * OpenAPI spec version: 1.0.0
 * Contact: you@your-company.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


/**
 * Información del PEP si el campo isPep es verdadero.
 */
export class FormPepModel { 
    /**
     * Cargo para Ecuador, descripción para Colombia.
     */
    charge?: string;
    /**
     * Fecha de ingreso, aplica solo Colombia.
     */
    dateEntry?: string;
    /**
     * Fecha de desvinculación, aplica solo Colombia.
     */
    dateUnlinked?: string;
    /**
     * Función pep, aplica solo Ecuador.
     */
    functionPep?: string;
    /**
     * Nivel jerarquico pep, aplica solo Ecuador.
     */
    hierarchicalLevel?: string;

    constructor(){

        this.charge = null;
        this.dateEntry = null;
        this.dateUnlinked = null;
        this.functionPep = null;
        this.hierarchicalLevel = null;
    }
}
