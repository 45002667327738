import { Component, OnInit } from '@angular/core';
import { Properties } from '../../general/properties';
import { ConfirmationService } from 'primeng/primeng';
import { SelectItem } from 'primeng/primeng';
// Servicios
import { BreadcrumbService } from '../../../breadcrumb.service';
import { MessageService } from 'primeng/components/common/messageservice';
import { GroupsService, UsersService } from '../../../_services';

// Modelos
import { GroupModel } from 'src/app/_model/security/group';
import { GroupMemberModel } from 'src/app/_model/security/groupMember';

//Encriptar
import { CriptoService } from '../../../_handler/cripto.service';

@Component({

    selector: 'socios',
    templateUrl: './socios.html',
    styleUrls: ['./socios.css']
})

export class SociosComponent implements OnInit {

    properties: Properties = new Properties();
    titulo: string;
    subtitulo: string;
    bandera: number = 0;
    cols: any[];
    groups: any;
    loading: boolean = false;
    // Catalogos
    tipoGrupoSelectedItem: SelectItem[] = [{ label: this.properties.labelSelecioneCombo, value: null }];
    // Variables
    socios: any[] = [];
    correo: any;
    nuevoGrupo: GroupModel = new GroupModel();
    grupo: any;
    grupoAux: any;
    groupMember: GroupMemberModel = new GroupMemberModel();
    groupMemberEliminar: GroupMemberModel = new GroupMemberModel();

    // Codificadores
    correoCode: any;
    nuevoGrupoIdCode: any;

    constructor(
        private breadcrumbService: BreadcrumbService,
        private messageService: MessageService,
        public groupsService: GroupsService,
        private confirmationService: ConfirmationService,
        public usersService: UsersService,
        private criptoService: CriptoService

    ) {
    }

    ngOnInit(): void {

        this.cargarBreadCrum();
        this.titulo = 'Gestión de Usuarios Liberty';
        this.subtitulo = 'Gestiona, visualiza Usuarios Liberty de acuerdo a las necesidades de tu negocio';
        this.armarTabla();
        this.chargeGroups();

    }

    cargarBreadCrum() {
        this.breadcrumbService.setItems([
            { label: 'Inicio', routerLink: ['/home'] },
            { label: "Usuarios Liberty", routerLink: ['/socios'] }
        ]);
    }

    chargeGroups() {
        this.groupsService.intlLatamEcFormSecureGetGroups()
            .subscribe((catalogos: any) => {
                catalogos.data.value.forEach(element => {
                    this.tipoGrupoSelectedItem.push({ label: element.description, value: element.id });
                });
            },
                (err: any) => console.log(err),
                () => {
                });
    }


    consultar() {
        this.loading = true;
        this.correoCode = null;
        this.correoCode = this.criptoService.set(this.correo);
        // var encrypted = this.criptoService.other(this.correo);
        // console.log('Encrypted :' + encrypted);

        // var decrypted = this.criptoService.get(encrypted);
        // console.log('Decrypted :' + decrypted);
        // this.correo = encrypted;

        this.usersService.intlLatamEcFormSecureGetUserByEmail(this.correoCode)
            .subscribe((resp: any) => {
                this.socios = [];
                if (resp.data) {
                    if (resp.data.value) {
                        resp.data.value.forEach(element => {
                            this.socios.push(element);
                        });
                    }
                }
                this.loading = false;
            },
                (err: any) => console.log(err),
                () => {
                    this.loading = false;
                });

    }

    armarTabla() {
        this.cols = [
            { field: 'displayName', header: 'Nombre' },
            { field: 'jobTitle', header: 'Cargo' },
            { field: 'officeLocation', header: 'Localización' },
            { field: null, header: 'Acciones' },
        ];

    }

    guardar(form) {
        this.armarGuardar();
        if (this.grupoAux == null) {
            this.loading = true;
            this.groupsService.intlLatamEcFormSecureAddMemberGroup(this.groupMember).subscribe((result: any) => {
                this.messageService.add({ severity: 'success', summary: '', detail: this.properties.msgSuccessGeneral });
                // this.consultar();
                this.cancelar(form);
                this.loading = false;
            },
                (err: any) => {
                    this.messageService.add({ severity: 'error', detail: this.properties.msgErrorGeneral });
                    this.loading = false;
                });
        }
        else {
            // Quitar del grupo
            this.loading = true;
            this.groupMemberEliminar.groupId = this.grupoAux;
            this.groupMemberEliminar.userId = this.nuevoGrupo.id;
            this.groupsService.intlLatamEcFormSecureDeleteMemberGroup(this.groupMemberEliminar)
                .subscribe((resp: any) => {
                    this.loading = true;
                    this.groupsService.intlLatamEcFormSecureAddMemberGroup(this.groupMember).subscribe((result: any) => {
                        this.messageService.add({ severity: 'success', summary: '', detail: this.properties.msgSuccessGeneral });
                        // this.consultar();
                        this.cancelar(form);
                        this.loading = false;
                    },
                        (err: any) => {
                            this.messageService.add({ severity: 'error', detail: this.properties.msgErrorGeneral });
                            this.loading = false;
                        });
                },
                    (err: any) => console.log(err),
                    () => {
                        this.loading = false;
                    });

        }
    }

    cancelar(form: any) {
        if (form != null) {
            form.onReset();
        }
        this.bandera = 0;
        this.titulo = 'Gestión de Usuarios Liberty';
        this.subtitulo = 'Gestiona, visualiza Usuarios Liberty de acuerdo a las necesidades de tu negocio';
        this.correo = null;
        this.nuevoGrupo = new GroupModel();
        this.grupo = null;
        this.socios = [];
    }


    view(rowData) {
        this.bandera = 1;
        this.nuevoGrupo = rowData;
        this.nuevoGrupo.displayName = rowData.displayName;
        this.nuevoGrupo.mailNickname = rowData.mail;
        this.loading = true;
        this.nuevoGrupoIdCode = null;
        this.nuevoGrupoIdCode = this.criptoService.set(this.nuevoGrupo.id);
        this.groupsService.intlLatamEcFormSecureGetGroupMembersByUserId(this.nuevoGrupoIdCode)
            .subscribe((resp: any) => {
                if (resp) {
                    if (resp.data[0]) {
                        this.grupo = resp.data[0].id;
                        this.grupoAux = resp.data[0].id;
                    }
                }
                else {
                    this.grupo = null;
                    this.grupoAux = null;
                }

                this.loading = false;
            },
                (err: any) => console.log(err),
                () => {
                    this.loading = false;
                });

    }

    mostrarEditar() {
        this.bandera = 2;
    }

    armarGuardar() {
        this.groupMember.groupId = this.grupo;
        this.groupMember.userId = this.nuevoGrupo.id;
    }

    quitarGrupo() {
        this.loading = true;
        this.groupMemberEliminar.groupId = this.grupoAux;
        this.groupMemberEliminar.userId = this.nuevoGrupo.id;
        this.groupsService.intlLatamEcFormSecureDeleteMemberGroup(this.groupMemberEliminar)
            .subscribe((resp: any) => {
                this.bandera = 0;
                this.titulo = 'Gestión de Usuarios Liberty';
                this.subtitulo = 'Gestiona, visualiza Usuarios Liberty de acuerdo a las necesidades de tu negocio';
                this.correo = null;
                this.nuevoGrupo = new GroupModel();
                this.grupo = null;
                this.socios = [];
                this.loading = false;

            },
                (err: any) => console.log(err),
                () => {
                    this.loading = false;
                });

    }

    confirmarEliminar() {
        this.properties.confirmarEliminacionMensaje(this.groupMemberEliminar.groupId),
            this.confirmationService.confirm({
                key: "eliminar",
                message: this.properties.labelModalEliminar,
                header: this.properties.tituloConfirmacionEliminacion,
                icon: this.properties.eliminar.icono,
                accept: () => {
                    this.messageService.add({ severity: 'success', summary: '', detail: this.properties.mensajeEliminarExito });
                }
            });
    }

}