import { Component, OnInit } from '@angular/core';
import { Properties } from '../../general/properties';
import { ConfirmationService, SelectItem } from 'primeng/primeng';
// Servicios
import { BreadcrumbService } from '../../../breadcrumb.service';
import { MessageService } from 'primeng/components/common/messageservice';
import { GroupsService, RolesService, RulesService, MenusService, UsersService, ApiResponse } from '../../../_services';

// Modelos
import { RolModel } from '../../../_model/security/rol';
import { Constantes } from '../../../_shared/var.constant';

import { TreeNode } from 'primeng/api';

//Encriptar
import { CriptoService } from '../../../_handler/cripto.service';

@Component({

    selector: 'app-roles',
    templateUrl: './roles.html',
    styleUrls: ['./roles.css']
})

export class RolesComponent implements OnInit {

    properties: Properties = new Properties();
    constantes: Constantes = new Constantes();
    titulo: string;
    subtitulo: string;
    bandera = 0;
    ruleSelectItme: SelectItem[];
    menuSelectItme: SelectItem[];
    cols: any[];
    colsAux: any[];
    groups: any;
    roles: any;
    nuevoRol: RolModel = new RolModel;
    restricted: any;
    rulesInfo = '';
    menuInfo = '';
    user: any;
    userRol: any;
    loading = false;
    superUser = false;

    data: TreeNode[];
    selectedFiles: TreeNode[];
    selectedNodes3: TreeNode[];
    menus: any = [];
    menusCopy: any = [];
    submenus: any[];
    menusSelected: any[];
    idMenusGuardar: any[];

    banderaEdit: boolean = false;

    // Codificadores
    nuevoRolIdCode: any;


    constructor(
        private breadcrumbService: BreadcrumbService,
        private messageService: MessageService,
        public groupsService: GroupsService,
        public rolesService: RolesService,
        private confirmationService: ConfirmationService,
        public rulesService: RulesService,
        public menusService: MenusService,
        public usersServices: UsersService,
        private criptoService: CriptoService
    ) {
    }

    ngOnInit(): void {
        this.cargarBreadCrum();
        const currentUser = JSON.parse(sessionStorage.getItem('access_token'));
        this.userRol = currentUser.user.roles;
        this.consultarRol();
        this.titulo = 'Gestión de Perfiles';
        this.subtitulo = 'Gestiona, visualiza y crea perfiles de acuerdo a las necesidades de tu negocio';
        this.armarTabla();
        this.consultarReglas();
        this.consultarMenus();
        this.consultarAllMenus();
        this.consultar();
        this.colsAux = [
            { field: 'label', header: 'Nombre' }
        ];

    }

    consultarMenus() {
        this.loading = true;
        this.menus = [];
        this.submenus = [];
        // this.data = [
        //     {
        //         "data": {
        //             "label": "Mis negocios",
        //             "id": "mis-negocios",
        //             "icon": "fa fa-briefcase",
        //             "sort": "2",
        //         }
        //     }
        // ]
        //         "children": [
        //             {
        //                 "data": {
        //                     "label": "Trabajo",
        //                     "icon": "55kb",
        //                 }
        //             }
        //         ]
        //     },
        //     {
        //         "data": {
        //             "label": "Imagenes",
        //             "icon": "75kb"
        //         },
        //     }

        // ];
        this.menusService.intlLatamEcFormSecureGetAllMenus()
            .subscribe((resp: any) => {
                this.menuSelectItme = [];
                return resp.data.forEach(resp => {
                        if (resp.status) {
                            if (resp.parentId === null) {
                                let menuPadre = {};
                                menuPadre = {
                                    id: resp.id,
                                    label: resp.description,
                                    icon: resp.path,
                                    sort: resp.sort
                                };
                                this.menus.push({ 'data': menuPadre });
                                this.menus.sort(function (a, b) {
                                    if (a.data.sort > b.data.sort) {
                                        return 1;
                                    }
                                    if (a.data.sort < b.data.sort) {
                                        return -1;
                                    }
                                    // a must be equal to b
                                    return 0;
                                });
                            } else {
                                const menuHijo = { data: {} };
                                menuHijo.data = {
                                    id: resp.id,
                                    label: resp.description,
                                    icon: resp.path,
                                    parent: resp.parentId,
                                    sort: resp.sort
                                };
                                this.submenus.push(menuHijo);
                                this.submenus.sort(function (a, b) {
                                    if (a.data.sort > b.data.sort) {
                                        return 1;
                                    }
                                    if (a.data.sort < b.data.sort) {
                                        return -1;
                                    }
                                    // a must be equal to b
                                    return 0;
                                });
                            }
                            this.menus.forEach(menu => {
                                let result = [];
                                result = this.submenus.filter(submenus => submenus.data.parent == menu['data'].id);
                                if (result.length > 0) {
                                    menu['children'] = {};
                                    menu['children'] = result.sort(function (a, b) {
                                        if (a.data.sort > b.data.sort) {
                                            return 1;
                                        }
                                        if (a.data.sort < b.data.sort) {
                                            return -1;
                                        }
                                        // a must be equal to b
                                        return 0;
                                    });
                                }
                            });
                            //console.log("Este es el munú armado", this.menus);
                            this.data = this.menus;
                        }
                });

            },
                (err: any) => console.log(err),
                () => {
                    this.loading = false;
                });
    }

    consultarRol() {
        this.userRol.forEach(rol => {
            if (rol === this.constantes.rolSuperAdminLiberty) {
                this.superUser = true;
            }
        });
    }

    cargarBreadCrum() {
        this.breadcrumbService.setItems([
            { label: 'Inicio', routerLink: ['/home'] },
            { label: 'Perfiles', routerLink: ['/roles'] }
        ]);
    }

    consultarUsuario() {
        this.usersServices.intlLatamEcFormSecureGetUser(this.nuevoRol.id).subscribe((resp: any) => {
            this.user = [];
            resp.data.forEach(element => {
                this.user.push(element);
            });
        });
    }

    consultar() {
        if (this.superUser === true) {
            this.chargeRoles();
        } else {
            this.chargeRolesPublic();
        }
    }

    chargeRoles() {
        this.loading = true;
        this.rolesService.intlLatamEcFormSecureGetRoles()
            .subscribe((resp: any) => {
                this.roles = [];
                if (resp.data) {
                    resp.data.forEach(element => {
                        element.x = '';
                        this.roles.push(element);
                    });
                }
                this.loading = false;
            },
                (err: any) => console.log(err),
                () => {
                    this.loading = false;
                });

    }
    chargeRolesPublic() {
        this.loading = true;
        this.rolesService.intlLatamEcFormSecureGetPublicRoles()
            .subscribe((resp: any) => {
                this.roles = [];
                if (resp && resp.data) {
                    resp.data.forEach(element => {
                        element.x = '';
                        this.roles.push(element);
                    });
                }
                this.loading = false;
            },
                (err: any) => console.log(err),
                () => {
                    this.loading = false;
                });
    }

    consultarReglas() {
        this.rulesService.intlLatamEcFormSecureGetAllRules()
            .subscribe((resp: any) => {
                this.ruleSelectItme = [];
                if (resp && resp.data) {
                    resp.data.forEach(element => {
                        // element.x = '';
                        this.ruleSelectItme.push({ label: element.name, value: element.id });
                    });
                }

            },
                (err: any) => console.log(err),
                () => {
                });
    }

    consultarAllMenus() {
        this.menusService.intlLatamEcFormSecureGetAllMenus()
            .subscribe((resp: any) => {
                this.menusCopy = [];
                resp.data.forEach(element => {
                    if (element.parentId !== undefined && element.parentId !== null) {
                        this.menusCopy.push({ id: element.id, label: element.description, icon: element.path, parent: element.parentId, sort: element.sort });
                    }
                    else {
                        this.menusCopy.push({ id: element.id, label: element.description, icon: element.path, sort: element.sort });
                    }
                });
                // console.log("***Menus", this.menusCopy);
            },
                (err: any) => console.log(err),
                () => {
                });
    }


    armarTabla() {
        this.cols = [
            { field: 'name', header: 'Nombre' },
            { field: 'rulesId', header: 'Reglas' },
            { field: 'menusId', header: 'Menús' },
            { field: 'x', header: 'Acciones' },
        ];

    }

    nuevo() {
        this.titulo = 'Nuevo Perfil';
        this.bandera = 2;
        this.restricted = false;
        this.banderaEdit = false;
    }

    guardar(form) {
        this.loading = true;
        this.armarGuardar();
        this.obtenerRestringido();
        if (this.nuevoRol.id == null) {
            this.nuevoRol.id = this.nuevoRol.name;
            this.rolesService.intlLatamEcFormSecureCreateRol(this.nuevoRol).subscribe((result: any) => {
                // this.consultar();
                this.messageService.add({ severity: 'success', summary: '', detail: this.properties.msgSuccessSaveGeneral });
                this.consultar();
                this.cancelar();
                this.loading = false;
            },
                (err: any) => {
                    this.messageService.add({ severity: 'error', detail: this.properties.msgErrorGeneral });
                    this.loading = false;
                });
        } else {
            this.rolesService.intlLatamEcFormSecureUpdateRol(this.nuevoRol).subscribe((result: any) => {
                this.messageService.add({ severity: 'success', summary: '', detail: this.properties.msgSuccessGeneral });
                this.consultar();
                this.cancelar();
                this.loading = false;
            },
                (err: any) => {
                    this.messageService.add({ severity: 'error', detail: this.properties.msgErrorGeneral });
                    this.loading = false;
                });
        }

    }
    obtenerRestringido() {
        if (this.restricted === false) {
            this.nuevoRol.restricted = 0;
        } else {
            this.nuevoRol.restricted = 1;
        }

    }
    cancelar() {
        this.bandera = 0;
        this.banderaEdit = false;
        this.nuevoRol = new RolModel();
    }

    view(rowData) {
        this.bandera = 1;
        this.banderaEdit = true;
        this.nuevoRol = rowData;
        if (this.nuevoRol.restricted === 0) {
            this.restricted = false;
        } else {
            this.restricted = true;
        }
        this.rulesInfo = '';
        if (this.nuevoRol.rulesId) {
            for (let i = 0; i < this.nuevoRol.rulesId.length; i++) {
                if (i === this.nuevoRol.rulesId.length - 1) {
                    this.rulesInfo = this.rulesInfo + this.nuevoRol.rulesId[i];
                } else {
                    this.rulesInfo = this.rulesInfo + this.nuevoRol.rulesId[i] + ', ';
                }
            }
        }
        this.menuInfo = '';
        if (this.nuevoRol.menusId) {
            for (let i = 0; i < this.nuevoRol.menusId.length; i++) {
                if (i === this.nuevoRol.menusId.length - 1) {
                    this.menuInfo = this.menuInfo + this.nuevoRol.menusId[i];
                } else {
                    this.menuInfo = this.menuInfo + this.nuevoRol.menusId[i] + ', ';
                }
            }
        }
        this.menusSelected = this.nuevoRol.menusId;
        if (this.menusSelected.length > 0) {
            this.selectedNodes3 = [];
            this.menusSelected.forEach(element => {
                let listSearch = {};
                listSearch = this.menusCopy.find(x => x.id == element);
                this.selectedNodes3.push({ 'data': listSearch });
            });
        }

        // console.log(this.selectedNodes3);

        // this.selectedNodes3 = [
        //     {
        //         "data": {
        //             "label": "Mis negocios",
        //             "id": "mis-negocios",
        //             "icon": "fa fa-briefcase",
        //             "sort": "2",
        //         }
        //     }

        // ]

    }



    mostrarEditar() {
        this.bandera = 2;
        // this.consultarMenus();
    }

    armarGuardar() {
        // this.nuevoRol;
        this.idMenusGuardar = [];
        this.selectedNodes3.forEach(element => {
            this.idMenusGuardar.push(element.data['id']);
            this.nuevoRol.menusId = this.idMenusGuardar;
        });;
        delete this.nuevoRol.x;
        //console.log(this.nuevoRol);

    }

    confirmarEliminar() {
        this.properties.confirmarEliminacionMensaje(this.nuevoRol.name),
            this.confirmationService.confirm({
                key: 'eliminar',
                message: this.properties.labelModalEliminar,
                header: this.properties.tituloConfirmacionEliminacion,
                icon: this.properties.eliminar.icono,
                accept: () => {
                    this.messageService.add({ severity: 'success', summary: '', detail: this.properties.mensajeEliminarExito });
                }
            });
    }

    eliminar() {
        this.loading = true;
        this.nuevoRolIdCode = null;
        this.nuevoRolIdCode = this.criptoService.set(this.nuevoRol.id);
        this.rolesService.intlLatamEcFormSecureDeleteRol(this.nuevoRolIdCode)
            .subscribe((result: any) => {
                this.messageService.add({ severity: 'success', summary: '', detail: this.properties.mensajeEliminarExito });
                this.consultar();
                this.cancelar();
                this.loading = false;

            },
                (err: any) => {
                    this.messageService.add({ severity: 'error', detail: this.properties.mensajeEliminarError });
                    this.loading = false;
                });
    }

    validarDependencias() {
        this.loading = true;
        this.rolesService.intlLatamEcFormSecureGetUsersByRolId(this.nuevoRol.id)
            .subscribe((result: any) => {
                if (result.data && result.data.length >= 1) {
                    this.messageService.add({ severity: 'error', detail: this.properties.mensajeEliminarErrorDependencias });
                    this.loading = false;
                } else {
                    this.eliminar();
                }
                this.loading = false;
            },
                (err: any) => {
                    this.messageService.add({ severity: 'error', detail: this.properties.mensajeEliminarError });
                    this.loading = false;
                });
    }





}
