import { SelectItem } from "primeng/api";

export class Properties {

    es: any;
    colorList: string[];
    respuestas: any;
    tipoFigura: SelectItem[];
    tipoRamo: SelectItem[];
    tipoPersona: any;
    tipoEstado: SelectItem[];
    tipoEstadoUsuario: SelectItem[];

    calendarEs = {
        firstDayOfWeek: 0,
        dayNames: ["Domingo", "Lunes", "Martes", "Miercoles", "Jueves", "Viernes", "Sábado"],
        dayNamesShort: ["Dom", "Lun", "Mar", "Mie", "Jue", "Vie", "Sab"],
        dayNamesMin: ["Do","Lu","Ma","Mi","Ju","Vi","Sa"],
        monthNames: ["Enero","Febrero","Marzo","Abril","Mayo","Junio","Julio","Agosto","Septiembre","Octubre","Noviembre","Diciembre"],
        monthNamesShort: ["Ene", "Feb", "Mar", "Abr", "May", "Jun","Jul", "Ago", "Sep", "Oct", "Nov", "Dic"],
        today: 'Día Actual',
        clear: 'Limpiar'
    };
    generos: any;
    countryEc = 'EC';
    countryCo = 'CO';
    estadosParaGestion = ['pendiente', 'Rejected', 'revisado', 'Expired', 'Failed'];
    estadosFinalizados = ['rejectedb', 'Signed']//, 'Cancelled'];
    estadosParaGestionSinAcciones = ['rejectedb', 'Signed']//, 'Cancelled'];
    monthNames = [];

    vacio: string;
    iconEditar: string;
    iconListar: string;
    iconLink: string;

    labelSelecioneCombo: string;
    messageTypeError = 'error';
    messageTypeSucccess = 'success';
    summaryError = 'Error';
    summarySuccess = 'Exito';
    docUpdate = 'El archivo se actualizó con exito!';
    docCreate = 'El archivo se almacenó con exito!';
    noExistForm = 'No existe el formulario';
    formatNoValidImg = 'El formato del archivo no es valido, solo archivos (.jpeg, jpg, png o .pdf)';
    formatNoValidPdf = 'El formato del archivo no es valido, solo archivos ( .pdf)';
    failedUploadDocument = 'Se produjo un error al subir el archivo, por favor inténtelo nuevamente.';
    // tipo documentos
    docCedula = 'CEDULA';
    docCedula1 = 'CEDULA_REVERSO'
    docCedulaConyuge = 'CEDULA_CONYUGE';
    docCedulaConyuge1 = 'CEDULA_CONYUGE_REVERSO';
    docPapeleta = 'PAPELETA';
    docImpuestoRenta1 = 'IMPUESTO_RENTA_REVERSO';
    docPapeletaConyuge = 'PAPELETA_CONYUGE';
    docServicioBasico = 'SERVICIO_BASICO';
    docImpuestoRenta = 'IMPUESTO_RENTA';
    docForm = 'FORMULARIO';
    // persona juridica
    docformVinculacion = 'FORMULARIO_VINCULACION';
    docRuc = 'RUC';
    docEscritura = 'ESCRITURA';
    docAcuerdoMinisterial = 'ACUERDO_MINISTERIAL';
    docCertNombRepLegal = 'NOMBRAMIENTO';
    docNomina = 'NOMINA';
    docCertificadoCumplimiento = 'CERTIFICADO_CUMPLIMIENTO';
    docEstadosFinancieros = 'ESTADOS_FINANCIEROS';
    docIdentificacionRepresentanteLegal = 'DOC_REPRESENTANTE_LEGAL';
    docIdentificacionConyuge = 'DOC_CONYUGE';
    docPagoImpuetoRenta = 'PAGO_IMPUESTO_RENTA';
    //docPagoImpuetoRenta1 = 'PAGO_IMPUESTO_RENTA_REVERSO';



    // idSociedadAnonima = 0;
    // idOng = 1;
    // idCialtda = 2;
    // idFundacion = 3;
    // idFideicomiso = 4;
    // idConsorcio = 5;
    idSociadesAnonimasCompañiasLtda = 1;
    idFundacionSinLucro = 2;
    idFideicomiso = 3;



    // catalog
    select = '--Seleccione--';
    documentType = 'documentType';
    civilStatus = 'civilStatus';
    countries = 'countries';
    province = 'province';
    birth = 'birth';
    city = 'city';
    companType = 'companyType';
    address = 'address';
    ciuu = 'ciuu';
    economicActivityPN = 'economicActivityPN';
    economicActivityPJ = 'economicActivityPJ';
    ramos = 'ramosIncentivos';
    ramosRenovations = 'ramosRenovations';
    expedition = 'expedition';
    charges = 'charges';

    //TiposCompania
    cmpFideicomisos = 'Fideicomisos';
    cmpFundSocSinFD = 'Fundación, Sociedades sin Fines de Lucro, Sociedades de Hecho'; 
    cmpSocAnonCompLimSCVS = 'Sociedades Anónimas o Compañías Limitadas que se encuentran bajo supervisión de la SCVS';

    // form
    msgSuccessForm = '¡Se actualizó correctamente su formulario!';
    msgErrorForm = '¡No se actualizó correctamente su formulario!';
    msgInvalidModel = 'Revise campos obligatorios';
    msgSuccessGeneral = '¡Se actualizó correctamente!';
    msgSuccessSaveGeneral = '¡Se guardó correctamente!';
    msgErrorGeneral = '¡No Se actualizó correctamente!';

    aceptacionTerminos = 'Se aceptaron los términos de uso';

    // paises
    codCatalogCo = 170;
    codCatalogEc = 1;

    tipoIngresoSelectItem: any;

    tipoOperacionSelectItem: any;
    labelModalEliminar: string;
    tituloConfirmacionEliminacion: string;
    mensajeEliminarExito: string;
    mensajeEliminarError: string;
    mensajeEliminarErrorDependencias: string;
    sucursalesReporte: any;
    tipoMovimientoReporte: any;
    fechasReporte: any;
    estadosResportes: any;
    // Botones
    agregar: Boton;
    editar: Boton;
    guardar: Boton;
    eliminar: Boton;
    cancelar: Boton;
    atras: Boton;
    resendInvitation: Boton;

    formatoFecha = 'dd/mm/yy';

    estadosPolizasFiltro: any;
    estadosPolizasEndFiltro: any;

    // Control
    tableProperties = { rows: 20 };
    traduccion = {
        "POLICY": "POLIZA",
        "FORM": "FORMULARIO",
        "PENDING": "PENDIENTE",
        "REVIEWED": "REVISADA",
        "LOCKED": "BLOQUEADA",
        "FAILED": "NOTIFICACIÓN FALLIDA",
        "REJECTED_CLIENT": "RECHAZADA CLIENTE",
        "SIGNED": "FIRMADO",
        "ANNUAL_EXPIRED": "VIGENCIA EXPIRADA",
        "REJECTED_BROKER": "RECHAZADA BROKER",
        "EXPIRED": "EXPIRADA"
    }
    estadosDocumentos = {
        "PENDING": "PENDING",
        "REJECTED_CLIENT": "REJECTED_CLIENT",
        "REVIEWED": "REVIEWED",
        "FAILED": "FAILED",
        "REJECTED_BROKER": "REJECTED_BROKER",
        "SIGNED": "SIGNED",
        "EXPIRED": "EXPIRED",
        "ANNUAL_EXPIRED": "ANNUAL_EXPIRED",
    }

    fechasReporteForm: any;
    fechasReportePolicy: any;
    resultados: any;
    estadosTramitesEndFiltro: ({ label: string; value: number; } | { label: string; value: string; })[];
    // Combos
    constructor() {

        //Fechas en Esp.
        this.es = {
            firstDayOfWeek: 0,
            dayNames: ["Domingo", "Lunes", "Martes", "Miercoles", "Jueves", "Viernes", "Sabado"],
            dayNamesShort: ["Dom", "Lun", "Mar", "Mie", "Jue", "Vie", "Sab"],
            dayNamesMin: ["Do", "Lu", "Ma", "Mi", "Ju", "Vi", "Sa"],
            monthNames: ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"],
            monthNamesShort: ["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"],
            today: 'Hoy',
            clear: 'Limpiar'
        };
        this.colorList = ['#FFD001', '#28A3AF', '#1E1045']

        this.monthNames = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];
        this.agregar = new Boton('Agregar', 'fa fa-fw fa-plus-circle', '', 'Agregar');
        this.editar = new Boton('Editar', 'fa fa-fw fa-pencil', '', 'Editar');
        this.guardar = new Boton('Guardar', 'fa fa-fw fa-save', 'buttonAction', 'Guardar');
        this.eliminar = new Boton('Eliminar', 'fa fa-fw fa-trash', '', 'Eliminar');
        this.cancelar = new Boton('Cancelar', 'fa fa-fw fa-times', '', 'Cancelar');
        this.atras = new Boton('Atrás', 'fa fa-fw fa-arrow-circle-left', '', 'Atrás');
        this.resendInvitation = new Boton('Enviar invitación', 'fa fa-fw fa-email', '', 'Enviar invitación');

        //Botones con ícono
        this.iconEditar = 'fa fa-edit';
        this.iconListar = 'fa fa-list';
        this.iconLink = "fa fa-link"

        // Combos
        this.vacio = '';
        this.labelSelecioneCombo = '--- Seleccione ---';

        // SelectButton
        this.respuestas = [
            { label: 'Si', value: true },
            { label: 'No', value: false },

        ];

        this.resultados = [
            { label: '---Seleccione---', value: null },
            { label: 'Reclamación', value: 'Reclamación' },
            { label: 'Indemnización', value: 'Indemnización' },
        ]

        this.generos = [
            { label: 'Masculino', value: 'M' },
            { label: 'Femenino', value: 'F' },

        ];

        this.tipoFigura = [
            { label: '---Seleccione---', value: null },
            { label: 'Contratante', value: 'CONTRACTOR' },

        ];

        this.tipoRamo = [
            { label: '---Seleccione---', value: null },
            { label: 'Vehículo', value: 1 }
        ];

        this.tipoPersona = [
            { label: 'Natural', value: 'N' },
            { label: 'Juridica', value: 'J' },
        ];

        this.tipoIngresoSelectItem = [
            { label: '---Seleccione---', value: null },
            { label: '0 - 1000', value: 1 },
            { label: '1001 - 2000', value: 2 },
            { label: '2001 - 3000', value: 3 },
            { label: '3001 - 4000', value: 4 },
            { label: '4001 - 100000', value: 5 },
        ]


        this.tipoOperacionSelectItem = [
            { label: '---Seleccione---', value: null },
            { label: 'PAGO DE SERVICIOS', value: { "description": "PAGO DE SERVICIOS", "code": null, "id": 1 } },
            { label: 'GIROS', value: { "description": "GIROS", "code": null, "id": 2 } },
            { label: 'PRESTAMOS', value: { "description": "PRESTAMOS", "code": null, "id": 3 } },
            { label: 'OTRO', value: { "description": "OTRO", "code": null, "id": 4 } },
            { label: 'IMPORTACIONES', value: { "description": "IMPORTACIONES", "code": null, "id": 5 } },
            { label: 'INVERSIONES', value: { "description": "INVERSIONES", "code": null, "id": 6 } },
            { label: 'EXPORTACIONES', value: { "description": "PENEXPORTACIONESSIONADO", "code": null, "id": 7 } },
            { label: 'TRANSFERENCIAS', value: { "description": "TRANSFERENCIAS", "code": null, "id": 8 } },
            { label: 'CUENTAS EN MONEDA EXTRANJERA', value: { "description": "CUENTAS EN MONEDA EXTRANJERA", "code": null, "id": 9 } },
            { label: 'PRODUCTOS FINANCIEROS EN EL EXTERIOR', value: { "description": "PRODUCTOS FINANCIEROS EN EL EXTERIOR", "code": null, "id": 10 } },
        ];

        this.tipoEstado = [
            { label: '---Seleccione---', value: null },
            { label: 'Activo', value: true },
            { label: 'Inactivo', value: false },

        ];

        this.tipoEstadoUsuario = [
            { label: '---Seleccione---', value: null },
            { label: 'Activo', value: 1 },
            { label: 'Inactivo', value: 0 },

        ];

        this.sucursalesReporte = [
            // { value: null, label: 'TODOS' },
            { value: 1, label: 'QUITO' },
            { value: 2, label: 'GUAYAQUIL' },
            { value: 3, label: 'CUENCA' },
            { value: 4, label: 'MANTA' },
            { value: 5, label: 'AGENCIA AMBATO' },
            { value: 6, label: 'AGENCIA STO DOMINGO' },
            { value: 7, label: 'AGENCIA QUITO' },
            { value: 8, label: 'AGENCIA GUAYAQUIL' },
            { value: 9, label: 'AGENCIA CUENCA' },
            { value: 10, label: 'AGENCIA MANTA' },
            { value: 11, label: 'MASIVOS' },
            { value: 12, label: 'REGION CENTRO GENERALES' },
            { value: 13, label: 'REGION COSTA GENERALES' }
        ];

        this.tipoMovimientoReporte = [
            // { value: null, label: 'TODOS' },
            { value: 1, label: 'PÓLIZA ORIGINAL' },
            { value: 2, label: 'ENDOSO ADICIONAL O DEVOLUCIÓN/INCLUSIÓN/EXCLUSIÓN' },
            { value: 3, label: 'ANULACIÓN/CANCELACIÓN DE PÓLIZA' },
            { value: 4, label: 'RENOVACIÓN' },
            { value: 5, label: 'SIN MOVIMIMIENTO DE PRIMA/ENDOSO BENEFICIARIO/ACLARACIONES' },
            { value: 6, label: 'ENDOSO DECLARACIONES' },
            { value: 10, label: 'REHABILITACION' },
            { value: 12, label: 'AJUSTE SOBRE OPERACIONES VENCIDAS' },
            { value: 13, label: 'PLANES DE PAGO' },
            { value: 15, label: 'FACTURACIÓN' },
            { value: 16, label: 'REFACTURACIÓN' },
            { value: 17, label: 'EXTENSIÓN DE COBERTURA' }
            // { value: 22, label: 'CREDITO HOSPITALARIO' },
            // { value: 21, label: 'NO RENOVACION  PANTALLA COMERCIAL' }
        ];

        this.fechasReporte = [
            { label: 'FECHA DE EMISIÓN', value: 'fechaEmision' },
            { label: 'FECHA INICIO VIGENCIA', value: 'fechaInicioVigencia' },
            { label: 'FECHA FIN VIGENCIA', value: 'fechaFinVigencia' }
        ];

        this.fechasReporteForm = [
            { label: 'FECHA CREACIÓN', value: 'CREATION_DATE' }
        ];

        this.fechasReportePolicy = [
            { label: 'FECHA DE EMISIÓN', value: 'EMISSION_DATE' },
            { label: 'FECHA CREACIÓN', value: 'CREATION_DATE' },
            { label: 'FECHA FIN VIGENCIA', value: 'EFFECTIVE_DATE' }
        ];

        this.estadosResportes = [
            { value: 0, label: 'Todos' },
            { label: 'Pendiente', value: 'PENDING' },
            { label: 'Rechazo Cliente', value: 'REJECTED_CLIENT' },
            { label: 'Revisado', value: 'REVIEWED' },
            { label: 'Expirado', value: 'EXPIRED' },
            { label: 'Fallido', value: 'FAILED' },
            { label: 'Rechazado Broker', value: 'REJECTED_BROKER' },
            { label: 'Firmado', value: 'SIGNED' },
            { label: 'Bloqueado', value: 'LOCKED' }
        ];

        this.estadosPolizasFiltro = [
            { value: null, label: 'Todos' },
            { label: 'Pendiente', value: 'PENDING' },
            { label: 'Rechazado Cliente', value: 'REJECTED_CLIENT' },
            { label: 'Revisado', value: 'REVIEWED' },
            { label: 'Expirado', value: 'EXPIRED' },
            { label: 'Fallido', value: 'FAILED' }
        ];

        this.estadosPolizasEndFiltro = [
            { label: 'Todos', value: 0 },
            { label: 'Rechazado Broker', value: 'rejectedb' },
            { label: 'Firmado', value: 'Signed' }
            // { label: 'Cancelado', value: 'Cancelled' }
        ];

        this.estadosTramitesEndFiltro = [
            { label: 'Todos', value: 0 },
            { label: 'Rechazado Broker', value: 'REJECTED_BROKER' },
            { label: 'Firmado', value: 'SIGNED' },
            { label: 'Vigencia Expirada', value: 'ANNUAL_EXPIRED' }
            // { label: 'Cancelado', value: 'Cancelled' }
        ];




        this.tituloConfirmacionEliminacion = 'Confirmación Eliminar';
        this.mensajeEliminarExito = 'Se eliminó correctamente el registro';
        this.mensajeEliminarError = 'Error al eliminar el registro';
        this.mensajeEliminarErrorDependencias = 'El registro tiene dependencias';


    }
    confirmarEliminacionMensaje(parametro: string): string {
        return this.labelModalEliminar = '¿Está seguro que desea eliminar el registro? ';
    }

}

class Boton {
    valor: string;
    icono: string;
    pclass: string;
    ptitulo: string;
    constructor(valor: string, icono: string, pclass: string, ptitulo: string) {
        this.valor = valor;
        this.icono = icono;
        this.pclass = pclass;
        this.ptitulo = ptitulo;

    }
}