
export class Util {

    formatoMoney(elemento) {
        if (elemento !== undefined && elemento != null) {

            elemento = elemento.replace('$', '');
            for (let i = 0; i <= 10; i++) {
                elemento = elemento.replace('.', '');

            }
            for (let i = 0; i <= 30; i++) {
                if (elemento.charAt(0) === '0') {
                    elemento = elemento.replace('0', '');
                }
            }

            //console.log(elemento);
            let valor = elemento;
            // let valorDecimal;
            let agregar00 = true;
            if (valor.indexOf('$') !== -1) {

            } else {
                // se compara para ver si tiene decimales
                if (valor.indexOf('.') !== -1) {
                    // si tiene decimales no se la gregara el .00
                    agregar00 = false;
                    // se separa el valor decimal del valor entero
                    // valorDecimal = valor.substring(valor.indexOf('.'), largo);
                    valor = valor.substring(0, valor.indexOf('.'));

                }
                // se toma el valor del entero
                const largo = valor.length;
                if (largo > 12) {
                    valor = valor.substring(largo - 15, largo - 12) + '.' + valor.substring(largo - 12, largo - 9)
                        + '.' + valor.substring(largo - 9, largo - 6) + '.' + valor.substring(largo - 6, largo - 3)
                        + '.' + valor.substring(largo - 3, largo);
                } else {
                    if (largo > 9) {
                        valor = valor.substring(largo - 12, largo - 9) + '.' + valor.substring(largo - 9, largo - 6)
                            + '.' + valor.substring(largo - 6, largo - 3) + '.' + valor.substring(largo - 3, largo);
                    } else {
                        if (largo > 6) {
                            valor = valor.substring(largo - 9, largo - 6) + '.' + valor.substring(largo - 6, largo - 3)
                                + '.' + valor.substring(largo - 3, largo);
                        } else {
                            if (largo > 3) {
                                valor = valor.substring(largo - 6, largo - 3) + '.' + valor.substring(largo - 3, largo);
                            }
                        }
                    }
                }
                valor = '$' + valor;
            }
            if (valor == "$")
                valor = null
            return valor;
        }

    }

    /**
     * @author atello-barcia
     * Request and returns catalogue
     * @param catalogName catalog's name in Dynamo
     * @param countryCode current countryCode
     * @param catalogService the service that request the catalogue
     * @param firstLabel the first label in the catalogue data
     */
    getCatalogue(catalogName: string, countryCode: string, catalogService: any, firstLabel: string): any[] {
        const catalogueFormatted = [];
        //console.log('requesting catalogue');
        catalogService.getCatalogs(catalogName, countryCode).subscribe(res => {
            if (res.length > 0) {
                catalogueFormatted.push({ label: firstLabel, value: null });
                res.forEach(cat =>
                    catalogueFormatted.push({ label: cat.description, value: cat.code, })
                );
            }
        }, err => console.error(err));
        return catalogueFormatted;
    }

}
