/**
 * intl-latam-ec-bg-mibg-api-link-form-bg
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 2.1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import {
    HttpClient, HttpHeaders, HttpParams,
    HttpResponse, HttpEvent
} from '@angular/common/http';
import { CustomHttpUrlEncodingCodec } from '../encoder';

import { Observable } from 'rxjs/Observable';

import { ApiResponse } from '../model-formulario/apiResponse';

import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';
import { environment } from '../../../environments/environment';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { map } from 'rxjs/operators';

@Injectable()
export class CatalogsService {
    @BlockUI() blockUI: NgBlockUI;
    // protected basePath = 'https://api-dev.liberty.ec/form-testbg';
    protected basePath = environment.URL_API_FORM;
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional() @Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * 
     * @param name 
     * @param countryId 
     * @param parentId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public catalogsNameCountriesCountryIdOPTIONS(name: string, countryId: string, parentId?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public catalogsNameCountriesCountryIdOPTIONS(name: string, countryId: string, parentId?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public catalogsNameCountriesCountryIdOPTIONS(name: string, countryId: string, parentId?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public catalogsNameCountriesCountryIdOPTIONS(name: string, countryId: string, parentId?: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
        this.blockUI.start();
        if (name === null || name === undefined) {
            throw new Error('Required parameter name was null or undefined when calling catalogsNameCountriesCountryIdOPTIONS.');
        }

        if (countryId === null || countryId === undefined) {
            throw new Error('Required parameter countryId was null or undefined when calling catalogsNameCountriesCountryIdOPTIONS.');
        }


        let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (parentId !== undefined && parentId !== null) {
            queryParameters = queryParameters.set('parentId', <any>parentId);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.options<any>(`${this.basePath}/catalogs/${encodeURIComponent(String(name))}/countries/${encodeURIComponent(String(countryId))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map(response => {
                this.blockUI.stop();
                return response
            })
        );
    }

    /**
     * 
     * 
     * @param name 
     * @param countryId 
     * @param parentId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public intlLatamEcMilfGetCatalogs(name: string, countryId: string, parentId?: string, observe?: 'body', reportProgress?: boolean): Observable<ApiResponse>;
    public intlLatamEcMilfGetCatalogs(name: string, countryId: string, parentId?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ApiResponse>>;
    public intlLatamEcMilfGetCatalogs(name: string, countryId: string, parentId?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ApiResponse>>;
    public intlLatamEcMilfGetCatalogs(name: string, countryId: string, parentId?: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
        this.blockUI.start();
        if (name === null || name === undefined) {
            throw new Error('Required parameter name was null or undefined when calling intlLatamEcMilfGetCatalogs.');
        }

        if (countryId === null || countryId === undefined) {
            throw new Error('Required parameter countryId was null or undefined when calling intlLatamEcMilfGetCatalogs.');
        }


        let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (parentId !== undefined && parentId !== null) {
            queryParameters = queryParameters.set('parentId', <any>parentId);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2-jwt-authorizer) required
        // if (this.configuration.apiKeys["Authorization"]) {
        //     headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        // }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<ApiResponse>(`${this.basePath}/catalogs/${encodeURIComponent(String(name))}/countries/${encodeURIComponent(String(countryId))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map(response => {
                this.blockUI.stop();
                return response
            })
        );
    }

}
