import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { MenuItem } from 'primeng/api';
import { MessageService } from 'primeng/components/common/messageservice';
import { AnualValueModel } from 'src/app/_model/form/incentivos';
import { CatalogService } from 'src/app/_services/api_v1/catalogs.service';
import { IncentivosService } from 'src/app/_services/api_v1/incentivos.service';
import { BreadcrumbService } from '../../../breadcrumb.service';
import { Properties } from '../../general/properties';

@Component({
  selector: 'app-incentivos',
  templateUrl: './incentivos.component.html',
  styleUrls: ['./incentivos.component.css']
})
export class IncentivosComponent implements OnInit {

  properties: Properties = new Properties();
  pais: string = 'EC';
  currentMonth: number;
  date: string;
  currentDay: number;
  currentYear: number;
  activeItem: MenuItem;
  anualValue: AnualValueModel;

  constructor(
    private breadcrumbService: BreadcrumbService,
    private incentivosService: IncentivosService,
    private messageService: MessageService,
    private catalogService: CatalogService,
  ) {
  };

  ngOnInit() {
    this.getIncentivosValues();
    this.cargarBreadCrum();
  }

  separeMil(num) {
    const numStr = num.toString();
    const exp = /(\d{1,3})(?=(\d{3})+$)/g;
    return numStr.replace(exp, '$1.')
  }

  getIncentivosValues() {
    const currentMoment = moment();
    const month = (currentMoment.month() + 1).toString();
    const year = currentMoment.year().toString();
    this.incentivosService.getBestByBranch(`${year}0101`).subscribe((val) => {
      val = JSON.parse(val.body);
      const sini = val.siniestralidad;
      const cart = val.cartera;
      this.anualValue = {
        ruc: val.RUC,
        broker: val.Broker,
        anualProduction: this.separeMil(val.produccion_actual.toFixed()),
        increase: val.crecimiento.toFixed(2),
        metaIncrease: this.separeMil(val.meta_crecimiento.toFixed()),
        foulForMeta: this.separeMil(val.falta_para_meta.toFixed()),
        accidents: sini.toFixed(2),
        wallet: cart.toFixed(2),
        prodLastYear: this.separeMil(val.prod_anio_anterior.toFixed()),
        // segment: val.segmento,
        anualCategory: val.categoria_anual,
        accidentsColor: sini >= -999 && sini <= 55? '#BEFBBD': '#FBBAB6',
        walletColor: cart >= -999 && cart <= 0.5? '#BEFBBD': '#FBBAB6'
      };
    }, err => {
      console.log(err);
      this.messageService.clear();
      this.messageService.add({
        severity: 'error', summary: '', detail: "Servicio temporalmente no disponible"
      });
    })
  }

  cargarBreadCrum() {
    this.breadcrumbService.setItems([
      { label: 'Inicio', routerLink: ['/home'] },
      { label: 'Incentivos', routerLink: ['/incentivos'] }
    ]);
  }
}
