import { Observable } from 'rxjs/Observable';
import { HttpParams } from "@angular/common/http";

import { Injectable } from '@angular/core';
import { UrlServices } from '../url.services';
import { GenericService, METHODS } from '../services.util';
import * as moment from 'moment';
import { CriptoService } from 'src/app/_handler/cripto.service';
@Injectable()
export class RenovationsService {

  urlServices: UrlServices = new UrlServices();

  constructor(public _genericService: GenericService, private cryptoService: CriptoService,) {
  }

  getCountRenovations({ endDate = '' }): Observable<any> {
    const { brokerId, url } = this.urlServices.getUrRenovations(null, true);
    if (brokerId) {
      const body = {
        endDate,
        broker: this.cryptoService.set(brokerId)
      };
      return this._genericService.genericCallServices(METHODS.POST, url, body, null, null, false);
    }
  }

  getListRenovations({ branch = '', policy = '', startDate = '', endDate = '', status = '', page = 1, limit = 1000, daysCase = '', download = false, withBlock = true, notify = null }): Observable<any> {
    const { brokerId, url } = this.urlServices.getUrRenovations(download);
    if (brokerId) {
      const body = {
        branch,
        policy,
        endDate,
        startDate,
        status,
        daysCase: notify ? '2,3,4,5' : daysCase,
        broker: this.cryptoService.set(brokerId)
      };
      const query = {
        page: page.toString(),
        limit: limit.toString()
      }
      let newBody = Object.keys(body).filter((k) => body[k] != '').reduce((a, k) => ({ ...a, [k]: body[k] }), {});
      if (newBody['daysCase']) {
        newBody['startDate'] = moment().format('YYYYMMDD');
      }
      const queryParamsString = new HttpParams({ fromObject: query }).toString();
      const finalUrl = download ? `${url}` : `${url}?${queryParamsString}`;
      return this._genericService.genericCallServices(METHODS.POST, finalUrl, newBody, null, null, withBlock);
    }
  }

  getRenovationPDF(body: any): Observable<any> {
    return this._genericService.genericCallServices(METHODS.POST,
      this.urlServices.getPDF(), body);
  }

  getRenovationCertificate(body: any): Observable<any> {
    return this._genericService.genericCallServices(METHODS.POST,
      this.urlServices.getCertificate(), body);
  }

  sendPDFRenovationCertificate(body: any): Observable<any> {
    return this._genericService.genericCallServices(METHODS.POST,
      this.urlServices.sendPDFCertificate(), body);
  }

  callURLBtnRenovar (item: any){
    return this.urlServices.getURLRenovationsBtnRenovar(item);
  }
  
  callURLBtnConsultar (item: any){
    return this.urlServices.getURLRenovationsBtnConsultar(item);
  }
}
