import { TOKE_NAME } from "../_shared/var.constant";
import { HttpClient, HttpHeaders } from "../../../node_modules/@angular/common/http";
import { Router } from "../../../node_modules/@angular/router";
import { Injectable } from "../../../node_modules/@angular/core";
// import * as CryptoJS from '../../../node_modules/crypto-js';
import * as CryptoJS from 'crypto-js';

@Injectable({
    providedIn: 'root'
})

export class CriptoService {

    constructor(private http: HttpClient, private router: Router) {

    }
    cryptoPassword: any = "LibertySegur0s2019";


    set(value) {
        var conversionEncryptOutput = CryptoJS.AES.encrypt(value, this.cryptoPassword);
        return conversionEncryptOutput.toString();
    }

    get(value) {

        var conversionDecryptOutput = CryptoJS.AES.decrypt(value, this.cryptoPassword.trim()).toString(CryptoJS.enc.Utf8);
        return conversionDecryptOutput
    }

    other(value) {
        var utf = CryptoJS.enc.Utf8.parse(value.toString());
        var hex = CryptoJS.enc.Hex.parse(utf.toString());
        var dtk = CryptoJS.AES.encrypt(hex, "LibertySegur0s2019", {
            mode: CryptoJS.mode.CTR,
            padding: CryptoJS.pad.Pkcs7
        });

        return dtk
    }

}