
import { CatalogModel } from './catalog';
import { ShareholderPersonDocumentModel } from './shareholderPersonDocument';


export class BeneficiaryPersonModel {
    document: ShareholderPersonDocumentModel;
    gender: CatalogModel;
    firstName?: string;
    secondName?: string;
    lastName?: string;
    secondLastName?: string;
    businessName?: string;
    percentageParticipation?: number;
    constructor() {
        this.document = new ShareholderPersonDocumentModel();
    }
}
