import { Component, OnInit, Input, OnChanges, Output, EventEmitter } from '@angular/core';
import { MessageService } from 'primeng/components/common/messageservice';
import { DefaultService } from 'src/app/_services';
import { DocumentsService } from 'src/app/_services/api_v1/documents.service';
import { Properties } from '../../general/properties';
import * as FileSaver from 'file-saver';

@Component({
  selector: 'app-poliza-review',
  templateUrl: './poliza-review.component.html',
  styleUrls: ['./poliza-review.component.css']
})
export class PolizaReviewComponent implements OnInit {

  // Parametros

  @Input() dateId: string;
  @Input() country: string;
  @Output() notificarPadre: EventEmitter<any> = new EventEmitter();
  properties: Properties = new Properties();
  monthNames = [];
  // Poliza
  statePolicy: any;
  botonRechazarPoliza: any;
  botonAceptarPoliza: any;
  createDate: any;
  policyNumber: any;
  endorsementNumber: any;
  premium: any;
  movementType: any;
  branch: any;
  branchOffice: any;
  startEffectiveDate: any;
  endEffectiveDate: any;
  commissionPercentage: any;
  names: any;
  email: any;

  motivo: boolean = false;
  emissionDate: any;
  valorComision: number;
  documentNumber: any;
  mostrarRechazar: boolean;
  tituloPopUp: string;

  descripcionRechazo: any;
  mostrarAceptar: boolean;
  correoPersona: any;

  modeleoRechaza: any;
  modeloAcepta: any;
  mensajeRechazo: any;

  keyPolicy: any;

  comisiones: any = [];
  primaAux: number;
  porcentajeAux: number;
  valorComisionAux: number;

  accionesBtn: any;



  constructor(
    private messageService: MessageService,
    public policyService: DefaultService,
    private _documentsService: DocumentsService

  ) {
    this.monthNames = this.properties.monthNames;
  }

  ngOnInit() {
    this.botonRechazarPoliza = 'Rechazar Póliza';
    this.botonAceptarPoliza = 'Aceptar Póliza';
    this.getPolicy();

  }


  volver() {
    this.notificarPadre.emit(true)
  }

  getPolicy() {

    this.comisiones = [];
    this.primaAux = 0;
    this.porcentajeAux = 0;
    this.valorComisionAux = 0;
    this.accionesBtn = false;
    this._documentsService.getDocumentsByDateIdByCountry(this.dateId, this.country).subscribe((document: any) => {
      if (document && document != null) {
        if (document.data && document.data != null) {

          this.statePolicy = document.status;
          const fechaCreateTmp = new Date(document.creationDate);
          this.createDate = fechaCreateTmp.getDate() + ' ' + this.monthNames[fechaCreateTmp.getMonth()] + ' ' + fechaCreateTmp.getFullYear();
          this.policyNumber = document.data.policyNumber;
          this.endorsementNumber = document.data.endorsementNumber;
          this.movementType = document.data.movementType.description;
          this.comisiones = document.data.commissions;
          this.comisiones.forEach(element => {
            this.primaAux = Number(this.primaAux) + Number(element.premium);
            this.porcentajeAux = Number(this.porcentajeAux) + Number(element.commissionPercentage);
            this.valorComisionAux = Number(this.valorComisionAux) + (Number(element.premium) * Number(element.commissionPercentage / 100));
          });
          this.premium = Number(this.primaAux.toFixed(2));
          this.commissionPercentage = this.porcentajeAux;
          this.branch = document.data.branch.description;
          this.branchOffice = document.data.branchOffice.description;
          const fechaInicioTmp = new Date(document.data.startEffectiveDate);
          const fechaFinTmp = new Date(document.data.endEffectiveDate);
          this.startEffectiveDate = fechaInicioTmp.getDate() + ' ' + this.monthNames[fechaInicioTmp.getMonth()] + ' ' + fechaInicioTmp.getFullYear();
          this.endEffectiveDate = fechaFinTmp.getDate() + ' ' + this.monthNames[fechaFinTmp.getMonth()] + ' ' + fechaFinTmp.getFullYear();
          let nombreA = document.data.person.firstName != undefined ? document.data.person.firstName : "";
          let nombreB = document.data.person.secondName != undefined ? document.data.person.secondName : "";
          let apellidoA = document.data.person.lastName != undefined ? document.data.person.lastName : "";
          let apellidoB = document.data.person.secondLastName != undefined ? document.data.person.secondLastName : "";
          this.names = nombreA + ' ' + nombreB + ' ' + apellidoA + ' ' + apellidoB;
          this.email = document.data.person.contacts.email.description;
          const fechaTmp = new Date(document.emissionDate);
          this.emissionDate = fechaTmp.getDate() + ' ' + this.monthNames[fechaTmp.getMonth()] + ' ' + fechaTmp.getFullYear();;
          this.valorComision = Number(this.valorComisionAux.toFixed(2));
          this.documentNumber = document.data.person.document.documentNumber;
          this.correoPersona = this.email;
          this.mensajeRechazo = document.data.reasonRejection != undefined ? document.data.reasonRejection : "";
          this.keyPolicy = document.data.files[0].key;
          if (document.status == "SIGNED" || document.status == "REJECTED_CLIENT" || document.status == "REJECTED_BROKER") {
            this.accionesBtn = true;
          }
          else {
            this.accionesBtn = false;
          }
        }
      }
    }, err => {
      //console.log('Error', err);
    });
  }

  inicializarRechazarPoliza() {
    this.mostrarRechazar = true;
    this.tituloPopUp = 'Rechazar Póliza';
  }

  rechazarPoliza() {
    this.modeleoRechaza = {
      message: this.descripcionRechazo
    }
    this._documentsService.events(this.dateId, this.country, 'REJECT_POLICY', this.modeleoRechaza).subscribe((resp: any) => {
      this.mostrarRechazar = false;
      this.messageService.add({ severity: 'success', summary: 'Exito', detail: '¡Se rechazó correctamente la póliza!' });
      this.getPolicy();

    },
      (err: any) => {
        this.messageService.add({ severity: 'error', summary: '', detail: '¡No se rechazó correctamente la póliza!' });

      });

  }

  descargarFactura() {

    this._documentsService.events(this.dateId, this.country, 'GET_INVOICE', this.modeleoRechaza).subscribe((resp: any) => {
      //console.log(resp);
      const a = document.createElement('a');
      a.href = 'data:application/octet-stream;base64,' + resp.pdf;
      a.download = 'Factura - ' + this.names + '.pdf';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      const b = document.createElement('a');
      b.href = 'data:application/octet-stream;base64,' + resp.xml;
      b.download = 'Factura - ' + this.names + '.xml';
      document.body.appendChild(b);
      b.click();
      document.body.removeChild(b);

    },
      (err: any) => {
        this.messageService.add({ severity: 'error', summary: '', detail: '¡No se ha encontrado el archivo!' });

      });

  }

  verPdf() {
    const keyPolicy = this.keyPolicy;
    if (keyPolicy) {
      let path = this.keyPolicy.split("/");
      this._documentsService.getPolicyPdf(keyPolicy).subscribe((resp: any) => {
        var blob = new Blob([resp], { type: 'application/pdf' });
        FileSaver.saveAs(blob, path[3]);
      },
        (err: any) => {
          this.messageService.add({ severity: 'error', summary: '', detail: '¡No se ha encontrado el archivo!' });
        });
    } else {
      this.messageService.add({ severity: 'error', summary: '', detail: '¡No se ha encontrado el archivo!' });
    }
  }

  fixBinary(bin) {
    var length = bin.length;
    var buf = new ArrayBuffer(length);
    var arr = new Uint8Array(buf);
    for (var i = 0; i < length; i++) {
      arr[i] = bin.charCodeAt(i);
    }
    return buf;
  }

  inicializarAceptarPoliza() {
    this.mostrarAceptar = true;
    this.tituloPopUp = 'Aceptar Póliza';
  }

  aceptarPoliza() {
    this.mostrarAceptar = false;
    this.modeloAcepta = {
      email: this.correoPersona,
      updateEmail: true
    }
    this._documentsService.events(this.dateId, this.country, 'ACCEPT_EVICERTIA', this.modeloAcepta).subscribe((resp: any) => {
      this.mostrarRechazar = false;
      this.messageService.add({ severity: 'success', summary: 'Exito', detail: '¡Se aceptó correctamente la póliza!' });
      this.getPolicy();

    },
      (err: any) => {
        this.messageService.add({ severity: 'error', summary: '', detail: '¡No se aceptó correctamente la póliza!' });

      });
  }
}
