import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-footer',
  styleUrls: ['./app.component.css'],
  templateUrl: './app.footer.component.html'}
)


export class AppFooterComponent implements OnInit {
  mobile: any;
  @Input() origen: number;

  ngOnInit(): void {
    this.mobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  }

  ngAfterViewInit() {
    this.responsive();
  }

  responsive() {
    let elm1;
    if (this.origen == 0) {
    elm1 = <HTMLElement>document.getElementById('footerLibertyEc');
    } else {
    elm1 = <HTMLElement>document.getElementById('footerLibertyCo');
    }
    const elm2 = <HTMLElement>document.getElementById('ftxt1');
    const elm3 = <HTMLElement>document.getElementById('ftxt2');
    if (this.mobile) {
      elm1.style.height = '270px';
      elm2.style.paddingLeft = '25px';
      elm3.style.paddingRight = '25px';
      elm3.style.marginTop = '40px';
    } else {
      elm1.style.height = '190px';
      elm2.style.paddingLeft = '120px';
      elm3.style.paddingRight = '120px';

    }
  }
}
