import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BlockUIModule } from 'primeng/primeng';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { GrowlModule } from 'primeng/growl';
import { CalendarModule } from 'primeng/calendar';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DropdownModule } from 'primeng/dropdown';
import { FieldsetModule } from 'primeng/fieldset';
import { TableModule } from 'primeng/table';
import { GestionFormularioComponent } from './gestionFormulario.component';

import { DialogModule } from 'primeng/primeng';
@NgModule({
  imports: [
    TableModule,
    FieldsetModule,
    CalendarModule,
    FormsModule,
    ReactiveFormsModule,
    GrowlModule,
    BlockUIModule,
    ProgressSpinnerModule,
    CommonModule,
    DropdownModule,
    DialogModule
  ],
  declarations: [GestionFormularioComponent],
  providers: [
  ],
})
export class GestionFormularioModule { }
