// CORE-ANGULAR
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { CommonModule } from '@angular/common';
// Formulario
import {TabMenuModule} from 'primeng/tabmenu';
import {TabViewModule} from 'primeng/tabview';
import {ChartModule} from 'primeng/chart'
import {DropdownModule} from 'primeng/dropdown';
import {TableModule} from 'primeng/table';
import {ScrollPanelModule} from 'primeng/scrollpanel';
import {TooltipModule} from 'primeng/tooltip';
import { ButtonModule } from 'primeng/button';
import { ProgressBarModule } from 'primeng/progressbar';

import { IncentivosComponent } from './incentivos.component';


@NgModule({
    declarations: [
        IncentivosComponent,
    ],
    imports: [
        TabMenuModule,
        TabViewModule,
        ChartModule,
        TooltipModule,
        DropdownModule,
        CommonModule,
        TableModule,
        ProgressBarModule,
        FormsModule,
        ScrollPanelModule,
        ButtonModule,
        HttpModule,
    ],
    providers: [
    ],
    bootstrap: [],
    exports: [

    ]

})
export class IncentivosModule { }
