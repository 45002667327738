/**
 * intl-latam-ec-portal-documents
 * No description
 *
 * OpenAPI spec version: 1.0.0
 * Contact: you@your-company.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { EconomicInformationModel } from './economicInformation';
import { FigurePersonModel } from './figurePerson';
import { FinancialMovementsModel } from './financialMovements';
import { FormUrlModel } from './formUrl';
import { FormVehicleModel } from './formVehicle';
import { PersonFormModel } from './personForm';
import { RelationshipPepPersonModel } from './relationshipPepPerson';
import { SpousePersonFormModel } from './spousePersonForm';
import { WorkModel } from './work';
import { Company } from './company';
import { DeclarationsModel } from './declarations';
import { FormAutomaticDebitInformationModel } from './formAutomaticDebitInformation';
import { ShareholderPersonModel } from './shareholderPerson';
import { PEPAdministratorPersonModel } from './pEPAdministratorPerson';
import { PersonalReferencePersonModel } from './personalReferencePerson';
import { CommercialReferenceModel } from './commercialReference';
import { EvicertiaModel } from './evicertia';
import { BrokerManagerPersonModel } from './brokerManagerPerson';
import { BankReferenceModel } from './bankReference';
import { ModelFileModel } from './modelFile';
import { BeneficiaryPersonModel } from './beneficiaryPerson';
import { ComercialInformationModel } from './comercialInfo';



export class FormModel {
    id?: string;
    /**
     * Número de solicitud.
     */
    requestNumber?: string;
    parentFormId?: string;
    childrenFormsId?: Array<string>;
    riskControlFamily?: boolean;
    submissions?: string;
    step?: number;
    /**
     * Fecha de emisión.
     */
    emissionDate?: Date;
    /**
     * Fecha inicio de vigencia.
     */
    startEffectiveDate: string;
    /**
     * Fecha fin de vigencia.
     */
    endEffectiveDate: string;
    vehicle?: FormVehicleModel;
    /**
     * Suma asegurada.
     */
    sumInsured?: number;
    personType: any;
    person: PersonFormModel;
    spouse?: SpousePersonFormModel;
    economicInformation?: EconomicInformationModel;
    comercialInformation? : ComercialInformationModel;
    work?: WorkModel;
    /**
     * ¿Existe un vínculo entre usted y una Persona Expuesta Políticamente?
     */
    haveRelationshipPep?: boolean;

    haveCoworkRelationshipPep?: boolean;
    /**
     * Relaciones PEP, aplica si el campo haveRelationshipPep es verdadero.
     */
    relationshipPep?: Array<RelationshipPepPersonModel>;
    
    coWorkRelationshipPep?:  Array<RelationshipPepPersonModel>;

    isContractingBeneficiaryInsured: boolean;
    /**
     * Aplica si isContractingBeneficiaryInsured es verdadero.
     */
    figures?: Array<FigurePersonModel>;
    financialMovements?: FinancialMovementsModel;
    /**
     * Archivos del formulario, cedula, papeleta,..etc.
     */
    files: Array<ModelFileModel>;
    company?: Company;
    declarations?: DeclarationsModel;
    isPaymentWithAutomaticDebit?: boolean;
    automaticDebitInformation?: FormAutomaticDebitInformationModel;
    shareholders?: Array<ShareholderPersonModel>;
    /**
     * Existe administradores PEP, aplica solo personas jurídicas.
     */
    existAdministratorsPep?: boolean;
    /**
     * Administradores aplica si existAdministratorsPep es verdadero y personas jurídicas.
     */
    administrators?: Array<PEPAdministratorPersonModel>;
    /**
     * Referencias personales, aplica si sumInsured es mayor a 200.000.
     */
    personalReferences?: Array<PersonalReferencePersonModel>;
    /**
     * Referencias comerciales , aplica si sumInsured es mayor a 200.000.
     */
    commercialReferences?: Array<CommercialReferenceModel>;
    /**
     * Referencias bancarias, aplica si sumInsured es mayor a 200.000.
     */
    bankReferences?: Array<BankReferenceModel>;
    manager: BrokerManagerPersonModel;
    broker: BrokerManagerPersonModel;
    evicertia?: EvicertiaModel;
    url?: FormUrlModel;
    beneficiaries? : Array<BeneficiaryPersonModel>;


    constructor() {
        this.id = null;
        this.childrenFormsId = new Array<string>();
        this.riskControlFamily = false;
        this.submissions = null;
        this.step = null;
        this.startEffectiveDate = null;
        this.endEffectiveDate = null;
        this.vehicle = new FormVehicleModel();
        this.url = new FormUrlModel();
        this.sumInsured = null;
        this.personType = null;
        this.person = new PersonFormModel();
        this.spouse = new SpousePersonFormModel();
        this.economicInformation = new EconomicInformationModel();
        this.comercialInformation = new ComercialInformationModel();
        this.work = new WorkModel();

        this.haveRelationshipPep = false;
        this.haveCoworkRelationshipPep = false;
        this.relationshipPep = new Array<RelationshipPepPersonModel>();
        this.coWorkRelationshipPep = new Array<RelationshipPepPersonModel>();
        this.isContractingBeneficiaryInsured = false;
        this.figures = new Array<FigurePersonModel>();
        this.financialMovements = new FinancialMovementsModel();
        this.declarations = new DeclarationsModel();
        this.automaticDebitInformation = new FormAutomaticDebitInformationModel();
        this.shareholders = new Array<ShareholderPersonModel>();
        this.administrators = new Array<PEPAdministratorPersonModel>();
        this.personalReferences = new Array<PersonalReferencePersonModel>();
        this.commercialReferences = new Array<CommercialReferenceModel>();


        this.bankReferences = new Array<BankReferenceModel>();
        this.manager = new BrokerManagerPersonModel();
        this.broker = new BrokerManagerPersonModel();
        this.evicertia = new EvicertiaModel();
        
        this.company = new Company();
        this.beneficiaries = new Array<BeneficiaryPersonModel>();

    }
}

