import { Comision } from './comision';
import { Contratante } from './contratante';
import { TipoMovimiento } from './tipoMovimiento';
import { Ramo } from './ramo';
import { Sucursal } from './sucursal';
import { PdfPoliza } from './pdfPoliza';
import { FacturaElectronica } from './facturaElectronica';
import { Broker } from './broker'
import { Estado } from './estado';
import { Gestor } from './gestor';

export class Poliza {
  idpv: number;
  numeroPoliza: any;
  numeroEndoso: any;
  tipoMovimiento: TipoMovimiento = new TipoMovimiento();
  ramo: Ramo = new Ramo();
  sucursal: Sucursal = new Sucursal();
  broker: Broker = new Broker();
  gestor: Gestor = new Gestor();
  fechaEmision: any;
  fechaInicioVigencia: any;
  fechaFinVigencia: any;
  comisiones: Array<Comision>;
  contratante: Contratante = new Contratante();
  autorizacionId: string;
  estado: Estado = new Estado();
  uniqueId: string;
  pdf: PdfPoliza;
  prima: number;
  descripcionRechazo: string;
  facturaElectronica: FacturaElectronica = new FacturaElectronica();
  motivoRechazo?: any;
  constructor() {
    this.comisiones = [];
  }

}