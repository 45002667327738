import { Component } from '@angular/core';
import { environment } from '../../../../environments/environment';

@Component({
    selector: 'app-search-portal',
    templateUrl: './home.component.html',
    styleUrls: ['./home.css']
})
export class SearchPortalComponent {
    urlSearch = environment.URL_SEARCH_PORTAL;
    
    redirec() {
        window.location.assign(this.urlSearch);
    }
}