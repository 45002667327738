import { Pipe, PipeTransform } from '@angular/core';
import { Properties } from '../liberty/general/properties';

@Pipe({
    name: 'capitalizado'
})
export class CapitalizadoPipe implements PipeTransform {
    properties: Properties = new Properties();

    transform(value: string, args: any[]): string {
        let nombreDoc;
        let nameFile: any;
        if (value != null && value !== undefined) {
            nameFile = value.split('.');
            nameFile = nameFile[0].split('-');
            switch (nameFile[0]) {
                // Persona Natural
                case this.properties.docCedula:
                    nombreDoc = 'Cédula';
                    break;
                case this.properties.docCedula1:
                    nombreDoc = 'Reverso Cédula';
                    break;
                case this.properties.docCedulaConyuge:
                    nombreDoc = 'Cédula Conyuge';
                    break;
                case this.properties.docCedulaConyuge1:
                    nombreDoc = 'Reveso Cédula Conyuge';
                    break;
                case this.properties.docPapeleta:
                    nombreDoc = 'Papeleta';
                    break;
                case this.properties.docImpuestoRenta1:
                    nombreDoc = 'Reverso Impuesto a la Renta';
                    break;
                case this.properties.docImpuestoRenta:
                    nombreDoc = 'Impuesto a la Renta';
                    break;
                case this.properties.docPapeletaConyuge:
                    nombreDoc = 'Papeleta Conyuge';
                    break;
                case this.properties.docServicioBasico:
                    nombreDoc = 'Servicio Básico';
                    break;
                // Persona Juridica
                case this.properties.docformVinculacion:
                    nombreDoc = 'Formulario de Vinculación';
                    break;
                case this.properties.docRuc:
                    nombreDoc = 'RUC';
                    break;
                case this.properties.docEscritura:
                    nombreDoc = 'Escritura';
                    break;
                case this.properties.docAcuerdoMinisterial:
                    nombreDoc = 'Acuerdo Ministerial';
                    break;
                case this.properties.docCertNombRepLegal:
                    nombreDoc = 'Nombramiento Representate Legal';
                    break;
                case this.properties.docNomina:
                    nombreDoc = 'Nómina';
                    break;
                case this.properties.docCertificadoCumplimiento:
                    nombreDoc = 'Certificado de Cumplimiento';
                    break;
                case this.properties.docEstadosFinancieros:
                    nombreDoc = 'Estado Financieros';
                    break;
                case this.properties.docIdentificacionRepresentanteLegal:
                    nombreDoc = 'Cédula Representate Legal';
                    break;
                case this.properties.docIdentificacionConyuge:
                    nombreDoc = 'Cédula Cónyuge Representante Legal';
                    break;
                case this.properties.docPagoImpuetoRenta:
                    nombreDoc = 'Pago Impuesto a la Renta';
                    break;
                // case this.properties.docPagoImpuetoRenta1:
                //     nombreDoc = 'Reverso Pago Impuesto a la Renta';
                //     break;
                case this.properties.docForm:
                    nombreDoc = 'Formulario Vinculación';
                    break;
                default:
                    nombreDoc = 'Documento';
                    break;
            }
        } else {
            nombreDoc = 'Documento';
        }

        return nombreDoc;
    }
}
