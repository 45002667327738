/**
 * intl-latam-ec-form-secure
 * Api to secure Application
 *
 * OpenAPI spec version: 1.2.1
 * Contact: cgudino@kruger.com.ec
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import {
    HttpClient, HttpHeaders, HttpParams,
    HttpResponse, HttpEvent
} from '@angular/common/http';
import { CustomHttpUrlEncodingCodec } from '../encoder';

import { Observable } from 'rxjs/Observable';

import { Group } from '../model-seguridad/group';
import { GroupMember } from '../model-seguridad/groupMember';
import { ModelApiResponse } from '../model-seguridad/modelApiResponse';

import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';


@Injectable()
export class GroupsService {
    @BlockUI() blockUI: NgBlockUI;
    protected basePath = environment.URL_API_SECURE;
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional() @Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * add member to group
     * add member to group
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public intlLatamEcFormSecureAddMemberGroup(body?: GroupMember, observe?: 'body', reportProgress?: boolean): Observable<ModelApiResponse>;
    public intlLatamEcFormSecureAddMemberGroup(body?: GroupMember, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ModelApiResponse>>;
    public intlLatamEcFormSecureAddMemberGroup(body?: GroupMember, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ModelApiResponse>>;
    public intlLatamEcFormSecureAddMemberGroup(body?: GroupMember, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
        this.blockUI.start();
        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        // const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        // if (httpHeaderAcceptSelected != undefined) {
        //     headers = headers.set('Accept', httpHeaderAcceptSelected);
        // }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<ModelApiResponse>(`${this.basePath}/groups/members`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map(response => {
                this.blockUI.stop();
                return response
            })
        );
    }

    /**
     * create group
     * create group
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public intlLatamEcFormSecureCreateGroup(body?: Group, observe?: 'body', reportProgress?: boolean): Observable<ModelApiResponse>;
    public intlLatamEcFormSecureCreateGroup(body?: Group, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ModelApiResponse>>;
    public intlLatamEcFormSecureCreateGroup(body?: Group, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ModelApiResponse>>;
    public intlLatamEcFormSecureCreateGroup(body?: Group, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
        this.blockUI.start();
        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        // const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        // if (httpHeaderAcceptSelected != undefined) {
        //     headers = headers.set('Accept', httpHeaderAcceptSelected);
        // }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<ModelApiResponse>(`${this.basePath}/groups`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map(response => {
                this.blockUI.stop();
                return response
            })
        );
    }

    /**
     * delete group
     * delete group
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public intlLatamEcFormSecureDeleteGroup(id: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public intlLatamEcFormSecureDeleteGroup(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public intlLatamEcFormSecureDeleteGroup(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public intlLatamEcFormSecureDeleteGroup(id: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
        this.blockUI.start();
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling intlLatamEcFormSecureDeleteGroup.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.delete<any>(`${this.basePath}/groups/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map(response => {
                this.blockUI.stop();
                return response
            })
        );
    }

    /**
     * delete member in group
     * delete member in group
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public intlLatamEcFormSecureDeleteMemberGroup(body?: GroupMember, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public intlLatamEcFormSecureDeleteMemberGroup(body?: GroupMember, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public intlLatamEcFormSecureDeleteMemberGroup(body?: GroupMember, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public intlLatamEcFormSecureDeleteMemberGroup(body?: GroupMember, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
        this.blockUI.start();
        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<any>(`${this.basePath}/groups/members`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map(response => {
                this.blockUI.stop();
                return response
            })
        );
    }

    /**
     * get group by id
     * get group by id
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public intlLatamEcFormSecureGetGroupById(id: string, observe?: 'body', reportProgress?: boolean): Observable<ModelApiResponse>;
    public intlLatamEcFormSecureGetGroupById(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ModelApiResponse>>;
    public intlLatamEcFormSecureGetGroupById(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ModelApiResponse>>;
    public intlLatamEcFormSecureGetGroupById(id: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
        this.blockUI.start();
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling intlLatamEcFormSecureGetGroupById.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<ModelApiResponse>(`${this.basePath}/groups/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map(response => {
                this.blockUI.stop();
                return response
            })
        );
    }

    /**
     * get members of group
     * get members (model User) of group by id
     * @param groupId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public intlLatamEcFormSecureGetGroupMembersById(groupId: string, observe?: 'body', reportProgress?: boolean): Observable<ModelApiResponse>;
    public intlLatamEcFormSecureGetGroupMembersById(groupId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ModelApiResponse>>;
    public intlLatamEcFormSecureGetGroupMembersById(groupId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ModelApiResponse>>;
    public intlLatamEcFormSecureGetGroupMembersById(groupId: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
        this.blockUI.start();
        if (groupId === null || groupId === undefined) {
            throw new Error('Required parameter groupId was null or undefined when calling intlLatamEcFormSecureGetGroupMembersById.');
        }

        let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (groupId !== undefined && groupId !== null) {
            queryParameters = queryParameters.set('groupId', <any>groupId);
        }

        let headers = this.defaultHeaders;

        // authentication (authorize-b2b) required
        // if (this.configuration.apiKeys["Authorization"]) {
        //     headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        // }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<ModelApiResponse>(`${this.basePath}/groups/members`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map(response => {
                this.blockUI.stop();
                return response
            })
        );
    }

    /**
     * get group of a specific User
     * get group of a specific User
     * @param userId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public intlLatamEcFormSecureGetGroupMembersByUserId(userId: string, observe?: 'body', reportProgress?: boolean): Observable<ModelApiResponse>;
    public intlLatamEcFormSecureGetGroupMembersByUserId(userId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ModelApiResponse>>;
    public intlLatamEcFormSecureGetGroupMembersByUserId(userId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ModelApiResponse>>;
    public intlLatamEcFormSecureGetGroupMembersByUserId(userId: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
        this.blockUI.start();
        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling intlLatamEcFormSecureGetGroupMembersByUserId.');
        }

        let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (userId !== undefined && userId !== null) {
            queryParameters = queryParameters.set('userId', <any>userId);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<ModelApiResponse>(`${this.basePath}/groups/members/user`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map(response => {
                this.blockUI.stop();
                return response
            })
        );
    }

    /**
     * get groups
     * get all groups of portal
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public intlLatamEcFormSecureGetGroups(observe?: 'body', reportProgress?: boolean): Observable<any>;
    public intlLatamEcFormSecureGetGroups(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public intlLatamEcFormSecureGetGroups(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public intlLatamEcFormSecureGetGroups(observe: any = 'body', reportProgress: boolean = false): Observable<any> {
        this.blockUI.start();
        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<any>(`${this.basePath}/groups`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map(response => {
                this.blockUI.stop();
                return response
            })
        );
    }

    /**
     * update group
     * update group
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public intlLatamEcFormSecureUpdateGroup(body?: Group, observe?: 'body', reportProgress?: boolean): Observable<ModelApiResponse>;
    public intlLatamEcFormSecureUpdateGroup(body?: Group, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ModelApiResponse>>;
    public intlLatamEcFormSecureUpdateGroup(body?: Group, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ModelApiResponse>>;
    public intlLatamEcFormSecureUpdateGroup(body?: Group, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
        this.blockUI.start();
        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<ModelApiResponse>(`${this.basePath}/groups`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map(response => {
                this.blockUI.stop();
                return response
            })
        );
    }

}
