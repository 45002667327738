/**
 * intl-latam-ec-portal-documents
 * No description
 *
 * OpenAPI spec version: 1.0.0
 * Contact: you@your-company.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { CatalogModel } from './catalog';
import { FinancialMovementsClaimsModel } from './financialMovementsClaims';
import { FinancialMovementsProductosModel } from './financialMovementsProductos';


export class FinancialMovementsModel { 
    /**
     * ¿Realizas transacciones en moneda extranjera?
     */
    haveTransactionsForeignCurrency: boolean;
    operationType?: CatalogModel;
    /**
     * ¿Tienes productos financieros en el exterior?.
     */
    haveFinancialProductsAbroad: boolean;
    products?: Array<FinancialMovementsProductosModel>;
    /**
     * Reclamos
     */
    claims?: Array<FinancialMovementsClaimsModel>;

    constructor(){
        this.haveTransactionsForeignCurrency = false;
        this.operationType =null;
        this.haveFinancialProductsAbroad = false;
        this.products = new Array<FinancialMovementsProductosModel>();
        this.claims = new Array <FinancialMovementsClaimsModel>();
    }

}
