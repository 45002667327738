/**
 * intl-latam-ec-portal-documents
 * No description
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { CatalogModel } from './catalog';
import { ShareholderPersonDocumentModel } from './shareholderPersonDocument';


export class PersonalReferencePersonModel { 
    firstName?: string;
    secondName?: string;
    lastName?: string;
    secondLastName?: string;
    document: ShareholderPersonDocumentModel;
    type?: CatalogModel;
    /**
     * Descripción otro tipo de relación personal.
     */
    otherType?: string;
    showOtherType?:boolean;
    constructor(){
        this.document= new ShareholderPersonDocumentModel();
        this.type= new CatalogModel();
    }
}
