//CORE-ANGULAR
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { CommonModule } from '@angular/common';
//CORE-CNM
import { UsuariosComponent } from './usuarios.component';
//UTILITARIOS.
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DatePipe } from '@angular/common';
import {
  InputTextModule, DialogModule, DataTableModule, SharedModule, ButtonModule, GrowlModule, DropdownModule,
  MessagesModule, FileUploadModule, ConfirmDialogModule, InputTextareaModule,
  AutoCompleteModule,
  TabViewModule, PanelModule, CalendarModule, ConfirmationService, CheckboxModule, FieldsetModule, RadioButtonModule, MultiSelectModule
} from 'primeng/primeng';
import { KeyFilterModule } from 'primeng/keyfilter';

// Import ngx-barcode module
import { TableModule } from 'primeng/table';
// import { LoadingComponent } from '../../general/loading/loading.component';
import { BlockUIModule } from 'primeng/blockui';
import { ProgressSpinnerModule } from 'primeng/primeng';

@NgModule({
  declarations: [
    UsuariosComponent

  ],
  imports: [
    TableModule,
    CommonModule,
    FormsModule,
    // ModuloGeneral,
    HttpModule,
    BrowserAnimationsModule,
    InputTextModule,
    DialogModule,
    DataTableModule,
    SharedModule,
    ButtonModule,
    GrowlModule,
    DropdownModule,
    MessagesModule,
    FileUploadModule,
    ConfirmDialogModule,
    InputTextareaModule,
    TabViewModule,
    PanelModule,
    CalendarModule,
    CheckboxModule,
    FieldsetModule,
    RadioButtonModule,
    KeyFilterModule,
    MultiSelectModule,
    AutoCompleteModule,
    BlockUIModule,
    ProgressSpinnerModule

  ],
  providers: [
  ],
  bootstrap: [],
  exports: [
    UsuariosComponent
  ]

})
export class UsuariosModule { }
