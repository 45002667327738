/**
 * intl-latam-ec-portal-documents
 * No description
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export class DeclarationsModel { 
    country: string;
    /**
     * Declaración de origen de fondos.
     */
    theOriginFundsIsCorrect: boolean;
    /**
     * Descripción de origen de fondos.
     */
    descriptionOriginFunds?: string;
    /**
     * Clausula de tratamiento de datos personales.
     */
    personalDataProcessingClause?: boolean;
    /**
     * Autorización de tratamiento de datos.
     */
    authorizationDataProcessing?: boolean;
    /**
     * Autorización para transferencia y transmisión de datos.
     */
    authorizationForTransferAndDataTransmission?: boolean;
    /**
     * Autorización de tratamiento de informacion financiera, comercial y crediticia.
     */
    authorizationTreatmentFinancialCommercialCreditInformation?: boolean;
    /**
     * Autorizo del tratamiento de datos personales.
     */
    authorizationProcessingOfPersonalData?: boolean;
    /**
     * Declaración de información verdadera.
     */
    trueInformationStatement?: boolean;
    constructor(country?){
        if (country && country == 'CO') {
            this.personalDataProcessingClause = true;
            this.authorizationDataProcessing = true;
            this.authorizationForTransferAndDataTransmission = true;
            this.authorizationTreatmentFinancialCommercialCreditInformation = true;
            this.trueInformationStatement = true;
        }
    }
}
