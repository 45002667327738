import { Reqcode } from "../../_services/model-seguridad/models";

/**
 * intl-latam-ec-form-secure
 * Api to secure Application
 *
 * OpenAPI spec version: 1.2.1
 * Contact: cgudino@kruger.com.ec
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export class ReqcodeModel implements Reqcode{ 
    code?: string;
    
    constructor() {
        this.code = null;
    }
}
