import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Subject, Observable } from 'rxjs';

interface ItemNotify {
  code: string,
  title: string,
  subtitle: string,
  value: string | number,
  onClick?: ()=>void
}

@Injectable()
export class NotificationService {

  notifyItems = new BehaviorSubject([]);

  itemsHandler = this.notifyItems.asObservable();
  initList(items: ItemNotify[]) {
    this.notifyItems.next(items);
  }

  getList() {
    return this.notifyItems.getValue();
  }

  getListByCode(code: string) {
    const value = this.notifyItems.getValue();
    return value.filter(curr =>  curr.code === code)[0]
  }

  removeItem(itemId: number) {
    const list = this.notifyItems.getValue();
    this.notifyItems.next(list.filter(val => (val.code != itemId)));
  }

  addItem(item: ItemNotify) {
    const list = this.notifyItems.getValue();
    list.push(item);
    this.notifyItems.next(list);
  }
}
