import { Component, OnInit } from '@angular/core';
import { Properties } from '../../general/properties';
import { SelectItem } from 'primeng/primeng';
// Servicios
import { BreadcrumbService } from '../../../breadcrumb.service';
import { MessageService } from 'primeng/components/common/messageservice';
import { GroupsService } from '../../../_services';
import { RolesService } from '../../../_services';
import { UsersService } from '../../../_services';
// Modelos
import { InvitationModel } from '../../../../app/_model/security/invitation';
import { UserRolModel } from 'src/app/_model/security/usuarioRol';

//Encriptar
import { CriptoService } from '../../../_handler/cripto.service';


@Component({

    selector: 'usuarios',
    templateUrl: './usuarios.html',
    styleUrls: ['./usuarios.css']
})

export class UsuariosComponent implements OnInit {

    properties: Properties = new Properties();
    titulo: string;
    subtitulo: string;
    bandera: number = 0;
    users: any;
    cols: any[];
    viewUser: any;
    // Catalogos
    tipoGrupoSelectedItem: SelectItem[] = [{ label: this.properties.labelSelecioneCombo, value: null }];
    tipoRolesSelectItem: SelectItem[] = [];
    tipoGrupo: any;
    tipoEstados: SelectItem[] = [];
    // Modelos
    nuevoUsuario: InvitationModel = new InvitationModel();
    editarUsuario: UserRolModel = new UserRolModel();

    // Variables
    mostrarCombo: boolean = false;
    roles: any;
    catalogRoles: SelectItem[] = [{ label: this.properties.labelSelecioneCombo, value: null }];
    loading: boolean = false;
    banderaEditar: boolean = false;
    rolUsuario: any;
    userRol: any;
    userGroup: any;
    userId: any;
    userStatus: any;
    decPassword: any;

    // Codificadores
    correoCode: any;
    viewUserMailCode: any;
    tipoGrupoCode: any;
    termsUseDate: any;

    constructor(
        private breadcrumbService: BreadcrumbService,
        private messageService: MessageService,
        public groupsService: GroupsService,
        public rolesService: RolesService,
        public usersService: UsersService,
        private criptoService: CriptoService
    ) {
        this.tipoEstados = this.properties.tipoEstado;
    }

    ngOnInit(): void {
        this.cargarBreadCrum();
        const currentUser = JSON.parse(sessionStorage.getItem('access_token'));
        this.userRol = currentUser;
        this.titulo = 'Gestión de Usuarios';
        this.subtitulo = 'Crea, edita y visualiza todos tus usuarios de acuerdo a las necesidades de tu negocio';
        this.armarTabla();
        this.consultarPersimos();
        this.chargeGroups();

    }

    async resendInvitation() {
        const modelSendUser = {
            invitedUserDisplayName: this.nuevoUsuario.invitedUserDisplayName,
            invitedUserEmailAddress: this.nuevoUsuario.invitedUserEmailAddress,
            inviteRedirectUrl: null,
            sendInvitationMessage: true,
            justInvite: true
        };
        try {
            await this.usersService.intlLatamEcFormSecureCreateUser(modelSendUser).subscribe(curr => {
                this.messageService.add({ severity: 'success', summary: '', detail: 'El correo de invitación, se envió exitosamente' });
            });
        } catch {
            this.messageService.add({ severity: 'error', summary: '', detail: 'No se pudo enviar el correo de invitación.' });
        }
    }


    consultarPersimos() {
        this.rolUsuario = this.userRol.user.roles;
        this.rolUsuario.forEach(element => {
            if (element == "super_adminliberty") {
                this.mostrarCombo = true;
            }
            else {
                this.mostrarCombo = false;
                this.userGroup = this.userRol.user.groupId;
                this.tipoGrupo = this.userGroup;
                this.chargeUsers();
            }
            if (element == "admin_intermediario") {
                this.chargeRolesPublic();
            }
            else if (element == "admin_intermediario_piloto") {
                this.chargeRolesPiloto();


            }
            else {
                this.chargeRoles();
            }
        });


    }

    cargarBreadCrum() {
        this.breadcrumbService.setItems([
            { label: 'Inicio', routerLink: ['/home'] },
            { label: "Gestión de usuarios" },
            { label: "Usuarios", routerLink: ['/usuarios'] }
        ]);
    }

    armarTabla() {
        this.cols = [
            { field: 'displayName', header: 'Nombre' },
            { field: 'mail', header: 'Correo' },
            { field: 'x', header: 'Acciones' }
        ];
    }


    chargeGroups() {
        this.groupsService.intlLatamEcFormSecureGetGroups()
            .subscribe((catalogos: any) => {
                catalogos.data.value.forEach(element => {
                    this.tipoGrupoSelectedItem.push({ label: element.description, value: element.id });
                });
            },
                (err: any) => console.log(err),
                () => {
                });
    }

    chargeRoles() {
        this.rolesService.intlLatamEcFormSecureGetRoles()
            .subscribe((catalogos: any) => {
                if (catalogos.data) {
                    catalogos.data.forEach(element => {
                        this.tipoRolesSelectItem.push({ label: element.name, value: element.id });
                    });
                }
            },
                (err: any) => console.log(err),
                () => {
                });
    }

    chargeRolesPublic() {
        this.rolesService.intlLatamEcFormSecureGetPublicRoles()
            .subscribe((catalogos: any) => {
                catalogos.data.forEach(element => {
                    if (element.id === 'admin_intermediario' || element.id === 'operativo_intermediario' || element.id === 'consulta_intermediario') {
                        this.tipoRolesSelectItem.push({ label: element.name, value: element.id });
                    }
                });
            },
                (err: any) => console.log(err),
                () => {
                });
    }

    chargeRolesPiloto() {
        this.rolesService.intlLatamEcFormSecureGetPublicRoles()
            .subscribe((catalogos: any) => {
                catalogos.data.forEach(element => {
                    if (element.id === 'admin_intermediario_piloto' || element.id === 'operativo_intermediario_piloto' || element.id === 'consulta_intermediario') {
                        this.tipoRolesSelectItem.push({ label: element.name, value: element.id });
                    }

                });
            },
                (err: any) => console.log(err),
                () => {
                });
    }


    chargeUsers() {
        this.loading = true;
        if (this.tipoGrupo) {
            this.tipoGrupoCode = null;
            this.tipoGrupoCode = this.criptoService.set(this.tipoGrupo);
            this.groupsService.intlLatamEcFormSecureGetGroupMembersById(this.tipoGrupoCode)
                .subscribe((resp: any) => {
                    this.users = resp.data.value;
                    this.users.forEach(element => {
                        element.x = "";
                    });
                    this.loading = false;
                },
                    (err: any) => console.log(err),
                    () => {
                        this.loading = false;
                    });
        }

    }

    add() {
        this.breadcrumbService.setItems([
            { label: 'Inicio', routerLink: ['/home'] },
            { label: "Crear Usuario", routerLink: ['/usuarios'] }
        ]);
        this.bandera = 1;
        this.titulo = "Nuevo usuario";
        this.subtitulo = "Completa la información del nuevo usuario a continuación"
        if (this.mostrarCombo == false) {
            this.nuevoUsuario.groupId = this.tipoGrupo;
        }


    }

    save() {
        this.loading = true;
        if (this.banderaEditar == false) {
            this.nuevoUsuario.userStatus = 1;
            this.correoCode = null;
            var encrypted = this.criptoService.set(this.nuevoUsuario.invitedUserEmailAddress);
            this.correoCode = encodeURIComponent(encrypted);
            this.usersService.intlLatamEcFormSecureGetUserByEmail(this.correoCode)
                .subscribe((resp: any) => {
                    if (resp.data.value.length == 0) {
                        this.usersService.intlLatamEcFormSecureCreateUser(this.nuevoUsuario)
                            .subscribe((resp: any) => {
                                let data = JSON.parse(resp);
                                if (data.code == "200" || data.code == "201") {
                                    this.messageService.add({ severity: 'success', summary: '', detail: this.properties.msgSuccessSaveGeneral });
                                    this.chargeUsers();
                                    this.cancelar();
                                }
                                else {
                                    this.messageService.add({ severity: 'error', detail: "El usuario pertenece al dominio de liberty" });
                                    this.chargeUsers();
                                    this.cancelar();
                                }

                            },
                                (err: any) => {
                                    this.messageService.add({ severity: 'error', detail: this.properties.msgErrorGeneral });
                                    this.loading = false;
                                });
                    }
                    else {
                        this.chargeUsers();
                        this.cancelar();
                        this.messageService.add({ severity: 'error', detail: "El usuario fue creado o ya pertenece al dominio de liberty" });
                        this.loading = false;
                    }

                },
                    (err: any) => console.log(err),
                    () => {
                    });

        }

        else {
            this.armarEditar();
            this.usersService.intlLatamEcFormSecureUpdateUser(this.editarUsuario)
                .subscribe((resp: any) => {
                    this.messageService.add({ severity: 'success', summary: '', detail: this.properties.msgSuccessGeneral });
                    this.chargeUsers();
                    this.cancelar();
                    this.loading = false;
                },
                    (err: any) => {
                        //console.log(err);
                        this.messageService.add({ severity: 'error', detail: this.properties.msgErrorGeneral });
                        this.loading = false;
                    });
        }

    }

    view(user) {
        this.termsUseDate = null;
        this.titulo = 'Gestión de Usuarios';
        this.subtitulo = 'Modifica tus usuarios de acuerdo a las necesidades de tu negocio';

        this.breadcrumbService.setItems([
            { label: 'Inicio', routerLink: ['/home'] },
            { label: "Gestión de usuarios" }
        ]);
        this.bandera = 2;
        this.viewUser = user;
        this.loading = true;
        this.viewUserMailCode = null;
        this.viewUserMailCode = this.criptoService.set(this.viewUser.mail);
        this.rolesService.intlLatamEcFormSecureGetRolesByUserId(this.viewUserMailCode)
            .subscribe((resp: any) => {
                this.roles = [];
                if (resp.code == 200) {
                    if (resp.data[0]) {
                        this.userStatus = resp.data[0].userStatus;
                        if (resp.data[0].termsUseDate) {
                            this.termsUseDate = resp.data[0].termsUseDate;
                        }
                    }

                    this.nuevoUsuario.userStatus = this.userStatus;
                    if (resp.data != null) {
                        resp.data.forEach(element => {
                            if (element.rolesId) {
                                element.rolesId.forEach(x => {
                                    this.roles.push(x);
                                    this.nuevoUsuario.rolesId = this.roles;
                                });
                            }
                        });
                    }
                }
                this.loading = false;

            });

        this.nuevoUsuario.invitedUserDisplayName = this.viewUser.displayName;
        this.nuevoUsuario.invitedUserEmailAddress = this.viewUser.mail;

    }

    cancelar() {
        this.bandera = 0;
        this.userStatus = 0;
        this.banderaEditar = false;
        this.roles = [];
        this.titulo = 'Gestión de Usuarios';
        this.subtitulo = 'Crea, edita y visualiza todos tus usuarios de acuerdo a las necesidades de tu negocio';
        this.nuevoUsuario = new InvitationModel();
        this.editarUsuario = new UserRolModel();
    }

    mostrarEditar() {
        this.bandera = 1;
        this.banderaEditar = true;
        this.titulo = 'Editar Usuario';
        this.subtitulo = 'Edita el estado o perfil de tus usuarios de acuerdo a tus necesidades';
    }

    armarEditar() {
        this.editarUsuario.userStatus = this.nuevoUsuario.userStatus;
        this.editarUsuario.id = this.nuevoUsuario.invitedUserEmailAddress;
        this.editarUsuario.userId = this.nuevoUsuario.invitedUserEmailAddress;
        this.editarUsuario.name = this.nuevoUsuario.invitedUserEmailAddress;
        this.editarUsuario.rolesId = this.nuevoUsuario.rolesId;
        if (this.termsUseDate) {
            this.editarUsuario.termsUseDate = this.termsUseDate;
        }

    }
}