/**
 * intl-latam-ec-portal-documents
 * No description
 *
 * OpenAPI spec version: 1.0.0
 * Contact: you@your-company.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { ContactModel } from './contact';
 

/**
 * Contactos, aplica solo Colombia.
 */
export class WorkContactsModel { 
    phoneNumber?: ContactModel;
    email?: ContactModel;
   constructor(){
       this.phoneNumber = new ContactModel();
       this.email = new ContactModel();
   }
}
