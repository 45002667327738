import { Component, OnInit } from '@angular/core';

@Component({

    selector: 'bienvenida',
    templateUrl: './bienvenida.html',
    styleUrls: ['./bienvenida.css']

})

export class BienvenidaComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {

    }
}