/**
 * intl-latam-ec-portal-documents
 * No description
 *
 * OpenAPI spec version: 1.0.0
 * Contact: you@your-company.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { CatalogModel } from './catalog';
import { LocationModel } from './location';
import { ɵangular_packages_platform_browser_dynamic_platform_browser_dynamic_a } from '@angular/platform-browser-dynamic';


/**
 * Información del documento de identidad como DNI o cédula de identidad.
 */
export class PersonFormDocumentModel { 
    expeditionPlace?: LocationModel;
    /**
     * Fecha de expedicón del DNI, aplica solo Colombia.
     */
    expeditionDate?: Date;
    /**
     * Número de documento de identidad.
     */
    documentNumber: string;
    type: CatalogModel;
    /**
     * Nombre del otro tipo de documento.
     */
    otherType?: string;

    constructor(){
        this.expeditionPlace = new LocationModel();
        this.expeditionDate = null;
        this.documentNumber = null;
        this.type = null;
        this.otherType = null;
    }
}
