/**
 * intl-latam-ec-milf-linkingform
 * Manage the information of the linking form.
 *
 * OpenAPI spec version: 2.1.0
 * Contact: kruger@kruger.com.ec
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Catalog } from './catalog';
import { Claim } from './claim';
import { Company } from './company';
import { EntityBankReferences } from './entityBankReferences';
import { EntityBranch } from './entityBranch';
import { EntityCommercialReferences } from './entityCommercialReferences';
import { EntityCountries } from './entityCountries';
import { EntityLegalRepresentative } from './entityLegalRepresentative';
import { EntityPersonalReferences } from './entityPersonalReferences';
import { EntityShareholders } from './entityShareholders';
import { IncomeExpenses } from './incomeExpenses';
import { Pep } from './pep';
import { Person } from './person';
import { Product } from './product';
import { RelationshipPep } from './relationshipPep';
import { Work } from './work';


export interface Entity {
    company?: Company;
    legalRepresentative?: EntityLegalRepresentative;
    spouseLegalRepresentative?: Person;
    branch?: EntityBranch;
    haveAdministratorsPep?: boolean;
    administrators?: Array<RelationshipPep>;
    shareholdersExceedCapital?: boolean;
    shareholders?: Array<EntityShareholders>;
    person?: Person;
    spouse?: Person;
    mainActivity?: string;
    descriptionMainActivity: string;
    ciiu?: Catalog;
    monthlyIncomeJu?:Catalog;
    monthlyIncomeNa?: Catalog;
    sourceResources?: string;
    work?: Work;
    incomeExpenses?: IncomeExpenses;
    personalReferences?: Array<EntityPersonalReferences>;
    commercialReferences?: Array<EntityCommercialReferences>;
    bankReferences?: Array<EntityBankReferences>;
    isPep?: boolean;
    pep?: Pep;
    hasTaxObligationsOtherCountries?: boolean;
    countries?: Array<EntityCountries>;
    haveRelationshipPep?: boolean;
    relationshipPep?: Array<RelationshipPep>;
    foreignCurrencyTransactions?: boolean;
    operationType?: Entity.OperationTypeEnum;
    otherType?: string;
    haveFinancialProductsAbroad?: boolean;
    products?: Array<Product>;
    claim?: Array<Claim>;
    clauDatosPersonales?: boolean;
    tratamientoDatos?: boolean;
    transferenciaDatos?: boolean;
    tratamientoInformacion?: boolean;
}
export namespace Entity {
    export type OperationTypeEnum = 'Pago de Servicios' | 'Giros' | 'Prestamos' | 'OTHERS';
    export const OperationTypeEnum = {
        PagoDeServicios: 'Pago de Servicios' as OperationTypeEnum,
        Giros: 'Giros' as OperationTypeEnum,
        Prestamos: 'Prestamos' as OperationTypeEnum,
        OTHERS: 'OTHERS' as OperationTypeEnum
    };
}
