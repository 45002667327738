import { Component, OnInit, OnDestroy } from '@angular/core';
import { SelectItem } from 'primeng/api';
import * as $ from 'jquery';
import { environment } from '../../../../environments/environment';
import { Router } from '../../../../../node_modules/@angular/router';

@Component({
  selector: 'app-terms-co',
  templateUrl: './terms.component.html',
  styleUrls: ['../terms.component.css']
})
export class TermsComponentCo implements OnInit {
  URL_PORTAL: string = environment.URL_PORTAL;
  items: SelectItem[];
  itemSelected: any;
  ruta: any;

  constructor(private router: Router) { }

  ngOnInit() {
    this.ruta = JSON.parse(sessionStorage.getItem('ruta'));
    this.items =
      [{ label: '¿Que buscas?', value: '' },
      { label: 'Terminos y condiciones', value: 'term1' },
      { label: 'Exclusión de responsabilidad', value: 'term2' },
      { label: 'Hipervínculos', value: 'term3' },
      { label: 'Privacidad', value: 'term4' },
      { label: 'Propiedad intelectual e industrial', value: 'term5' },
      { label: 'Ley aplicable y jurisdicción', value: 'term5' }];
    this.goIn('inicio');
  }

  enviarCodigo() {
    if (this.ruta) {
      if (this.ruta == true) {
        this.router.navigate(['termCondiciones']);
      } else if (this.ruta == false) {
        this.router.navigate(['landing']);
      }
    } else {
      this.router.navigate(['landing']);
    }
  }

  goInicio() {
    const start = 'inicio';
    this.goIn(start);
  }

  goIn(section) {
    $('html,body').animate({
      scrollTop: $('#' + section).offset().top
    }, 1).end();
  }


  goSection(section) {
    if (section !== '') {

      $('html,body').animate({
        scrollTop: $('#' + section).offset().top
      }, 1000).end();
    }

  }

  goTopPage() {
    $('.ir-arriba').click(function () {
      $('body, html').animate({
        scrollTop: $('#layout-main').offset().top
      }, 1000);
    }).end();
  }

}
