/**
 * intl-latam-ec-portal-documents
 * No description
 *
 * OpenAPI spec version: 1.0.0
 * Contact: you@your-company.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { CatalogModel } from './catalog';


export class EconomicInformationModel { 
    /**
     * ¿Posee ingresos diferentes de la actividad económica detallada?
     */
    haveOtherIncome?: boolean;
    /**
     * Valor de otros ingresos.
     */
    otherIncome?: string;
    /**
     * Concepto de otros ingresos.
     */
    otherIncomeConcept?: string;
    /**
     * Gastos mensuales.
     */
    monthlyExpenses?: string;
    monthlyIncome?: CatalogModel;
    /**
     * Ingresos mensuales, aplica Colombia.
     */
    monthlyIncomeDescription?: string;
    /**
     * Valor de activos.
     */
    actives?: string;
    /**
     * Valor de pasivos.
     */
    pasives?: string;

    totalActive: any;
    totalPasives: any;


    constructor(){
        this.haveOtherIncome = false;
        this.otherIncome = null;
        this.otherIncomeConcept = null;
        this.monthlyExpenses = null;
        this.monthlyIncome = new CatalogModel ();
        this.monthlyIncomeDescription = null;
        this.actives = null;
        this.pasives = null;

    }
}
