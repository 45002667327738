import { Component, OnInit } from '@angular/core';
import { Properties } from '../../general/properties';
import { ConfirmationService, SelectItem } from 'primeng/primeng';
// Servicios
import { BreadcrumbService } from '../../../breadcrumb.service';
import { MessageService } from 'primeng/components/common/messageservice';
import { MenusService } from '../../../_services';
import { GroupsService } from '../../../_services';

// Modelos
import { MenuModel } from '../../../../app/_model/security/menu';


@Component({

    selector: 'menus',
    templateUrl: './menus.html'
})

export class MenusComponent implements OnInit {

    properties: Properties = new Properties();
    titulo: string;
    subtitulo: string;
    banderaPadre: number = 0;
    bandera: number = 0;
    colsPadre: any[];
    colsSon: any[];
    nuevoMenu: MenuModel = new MenuModel;
    menusPadres: any;
    menusHijos: any;
    estados: SelectItem[] = [];
    groups: any;
    idPadre: any;
    loading: boolean = false;

    constructor(
        private breadcrumbService: BreadcrumbService,
        private messageService: MessageService,
        public menusService: MenusService,
        public groupsService: GroupsService,
        private confirmationService: ConfirmationService,
    ) {
        this.estados = this.properties.tipoEstado;
    }

    ngOnInit(): void {

        this.cargarBreadCrum();
        this.titulo = 'Gestión de Menús';
        this.subtitulo = 'Gestiona, visualiza y crea menús de acuerdo a las necesidades de tu negocio';
        this.armarTabla();
        this.consultar();

    }

    cargarBreadCrum() {
        this.breadcrumbService.setItems([
            { label: 'Inicio', routerLink: ['/home'] },
            { label: "Menus", routerLink: ['/menus'] }
        ]);
    }

    consultar() {
        this.loading = true;
        this.menusService.intlLatamEcFormSecureGetAllMenus()
            .subscribe((resp: any) => {
                this.menusPadres = [];
                if (resp.data) {
                    resp.data.forEach(element => {
                        element.x = "";
                        if (element.parentId == null) {
                            this.menusPadres.push(element);
                        }
                    });
                }

                this.loading = false;
            },
                (err: any) => console.log(err),
                () => {
                });

    }

    armarTabla() {
        this.colsPadre = [
            { field: 'name', header: 'Nombre' },
            { field: 'description', header: 'Descripción' },
            { field: 'x', header: 'Acciones' },
        ];

    }

    armarTablaSon() {
        this.colsSon = [
            { field: 'name', header: 'Nombre' },
            { field: 'description', header: 'Descripción' },
            { field: 'url', header: 'Url' },
            { field: 'x', header: 'Acciones' },
        ];

    }

    nuevoPadre() {
        this.titulo = "Nuevo Menú"
        this.bandera = 2;
        this.banderaPadre = 2;
    }

    nuevoHijo() {
        this.titulo = "Nuevo Sub-Menú"
        this.nuevoMenu = new MenuModel();
        this.bandera = 2;
        this.banderaPadre = 2;
    }

    guardar(form) {
        this.loading = true;
        this.armarGuardar();
        this.menusService.intlLatamEcFormSecureCreateMenu(this.nuevoMenu).subscribe((result: any) => {
            this.messageService.add({ severity: 'success', summary: '', detail: this.properties.msgSuccessGeneral });
            this.consultar();
            this.cancelar();
            this.loading = false;
        },
            (err: any) => {
                this.messageService.add({ severity: 'error', detail: this.properties.msgErrorGeneral });
                this.loading = false;
            });
    }

    cancelar() {
        this.banderaPadre = 0;
        this.bandera = 0;
        this.titulo = 'Gestión de Menús';
        this.subtitulo = 'Gestiona, visualiza y crea menús de acuerdo a las necesidades de tu negocio';
        this.nuevoMenu = new MenuModel();
        this.idPadre = null;
    }

    viewPadre(rowData) {
        this.banderaPadre = 1;
        this.titulo = 'Gestión de Sub-Menús';
        this.subtitulo = 'Gestiona, visualiza y crea sub-menús de acuerdo a las necesidades de tu negocio';
        this.nuevoMenu = rowData;
        this.idPadre = this.nuevoMenu.id;
        this.consultarHijos();
    }

    consultarHijos() {
        this.loading = true;
        this.menusService.intlLatamEcFormSecureGetMenusByParentId(this.nuevoMenu.id)
            .subscribe((resp: any) => {
                this.menusHijos = [];
                if (resp.data) {
                    resp.data.forEach(element => {
                        element.x = "";
                        this.menusHijos.push(element);
                    });
                }
                this.armarTablaSon();
                this.loading = false;
            },
                (err: any) => console.log(err),
                () => {
                    this.loading = false;
                });

    }

    viewSon(rowData) {
        this.banderaPadre = 2;
        this.bandera = 1;
        this.titulo = 'Gestión de Sub-Menús';
        this.subtitulo = 'Gestiona, visualiza y crea sub-menús de acuerdo a las necesidades de tu negocio';
        this.nuevoMenu = rowData;
    }

    mostrarEditar() {
        this.bandera = 2;
    }

    armarGuardar() {
        this.nuevoMenu.id = this.nuevoMenu.name;
        this.nuevoMenu.parentId = this.idPadre;
        delete this.nuevoMenu.x;
    }

    confirmarEliminar() {
        this.properties.confirmarEliminacionMensaje(this.nuevoMenu.description),
            this.confirmationService.confirm({
                key: "eliminar",
                message: this.properties.labelModalEliminar,
                header: this.properties.tituloConfirmacionEliminacion,
                icon: this.properties.eliminar.icono,
                accept: () => {
                    this.messageService.add({ severity: 'success', summary: '', detail: this.properties.mensajeEliminarExito });
                }
            });
    }

    eliminar() {
        this.loading = true;
        this.menusService.intlLatamEcFormSecureDeleteMenu(this.nuevoMenu.name)
            .subscribe((result: any) => {
                this.messageService.add({ severity: 'success', summary: '', detail: this.properties.mensajeEliminarExito });
                this.consultar();
                this.cancelar();
                this.loading = false;

            },
                (err: any) => {
                    this.messageService.add({ severity: 'error', detail: this.properties.mensajeEliminarError });
                });
    }
}