import { Component, OnInit, ViewEncapsulation, ViewChild, OnChanges, AfterContentInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormGroup } from '@angular/forms';
import { MessageService } from 'primeng/components/common/messageservice';
import { Message } from 'primeng/components/common/api';
import { Router, ActivatedRoute } from '@angular/router';
import { SelectItem } from 'primeng/api';
import { BreadcrumbService } from '../../breadcrumb.service';
import { Properties } from '../general/properties';
import { Poliza } from '../../_model/policy/poliza';
import { BusquedaPolizaModel, FacturacionElectronicaModel } from '../../_services/model-poliza/modelsPolicy';
import { DefaultService, RulesService } from '../../_services';
import { ApiResponse } from '../../_model/policy/apiResponse';
import { Constantes } from '../../_shared/var.constant';
import { CatalogService } from 'src/app/_services/api_v1/catalogs.service';

@Component({
  selector: 'app-poliza',
  templateUrl: './poliza.component.html',
  styleUrls: ['./poliza.component.css']
})

export class PolizaComponent implements OnInit {

  //  @ViewChild(PolizaRevisionComponent) gestionaPolizaDisplay: PolizaRevisionComponent;
  properties = new Properties();
  pais: string = 'EC';
  constantes = new Constantes();
  facturaElectronicaModel: FacturacionElectronicaModel;
  busquedaPolizaModel: BusquedaPolizaModel;
  parametrosContarPolizaPoEstado: BusquedaPolizaModel;
  parametrosContarPolizaFinEstado: BusquedaPolizaModel;
  polizas: Poliza[] = [];
  cols: any[];
  tituloPopUp: string;
  mostrarAceptar: boolean;
  mostrarRechazar: boolean;
  public fGaceptarPoliza: FormGroup;
  poliza: Poliza;
  polizaSeleccionada: Poliza;
  mostrarComisiones: boolean;
  permiteAceptarRechazar: boolean;
  msgs: Message[];
  checked = false;
  selectedType: string;
  types: SelectItem[];
  filtroFechaInicial: Date;
  filtroFechaFin: Date;
  numeroPolizasGestion = 0;
  numeroPolizasFinalizado = 0;
  dataPolizas: any;
  countPolizasFinalizadas: any;
  filtroBusquedaGlobal: string;
  filtroBusquedaBroker: string;
  banderaGestor = 0;
  blockedDocument = false;
  etiquetaMisTramites: any;
  estadosParaGestion: any;
  estadosFinalizados: any;
  es: any = this.properties.es;
  ramos: SelectItem[] = [];
  estadosFiltro: any[];
  cantidades = new Map();
  gestionaPolizaDisplay = 0;
  userRol: any;
  gestionPoliza: any;
  segGestionPoliza = false;

  countX: any;
  countY: any;
  userAux: any;
  userMailAux: any;
  brokerIdAux: any;
  filtroMail: any;

  constructor(private http: HttpClient,
    public policyService: DefaultService,
    public route: ActivatedRoute,
    private messageService: MessageService,
    public service: DefaultService,
    private catalogService: CatalogService,
    private router: Router,
    public rulesServices: RulesService,
    private breadcrumbService: BreadcrumbService,
  ) {
  }


  ngOnInit() {
    this.cargarBreadCrum();
    const currentUser = JSON.parse(sessionStorage.getItem('access_token'));
    this.userRol = currentUser.user.roles;
    this.consultarSeguridad();
    this.gestionaPolizaDisplay = 1;
    this.consultarTipoUser();
    this.cargarCatalogos();
    this.estadosParaGestion = this.properties.estadosParaGestion;
    this.estadosFinalizados = this.properties.estadosFinalizados;
    this.blockedDocument = true;
    // debe cargar con permisos del perfil
    this.permiteAceptarRechazar = true;
    this.polizaSeleccionada = new Poliza();
    this.busquedaPolizaModel = { 'estados': this.estadosParaGestion };
    this.parametrosContarPolizaPoEstado = { 'estados': this.estadosParaGestion };
    this.parametrosContarPolizaFinEstado = { 'estados': this.estadosFinalizados };
    this.filtrar();
    this.aramarTablaPoliza();
    this.mostrarAceptar = false;
    this.mostrarRechazar = false;
    this.mostrarComisiones = false;
    this.selectedType = 'paraGestion';
    this.types = [];
    this.types.push({ label: 'Para tu gestión ', title: 'Para tu gestión', value: 'paraGestion' });
    this.types.push({ label: 'Finalizados ', title: 'Finalizados', value: 'finalizado' });

    this.breadcrumbService.itemsSource.subscribe(data => {

    });
    this.filtrar();
  }

  filtrar() {
    // operativo_intermediario/Broker
    // consulta_intermediario/ Gestor
    let user = JSON.parse(sessionStorage.getItem('access_token'));
    this.userAux = user.user.roles;
    this.userMailAux = user.user.email;
    this.brokerIdAux = user.user.brokerId;
    // if (this.userAux[0] == "consulta_intermediario") {
    //   this.busquedaPolizaModel.emailGestor = this.userMailAux;
    //   this.parametrosContarPolizaFinEstado.emailGestor = this.userMailAux;
    //   this.parametrosContarPolizaPoEstado.emailGestor = this.userMailAux;
    // }
    if (this.userAux[0] == "operativo_intermediario"
      || this.userAux[0] == "consulta_intermediario"
      || this.userAux[0] == "admin_intermediario"
      || this.userAux[0] == "admin_intermediario_piloto") {
      this.busquedaPolizaModel.brokerId = this.brokerIdAux;
      this.parametrosContarPolizaFinEstado.brokerId = this.brokerIdAux;
      this.parametrosContarPolizaPoEstado.brokerId = this.brokerIdAux;
    }
    // this.busquedaPolizaModel.emailGestor = "default@liberty.ec";
    // this.busquedaPolizaModel.emailBroker = "bguamanzara@ec.krugercorp.com"
    // this.parametrosContarPolizaFinEstado.emailBroker = "bguamanzara@ec.krugercorp.com";
    // this.parametrosContarPolizaPoEstado.emailBroker = "bguamanzara@ec.krugercorp.com";
    this.cargarPolizas();
    this.contarPolizasEstados();

  }

  consultarSeguridad() {
    this.blockedDocument = true;
    this.gestionPoliza = {
      rolesId: this.userRol,
      rule: this.constantes.gestionarPoliza
    };
    this.rulesServices.intlLatamEcFormSecureVerifyComponentAccess(this.gestionPoliza).subscribe((resp: ApiResponse) => {
      const auxcode = parseInt(resp.code + '', 10);
      if (auxcode === 200) {
        this.blockedDocument = false;
        this.segGestionPoliza = true;
        this.aramarTablaPoliza();
      }
      this.blockedDocument = false;
    },
      (err: any) => console.log(err),
      () => {
        this.blockedDocument = false;
      });
  }

  consultarTipoUser() {
    this.userRol.forEach(element => {
      if (element === this.constantes.rolConsultaLibertyGeneral) {
        this.banderaGestor = 1;
      } else {
        this.banderaGestor = 0;
      }
    });

  }

  cargarBreadCrum() {
    this.breadcrumbService.itemsSource.next([
      { label: 'Mis solicitudes', routerLink: ['polizas'] }

    ]);
  }

  // consultarRol(){
  //   var aux=false;
  //   this.userRol.forEach(element => {
  //     if(element == this.constantes.rolAdminIntermediario){
  //       aux = true;
  //       this.busquedaPolizaModel.brokerId=null;
  //       this.parametrosContarPolizaFinEstado.brokerId=null;
  //       this.parametrosContarPolizaPoEstado.brokerId=null;
  //     }
  //   });
  //   return aux;

  // }

  cargarPolizas() {
    // this.consultarRol();
    this.blockedDocument = true;
    if (this.busquedaPolizaModel == undefined) {
      this.busquedaPolizaModel = { 'estados': this.estadosParaGestion }
    }
    this.service.buscarPolizaPost(this.busquedaPolizaModel).subscribe((resp: ApiResponse) => {
      if (resp.data !== undefined) {
        this.polizas = [];
        this.polizas = resp.data;
        this.blockedDocument = false;
      }
      this.blockedDocument = false;
    });

  }

  polizaByBroker() {
    // this.consultarRol();
    this.busquedaPolizaModel.parametroBusquedaGlobal = this.filtroBusquedaBroker;
    this.blockedDocument = true;
    this.service.buscarPolizaPost(this.busquedaPolizaModel).subscribe((resp: ApiResponse) => {
      if (resp.data !== undefined) {
        this.polizas = resp.data;
        this.blockedDocument = false;
      }

    });
  }

  public contarPolizasEstados() {
    // this.consultarRol();
    const estadoInicio = this.service.contarPorEstadoPost(this.parametrosContarPolizaFinEstado).toPromise().then((res: ApiResponse) => res);
    const estadoFinal = this.service.contarPorEstadoPost(this.parametrosContarPolizaPoEstado).toPromise().then((res: ApiResponse) => res);
    this.cantidades.clear();
    Promise.all([estadoInicio, estadoFinal]).then(result => {
      this.types.forEach(element => {

        if (element.value === 'finalizado') {
          element.label = 'Finalizados ';
          this.cantidades.set(element.value, result[0].data);
        }
      });
      this.types.forEach(element => {
        if (element.value === 'paraGestion') {
          element.label = 'Para tu gestión ';
          this.cantidades.set(element.value, result[1].data);

        }
      });
      this.blockedDocument = false;
    });

  }

  aramarTablaPoliza() {
    if (this.segGestionPoliza === true) {
      this.cols = [
        { field: 'numeroPoliza', header: ' N° Póliza' },
        { field: 'contratante', subfield: 'nombresApellidos', header: 'Contratante', id: 'contratante' },
        { field: 'contratante', subfield: 'numeroDocumento', header: 'Número de Documento', id: 'contratanteId' },
        { field: 'ramo', subfield: 'descripcion', header: 'Ramo', id: 'ramo' },
        { field: 'fechaEmision', header: 'Fecha Emisión' },
        { field: 'fechaInicioVigencia', header: 'Fecha Inicio Vigencia' },
        { field: null, header: 'Estado', id: 'estado' },
        { field: null, header: 'Acción' }
      ];
    } else {
      this.cols = [
        { field: 'numeroPoliza', header: ' N° Póliza' },
        { field: 'contratante', subfield: 'nombresApellidos', header: 'Contratante', id: 'contratante' },
        { field: 'contratante', subfield: 'numeroDocumento', header: 'Número de Documento', id: 'contratanteId' },
        { field: 'ramo', subfield: 'descripcion', header: 'Ramo', id: 'ramo' },
        { field: 'fechaEmision', header: 'Fecha Emisión' },
        { field: 'fechaInicioVigencia', header: 'Fecha Inicio Vigencia' },
        { field: null, header: 'Estado', id: 'estado' }];
    }

  }

  inicializarAceptarPoliza(poliza: Poliza) {
    this.mostrarAceptar = true;
    this.tituloPopUp = 'Aceptar Póliza';
    this.polizaSeleccionada = poliza;
  }

  aceptarPoliza(poliza: Poliza) {
    this.mostrarAceptar = false;
    this.messageService.add({ severity: 'success', summary: 'Service Message', detail: 'Poliza revisada' });
  }

  submitAceptarPoliza(value: any) {

  }

  inicializarRechazarPoliza(poliza: Poliza) {
    this.mostrarRechazar = true;
    this.tituloPopUp = 'Rechazar Póliza';
    this.polizaSeleccionada = poliza;
  }

  rechazarPoliza() {
    this.mostrarRechazar = false;
    this.messageService.add({ severity: 'success', summary: 'Service Message', detail: 'Poliza rechazada' });
  }

  revisarComision(poliza: Poliza) {
    this.tituloPopUp = 'Revisar Comisión';
    this.mostrarComisiones = true;
    this.polizaSeleccionada = poliza;
  }

  rechazarComision() {
    this.mostrarComisiones = false;
    this.messageService.add({ severity: 'success', summary: 'Service Message', detail: 'Comisión rechazada' });
  }


  verGestionarPoloza(poliza: Poliza) {
    this.gestionaPolizaDisplay = 0;
    this.router.navigate(['polizas', poliza.idpv]);
  }


  cambiarEstadoBusquedaPoliza(selectedType: any) {
    if (selectedType.value === 'finalizado') {
      this.busquedaPolizaModel.estados = this.estadosFinalizados;
      this.estadosFiltro = [];
      this.estadosFiltro = this.properties.estadosPolizasEndFiltro;
    } else {
      this.busquedaPolizaModel.estados = this.estadosParaGestion;
      this.estadosFiltro = [];
      this.estadosFiltro = this.properties.estadosPolizasFiltro;
    }
    this.cargarPolizas();
    this.contarPolizasEstados();
  }

  buscarPolizas() {
    this.busquedaPolizaModel.parametroBusquedaGlobal = this.filtroBusquedaGlobal;

    if (this.filtroFechaFin != null) {
      this.filtroFechaFin.setDate(this.filtroFechaFin.getDate() + 1);
    }


    this.busquedaPolizaModel.fechaInicial = this.filtroFechaInicial == null ? undefined : this.filtroFechaInicial;
    this.busquedaPolizaModel.fechaFinal = this.filtroFechaFin == null ? undefined : this.filtroFechaFin;

    if ((this.busquedaPolizaModel.fechaInicial && this.busquedaPolizaModel.fechaFinal == null)
      || (this.busquedaPolizaModel.fechaInicial == null && this.busquedaPolizaModel.fechaFinal)) {
      this.messageService.add({ severity: 'info', summary: 'Información', detail: 'Es necesario las dos fechas' });
    }

    if ((this.busquedaPolizaModel.fechaInicial &&
      this.busquedaPolizaModel.fechaFinal) &&
      (this.busquedaPolizaModel.fechaInicial > this.busquedaPolizaModel.fechaFinal)) {
      this.messageService.add({ severity: 'info', summary: 'Información', detail: 'La fecha inicial no puede ser mayor a la fecha final' });
    } else {
      this.cargarPolizas();
    }
  }

  cargarCatalogos() {

    this.estadosFiltro = this.properties.estadosPolizasFiltro;
    this.catalogService.getCatalogs(this.properties.ramos, this.pais)
      .subscribe((resp: any) => {
        if (resp) {
          resp.forEach(element => {
            this.ramos.push({ label: element.description, value: element.id });
          });
        }
      },
        (err: any) => console.log(err),
        () => {
        });
  }


  onFilterChange(event, col, dt) {
    const parameter = '';
    if (event === 'Todos') {
      event = parameter;
    }
    dt.filter(event, col, 'equals');
  }

}

