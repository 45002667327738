/**
 * intl-latam-ec-portal-documents
 * No description
 *
 * OpenAPI spec version: 1.0.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { CatalogModel } from './catalog';
import { OtherShareholder } from './otherShareholder';
import { ShareholderPersonDocumentModel } from './shareholderPersonDocument';


export class ShareholdersOfShareholder {
    document: ShareholderPersonDocumentModel;
    firstName?: string;
    secondName?: string;
    lastName?: string;
    secondLastName?: string;
    businessName?: string;
    percentageParticipation?: number;
    nameRepresentShareholder?: string;
    nationality?: CatalogModel;
    typeInvestment?: string;
    shareholders?: Array<OtherShareholder>;
    constructor() {
        this.document = new ShareholderPersonDocumentModel();
        this.nationality = new CatalogModel();
        this.shareholders = new Array<OtherShareholder>();
    }
}
