/**
 * intl-latam-ec-milf-linkingform
 * Manage the information of the linking form.
 *
 * OpenAPI spec version: 2.1.0
 * Contact: kruger@kruger.com.ec
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Catalog } from './catalog';
import { Company } from './company';
import { Person } from './person';


export interface EntityShareholders { 
    typePerson?: EntityShareholders.TypePersonEnum;
    percentageParticipation?: number;
    person?: Person;
    company?: Company;
    legalRepresentative?: Person;
    obligatedTaxReturnOtherCountries?: boolean;
    taxReturn?: string;
    countries?: Array<Catalog>;
    esPEP?: boolean;
}
export namespace EntityShareholders {
    export type TypePersonEnum = 'N' | 'J';
    export const TypePersonEnum = {
        N: 'N' as TypePersonEnum,
        J: 'J' as TypePersonEnum
    };
}
