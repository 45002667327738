import { Component, OnInit, OnDestroy } from '@angular/core';
import { SelectItem } from 'primeng/api';
import * as $ from 'jquery';
import { environment } from '../../../environments/environment';
import { BreadcrumbService } from 'src/app/breadcrumb.service';
import { AppMenuComponent } from 'src/app/app.menu.component';
import { CriptoService } from 'src/app/_handler/cripto.service';
import { UsersService } from 'src/app/_services';
import { UserRolModel } from 'src/app/_model/security/usuarioRol';
import { MessageService } from 'primeng/components/common/messageservice';
import { Properties } from '../general/properties';
import { Router } from '@angular/router';
import { LoginService } from 'src/app/_handler/login.service';



@Component({
  selector: 'app-termCondiciones',
  templateUrl: './termCondiciones.component.html',
  styleUrls: ['./termCondiciones.css']
})
export class TermCondicionesComponent implements OnInit {
  infoUser: any;
  email: string;
  terminos: boolean = false;
  loading = false;
  properties: Properties = new Properties();
  correoCode: any;
  editarUsuario: UserRolModel = new UserRolModel();
  mobile: any;
  constructor(private breadcrumbService: BreadcrumbService,
    private criptoService: CriptoService,
    public usersService: UsersService,
    private messageService: MessageService,
    private router: Router,
    public loginService: LoginService, ) { }

  ngOnInit() {
    sessionStorage.setItem('ruta', JSON.stringify(true));
    this.mobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    setTimeout(() => {
     
      this.reponsive();
    }, 100);
    const currentUser = JSON.parse(sessionStorage.getItem('access_token'));
    if(currentUser){
      this.email = currentUser.user.email;
      this.infoUser = currentUser.user;
    }
  }

  reponsive(){
    if (this.mobile) {
      const elm1 = <HTMLElement>document.getElementById('section');
      const elm2 = <HTMLElement>document.getElementById('text');
      if (elm1 || elm2) {
        elm1.style.marginTop = '-32%';
        elm2.style.padding = '30px';
      }

    }
  }
  generar() {
    this.armarEditar();
    this.loading = true;
    this.infoUser.termsUseDate = new Date + "";
    this.usersService.intlLatamEcFormSecureUpdateUser(this.editarUsuario)
      .subscribe((resp: any) => {
        if (resp.code == 201) {
          this.messageService.add({ severity: 'success', summary: '', detail: this.properties.aceptacionTerminos });
          this.loading = false;
          setTimeout(() => {
            this.router.navigate(['home']); 
            sessionStorage.setItem('sendTermsConditions', JSON.stringify(false));
            this.loginService.getShowMenu();
          }, 2000);

        } else {
          this.messageService.add({ severity: 'error', detail: this.properties.msgErrorGeneral });
          this.loading = false;
        }

      },
        (err: any) => {
          //console.log(err);
          this.messageService.add({ severity: 'error', detail: this.properties.msgErrorGeneral });
          this.loading = false;
        });
   
  }


  armarEditar() {
    this.editarUsuario.userStatus = 1;
    this.editarUsuario.id = this.infoUser.email;
    this.editarUsuario.userId = this.infoUser.email;
    this.editarUsuario.name = this.infoUser.email;
    this.editarUsuario.rolesId = this.infoUser.roles;
    this.editarUsuario.termsUseDate = new Date + "";
  }
}
