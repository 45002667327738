
export class RecuperarModel {
    bucket: string;
    key: string;

    constructor() {
        this.bucket = null;
        this.key = null;
    }
}
