/**
 * intl-latam-ec-form-secure
 * Api to secure Application
 *
 * OpenAPI spec version: 1.2.1
 * Contact: cgudino@kruger.com.ec
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import {
    HttpClient, HttpHeaders, HttpParams,
    HttpResponse, HttpEvent
} from '@angular/common/http';
import { CustomHttpUrlEncodingCodec } from '../encoder';

import { Observable } from 'rxjs/Observable';

import { Invitation } from '../model-seguridad/invitation';
import { ModelApiResponse } from '../model-seguridad/modelApiResponse';
import { UserRol } from '../model-seguridad/userRol';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { map } from 'rxjs/operators';
import { Configuration } from '../configuration';
import { environment } from '../../../environments/environment';


@Injectable()
export class UsersService {
    @BlockUI() blockUI: NgBlockUI;
    protected basePath = environment.URL_API_SECURE;
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional() @Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Post user
     * Create new user structure
     * @param body Request object for user
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public intlLatamEcFormSecureCreateUser(body: Invitation, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public intlLatamEcFormSecureCreateUser(body: Invitation, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public intlLatamEcFormSecureCreateUser(body: Invitation, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public intlLatamEcFormSecureCreateUser(body: Invitation, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
        this.blockUI.start();
        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling intlLatamEcFormSecureCreateUser.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.basePath}/users`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map(response => {
                this.blockUI.stop();
                return response
            })
        );
    }

    /**
     * get roles Array of user
     * get existing roles of specific user 
     * @param userId userid
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public intlLatamEcFormSecureGetRolByUser(userId: string, observe?: 'body', reportProgress?: boolean): Observable<ModelApiResponse>;
    public intlLatamEcFormSecureGetRolByUser(userId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ModelApiResponse>>;
    public intlLatamEcFormSecureGetRolByUser(userId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ModelApiResponse>>;
    public intlLatamEcFormSecureGetRolByUser(userId: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
        this.blockUI.start();
        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling intlLatamEcFormSecureGetRolByUser.');
        }

        let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (userId !== undefined && userId !== null) {
            queryParameters = queryParameters.set('userId', <any>userId);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<ModelApiResponse>(`${this.basePath}/users/rol/user`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map(response => {
                this.blockUI.stop();
                return response
            })
        );
    }

    /**
     * get user
     * get existing user Model
     * @param userId get user by userId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public intlLatamEcFormSecureGetUser(userId: string, observe?: 'body', reportProgress?: boolean): Observable<ModelApiResponse>;
    public intlLatamEcFormSecureGetUser(userId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ModelApiResponse>>;
    public intlLatamEcFormSecureGetUser(userId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ModelApiResponse>>;
    public intlLatamEcFormSecureGetUser(userId: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
        this.blockUI.start();
        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling intlLatamEcFormSecureGetUser.');
        }

        let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (userId !== undefined && userId !== null) {
            queryParameters = queryParameters.set('userId', <any>userId);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<ModelApiResponse>(`${this.basePath}/users`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map(response => {
                this.blockUI.stop();
                return response
            })
        );
    }

    /**
     * get user by email
     * get existing by email user structure
     * @param email get user by email
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public intlLatamEcFormSecureGetUserByEmail(email: string, observe?: 'body', reportProgress?: boolean): Observable<ModelApiResponse>;
    public intlLatamEcFormSecureGetUserByEmail(email: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ModelApiResponse>>;
    public intlLatamEcFormSecureGetUserByEmail(email: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ModelApiResponse>>;
    public intlLatamEcFormSecureGetUserByEmail(email: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
        this.blockUI.start();
        if (email === null || email === undefined) {
            throw new Error('Required parameter email was null or undefined when calling intlLatamEcFormSecureGetUserByEmail.');
        }

        let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (email !== undefined && email !== null) {
            queryParameters = queryParameters.set('email', <any>email);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<ModelApiResponse>(`${this.basePath}/users/exist`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map( response => {
                this.blockUI.stop();
                return response
           } )
          );
    }

    /**
     * get user
     * get existing user structure
     * @param rolId get user by rol
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public intlLatamEcFormSecureGetUserByRol(rolId: string, observe?: 'body', reportProgress?: boolean): Observable<ModelApiResponse>;
    public intlLatamEcFormSecureGetUserByRol(rolId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ModelApiResponse>>;
    public intlLatamEcFormSecureGetUserByRol(rolId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ModelApiResponse>>;
    public intlLatamEcFormSecureGetUserByRol(rolId: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
        this.blockUI.start();
        if (rolId === null || rolId === undefined) {
            throw new Error('Required parameter rolId was null or undefined when calling intlLatamEcFormSecureGetUserByRol.');
        }

        let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (rolId !== undefined && rolId !== null) {
            queryParameters = queryParameters.set('rolId', <any>rolId);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<ModelApiResponse>(`${this.basePath}/users/list/rol`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map(response => {
                this.blockUI.stop();
                return response
            })
        );
    }

    /**
     * update user
     * update user rol object
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public intlLatamEcFormSecureUpdateUser(body?: UserRol, observe?: 'body', reportProgress?: boolean): Observable<ModelApiResponse>;
    public intlLatamEcFormSecureUpdateUser(body?: UserRol, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ModelApiResponse>>;
    public intlLatamEcFormSecureUpdateUser(body?: UserRol, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ModelApiResponse>>;
    public intlLatamEcFormSecureUpdateUser(body?: UserRol, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
        this.blockUI.start();
        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<ModelApiResponse>(`${this.basePath}/users`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map(response => {
                this.blockUI.stop();
                return response
            })
        );
    }

}
