
import { Component, OnInit, Output, EventEmitter, ViewChild } from '@angular/core';

import { NgForm } from '@angular/forms';
import { SelectItem } from 'primeng/primeng';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/components/common/messageservice';
import { Properties } from '../../../../../general/properties';
import { FormsService } from '../../../../../../_services';
import {
    DocumentModel, FormModel, EconomicInformationModel,
    FigurePersonModel, FinancialMovementsProductosModel, RelationshipPepPersonModel,
    CatalogModel, FinancialMovementsClaimsModel, PersonFormModel, FinancialMovementsModel
} from 'src/app/_model/documents/models';
import { PersonFormPepModel } from 'src/app/_model/documents/personFormPep';
import { DocumentsService } from 'src/app/_services/api_v1/documents.service';
import { CatalogService } from 'src/app/_services/api_v1/catalogs.service';
import { DeclarationsModel } from 'src/app/_model/documents/declarations';
import { FormAutomaticDebitInformationModel } from 'src/app/_model/documents/formAutomaticDebitInformation';
import { Util } from 'src/app/liberty/general/util';
import { SharedCatalogueService } from '../../../shared-catalogue.service';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { BeneficiaryPersonModel } from 'src/app/_model/documents/beneficiaryPerson';

@Component({
    selector: 'app-datos-declaraciones-co',
    templateUrl: './datosDeclaraciones.html',
    styleUrls: ['./datosDeclaraciones.css']
})

export class DatosDeclaracionesComponent implements OnInit {

    @Output() changed = new EventEmitter<boolean>();
    @ViewChild('form') public userFrm: NgForm;

    properties: Properties = new Properties();
    util: Util = new Util();
    es: any = this.properties.es;
    titulo: string = "Declaraciones";
    subtitulo: string = "Te recomendamos leer atentamente las declaraciones y preguntas a continuación";
    textoDeclaracionCoA = "Tanto mi actividad, profesión u oficio es lícita y la ejerzo dentro del marco legal y los recursos que poseo no provienen de actividades ilícitas de las contempladas en el Código Penal Colombiano.";
    textoDeclaracionCoB = "La información que he suministrado en la solicitud y en este documento es veraz y verificable y me comprometo a actualizarla anualmente.";
    textoDeclaracionCoC = "Los recursos que se deriven del desarrollo de este contrato no se destinarán a la financiación del terrorismo, grupos terroristas o actividades terroristas.";
    textoDeclaracionCoD = "Los recursos que poseo provienen de las siguientes fuentes (detalle ocupación, oficio, actividad o negocio).";
    pais: any;
    idFormulario: any;

    // Catalogos
    tipoDocumentoSelectItem: any[];
    tipoFiguraSelectItem: SelectItem[];
    tipoPaisSelectItem: any[];
    tipoRelacionSelectItem: any[];
    financialEntitySelectItem: any[];
    tipoMonedaSelectItem: any[];
    countriesSelectItem: SelectItem[];
    provinceSelectItem: SelectItem[];
    citySelectItem = [];
    tipoOperacionSelectItem: SelectItem[];
    tipoRamo: SelectItem[];
    chargesSelectItem: SelectItem[] = [];

    // Variables
    respuesta: any;
    respuestas: SelectItem[];
    declaracionObservaciones: any = new DeclarationsModel('CO');
    autorizacionDatos: any;
    informacionVedadera: any;
    step: any;
    nombres: any;
    primerApellido: any;
    segundoApellido: any;
    nombreCompleto: any;
    numeroDocumento: any;
    textoDeclaracionEcu: any;
    display: boolean = false;
    selectCountry: boolean;
    idParentForm: any;
    esFormularioHijo: boolean = false;
    estadoFormularioAux: any;

    acordion1 = false;
    acordion2 = false;
    acordion3 = false;
    acordion4 = false;
    acordion5 = false;
    // Modelos
    // Modelos
    nuevoFormulario = new DocumentModel();
    formulario = new FormModel();
    //entity = new EntityModel();
    economicInformation = new EconomicInformationModel();

    // Listas
    figures = new FigurePersonModel();
    listaProductos = new Array<FinancialMovementsProductosModel>();
    listaPaises = new Array<CatalogModel>();
    listaReclamos = new Array<FinancialMovementsClaimsModel>();
    relationshipPep: RelationshipPepPersonModel[] = [];
    esDiferente: any;
    oparacionesExtranjero: any;
    debitoAutomatico = false;
    loading: boolean;

    // Variables
    today = new Date();
    yesterday = new Date();

    // variables para el conteo de campos dilegenciados
    countDebito = 0;
    listInputsDebito = [];
    meses = [];
    typeAcountItem = [];
    mobile: any;
    bndCamposDebito = false;
    bndValidHeaderDebito = false;
    pagoPoliza = false;
    bndValidHeaderReference = false;

    bndValidHeaderLink = false;
    listInputsLink = [];
    bndCamposLink = false;
    countLink = 0;

    listSections = [];
    bndValidHeaderPep = false;
    listInputsPep = [];
    bndCamposPep = false;
    countPep = 0;
    bndValidHeaderFinancial = false;
    listInputsFinancial = [];
    bndCamposFinancial = false;
    countFinancial = 0;

    tipoPersonaContratante: any;
    paisesSelectItem: any[];
    tipoFormularioHijo: string = null;
    identidadValidado: boolean = false;
    usuarioValidoDocumento: boolean = false
    mostrarValidDoc: boolean = false;
    maxDate = new Date();
    person: PersonFormModel = new PersonFormModel();
    bndValidHeaderDeclaraciones = false

    mostrarNumeroFigura: boolean = false;

    tipoParentezcoSelectItem: any[];
    civilStatusSelectItem = []
    // Factura
    facturaModel = new FigurePersonModel();

    // Debito Automático
    automaticDebit = new FormAutomaticDebitInformationModel();
    tipoDocumento: any;

    resultados: SelectItem[];

    tipoCargoSelectItem: any[];

    countReclamo: number = 0;

    tipoPersona: SelectItem[];
    nationSelectItem: any[];
    countVinculos: number = 0;

    // Beneficiarios
    bndValidHeaderBeneficiary = false;
    listInputsBeneficiary = [];
    bndCamposBeneficiary = false;
    countBeneficiary = 0;
    beneficiaries: Array<BeneficiaryPersonModel>;
    countBeneficiaries = 0;
    generos: SelectItem[] = [];

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private messageService: MessageService,
        public formService: FormsService,
        private catalogService: CatalogService,
        private _documentsService: DocumentsService,
        public _sharedCatalogue: SharedCatalogueService,

    ) {
        this.tipoFiguraSelectItem = [];
        this.respuestas = this.properties.respuestas;
        this.tipoFiguraSelectItem = this.properties.tipoFigura;
        this.tipoRamo = this.properties.tipoRamo;
        this.listaProductos = new Array<FinancialMovementsProductosModel>();
        this.yesterday = new Date(this.today.getTime() - 24 * 60 * 60 * 1000);
        this.tipoOperacionSelectItem = this.properties.tipoOperacionSelectItem;
        this.listSections = ['pep', 'financial', 'link', 'debito', 'beneficiary'];
        for (var i = 1; i <= 10; i++) {
            this.meses.push({ 'mes': i });
        }
        this.typeAcountItem = [{ 'description': 'AHORROS' }, { 'description': 'CORRIENTE' }];
        this.resultados = this.properties.resultados;
        this.tipoPersona = this.properties.tipoPersona;
        this.beneficiaries = [];
        this.generos = this.properties.generos;

    }

    ngOnInit(): void {
        this.mobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
        const id = this.route.snapshot.paramMap.get('idFormulario');
        const country = this.route.snapshot.paramMap.get('idCountry');

        this.idFormulario = id;
        this.pais = country;
        // this.cargarTipoMoneda();
        this.cargarTipoCargo();
        this.cargarTipoRelacion();
        // this.cargarEntidadFinanciera();
        this.cargarTipoDocumento();
        // this.cargarPais();
        // this.getCountries();
        this.consultarFormulario();
        // this.getPaises();
        // this.getCivilStatus();
        // this.cargarTipoParentezco();
        // this.cargarNation();
    }
    validateHasTax(event) {
        if (!event.value) {
            this.listaPaises = []
        }

    }

    cargarNation() {

        this.catalogService.getCatalogs('countries', this.pais)
            .subscribe((resp: any) => {
                this.nationSelectItem = [];

                this.nationSelectItem.push({ 'value': null, 'label': this.properties.labelSelecioneCombo });
                resp.forEach(x => {
                    this.nationSelectItem.push({ 'value': x, 'label': x.description });

                });
            },
                (err: any) => console.log(err),
                () => {
                });

    }
    cargarTipoParentezco() {
        this.catalogService.getCatalogsBasic('relationship', this.pais)
            .subscribe((catalogos: any) => {
                this.tipoParentezcoSelectItem = [];
                this.tipoParentezcoSelectItem.push({ 'value': null, 'label': this.properties.labelSelecioneCombo });
                catalogos.forEach(element => {
                    this.tipoParentezcoSelectItem.push({ 'value': element, 'label': element.description });
                });
            },
                (err: any) => console.log(err),
                () => {
                });
    }


    cargarTipoCargo() {
        this.catalogService.getCatalogs('charges', 'CO')
            .subscribe((resp: any) => {
                this.tipoCargoSelectItem = [];
                this.tipoCargoSelectItem.push({ 'value': null, 'label': this.properties.labelSelecioneCombo });
                resp.forEach(element => {
                    this.tipoCargoSelectItem.push({ 'value': element, 'label': element.description })
                });
            },
                (err: any) => console.log(err),
                () => {
                });
    }

    montoFormat(i) {
        this.listaProductos[i].amount = this.util.formatoMoney(this.listaProductos[i].amount);
    }

    valorFormat(w) {
        this.listaReclamos[w].value = this.util.formatoMoney(this.listaReclamos[w].value);
    }


    getCivilStatus() {
        this.catalogService.getCatalogs(this.properties.civilStatus, this.pais)
            .subscribe((resp: any) => {
                this.civilStatusSelectItem = [];
                this.civilStatusSelectItem.push({ label: this.properties.labelSelecioneCombo, value: null });
                resp.forEach(element => {
                    const catalog = {
                        label: element.description,
                        value: element
                    };
                    this.civilStatusSelectItem.push(catalog);
                });
            },
                (err: any) => console.log(err),
                () => {
                });
    }

    onTabOpen(e) {

        let index = e.index;
        switch (index) {
            case 0:
                this.bndValidHeaderPep = true;
                break;
            case 1:
                this.bndValidHeaderFinancial = true;
                this.bndValidHeaderDebito = true;
                break;
            case 2:
                this.bndValidHeaderDeclaraciones = true;
                break;
            case 3:
                this.bndValidHeaderBeneficiary = true;
                break;
            default:
                break;
        }
    }

    onTabClose(e) {
        let index = e.index;
        this.mobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
        switch (index) {
            case 0:
                this.bndValidHeaderPep = false;
                break;
            case 1:
                this.bndValidHeaderFinancial = false;
                this.bndValidHeaderDebito = false;
                break;
            case 2:
                this.bndValidHeaderDeclaraciones = true;
                break;
            case 3:
                this.bndValidHeaderBeneficiary = false;
                break;
            default:
                break;
        }
    }

    validacionSection(form: NgForm, listSections: Array<String>) {
        const catalog = new CatalogModel();
        this.countPep = 0;
        this.listInputsPep = [];
        this.countFinancial = 0;
        this.listInputsFinancial = [];
        this.countLink = 0;
        this.listInputsLink = [];
        this.countDebito = 0;
        this.listInputsDebito = [];

        const controls = form.controls;
        Object.keys(controls).forEach(input => {
            const nameInput = input.split('_');
            listSections.forEach(section => {
                if (nameInput[1] === section) {
                    switch (section) {
                        case 'pep':
                            this.listInputsPep.push(input);
                            if (form.controls[input].value !== '' || form.controls[input].status !== 'INVALID') {
                                if (form.controls[input].value !== 0) {
                                    if (form.controls[input].value !== null || form.controls[input].status !== 'INVALID') {
                                        if (form.controls[input].value !== undefined && form.controls[input].status !== 'INVALID') {
                                            this.countPep++;
                                        }
                                    }
                                }
                            }
                            break;
                        case 'beneficiary':
                            this.listInputsBeneficiary.push(input);
                            if (form.controls[input].status === 'VALID') {
                                this.countBeneficiary++;
                            }
                            break;
                        case 'financial':
                            this.listInputsFinancial.push(input);
                            if (form.controls[input].value !== '') {
                                if (form.controls[input].value !== 0) {
                                    if (form.controls[input].value !== null && form.controls[input].value !== undefined) {
                                        if (form.controls[input].value.id !== null && form.controls[input].value.id !== '') {
                                            this.countFinancial++;
                                        }
                                    }
                                }
                            }
                            break;
                        case 'link':
                            this.listInputsLink.push(input);
                            if (form.controls[input].value !== '') {
                                if (form.controls[input].value !== 0) {
                                    if (form.controls[input].value !== null && form.controls[input].value !== undefined) {
                                        if (form.controls[input].value.id !== null && form.controls[input].value.id !== '') {
                                            this.countLink++;
                                        }
                                    }
                                }
                            }
                            break;
                        case 'debito':
                            this.listInputsDebito.push(input);
                            if (form.controls[input].value !== '') {
                                if (form.controls[input].value !== 0) {
                                    if (form.controls[input].value !== null && form.controls[input].value !== undefined) {
                                        if (form.controls[input].value.id !== null && form.controls[input].value.id !== '') {
                                            this.countDebito++;
                                        }
                                    }
                                }
                            }
                        default:
                            break;
                    }
                }
                if (this.countPep === this.listInputsPep.length) {
                    this.bndCamposPep = true;
                } else {
                    this.bndCamposPep = false;
                }
                if (this.countBeneficiary === this.listInputsBeneficiary.length) {
                    this.bndCamposBeneficiary = true;
                } else {
                    this.bndCamposBeneficiary = false;
                }
                if (this.countFinancial === this.listInputsFinancial.length) {
                    this.bndCamposFinancial = true;
                } else {
                    this.bndCamposFinancial = false;
                }
                if (this.countLink === this.listInputsLink.length) {
                    this.bndCamposLink = true;
                } else {
                    this.bndCamposLink = false;
                }
                if (this.countDebito === this.listInputsDebito.length) {
                    this.bndCamposDebito = true;
                } else {
                    this.bndCamposDebito = false;
                }
            });
        });
    }

    getDebitValues() {
        if (this.formulario.isPaymentWithAutomaticDebit == true && this.esDiferente === true) {
            this.automaticDebit.accountHolder = `${this.nombreCompleto}`;
            this.automaticDebit.numberIdentification = `${this.numeroDocumento}`;
        } else if (this.formulario.isPaymentWithAutomaticDebit == true && this.esDiferente === false) {
            let figureName;
            if (this.figures.personType === 'N') {
                figureName = `${this.figures.firstName} ${this.figures.secondName} ${this.figures.lastName} ${this.figures.secondLastName}`
            } else {
                figureName = this.figures.businessName;
            }
            this.automaticDebit.accountHolder = figureName;
            this.automaticDebit.numberIdentification = this.figures.document.documentNumber;
        } else {
            this.automaticDebit.accountHolder = '';
            this.automaticDebit.numberIdentification = '';
        }
    }

    validateInput(form: NgForm, key) {
        setTimeout(() => {
            switch (key) {
                case 'pep':
                    this.countPep = 0;
                    this.listInputsPep = [];
                    break;
                case 'beneficiary':
                    this.countBeneficiary = 0;
                    this.listInputsBeneficiary = [];
                    break;
                case 'financial':
                    this.countFinancial = 0;
                    this.listInputsFinancial = [];
                    break;
                case 'link':
                    this.countLink = 0;
                    this.listInputsLink = [];
                    break;
                case 'debito':
                    this.countDebito = 0;
                    this.listInputsDebito = [];
                    break;
                default:
                    break;
            }

            const controls = form.controls;
            Object.keys(controls).forEach(input => {
                const nameInput = input.split('_');
                if (nameInput[1] === key) {
                    switch (key) {
                        case 'pep':
                            this.listInputsPep.push(input);
                            if (form.controls[input].value !== '' || form.controls[input].status !== 'INVALID') {
                                if (form.controls[input].value !== 0) {
                                    if (form.controls[input].value !== null || form.controls[input].status !== 'INVALID') {
                                        if (form.controls[input].value !== undefined && form.controls[input].status !== 'INVALID') {
                                            this.countPep++;
                                        }
                                    }
                                }
                            }
                            break;
                        case 'beneficiary':
                            this.listInputsBeneficiary.push(input);
                            if (form.controls[input].status === 'VALID') {
                                this.countBeneficiary++;
                            }
                            break;
                        case 'financial':
                            this.listInputsFinancial.push(input);
                            if (form.controls[input].value !== '') {
                                if (form.controls[input].value !== 0) {
                                    if (form.controls[input].value !== null && form.controls[input].value !== undefined) {
                                        if (form.controls[input].value.id !== null && form.controls[input].value.id !== '') {
                                            this.countFinancial++;
                                        }
                                    }
                                }
                            }
                            break;
                        case 'link':
                            this.listInputsLink.push(input);
                            if (form.controls[input].value !== '') {
                                if (form.controls[input].value !== 0) {
                                    if (form.controls[input].value !== null && form.controls[input].value !== undefined) {
                                        if (form.controls[input].value.id !== null && form.controls[input].value.id !== '') {
                                            this.countLink++;
                                        }
                                    }
                                }
                            }
                            break;
                        case 'debito':
                            this.listInputsDebito.push(input);
                            if (form.controls[input].value !== '') {
                                if (form.controls[input].value !== 0) {
                                    if (form.controls[input].value !== null && form.controls[input].value !== undefined) {
                                        if (form.controls[input].value.id !== null && form.controls[input].value.id !== '') {
                                            this.countDebito++;
                                        }
                                    }
                                }
                            }
                            break;
                        default:
                            break;
                    }
                }
                if (this.countPep === this.listInputsPep.length) {
                    this.bndCamposPep = true;
                } else {
                    this.bndCamposPep = false;
                }
                if (this.countBeneficiary === this.listInputsBeneficiary.length) {
                    this.bndCamposBeneficiary = true;
                } else {
                    this.bndCamposBeneficiary = false;
                }
                if (this.countFinancial === this.listInputsFinancial.length) {
                    this.bndCamposFinancial = true;
                } else {
                    this.bndCamposFinancial = false;
                }
                if (this.countLink === this.listInputsLink.length) {
                    this.bndCamposLink = true;
                } else {
                    this.bndCamposLink = false;
                }
                if (this.countDebito === this.listInputsDebito.length) {
                    this.bndCamposDebito = true;
                } else {
                    this.bndCamposDebito = false;
                }
            });
        }, 1000);
    }


    getPaises() {
        this.catalogService.getCatalogs(this.properties.countries, this.pais)
            .subscribe((resp: any) => {
                this.paisesSelectItem = [];
                // this.paisesSelectItem.push({ 'code': '', 'description': this.properties.labelSelecioneCombo, 'id': '' });

                resp.forEach(element => {
                    this.paisesSelectItem.push(element)
                });
            },
                (err: any) => console.log(err),
                () => {
                });
    }

    cargarTipoDocumento() {
        this.catalogService.getCatalogs('documentType', this.pais)
            .subscribe((resp: any) => {
                this.tipoDocumentoSelectItem = [];

                this.tipoDocumentoSelectItem.push({ 'value': null, 'label': this.properties.labelSelecioneCombo });
                resp.forEach(x => {
                    this.tipoDocumentoSelectItem.push({ 'value': x, 'label': x.description });

                });
            },
                (err: any) => console.log(err),
                () => {
                });
    }

    cargarTipoRelacion() {
        this.catalogService.getCatalogs('relationship', this.pais)
            .subscribe((resp: any) => {
                this.tipoRelacionSelectItem = [];
                this.tipoRelacionSelectItem.push({ 'value': null, 'label': this.properties.labelSelecioneCombo });
                resp.forEach(element => {
                    this.tipoRelacionSelectItem.push({ 'value': element, 'label': element.description })
                });
            },
                (err: any) => console.log(err),
                () => {
                });
    }

    cargarEntidadFinanciera() {
        this.catalogService.getCatalogs('financialEntity', this.pais)
            .subscribe((resp: any) => {
                this.financialEntitySelectItem = [];
                this.financialEntitySelectItem.push({ 'value': null, 'label': this.properties.labelSelecioneCombo });
                resp.forEach(element => {
                    this.financialEntitySelectItem.push({ 'value': element, 'label': element.description })
                });

            },
                (err: any) => console.log(err),
                () => {
                });
    }

    cargarTipoMoneda() {
        this.catalogService.getCatalogs('currency', 'CO')
            .subscribe((resp: any) => {
                this.tipoMonedaSelectItem = [];
                this.tipoMonedaSelectItem.push({ 'value': null, 'label': this.properties.labelSelecioneCombo });
                resp.forEach(element => {
                    if (element.id != 1) {
                        this.tipoMonedaSelectItem.push({ 'value': element, 'label': element.description })
                    }

                });
            },
                (err: any) => console.log(err),
                () => {
                });
    }

    consultarFormulario() {
        this.loading = true;
        this._documentsService.getDocumentsByDateIdByCountry(this.idFormulario, this.pais)
            .subscribe((resp: any) => {
                this.nuevoFormulario = resp
                this.formulario = resp.data;
                if (resp.data === '') {
                    this.formulario = new FormModel();
                }
                if (this.formulario.automaticDebitInformation == undefined) {
                    this.automaticDebit = new FormAutomaticDebitInformationModel();
                }
                else {
                    this.automaticDebit = this.formulario.automaticDebitInformation;
                }

                if (resp.status === 'REVIEWED') {
                    this.router.navigate(['/formularioCo/finalizadosCo/CO']);
                }
                this.idParentForm = resp.parentFormId;
                if (this.idParentForm) {
                    this.esFormularioHijo = true;

                } else {
                    this.esFormularioHijo = false;
                }


                this.person = this.formulario.person;
                if (this.formulario.person.pep == null) {
                    this.formulario.person.pep = new PersonFormPepModel();
                }
                if (this.person.pep.dateEntry)
                    this.person.pep.dateEntry = new Date(this.person.pep.dateEntry);
                if (this.person.pep.dateUnlinked)
                    this.person.pep.dateUnlinked = new Date(this.person.pep.dateUnlinked);
                this.esDiferente = this.formulario.isContractingBeneficiaryInsured;
                if (this.person.firstName) {
                    this.nombres = this.person.firstName;
                } else {
                    this.nombres = '';
                }
                if (this.person.secondName) {
                    this.nombres += " " + this.person.secondName;
                }

                if (this.person.lastName) {
                    this.primerApellido = this.person.lastName;
                } else {
                    this.primerApellido = '';
                }
                if (this.person.secondLastName) {
                    this.segundoApellido = this.person.secondLastName;
                } else {
                    this.segundoApellido = '';
                }
                this.nombreCompleto = this.nombres + ' ' + this.primerApellido + ' ' + this.segundoApellido;
                this.numeroDocumento = this.person.document.documentNumber;
                this.tipoDocumento = this.person.document.type.description;
                if (this.formulario.figures == null) {
                    this.formulario.figures = new Array<FigurePersonModel>();
                    this.formulario.figures[0] = new FigurePersonModel();
                    this.figures = this.formulario.figures[0];
                    this.mostrarValidDoc = true;
                    if (this.esDiferente == true) {
                        this.facturaModel.firstName = this.person.firstName;
                        this.facturaModel.secondName = this.person.secondName;
                        this.facturaModel.lastName = this.person.lastName;
                        this.facturaModel.secondLastName = this.person.secondLastName;
                        this.facturaModel.document.documentNumber = this.person.document.documentNumber;
                    }
                } else {
                    if (this.formulario.figures[0].birthDate) {
                        this.formulario.figures[0].birthDate = new Date(this.formulario.figures[0].birthDate)
                    }
                    this.figures = this.formulario.figures[0];
                    if (this.esDiferente == false) {
                        this.facturaModel = this.figures;
                    } else if (this.esDiferente == true) {
                        this.facturaModel.firstName = this.person.firstName;
                        this.facturaModel.secondName = this.person.secondName;
                        this.facturaModel.lastName = this.person.lastName;
                        this.facturaModel.secondLastName = this.person.secondLastName;
                        this.facturaModel.document.documentNumber = this.person.document.documentNumber;
                    }
                    this.tipoFormularioHijo = this.formulario.figures[0].personType;
                    if (this.figures.document && this.figures.document.type) {
                        this.mostrarNumeroFigura = true
                    }
                    if (this.formulario.figures[0].document && this.formulario.figures[0].document.type.id !== 3) {
                        this.mostrarValidDoc = true;
                    }
                    this.identidadValidado = true;
                }
                if (this.formulario.relationshipPep == null) {
                    const relacion = new RelationshipPepPersonModel();
                    this.relationshipPep.push(relacion);
                    this.countVinculos = this.relationshipPep.length;
                } else {
                    this.relationshipPep = this.formulario.relationshipPep;
                    this.countVinculos = this.relationshipPep.length;
                }

                if (this.formulario.beneficiaries != null) {
                    this.beneficiaries = this.formulario.beneficiaries;
                    this.countBeneficiaries = this.beneficiaries.length;

                } else {
                    this.beneficiaries = new Array<BeneficiaryPersonModel>();

                }


                if (this.relationshipPep) {
                    this.relationshipPep.forEach(element => {
                        if (element.dateEntry) {
                            element.dateEntry = new Date(element.dateEntry);
                        }
                        if (element.dateUnlinked) {
                            element.dateUnlinked = new Date(element.dateUnlinked);
                        }
                        if (element.otherType) {
                            element.showOtherType = true;
                        }
                    });
                }
                if (!this.formulario.financialMovements) {
                    this.formulario.financialMovements = new FinancialMovementsModel();
                }
                if (this.formulario.financialMovements.products == null) {
                    this.formulario.financialMovements.products = new Array<FinancialMovementsProductosModel>();
                    const producto = new FinancialMovementsProductosModel();
                    this.listaProductos.push(producto);
                    setTimeout(() => {
                        this.loading = true;
                        this.listaProductos.forEach(product => {
                            if (product.country.id === 0) {
                                product.country.id = this.properties.codCatalogCo;
                                this.getProvinceByCountry(product.country.id, 0);
                                this.selectCountry = false;
                            }
                        });
                        this.loading = false;
                    }, 980);

                } else {
                    this.listaProductos = this.formulario.financialMovements.products;
                    setTimeout(() => {
                        this.loading = true;
                        let index = 0;
                        this.listaProductos.forEach(product => {
                            if (product.country.id === this.properties.codCatalogCo) {
                                this.getProvinceByCountry(product.country.id, index);
                                this.selectCountry = false;
                            }
                            index++;
                        });
                        this.loading = false;
                    }, 980);
                }
                if (this.person.countriesTaxObligations != null) {
                    this.listaPaises = this.person.countriesTaxObligations;
                }


                if (this.formulario.financialMovements.claims == null) {
                    this.formulario.financialMovements.claims = new Array<FinancialMovementsClaimsModel>();
                    const reclamo = new FinancialMovementsClaimsModel();
                    this.listaReclamos.push(reclamo);
                } else {
                    this.listaReclamos = this.formulario.financialMovements.claims;
                }
                this.formulario.declarations = this.declaracionObservaciones;
                this.autorizacionDatos = this.formulario.declarations.authorizationProcessingOfPersonalData;
                //this.informacionVedadera = this.formulario.declarations.trueInformationStatement;
                this.step = this.formulario.step;
                this.loading = false;
                setTimeout(() => {
                    this.validacionSection(this.userFrm, this.listSections);
                    this.loading = false;
                }, 1000);
            },
                (err: any) => {
                    console.log(err);
                    this.loading = true;
                });

        if (this.mobile) {
            for (let index = 0; index < 2; index++) {
                let elm = <HTMLElement>document.getElementById('countAccordion' + index);
                if (elm) {
                    elm.style.display = 'none';
                }
            }
            let card = <HTMLElement>document.getElementById('cardIntermediarios');
            card.style.paddingLeft = '10px';
            card.style.paddingRight = '10px';
        }

    }

    cargarPais() {
        this.catalogService.getCatalogs('countries', this.pais)
            .subscribe((resp: any) => {
                this.tipoPaisSelectItem = [];
                this.tipoPaisSelectItem.push({ 'code': '', 'description': this.properties.labelSelecioneCombo, 'id': '' });
                resp.forEach(element => {
                    this.tipoPaisSelectItem.push(element)
                });

            },
                (err: any) => console.log(err),
                () => {
                });
    }


    actualizarFormulario(myForm: NgForm) {
        let count = 0;
        //console.log("Este es el formualrio", myForm)
        if (this.pais === 'CO') {
            if (myForm.valid && this.autorizacionDatos) {
                this.loading = true;
                this.armarFormulario();
                this._documentsService.updateDocument(this.nuevoFormulario)
                    .subscribe((resp: any) => {
                        this.messageService.add({ severity: 'success', summary: '', detail: this.properties.msgSuccessForm });
                        this.router.navigate(['/formularioCo/finalizadosCo/CO']);
                        //  this.changed.emit(true);
                        this.loading = false;
                    },
                        (err: any) => {
                            this.messageService.add({ severity: 'error', summary: '', detail: this.properties.msgErrorForm });
                            this.loading = true;
                        },
                        () => {
                        });
            } else {
                Object.keys(myForm.controls).forEach(element => {
                    if (!myForm.controls[element].valid) {
                        count++;
                    }
                    // if (element === 'informacionVedadera' && !this.informacionVedadera) {
                    //     count++;
                    // }
                    if (element === 'authorizationPersonalData' && !this.autorizacionDatos) {
                        count++;
                    }
                });
                this.messageService.add({ severity: 'error', summary: '', detail: "El formulario aun tiene " + count + " Error(es) revise las alertas en cada campo" });
                this.loading = false;
            }
        } else if (this.pais === 'EC') {
            if (myForm.valid) {
                this.loading = true;
                this.armarFormulario();
                this._documentsService.updateDocument(this.nuevoFormulario)
                    .subscribe((resp: any) => {
                        this.messageService.add({ severity: 'success', summary: '', detail: this.properties.msgSuccessForm });
                        this.changed.emit(true);
                        this.loading = false;
                    },
                        (err: any) => {
                            this.messageService.add({ severity: 'error', summary: '', detail: this.properties.msgErrorForm });
                            this.loading = true;
                        },
                        () => {
                        });

            } else {
                Object.keys(myForm.controls).forEach(element => {
                    if (!myForm.controls[element].valid) {
                        count++;
                    }
                });
                this.messageService.add({ severity: 'error', summary: '', detail: "El formulario aun tiene " + count + " Error(es) revise las alertas en cada campo" });
                this.loading = false;
            }
        }
    }

    armarFormulario() {
        this.estadoFormularioAux = this.properties.estadosDocumentos.REVIEWED;



        if (this.pais == 'CO') {
            this.nuevoFormulario.status = this.estadoFormularioAux;
        }
        let paso = 3;
        if (paso <= this.step) {
            paso = this.step;
        } else {
            this.step = paso;
        }
        this.formulario.step = this.step;
        this.formulario.isContractingBeneficiaryInsured = this.esDiferente;
        this.formulario.relationshipPep = this.relationshipPep;
        this.formulario.beneficiaries = this.beneficiaries;
        this.formulario.declarations = this.declaracionObservaciones;
        //this.formulario.declarations.trueInformationStatement = this.informacionVedadera;
        this.formulario.declarations.authorizationProcessingOfPersonalData = this.autorizacionDatos;
        this.formulario.financialMovements.products = this.listaProductos;
        this.formulario.financialMovements.claims = this.listaReclamos;
        this.formulario.person.countriesTaxObligations = this.listaPaises;
        if (this.formulario.isPaymentWithAutomaticDebit == true) {
            this.formulario.automaticDebitInformation = this.automaticDebit;

        }
        this.nuevoFormulario.data = this.formulario;


    }

    agregar() {
        let relacionPep = new RelationshipPepPersonModel();
        this.relationshipPep.push(relacionPep);
        this.validateInput(this.userFrm, 'pep');
        this.countVinculos = ++this.countVinculos;
    }

    agregarProducto() {
        let producto = new FinancialMovementsProductosModel();
        this.listaProductos.push(producto);
        this.citySelectItem.push([])
        this.validateInput(this.userFrm, 'financial');
    }

    armarPaises(event) {
        this.listaPaises = event;

        this.validateInput(this.userFrm, 'pep');
    }

    showDialog() {
        this.display = true;
    }

    getCountries() {
        this.catalogService.getCatalogs(this.properties.countries, this.pais)
            .subscribe((resp: any) => {
                this.countriesSelectItem = [];
                this.countriesSelectItem.push({ label: this.properties.labelSelecioneCombo, value: null });
                resp.forEach(element => {
                    const catalog = {
                        label: element.description,
                        value: element.id
                    };
                    this.countriesSelectItem.push(catalog);
                });
            },
                (err: any) => console.log(err),
                () => {
                });
    }


    getProvinceByCountry(idPais, index?) {
        let descriptionCountry = null;
        if (this.countriesSelectItem) {
            this.countriesSelectItem.forEach(country => {
                if (country.value === idPais) {
                    descriptionCountry = country.label;
                }
            });
        }
        if (idPais === this.properties.codCatalogEc || idPais === this.properties.codCatalogCo) {
            this.catalogService.getCatalogs(this.properties.province, this.pais, idPais)
                .subscribe((resp: any) => {
                    this.provinceSelectItem = [];
                    this.provinceSelectItem.push({ label: this.properties.labelSelecioneCombo, value: null });
                    this.listaProductos[index].country.description = descriptionCountry;
                    resp.forEach(element => {
                        const catalog = {
                            label: element.description,
                            value: element.id
                        };
                        this.provinceSelectItem.push(catalog);
                        this.selectCountry = false;
                        if (this.listaProductos[index].province.id) {
                            this.getCityByProvince(this.listaProductos[index].province.id, index);
                        }
                    });
                },
                    (err: any) => console.log(err),
                    () => {
                    });
        }
        if (index != null) {
            this.listaProductos[index].country.description = this.countriesSelectItem.find(x => x.value === idPais).label;
        }
        this.validateInput(this.userFrm, 'financial');
    }

    getCityByProvince(idProvince, index) {
        this.catalogService.getCatalogs(this.properties.city, this.pais, idProvince).subscribe((resp: any) => {
            let citySelectItem = [];
            citySelectItem.push({ label: this.properties.labelSelecioneCombo, value: null });
            resp.forEach(element => {
                const catalog = {
                    label: element.description,
                    value: element.id
                };
                citySelectItem.push(catalog);
                this.provinceSelectItem.forEach(province => {
                    if (province.value === idProvince) {
                        this.listaProductos[index].province.description = province.label;
                    }
                });
            });
            this.citySelectItem[index] = citySelectItem
        },
            (err: any) => console.log(err),
            () => {
            });
        this.validateInput(this.userFrm, 'financial');
    }

    updateDescriptionCity(idCity, index) {

        this.listaProductos[index].city.description = this.citySelectItem[index].find(x => x.value === idCity).label;
        this.validateInput(this.userFrm, 'financial');
    }


    agregarReclamo() {
        let reclamo = new FinancialMovementsClaimsModel();
        this.listaReclamos.push(reclamo);
        this.validateInput(this.userFrm, 'financial');
        this.countReclamo = ++this.countReclamo;
    }


    eventoOtraRelacion(event) {

        if (event.type && event.type.id == 11) {
            event.showOtherType = true;
        }
        else {
            event.showOtherType = false;
        }
        this.validateInput(this.userFrm, 'pep');
    }

    eventoOtrafinancialEntity(event) {

        if (event.type.id == 6) {
            event.showOtherType = true;
        }
        else {
            event.showOtherType = false;
        }
        this.validateInput(this.userFrm, 'debito');
    }


    eliminarRelationshipPep(index) {

        for (var i = 0; i < this.relationshipPep.length; i++) {
            if (i == index) {

                this.relationshipPep.splice(i, 1)
            }
        }
        this.validateInput(this.userFrm, 'pep');
        this.countVinculos = this.countVinculos - 1;
    }

    eliminarProducto(index) {
        this.listaProductos.splice(index, 1)
        this.citySelectItem.splice(index, 1)
        if (this.listaProductos.length == 0) {
            this.formulario.financialMovements.haveFinancialProductsAbroad = false
        }
        this.validateInput(this.userFrm, 'financial');
    }

    eliminarReclamo(index) {
        for (var i = 0; i < this.listaReclamos.length; i++) {
            if (i == index) {

                this.listaReclamos.splice(i, 1)
            }
        }
        this.countReclamo = this.countReclamo - 1;
        this.validateInput(this.userFrm, 'financial');
    }

    validarDocumento(numero) {
        this.identidadValidado = false;
        if (numero.length > 9) {

            this.identidadValidado = true;
        } else {
            this.messageService.add({ severity: 'error', summary: '', detail: "El número ingresado no es válido" });
        }
        // if (numero != null && numero != "") {
        //     this.identidadValidado = false;
        //     var suma = 0;
        //     var residuo = 0;
        //     var pri = false;
        //     var pub = false;
        //     var nat = false;
        //     var numeroProvincias = 22;
        //     var modulo = 11;
        //     var provincia: any;
        //     var d1: any;
        //     var d2: any;
        //     var d3: any;
        //     var d4: any;
        //     var d5: any;
        //     var d6: any;
        //     var d7: any;
        //     var d8: any;
        //     var d9: any;
        //     var d10: any;
        //     var p1: any;
        //     var p2: any;
        //     var p3: any;
        //     var p4: any;
        //     var p5: any;
        //     var p6: any;
        //     var p7: any;
        //     var p8: any;
        //     var p9: any;
        //     var p10: any;
        //     var digitoVerificador: any;

        //     /* Verifico que el campo no contenga letras */
        //     var ok = 1;
        //     for (var i = 0; i < numero.length && ok == 1; i++) {
        //         var n = parseInt(numero.charAt(i));
        //         if (isNaN(n)) ok = 0;
        //     }
        //     if (ok == 0) {
        //         this.messageService.add({ severity: 'error', summary: '', detail: "No puede ingresar caracteres en el número" });
        //         return false;
        //     }

        //     if (numero.length < 10) {
        //         this.messageService.add({ severity: 'error', summary: '', detail: "El número ingresado no es válido" });
        //         return false;
        //     }

        //     /* Los primeros dos digitos corresponden al codigo de la provincia */
        //     provincia = numero.substr(0, 2);
        //     if (provincia < 1 || provincia > numeroProvincias) {
        //         this.messageService.add({ severity: 'error', summary: '', detail: "El código de la provincia (dos primeros dígitos) es inválido" });
        //         return false;
        //     }

        //     /* Aqui almacenamos los digitos de la cedula en variables. */
        //     d1 = numero.substr(0, 1);
        //     d2 = numero.substr(1, 1);
        //     d3 = numero.substr(2, 1);
        //     d4 = numero.substr(3, 1);
        //     d5 = numero.substr(4, 1);
        //     d6 = numero.substr(5, 1);
        //     d7 = numero.substr(6, 1);
        //     d8 = numero.substr(7, 1);
        //     d9 = numero.substr(8, 1);
        //     d10 = numero.substr(9, 1);

        //     if (d3 == 7 || d3 == 8) {
        //         this.messageService.add({ severity: 'error', summary: '', detail: "El tercer dígito ingresado es inválido" });
        //         return false;
        //     }

        //     if (d3 < 6) {
        //         nat = true;
        //         p1 = d1 * 2; if (p1 >= 10) p1 -= 9;
        //         p2 = d2 * 1; if (p2 >= 10) p2 -= 9;
        //         p3 = d3 * 2; if (p3 >= 10) p3 -= 9;
        //         p4 = d4 * 1; if (p4 >= 10) p4 -= 9;
        //         p5 = d5 * 2; if (p5 >= 10) p5 -= 9;
        //         p6 = d6 * 1; if (p6 >= 10) p6 -= 9;
        //         p7 = d7 * 2; if (p7 >= 10) p7 -= 9;
        //         p8 = d8 * 1; if (p8 >= 10) p8 -= 9;
        //         p9 = d9 * 2; if (p9 >= 10) p9 -= 9;
        //         modulo = 10;
        //     }

        //     /* Solo para sociedades publicas (modulo 11) */
        //     /* Aqui el digito verficador esta en la posicion 9, en las otras 2 en la pos. 10 */
        //     else if (d3 == 6) {
        //         pub = true;
        //         p1 = d1 * 3;
        //         p2 = d2 * 2;
        //         p3 = d3 * 7;
        //         p4 = d4 * 6;
        //         p5 = d5 * 5;
        //         p6 = d6 * 4;
        //         p7 = d7 * 3;
        //         p8 = d8 * 2;
        //         p9 = 0;
        //     }

        //     /* Solo para entidades privadas (modulo 11) */
        //     else if (d3 == 9) {
        //         pri = true;
        //         p1 = d1 * 4;
        //         p2 = d2 * 3;
        //         p3 = d3 * 2;
        //         p4 = d4 * 7;
        //         p5 = d5 * 6;
        //         p6 = d6 * 5;
        //         p7 = d7 * 4;
        //         p8 = d8 * 3;
        //         p9 = d9 * 2;
        //     }

        //     suma = p1 + p2 + p3 + p4 + p5 + p6 + p7 + p8 + p9;
        //     residuo = suma % modulo;
        //     /* Si residuo=0, dig.ver.=0, caso contrario 10 - residuo*/
        //     digitoVerificador = residuo == 0 ? 0 : modulo - residuo;

        //     /* ahora comparamos el elemento de la posicion 10 con el dig. ver.*/
        //     if (pub == true) {
        //         if (digitoVerificador != d9) {
        //             this.messageService.add({ severity: 'error', summary: '', detail: "El ruc de la empresa del sector público es incorrecto." });
        //             return false;
        //         }
        //         /* El ruc de las empresas del sector publico terminan con 0001*/
        //         if (numero.substr(9, 4) != '0001') {
        //             this.messageService.add({ severity: 'error', summary: '', detail: "El ruc de la empresa del sector público debe terminar con 0001" });
        //             return false;
        //         }
        //         this.tipoPersonaContratante = "pub"
        //     }
        //     else if (pri == true) {
        //         if (digitoVerificador != d10) {
        //             this.messageService.add({ severity: 'error', summary: '', detail: "El ruc de la empresa del sector privado es incorrecto." });
        //             return false;
        //         }
        //         if (numero.substr(10, 3) != '001') {
        //             this.messageService.add({ severity: 'error', summary: '', detail: "El ruc de la empresa del sector privado debe terminar con 001" });
        //             return false;
        //         }
        //         this.tipoPersonaContratante = "pri"
        //     }
        //     else if (nat == true) {
        //         if (digitoVerificador != d10) {
        //             this.messageService.add({ severity: 'error', summary: '', detail: "El número de cédula de la persona natural es incorrecto." });
        //             return false;
        //         }
        //         if (numero.length > 10 && numero.substr(10, 3) != '001') {
        //             this.messageService.add({ severity: 'error', summary: '', detail: "El ruc de la persona natural debe terminar con 001" });
        //             return false;
        //         }
        //         this.tipoPersonaContratante = "nat"
        //     }
        //     this.messageService.add({ severity: 'success', summary: '', detail: 'Número de identificación correcto' });
        //     this.identidadValidado = true;

        //     this.generarFormularioHijo(this.tipoPersonaContratante);
        //     return true;
        // }
        // else {
        //     this.messageService.add({ severity: 'error', summary: '', detail: "El número ingresado no es válido" });
        // }


    }

    eventoTipoDocumento(event) {
        if (event && event.id != null && event.id != null && event.id != "") {
            this.mostrarNumeroFigura = true;
        }
        else {
            this.mostrarNumeroFigura = false;
        }
        this.validateInput(this.userFrm, 'link');

    }

    generarFormularioHijo(event) {
        if (event)
            switch (event.id) {
                case 1:
                    this.tipoFormularioHijo = "N";
                    break;
                case 2:
                    this.tipoFormularioHijo = "J";
                    break;
                case 3:
                    this.tipoFormularioHijo = "N";
                    break;
                default:
                    this.tipoFormularioHijo = "N";
                    break;
            }

    }

    validarCedula(cedula: string) {
        if (cedula.length > 9) {

        } else {
            this.messageService.add({ severity: 'error', summary: '', detail: "El número ingresado no es válido" });
        }
        // Créditos: Victor Diaz De La Gasca.
        // Autor: Arcangel
        // Preguntamos si la cedula consta de 10 digitos
        // if (cedula.length === 10) {

        //     // Obtenemos el digito de la region que sonlos dos primeros digitos
        //     const digitoRegion = cedula.substring(0, 2);

        //     // Pregunto si la region existe ecuador se divide en 24 regiones
        //     if (digitoRegion >= String(1) && digitoRegion <= String(24)) {

        //         // Extraigo el ultimo digito
        //         const ultimoDigito = Number(cedula.substring(9, 10));

        //         // Agrupo todos los pares y los sumo
        //         const pares = Number(cedula.substring(1, 2)) + Number(cedula.substring(3, 4)) + Number(cedula.substring(5, 6)) + Number(cedula.substring(7, 8));

        //         // Agrupo los impares, los multiplico por un factor de 2, si la resultante es > que 9 le restamos el 9 a la resultante
        //         let numeroUno: any = cedula.substring(0, 1);
        //         numeroUno = (numeroUno * 2);
        //         if (numeroUno > 9) {
        //             numeroUno = (numeroUno - 9);
        //         }

        //         let numeroTres: any = cedula.substring(2, 3);
        //         numeroTres = (numeroTres * 2);
        //         if (numeroTres > 9) {
        //             numeroTres = (numeroTres - 9);
        //         }

        //         let numeroCinco: any = cedula.substring(4, 5);
        //         numeroCinco = (numeroCinco * 2);
        //         if (numeroCinco > 9) {
        //             numeroCinco = (numeroCinco - 9);
        //         }

        //         let numeroSiete: any = cedula.substring(6, 7);
        //         numeroSiete = (numeroSiete * 2);
        //         if (numeroSiete > 9) {
        //             numeroSiete = (numeroSiete - 9);
        //         }

        //         let numeroNueve: any = cedula.substring(8, 9);
        //         numeroNueve = (numeroNueve * 2);
        //         if (numeroNueve > 9) {
        //             numeroNueve = (numeroNueve - 9);
        //         }

        //         const impares = numeroUno + numeroTres + numeroCinco + numeroSiete + numeroNueve;

        //         // Suma total
        //         const sumaTotal = (pares + impares);

        //         // extraemos el primero digito
        //         const primerDigitoSuma = String(sumaTotal).substring(0, 1);

        //         // Obtenemos la decena inmediata
        //         const decena = (Number(primerDigitoSuma) + 1) * 10;

        //         // Obtenemos la resta de la decena inmediata - la suma_total esto nos da el digito validador
        //         let digitoValidador = decena - sumaTotal;

        //         // Si el digito validador es = a 10 toma el valor de 0
        //         if (digitoValidador === 10) {
        //             digitoValidador = 0;
        //         }

        //         // Validamos que el digito validador sea igual al de la cedula
        //         if (digitoValidador === ultimoDigito) {

        //             this.messageService.add({ severity: 'success', summary: '', detail: 'Número de identificación correcto' });
        //         } else {

        //             this.messageService.add({ severity: 'error', summary: '', detail: "El número ingresado no es válido" });
        //             this.figures.document.documentNumber = "";

        //         }

        //     } else {
        //         // imprimimos en consola si la region no pertenece
        //         this.messageService.add({ severity: 'error', summary: '', detail: "El número ingresado no es válido" });
        //         this.figures.document.documentNumber = "";

        //     }
        // } else {
        //     // Imprimimos en consola si la cedula tiene mas o menos de 10 digitos
        //     this.messageService.add({ severity: 'error', summary: '', detail: "El número ingresado no es válido" });
        //     this.figures.document.documentNumber = "";

        // }

    }
    validarProductos(event) {
        if (!event.value)
            this.listaProductos = []
        if (event.value && this.listaProductos.length == 0)
            this.agregarProducto()

    }
    aceptoTodo(autorizacionDatos) {

        // if (autorizacionDatos == true) {
        //     this.formulario.declarations.authorizationTreatmentFinancialCommercialCreditInformation = true;
        //     this.formulario.declarations.authorizationForTransferAndDataTransmission = true;
        //     this.formulario.declarations.authorizationDataProcessing = true;
        //     this.formulario.declarations.personalDataProcessingClause = true;
        // } else {
        //     this.formulario.declarations.authorizationTreatmentFinancialCommercialCreditInformation = false;
        //     this.formulario.declarations.authorizationForTransferAndDataTransmission = false;
        //     this.formulario.declarations.authorizationDataProcessing = false;
        //     this.formulario.declarations.personalDataProcessingClause = false;
        // }
    }

    facturar(form, isPagador) {
        this.facturaModel = new FigurePersonModel();
        if (isPagador == true) {
            this.facturaModel = this.figures;

        } else if (isPagador == false) {
            this.facturaModel.firstName = this.person.firstName;
            this.facturaModel.secondName = this.person.secondName;
            this.facturaModel.lastName = this.person.lastName;
            this.facturaModel.secondLastName = this.person.secondLastName;
            this.facturaModel.document.documentNumber = this.person.document.documentNumber;
        }
        this.validateInput(form, 'link');
    }

    onTabCloseA(param) {
        if (param === '1') {
            this.acordion1 = false;
        } else if (param === '2') {
            this.acordion2 = false;
        } else if (param === '3') {
            this.acordion3 = false;
        } else if (param === '4') {
            this.acordion4 = false;
        }
    }

    onTabOpenA(param) {

        if (param) {

            if (param === '1') {
                this.acordion1 = true;
            } else if (param === '2') {
                this.acordion2 = true;
            } else if (param === '3') {
                this.acordion3 = true;
            } else if (param === '4') {
                this.acordion4 = true;
            }
        }
    }

    addBeneficiary() {
        const beneficiary = new BeneficiaryPersonModel();
        this.beneficiaries.push(beneficiary);
        this.countBeneficiaries = ++this.countBeneficiaries;
        this.validateInput(this.userFrm, 'beneficiary');
    }

    deleteBeneficiary(index) {
        for (let i = 0; i < this.beneficiaries.length; i++) {
            if (i === index) {
                this.beneficiaries.splice(i, 1);
            }
        }
        this.validateInput(this.userFrm, 'beneficiary');
        this.countBeneficiaries = this.countBeneficiaries - 1;
    }




}

