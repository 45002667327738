import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AccesoComponent } from './acceso/acceso.component';
import { CambiarNumeroComponent } from './cambiarNumero/cambiarNumero.component';
import { BienvenidaComponent } from './bienvenida/bienvenida.component';
import { RegistroComponent } from './registro/registro.component';
import { FinalizacionComponent } from './finalizacion/finalizacion.component';
import { FinalComponent } from './finalizacionCo/final.component';
const routes: Routes = [
    {
        path: 'formulario/acceso/:idFormulario/:idCountry',
        component: AccesoComponent
    },
    {
        path: 'formulario/cambiarNumero/:idFormulario/:idCountry',
        component: CambiarNumeroComponent
    },
    {
        path: 'formulario/caducado',
        component: BienvenidaComponent
    },
    {
        path: 'formulario/notFound',
        component: BienvenidaComponent
    },
    {
        path: 'formulario/registro/:idFormulario/:idCountry',
        component: RegistroComponent
    },
    {
        path: 'formulario/finalizado',
        component: FinalizacionComponent
    },
    {
        path: 'formularioCo/finalizadosCo/CO',
        component: FinalComponent
    },
    {
        path: 'formularioCo/finalizadosCo/CO',
        component: FinalComponent
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class FormularioRoutingModule { }
