//angular
import { Component, OnInit, Input } from '@angular/core';
//liberty-properties
import { Properties } from '../../../general/properties';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/components/common/messageservice';
import { CatalogsService, FormsService } from '../../../../_services';
import { ApiResponse } from '../../../../_services/model-formulario/modelsForm';
import { DocumentsService } from 'src/app/_services/api_v1/documents.service';
import { TOKE_NAME, TOKE_NAME_OAUTH, CURRENT_FORM_OAUTH } from 'src/app/_shared/var.constant';

@Component({
    selector: 'acceso-ec',
    templateUrl: './acceso.html',
    styleUrls: ['../acceso.css']
})

export class AccesoComponentEc implements OnInit {

    properties: Properties = new Properties();
    data: any;
    tituloAcceso: string = 'Para continuar debemos validar tu identidad';
    subTituloAcceso: string = 'Te enviaremos un código de seguridad al siguiente número';

    // Variables
    loading: boolean;
    bandera: number = 0;
    banderaBloqueado: number = 0;
    numeroAcceso: number;
    numeroIngresado: number = 0;
    contadorIntentos: any = null;
    validacion: number = 0;
    cellPhoneNumber: any;
    estadoFormulario: any;
    estadoFormularioAux: any;
    //General
    pais: any;
    idFormulario: any;

    // Modelos
    nuevoFormulario: any;
    formulario: any;

    // Tipo Persona
    entityType: any;
    maximoIntentos = 3;

    fechaVigencia: any;
    fechaActual = new Date();

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private messageService: MessageService,
        public formService: FormsService,
        private _documentsService: DocumentsService
    ) { }

    ngOnInit(): void {
        let id = this.route.snapshot.paramMap.get('idFormulario');
        let country = this.route.snapshot.paramMap.get('idCountry');
        this.idFormulario = id;
        this.pais = country;
        // this.consultarFormulario(country, id);
        this.getLinkingForm();
    }

    getLinkingForm() {
        // let user = JSON.parse(sessionStorage.getItem('access_token'));
        this._documentsService.events(this.idFormulario, this.pais, "GET_PERSON_URL").subscribe((document: any) => {
            if (document && document != null) {
                if (document.status == 'LOCKED' || this.fechaVigencia < this.fechaActual) {
                    this.router.navigate(['/formulario/caducado']);
                } else {
                    if (document.status == 'PENDING') {
                        if (document.data && document.data != null) {
                            this.nuevoFormulario = null;
                            this.formulario = document.data;
                            if (this.formulario.personType === 'J') {
                                this.cellPhoneNumber = this.formulario.company.contacts.cellPhoneNumber.description;
                            } else if (this.formulario.personType === 'N') {
                                this.cellPhoneNumber = this.formulario.person.contacts.cellPhoneNumber.description;
                            }
                            this.estadoFormulario = document.status;
                            if (document.sms.locked) {
                                this.router.navigate(['/formulario/caducado']);
                                this.banderaBloqueado = 1;
                            } else {
                                this.banderaBloqueado = 0;
                            }
                            this.loading = false;
                        } else {
                            this.pais == 'EC' ? this.router.navigate(['/formulario/finalizado']) : this.router.navigate(['/formularioCo/finalizadosCo/CO']);
                        }
                    } else {
                        this.pais == 'EC' ? this.router.navigate(['/formulario/finalizado']) : this.router.navigate(['/formularioCo/finalizadosCo/CO']);
                    }

                }
            }
        }, err => {

            this.loading = true;
        });

    }

    enviarCodigo() {
        this.loading = true;
        this.armarFormularioEnvioSms();
        this._documentsService.events(this.idFormulario, this.pais, 'SUBMIT_SMS_FORM_CODE').subscribe((resp: ApiResponse) => {
            this.bandera = 1;
            this.tituloAcceso = 'Ingrese el código de verificación';
            this.subTituloAcceso = 'Hemos enviado un código de 6 dígitos al número seleccionado';
            //this.numeroAcceso = Number(atob(resp.data[0]));
            this.loading = false;
        },
            (err: any) => {
                this.messageService.add({ severity: 'error', summary: '', detail: '¡No se actualizó correctamente su número!' });
                this.loading = true;
            });
    }

    validarAcceso() {
        let code = {
            "code": btoa(this.numeroIngresado + "")
        }
        this._documentsService.logingForm(this.idFormulario, this.pais, code).subscribe((resp: any) => {

            if (resp.access_token) {
                sessionStorage.setItem(TOKE_NAME_OAUTH, JSON.stringify(resp));
                sessionStorage.setItem(CURRENT_FORM_OAUTH, this.idFormulario + "&" + this.pais)
                this.messageService.add({ severity: 'success', summary: 'Exito', detail: '¡Código correcto!' });
                this.irRegistro();
            } else {
                let intentos = JSON.parse(resp)
                if (intentos && intentos.intentosFallidos) {
                    this.contadorIntentos = this.maximoIntentos - intentos.intentosFallidos
                    this.messageService.add({ severity: 'error', summary: 'Acceso', detail: 'Código incorrecto tienes' + ' ' + this.contadorIntentos + ' ' + 'intentos más' });
                }
            }

        },
            (err: any) => {
                this.router.navigate(['/formulario/caducado']);
                this.banderaBloqueado = 1;
                this.messageService.add({ severity: 'error', summary: '', detail: '¡Ha bloquedo su formulario!' });
                this.loading = true;
            }
        );
    }


    irRegistro() {
        this.router.navigate(['formulario/registro', this.idFormulario, this.pais]);
    }


    bloquearFormulario() {
        this.armarFormulario()
        this.formService.intlLatamEcMilfUpdateLinkingForm(this.idFormulario, this.nuevoFormulario).subscribe((resp: ApiResponse) => {

        },
            (err: any) => {
                this.messageService.add({ severity: 'error', summary: '', detail: '¡No se actualizó correctamente su formulario!' });
            },
            () => {
            });
    }

    armarFormulario() {
        this.estadoFormularioAux = "LOCKED";
        this.formulario.formStatus = this.estadoFormularioAux;
        this.nuevoFormulario = this.formulario
    }

    armarFormularioEnvioSms() {
        //  this.formulario.url.attempt = this.contadorIntentos;
        this.nuevoFormulario = this.formulario;
    }

    guardarIntentos() {
        this.armarIntentos()
        this.formService.intlLatamEcMilfUpdateLinkingForm(this.idFormulario, this.nuevoFormulario).subscribe((resp: ApiResponse) => {
        },
            (err: any) => {
            },
            () => {
            });
    }

    armarIntentos() {
        this.formulario.url.attempt = this.contadorIntentos;
        this.nuevoFormulario = this.formulario
    }

    test(e: any) {
        //console.log("Entro al test: ", e);
    }

}