import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '../../../../node_modules/@angular/router';

import { RolesService, AccessService, ApiResponse, UsersService, GroupsService } from '../../_services';
import { ReqcodeModel } from '../../_model/security/reqcode';
import { JwtHelperService } from '../../../../node_modules/@auth0/angular-jwt';
import { TOKE_NAME_OAUTH, TOKEN_ID, CODE } from '../../_shared/var.constant';
import { environment } from '../../../environments/environment';
import { AppComponent } from '../../app.component';
import { AppTopBarComponent } from '../../app.topbar.component';
import { LoginService } from 'src/app/_services/login.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  //styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  URL_LOGOUT: string = environment.URL_LOGOUT;
  modelCode: ReqcodeModel;
  code: string;
  helper = new JwtHelperService();
  // @Output() cambio = new EventEmitter<boolean>();
  @Output() cambioNombre = new EventEmitter<boolean>();


  constructor(private route: ActivatedRoute,
    private router: Router,
    private loginService: LoginService,
    private usersService: UsersService,
    private groupsService: GroupsService,
    private roleService: RolesService,
    private accesService: AccessService) { }

  ngOnInit() {
    // console.log("Ingressando a login");
    // this.loginService.loginOauth().subscribe(data => {
    //   console.log("respuest ", data)
    //   sessionStorage.setItem(TOKE_NAME_OAUTH, JSON.stringify(data));
    // });

    // this.modelCode = new ReqcodeModel();

    // if (this.loginService.estaLogueado()) {
    //   return true;
    // } else {
    //   this.code = localStorage.getItem(CODE);
    //   if (this.code != 'undefined' && this.code != null && this.code != 'null') {
    //     this.modelCode.code = this.code;
    //     this.accesService.intlLatamEcSecureGetTokenClient(this.modelCode, TOKEN_ID).subscribe((resp: ApiResponse) => {
    //       localStorage.removeItem(CODE);
    //       if (resp['data']['error']) {
    //         console.log("Error ", resp)
    //       } else {
    //         let respuesta = resp.data;
    //         if (respuesta['access_token']) {
    //           let bodyToken = this.helper.decodeToken(respuesta['access_token']);
    //           let user = { email: undefined == bodyToken.email ? bodyToken.unique_name : bodyToken.email, name: bodyToken.name, roles: [] };
    //           respuesta['user'] = user;

    //           sessionStorage.setItem(TOKE_NAME, JSON.stringify(resp.data));
    //           this.roleService.intlLatamEcFormSecureGetRolesByUserId(user.email).subscribe((resp: ApiResponse) => {
    //             this.usersService.intlLatamEcFormSecureGetUserByEmail(user.email).subscribe((respA: any) => {
    //               if (respA.code = "200") {
    //                 this.groupsService.intlLatamEcFormSecureGetGroupMembersByUserId(respA.data.value[0].id).subscribe((respG: any) => {
    //                   if (resp.data == null) {
    //                     this.cerrarSesion();
    //                   } else {
    //                     if (resp.data[0].userStatus == 0) {
    //                       this.cerrarSesion();
    //                     } else {
    //                       let tk = JSON.parse(sessionStorage.getItem(TOKE_NAME));
    //                       tk.user.roles = resp.data[0].rolesId;
    //                       tk.user["groupId"] = respG.data[0].id;
    //                       tk.user["brokerId"] = isNaN(respG.data[0].displayName) == false ? Number(respG.data[0].displayName) : 0;
    //                       sessionStorage.setItem(TOKE_NAME, JSON.stringify(tk));
    //                       this.router.navigate(['home']);
    //                       this.cambioNombre.emit(true);
    //                     }
    //                   }
    //                 },
    //                   (err: any) => console.log(err),
    //                   () => {
    //                   });
    //               }
    //             },
    //               (err: any) => console.log(err),
    //               () => {
    //               });
    //           })
    //         } else {
    //           this.loginService.cerrarSesion();
    //           this.router.navigate(['landing']);
    //         }
    //       }
    //     })
    //   } else {
    //     return true;
    //     // this.loginService.cerrarSesion();
    //     // this.router.navigate(['landing']);
    //   }
    // }
  }

  // cerrarSesion() {
  //   this.loginService.cerrarSesion();
  //   window.location.href = this.URL_LOGOUT;
  // }

}
