import { Component, OnInit } from '@angular/core';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-hogar',
  templateUrl: './hogar.component.html',
  styleUrls: ['./hogar.css']
})
export class HogarComponent implements OnInit {
  ngOnInit(): void {
    //  throw new Error("Method not implemented.");
  }

  mobile: any;
  urlAutos = environment.URL_NEGOCIOS_AUTOS_NUEVO;

  constructor() { }

  redirec() {
    window.location.assign(this.urlAutos);
  }

}