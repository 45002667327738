import { TOKE_NAME } from "../_shared/var.constant";
import { HttpClient, HttpHeaders } from "../../../node_modules/@angular/common/http";
import { Router } from "../../../node_modules/@angular/router";
import { Injectable } from "../../../node_modules/@angular/core";

@Injectable({
    providedIn: 'root'
})

export class LoginService {

    constructor(private http: HttpClient, private router: Router){}
    
    estaLogueado(){
        let token = sessionStorage.getItem(TOKE_NAME);
        return token != null;
    }

    cerrarSesion(){
        sessionStorage.clear();
    }

    getRoles(){
        return JSON.parse(sessionStorage.getItem(TOKE_NAME)).user.roles;
    }

    getUser(){
        return JSON.parse(sessionStorage.getItem(TOKE_NAME)).user.name;
    }

    getEmail(){
        return JSON.parse(sessionStorage.getItem(TOKE_NAME)).user.email;
    }

    getShowMenu(){
        let resp = sessionStorage.getItem('sendTermsConditions');
       //console.log("rep ", resp)
        if(resp != null && resp === 'true'){
           // console.log("no muestres")
            return false;
        } else {
            //console.log("Muestra menu")
            return true
        }
        
    }
}