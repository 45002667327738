import { Routes, RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { ReportePolizasComponent } from './liberty/reportes/reporte-polizas/reporte-polizas.component';
import { UsuariosComponent } from './liberty/seguridad/usuarios/usuarios.component';
import { GruposComponent } from './liberty/seguridad/grupos/grupos.component';
import { ReglasComponent } from './liberty/seguridad/reglas/reglas.component';
import { MenusComponent } from './liberty/seguridad/menus/menus.component';
import { RolesComponent } from './liberty/seguridad/roles/roles.component';
import { SociosComponent } from './liberty/seguridad/socios/socios.component';
import { FormularioComponent } from './liberty/formulario/formulario.component';
import { GestionFormularioComponent } from './liberty/gestionFormulario/gestionFormulario.component';
import { TermsComponentCo } from './liberty/terms/termsCo/terms.component';
import { TermsComponentEc } from './liberty/terms/termsEc/terms.component';
import { PoliciesComponentEc } from './liberty/policies/policiesEc/policies.component';
import { PoliciesComponentCo } from './liberty/policies/policiesCo/policies.component';
import { NegociosComponent } from './liberty/negocios/negocios.component';
import { HomeComponent } from './liberty/home/home.component';
import { IncentivosComponent } from './liberty/mis-negocios/incentivos/incentivos.component';
import { GuardService } from './_handler/guard.service';
import { PolizaComponent } from './liberty/polizas/poliza.component';
import { PolizaRevisionComponent } from './liberty/polizas/poliza-revision/poliza-revision.component';
import { LandingComponent } from './liberty/lading/landing.component';
import { Not403Component } from './liberty/not403/not403.component';
import { LoginComponent } from './liberty/login/login.component';
import { TermCondicionesComponent } from './liberty/terminosCondiciones/termCondiciones.component';


// Control de Cambios
import { TramitesComponent } from './liberty/tramites/tramites.component';
import { RenovationsComponent } from './liberty/renovations/renovations.component';
import { FormularioRevisionComponent } from './liberty/tramites/formulario-revision/formulario-revision.component';
import { PolizaReviewComponent } from './liberty/tramites/poliza-review/poliza-review.component';
import { ReporteFormularioComponent } from './liberty/reportes/reporte-formulario/reporte-formulario.component';
import { SolicitudesComponent } from './liberty/solicitudes/solicitudes.component';
import { CuentaComponent } from './liberty/cuenta/cuenta.component';
import { SolicitudesEndComponent } from './liberty/solicitudesEnd/solicitudesEnd.component';
import { BpmComponent } from './liberty/bpm/bpm.component';
import { AcademiaComponent } from './liberty/academiaLib/academia.component';
import { MisSolicitudesComponent } from './liberty/misSolicitudes/mis-solicitudes.component';
import { AutoInspectionsComponent } from './liberty/autoInspections/autoInspections.component';
import { InspectionsCComponent } from './liberty/autoInspections/inspectionsCreated/inspections-c.component';
import { CotizadorComponent } from './liberty/cotizadorRedirect/cotizador.component';


export const routes: Routes = [
    {
        path: 'landing', component: LandingComponent
    },
    {
        path: 'polizas', component: PolizaComponent, children: [
            {
                path: ':idpv',
                component: PolizaRevisionComponent
            }
        ]
    },
    { path: 'reportes/reportePolizas', component: ReportePolizasComponent, canActivate: [GuardService] },
    { path: 'reportes/reporteFormularios', component: ReporteFormularioComponent },
    { path: 'usuarios', component: UsuariosComponent, canActivate: [GuardService] },
    { path: 'grupos', component: GruposComponent, canActivate: [GuardService] },
    { path: 'reglas', component: ReglasComponent, canActivate: [GuardService] },
    { path: 'menus', component: MenusComponent, canActivate: [GuardService] },
    { path: 'roles', component: RolesComponent, canActivate: [GuardService] },
    { path: 'socios', component: SociosComponent, canActivate: [GuardService] },
    { path: 'formulario/:idFormulario/:idCountry', component: FormularioComponent },
    { path: 'gestionFormulario', component: GestionFormularioComponent, canActivate: [GuardService] },
    { path: 'negocios', component: NegociosComponent, canActivate: [GuardService] },
    { path: 'autoInspections', component: InspectionsCComponent},
    { path: 'inspections', component: AutoInspectionsComponent, canActivate: [GuardService]},
    { path: 'home', component: HomeComponent, canActivate: [GuardService] },
    { path: 'renovations', component: RenovationsComponent, canActivate: [GuardService] },
    { path: 'incentivos', component: IncentivosComponent, canActivate: [GuardService] },
    // { path: 'login', component: LoginComponent },
    { path: '', redirectTo: 'home', pathMatch: 'full' },
    { path: 'not403', component: Not403Component },
    { path: 'termCondiciones', component: TermCondicionesComponent, canActivate: [GuardService] },
    { path: 'terms', component: TermsComponentEc },
    { path: 'termsCo/CO', component: TermsComponentCo },
    { path: 'policies', component: PoliciesComponentEc },
    { path: 'policiesCo/CO', component: PoliciesComponentCo },
    {
        path: 'tramites', component: TramitesComponent, children: [
            { path: 'idpo', component: PolizaReviewComponent },
            { path: 'idfv', component: FormularioRevisionComponent }
        ], canActivate: [GuardService]
    },
    { path: 'formulario/:idFormulario/:idCountry', component: FormularioComponent },
    { path: 'solicitudes', component: SolicitudesComponent, canActivate: [GuardService] },
    { path: 'cuenta', component: CuentaComponent },
    { path: 'solicitudesFin/:idCode', component: SolicitudesEndComponent, canActivate: [GuardService] },
    { path: 'bpm', component: BpmComponent, canActivate: [GuardService] },
    { path: 'academy', component: AcademiaComponent, canActivate: [GuardService] },
    { path: 'cotizador', component: CotizadorComponent, canActivate: [GuardService] },
    { path: 'misSols', component: MisSolicitudesComponent, canActivate: [GuardService] }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutes { }
