import { Component, OnInit } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { environment } from '../../../../environments/environment';

@Component({
    selector: 'app-autos',
    templateUrl: './autos.component.html',
    styleUrls: ['./autos.css']
})
export class AutosComponent implements OnInit {
    items: MenuItem[];
    activeItem: MenuItem;
    acordion1 = true;
    acordion2 = false;
    acordion3 = false;
    acordion4 = false;
    acordion5 = false;
    mobile: any;
    preguntaFr1 = true;
    preguntaFr2 = true;
    preguntaFr3 = true;
    preguntaFr4 = true;
    preguntaFr5 = true;
    urlAutos = environment.URL_NEGOCIOS_AUTOS_OLD;

    ngOnInit(): void {
       // console.log('urlMisNegocios >>> '+ this.urlAutos);
        this.mobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
        setTimeout(() => {
            this.responsive();
           // this.estyloPr1();
        }, 100);
    }

    responsive() {
        // const elm1 = <HTMLElement>document.getElementById('negocio');
        // const elm2 = <HTMLElement>document.getElementById('labelNeg');
        const elm3 = <HTMLElement>document.getElementById('imgNeg');

        if (this.mobile) {
            // elm1.style.width = '140px';
            // elm1.style.height = '58px';
            // elm1.style.marginTop = '98px';
            // elm1.style.marginLeft = '20px';
            // elm2.style.fontSize = '1.5em';
            elm3.style.height = '255px';



        }

    }

    onTabClose(event) {
        if (event.index) {
            this.acordion1 = false;
            this.acordion2 = false;
            this.acordion3 = false;
            this.acordion4 = false;
            this.acordion5 = false;
        }
    }

    onTabOpen(event) {
        if (event) {
            if (event.index == 0) {
                this.acordion1 = true;
                this.acordion2 = false;
                this.acordion3 = false;
                this.acordion4 = false;
                this.acordion5 = false;
            } else if (event.index === 1) {
                this.acordion1 = false;
                this.acordion2 = true;
                this.acordion3 = false;
                this.acordion4 = false;
                this.acordion5 = false;
            } else if (event.index === 2) {
                this.acordion1 = false;
                this.acordion2 = false;
                this.acordion3 = true;
                this.acordion4 = false;
                this.acordion5 = false;
            } else if (event.index === 3) {
                this.acordion1 = false;
                this.acordion2 = false;
                this.acordion3 = false;
                this.acordion4 = true;
                this.acordion5 = false;
            } else if (event.index === 4) {
                this.acordion1 = false;
                this.acordion2 = false;
                this.acordion3 = false;
                this.acordion4 = false;
                this.acordion5 = true;
            }
        }
    }

    verPreguntas(cod) {
        if (cod === 1) {
            this.preguntaFr1 = true;
            this.preguntaFr2 = true;
            this.preguntaFr3 = true;
            this.preguntaFr4 = true;
            this.preguntaFr5 = true;
            this.estyloPr1();
        } else if (cod === 2) {
            this.preguntaFr1 = false;
            this.preguntaFr2 = true;
            this.preguntaFr3 = true;
            this.preguntaFr4 = false;
            this.preguntaFr5 = false;
            this.estyloPr2();
        } else if (cod === 3) {
            this.preguntaFr1 = false;
            this.preguntaFr2 = false;
            this.preguntaFr3 = false;
            this.preguntaFr4 = true;
            this.preguntaFr5 = false;
            this.estyloPr3();
        } else if (cod === 4) {
            this.preguntaFr1 = false;
            this.preguntaFr2 = false;
            this.preguntaFr3 = false;
            this.preguntaFr4 = false;
            this.preguntaFr5 = true;
            this.estyloPr4();
        }
    }

    estyloPr1() {
        const elm1 = <HTMLElement>document.getElementById('vrT');
        const elm2 = <HTMLElement>document.getElementById('pro');
        const elm3 = <HTMLElement>document.getElementById('prd');
        const elm4 = <HTMLElement>document.getElementById('her');
        elm1.style.backgroundColor = '#99E5EA';
        elm1.style.borderRadius = '40px';
        elm2.style.backgroundColor = 'transparent';
        elm3.style.backgroundColor = 'transparent';
        elm4.style.backgroundColor = 'transparent';
    }

    estyloPr2() {
        const elm1 = <HTMLElement>document.getElementById('vrT');
        const elm2 = <HTMLElement>document.getElementById('pro');
        const elm3 = <HTMLElement>document.getElementById('prd');
        const elm4 = <HTMLElement>document.getElementById('her');
        elm2.style.backgroundColor = '#99E5EA';
        elm2.style.borderRadius = '40px';
        elm1.style.backgroundColor = 'transparent';
        elm3.style.backgroundColor = 'transparent';
        elm4.style.backgroundColor = 'transparent';
    }

    estyloPr3() {
        const elm1 = <HTMLElement>document.getElementById('vrT');
        const elm2 = <HTMLElement>document.getElementById('pro');
        const elm3 = <HTMLElement>document.getElementById('prd');
        const elm4 = <HTMLElement>document.getElementById('her');
        elm3.style.backgroundColor = '#99E5EA';
        elm3.style.borderRadius = '40px';
        elm2.style.backgroundColor = 'transparent';
        elm1.style.backgroundColor = 'transparent';
        elm4.style.backgroundColor = 'transparent';
    }

    estyloPr4() {
        const elm1 = <HTMLElement>document.getElementById('vrT');
        const elm2 = <HTMLElement>document.getElementById('pro');
        const elm3 = <HTMLElement>document.getElementById('prd');
        const elm4 = <HTMLElement>document.getElementById('her');
        elm4.style.backgroundColor = '#99E5EA';
        elm4.style.borderRadius = '40px';
        elm2.style.backgroundColor = 'transparent';
        elm3.style.backgroundColor = 'transparent';
        elm1.style.backgroundColor = 'transparent';
    }


    redirec(){
      
        window.location.assign(this.urlAutos);
    }
    
    

}

