/**
 * intl-latam-ec-portal-documents
 * No description
 *
 * OpenAPI spec version: 1.0.0
 * Contact: you@your-company.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { CatalogModel } from './catalog';
import { FigurePersonAutomaticDebitModel } from './figurePersonAutomaticDebit';
import { FigurePersonContactsModel } from './figurePersonContacts';
import { FigurePersonDocumentModel } from './figurePersonDocument';


export class FigurePersonModel { 
    type: any;
    typeRelationship: CatalogModel;
    /**
     * Primer nombre.
     */
    firstName: string;
    /**
     * Segundo nombre.
     */
    secondName: string;
    /**
     * Apellido.
     */
    lastName: string;
    /**
     * Segundo apellido.
     */
    secondLastName: string;
    document: FigurePersonDocumentModel;
    contacts: FigurePersonContactsModel;
    /**
     * ¿Ésta persona pagará la póliza?
     */
    thisWillPersonPayPolicy?: boolean;
    /**
     * Razón social  si el tipo de persona es Juridica.
     */
    businessName?: string;
    personType?: string;
    birthDate
 
    constructor(){
        this.type = null;
        this.typeRelationship = null;
        this.firstName = null;
        this.secondName = null;
        this.lastName = null;
        this.secondLastName = null;
        this.document = new FigurePersonDocumentModel ();
        this.contacts = new FigurePersonContactsModel();
       
    }
}

