import { Component, OnInit } from '@angular/core';
import { BreadcrumbService } from 'src/app/breadcrumb.service';
import { SelectItem } from 'primeng/primeng';
import { Properties } from '../general/properties';
import { CatalogService } from 'src/app/_services/api_v1/catalogs.service';
import { StateAccountService } from '../../_services/api_v1/state-account.service';
import { FormsService } from '../../_services/api_v1/forms.service';
import { ACCOUNT_CONST } from './cuenta.constants';
import { MessageService } from 'primeng/components/common/messageservice';


@Component({
  selector: 'app-cuenta',
  templateUrl: './cuenta.component.html',
  styleUrls: ['./cuenta.component.css']
})
export class CuentaComponent implements OnInit {

  titulo: string;
  subtitulo1: string;
  subtitulo1_1: string;
  subtitulo2: string;
  subtitulo2_1: string;
  subtitulo2_2: string;

  properties: Properties = new Properties();
  tipoDocumento: any;
  numeroDocumento: any;

  brokerId: string;


  // Catalogos
  tipoDocumentoSelectItem: SelectItem[] = [{ label: this.properties.labelSelecioneCombo, value: null }];

  documents: any[];
  selectedDocument: any;
  fileName: any;

  constructor(
    private breadcrumbService: BreadcrumbService,
    private catalogService: CatalogService,
    private stateAccountService: StateAccountService,
    private readonly messageService: MessageService, ) {
    this.brokerId = JSON.parse(sessionStorage.getItem('access_token')).user.brokerId; // TODO 
    // this.brokerId = '1790048772001'

  }


  ngOnInit() {
    this.cargarBreadCrum();
    this.setConstants();
    this.cargarTipoDocumento();
  }

  /**
   * Set constants
   */
  setConstants() {
    this.titulo = ACCOUNT_CONST.TITLE;
    this.subtitulo1 = ACCOUNT_CONST.SUBTITLE1;
    this.subtitulo1_1 = ACCOUNT_CONST.SUBTITLE1_1;
    this.subtitulo2 = ACCOUNT_CONST.SUBTITLE2;
    this.subtitulo2_1 = ACCOUNT_CONST.SUBTITLE2_1;
    this.documents = ACCOUNT_CONST.DOCUMENTS;
  }

  cargarBreadCrum() {
    this.breadcrumbService.setItems([
      { label: 'Inicio', routerLink: ['/home'] },
      { label: 'Estado de cuenta', routerLink: ['/cuenta'] }
    ]);
  }

  cargarTipoDocumento() {
    this.catalogService.getCatalogs('documentType', 'EC')
      .subscribe((catalogos: any) => {
        catalogos.forEach(element => {
          this.tipoDocumentoSelectItem.push({ label: element.description, value: element.id });
        });
      },
        (err: any) => console.log(err),
        () => {
        });
  }


  limpiar(form) {
    if (form) {
      form.onReset();
    }
    this.tipoDocumento = null;
    this.numeroDocumento = null;
    this.selectedDocument = null;
  }

  /**
   * @author atello-barcia
   * Generates pdf report
   */
  generateStateAccountByClient() {
    // console.log(this.getBody(ACCOUNT_CONST.ISNOTWALLETBROKER));
    this.stateAccountService.intlLatamEcGetStateAccount(this.getBody(ACCOUNT_CONST.ISNOTWALLETBROKER))
      .subscribe(response => {
        this.fileName = null;
        this.fileName = response.fileName;
        // console.log(this.fileName);
        if (response.data != undefined) {
          if (response.data.pdf) {
            this.toPdf(response.data.pdf);
            this.messageService.add({
              severity: ACCOUNT_CONST.MSG.SUC.SEVERITY, summary: ACCOUNT_CONST.MSG.SUC.SUMMARY,
              detail: `${this.fileName + ACCOUNT_CONST.REPORTS.PDF_EXT}`
            });
          } else {
            this.messageService.add({
              severity: ACCOUNT_CONST.MSG.ERR.SEVERITY, summary: ACCOUNT_CONST.MSG.ERR.SUMMARY, detail: ACCOUNT_CONST.MSG.USER_NOT_FOUND
            });
          }

        } else {
          this.messageService.add({
            severity: ACCOUNT_CONST.MSG.ERR.SEVERITY, summary: ACCOUNT_CONST.MSG.ERR.SUMMARY, detail: ACCOUNT_CONST.MSG.USER_NOT_FOUND
          });
        }

      }, (err) => this.messageService.add({
        severity: ACCOUNT_CONST.MSG.ERR.SEVERITY, summary: ACCOUNT_CONST.MSG.ERR.SEVERITY, detail: ACCOUNT_CONST.MSG.USER_NOT_FOUND
      }));
  }

  /**
   * @author atello-barcia
   * Generates excel report for all clients of
   * the current broker
   */
  generateAllStateAccount() {
    //console.log(this.getBody(ACCOUNT_CONST.ISWALLETBROKER));
    this.stateAccountService.intlLatamEcGetStateAccount(this.getBody(ACCOUNT_CONST.ISWALLETBROKER))
      .subscribe(response => {
        this.fileName = null;
        this.fileName = response.fileName;
        if (response.data != undefined) {
          if (response.data.xls) {
            this.toExcel(response.data.xls);
            this.messageService.add({
              severity: ACCOUNT_CONST.MSG.SUC.SEVERITY, summary: ACCOUNT_CONST.MSG.SUC.SUMMARY,
              detail: `${this.fileName + ACCOUNT_CONST.REPORTS.EXCEL_EXT}`
            });
          } else {
            this.messageService.add({
              severity: ACCOUNT_CONST.MSG.ERR.SEVERITY, summary: ACCOUNT_CONST.MSG.ERR.SUMMARY, detail: ACCOUNT_CONST.MSG.USER_NOT_FOUND
            });
          }

        }
        else {
          this.messageService.add({
            severity: ACCOUNT_CONST.MSG.ERR.SEVERITY, summary: ACCOUNT_CONST.MSG.ERR.SUMMARY, detail: ACCOUNT_CONST.MSG.USER_NOT_FOUND
          });

        }

      }, (err) => this.messageService.add({
        // severity: ACCOUNT_CONST.MSG.ERR.SEVERITY, summary: ACCOUNT_CONST.MSG.ERR.SEVERITY, detail: err
        severity: ACCOUNT_CONST.MSG.ERR.SEVERITY, summary: ACCOUNT_CONST.MSG.ERR.SEVERITY, detail: ACCOUNT_CONST.MSG.USER_NOT_FOUND
      }));
  }

  /**
   * @author atello-barcia
   * Build body request object
   */
  getBody(isBrokerWallet: number): object {
    return {
      Agente: {
        NroDocumento: this.brokerId
      },
      Asegurado: {
        TipoDocumento: this.tipoDocumento ? this.tipoDocumento.toString() : '',
        NroDocumento: this.numeroDocumento,
      },
      EsCarteraBroker: isBrokerWallet,
    };
  }

  /**
   * Converts base64 data to pdf and dowload the file
   * @param pdfData to convert
   */
  toPdf(pdfData: any) {
    const pdfElement = document.createElement('a');
    pdfElement.href = `${ACCOUNT_CONST.REPORTS.PDF + pdfData}`;
    pdfElement.download = `${this.fileName + ACCOUNT_CONST.REPORTS.PDF_EXT}`;
    document.body.appendChild(pdfElement);
    pdfElement.click();
    document.body.removeChild(pdfElement);
  }

  /**
   * Converts xml to excel and dowload the file
   * @param xmlData xml data to convert
   */
  toExcel(xmlData: any) {
    const excelElement = document.createElement('a');
    excelElement.href = `${ACCOUNT_CONST.REPORTS.EXCEL + xmlData}`;
    excelElement.download = `${this.fileName + ACCOUNT_CONST.REPORTS.EXCEL_EXT}`;
    document.body.appendChild(excelElement);
    excelElement.click();
    document.body.removeChild(excelElement);
  }

}
