import { Component, OnInit, Input } from '@angular/core';
import { MessageService } from 'primeng/components/common/messageservice';
import { SelectItem } from 'primeng/primeng';
import { Router } from '@angular/router';
import { Properties } from '../general/properties';
import { FormsService, RulesService } from '../../_services';
import { ApiResponse } from '../../_services/model-formulario/modelsForm';
import { Constantes } from '../../_shared/var.constant';

@Component({
  selector: 'app-gestionFormulario',
  templateUrl: './gestionFormulario.html',
})
export class GestionFormularioComponent implements OnInit {

  properties: Properties = new Properties();
  constantes: Constantes = new Constantes();
  titulo: string;
  subtitulo: string;
  formularios: any;
  es: any = this.properties.es;
  ventana = false;
  nombres: any;

  // Variables
  broker: any;
  pais: any;
  forms: any;
  figura: any;
  estadoId: any;
  firmaId: any;

  // Modelos
  newForm: any;
  observacion: any;

  acciones = false;
  userRol: any;
  blockedDocument = false;
  gestion = false;
  formulario = false;
  padre = false;



  // Combos
  tipoEstadoSelectItem: SelectItem[] = [
    { label: this.properties.labelSelecioneCombo, value: null },
    { label: 'PENDIENTE', value: 'PENDING' },
    { label: 'OBSERVADO', value: 'OBSERVED' },
    { label: 'FINALIZADO', value: 'FINALIZED' },
    { label: 'BLOQUEADO', value: 'LOCKED' },
    { label: 'EXPIRADO', value: 'EXPIRED' }
  ];
  tipoFirmaSelectItem: SelectItem[] = [
    { label: this.properties.labelSelecioneCombo, value: null },
    { label: 'CANCELADO', value: 'CANCELLED' },
    { label: 'EXPIRADO', value: 'EXPIRED' },
    { label: 'FALLIDO', value: 'FAILED' },
    { label: 'RECHAZADO', value: 'REJECTED' },
    { label: 'FIRMADO', value: 'SIGNED' },
    { label: 'ENVIADO', value: 'SENT' },
  ];


  constructor(
    private router: Router,
    private messageService: MessageService,
    public formService: FormsService,
    public rulesServices: RulesService,
  ) { }

  ngOnInit(): void {
    // this.broker = 'lapaunte@kruger.com.ec';
    this.titulo = 'Gestión de Formularios';
    this.subtitulo = 'Text';
    this.pais = 'EC';
    const currentUser = JSON.parse(sessionStorage.getItem('access_token'));
    if (currentUser) {
      this.userRol = currentUser.user.roles;
      this.broker = currentUser.user.email;
      //console.log(this.userRol + ' ' + this.broker);
      this.cargarFormularios();
    }
    this.seguridadGestionarFormulario();
    this.seguridadVerFormulario();
    this.seguridadVerFormularioPadre();
  }

  seguridadGestionarFormulario() {
    // this.blockedDocument = true;
    const gestionar = {
      rolesId: this.userRol,
      rule: this.constantes.gestionForm
    };
    this.rulesServices.intlLatamEcFormSecureVerifyComponentAccess(gestionar).subscribe((resp: ApiResponse) => {
      //console.log(resp);
      const auxcode = parseInt(resp.code + '', 10);
      if (auxcode === 200) {
        this.blockedDocument = false;
        this.gestion = true;
        this.acciones = true;
      }
      this.blockedDocument = false;
    },
      (err: any) => console.log(err),
      () => {
        this.blockedDocument = false;
      });
  }

  seguridadVerFormulario() {
    // this.blockedDocument = true;
    const gestionar = {
      rolesId: this.userRol,
      rule: this.constantes.verFormulario
    };
    this.rulesServices.intlLatamEcFormSecureVerifyComponentAccess(gestionar).subscribe((resp: ApiResponse) => {
      //console.log(resp);
      const auxcode = parseInt(resp.code + '', 10);
      if (auxcode === 200) {
        this.blockedDocument = false;
        this.formulario = true;
        this.acciones = true;
      }
      this.blockedDocument = false;
    },
      (err: any) => console.log(err),
      () => {
        this.blockedDocument = false;
      });
  }

  seguridadVerFormularioPadre() {
    const gestionar = {
      rolesId: this.userRol,
      rule: this.constantes.verFormPadre
    };
    this.rulesServices.intlLatamEcFormSecureVerifyComponentAccess(gestionar).subscribe((resp: ApiResponse) => {
      //console.log(resp);
      const auxcode = parseInt(resp.code + '', 10);
      if (auxcode === 200) {
        this.blockedDocument = false;
        this.padre = true;
        this.acciones = true;
      }
      this.blockedDocument = false;
    },
      (err: any) => console.log(err),
      () => {
        this.blockedDocument = false;
      });
  }

  cargarFormularios() {
    const country = this.pais;
    const brogerAux = this.broker;
    let filter = '';
    let status = '';
    let evicertia = '';

    if (this.figura == null || this.figura == undefined) {
      filter = '';
    } else {
      filter = this.figura;
    }

    if (this.estadoId == null || this.estadoId == undefined) {
      status = '';
    } else {
      status = this.estadoId;
    }

    if (this.firmaId == null || this.firmaId == undefined) {
      evicertia = '';
    } else {
      evicertia = this.firmaId;
    }

    this.formService.intlLatamEcMilfGetLinkingFormByBrokerId(country, brogerAux, evicertia, status, filter)
      .subscribe((resp: ApiResponse) => {
        if (resp != undefined) {
          this.forms = [];
          this.forms = resp.data;
          this.forms.forEach(element => {
            if (element.idParentForm != 0) {
              element.esHijo = 1;
            }
            if (element.entityType == 'N') {
              if (element.entity.person) {
                let nombres: any;
                let apellidoPaterno: any;
                let apellidoMaterno: any;
                if (element.entity.person.firstName) {
                  nombres = element.entity.person.firstName;
                } else {
                  nombres = '';
                }
                if (element.entity.person.lastName) {
                  apellidoPaterno = element.entity.person.lastName;
                } else {
                  apellidoPaterno = '';
                }
                if (element.entity.person.secondLastName) {
                  apellidoMaterno = element.entity.person.secondLastName;
                } else {
                  apellidoMaterno = '';
                }
                element.names = nombres + ' ' + apellidoPaterno + ' ' + apellidoMaterno;
              } else {
                element.names = '';
              }
              if (element.entity.person) {
                if (element.entity.person.document) {
                  element.documentNumber = element.entity.person.document.documentNumber;
                }
              } else {
                element.documentNumber = '';
              }

              if (element.entity.person) {
                if (element.entity.person.cellPhoneNumber) {
                  element.cellPhoneNumber = element.entity.person.cellPhoneNumber.description;
                }
              } else {
                element.cellPhoneNumber = '';
              }

              if (element.entity.person) {
                if (element.entity.person.email) {
                  element.email = element.entity.person.email.description;
                }
              } else {
                element.email = '';
              }

              if (element.formStatus) {
                switch (element.formStatus) {
                  case 'PENDING':
                    element.state = 'PENDIENTE';
                    break;
                  case 'OBSERVED':
                    element.state = 'OBSERVADO';
                    break;
                  case 'FINALIZED':
                    element.state = 'FINALIZADO';
                    break;
                  case 'LOCKED':
                    element.state = 'BLOQUEADO';
                    break;
                  case 'EXPIRED':
                    element.state = 'EXPIRADO';
                    break;
                }
              } else {
                element.state = '';
              }

              switch (element.step) {
                case 0:
                  element.paso = 'PENDIENTE';
                  break;
                case 1:
                  element.paso = 'PERSONAL';
                  break;
                case 2:
                  element.paso = 'FINANCIERA';
                  break;
                case 3:
                  element.paso = 'DECLARACIONES';
                  break;
                case 4:
                  element.paso = 'DOCUMENTOS';
                  break;
              }

              if (element.evicertia) {
                switch (element.evicertia.outcome) {
                  case 'CANCELLED':
                    element.firma = 'CANCELADO';
                    break;
                  case 'EXPIRED':
                    element.firma = 'EXPIRADO';
                    break;
                  case 'FAILED':
                    element.firma = 'FALLIDO';
                    break;
                  case 'REJECTED':
                    element.firma = 'RECHAZADO';
                    break;
                  case 'SIGNED':
                    element.firma = 'FIRMADO';
                    break;
                  case 'SENT':
                    element.firma = 'ENVIADO';
                    break;
                }
              } else {
                element.firma = '';
              }



            }
            if (element.entityType == 'J') {
              if (element.entity.company) {
                element.names = element.entity.company.businessName;
              } else {
                element.names = '';
              }
              if (element.entity.company) {
                if (element.entity.company.document) {
                  element.documentNumber = element.entity.company.document.documentNumber;
                } else {
                  element.documentNumber = '';
                }
              } else {
                element.names = '';
              }

              if (element.entity.legalRepresentative) {
                if (element.entity.legalRepresentative.person) {
                  element.cellPhoneNumber = element.entity.legalRepresentative.person.cellPhoneNumber.description;
                } else {
                  element.cellPhoneNumber = '';
                }
              } else {
                element.cellPhoneNumber = '';
              }

              if (element.entity.legalRepresentative) {
                if (element.entity.legalRepresentative.person) {
                  element.email = element.entity.legalRepresentative.person.email.description;
                } else {
                  element.email = '';
                }
              } else {
                element.email = '';
              }

              if (element.formStatus) {
                switch (element.formStatus) {
                  case 'PENDING':
                    element.state = 'PENDIENTE';
                    break;
                  case 'OBSERVED':
                    element.state = 'OBSERVADO';
                    break;
                  case 'FINALIZED':
                    element.state = 'FINALIZADO';
                    break;
                  case 'LOCKED':
                    element.state = 'BLOQUEADO';
                    break;
                  case 'EXPIRED':
                    element.state = 'EXPIRADO';
                    break;
                }
              } else {
                element.state = '';
              }
              switch (element.step) {
                case 0:
                  element.paso = 'PENDIENTE';
                  break;
                case 1:
                  element.paso = 'BASICA';
                  break;
                case 2:
                  element.paso = 'FINANCIERA';
                  break;
                case 3:
                  element.paso = 'DECLARACIONES';
                  break;
                case 4:
                  element.paso = 'DOCUMENTOS';
                  break;
              }

              if (element.evicertia) {
                switch (element.evicertia.signatureStatus) {
                  case 'CANCELLED':
                    element.firma = 'CANCELADO';
                    break;
                  case 'EXPIRED':
                    element.firma = 'EXPIRADO';
                    break;
                  case 'FAILED':
                    element.firma = 'FALLIDO';
                    break;
                  case 'REJECTED':
                    element.firma = 'RECHAZADO';
                    break;
                  case 'SIGNED':
                    element.firma = 'FIRMADO';
                    break;
                  case 'SENT':
                    element.firma = 'ENVIADO';
                    break;
                }
              } else {
                element.firma = '';
              }

            }
          });

        }
      },
        (err: any) => {
          //console.log(err);
        }
      );
  }



  confirmar(form) {
    //console.log(form);
    this.nombres = form.names;
    this.ventana = true;
    this.newForm = form;

  }

  abrirFormulario(form) {
    //console.log(form);
    const idFormulario = form.id;
    const country = form.country;
    this.router.navigate(['formulario/registro', idFormulario, country]);
  }

  abrirFormularioHijo(form) {
    const idFormulario = form.idParentForm;
    const country = form.country;
    this.router.navigate(['formulario/registro', idFormulario, country]);
  }

  cambiarEstado() {
    delete this.newForm['names'];
    delete this.newForm['documentNumber'];
    delete this.newForm['cellPhoneNumber'];
    delete this.newForm['email'];
    delete this.newForm['state'];
    delete this.newForm['paso'];
    if (this.newForm['esHijo']) {
      delete this.newForm['esHijo'];
    }
    //console.log(this.newForm);
    this.newForm.formStatus = 'PENDING';
    this.formService.intlLatamEcMilfUpdateLinkingForm(this.newForm.id, this.newForm).subscribe((resp: ApiResponse) => {
      this.messageService.add({ severity: 'success', summary: '', detail: this.properties.msgSuccessForm });
    },
      (err: any) => {
        this.messageService.add({ severity: 'error', summary: '', detail: '¡No se actualizó correctamente su número!' });
      },
      () => {
      });

  }

}
