/**
 * intl-latam-ec-portal-documents
 * No description
 *
 * OpenAPI spec version: 1.0.0
 * Contact: you@your-company.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { CatalogModel } from './catalog';
import { PlaceAddressInformationModel } from './placeAddressInformation';
import { WorkContactsModel } from './workContacts';


export class WorkModel {
    economicActivity?: CatalogModel;
    typeEconomicActivity?:CatalogModel;
    /**
     * Razón social de trabajo o negocio donde labora.
     */
    businessName?: string;
    ciuu?: CatalogModel;
    /**
     * Fuente de ingresos, aplica solo Ecuador.
     */
    sourceIncome?: string;
    /**
     * Cargo.
     */
    charge?: string;
    /**
     * Otro cargo.
     */
    otherCharge?: string;
    job?: CatalogModel;
    profession?: CatalogModel;
    /**
     * Tipo de producto/servicio que comercializa, aplica solo Colombia.
     */
    productServiceSells?: string;
    placeAddressWork?: PlaceAddressInformationModel;
    contacts?: WorkContactsModel;
    sourceIncomeDescription: string;
    otherLaboralType? :string;
    constructor() {
 
        this.economicActivity = null;
        this.typeEconomicActivity = null;
        this.businessName = null;
        this.ciuu = null;
        this.sourceIncome = null;
        this.charge = null;
        this.otherCharge = null;
        this.job = null;
        this.profession= null;
        this.productServiceSells = null;
        this.placeAddressWork = new PlaceAddressInformationModel();
        this.contacts = new WorkContactsModel();
        this.otherLaboralType = null;
    }
}
