import { Rol } from '../../_services/model-seguridad/rol';

export class RolModel implements Rol {
    docType?: string;
    id?: string;
    rulesId?: Array<string>;
    menusId?: Array<string>;
    name?: string;
    restricted?: number;
    x: string;

    constructor() {
     this.docType = 'rol';
     this.id = null;
     this.rulesId = [];
     this.menusId = [];
     this.name = null;
     this.restricted = null;
     this.x = null;
    }
}
