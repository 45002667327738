import { AfterViewInit, Component, ElementRef, Inject, OnInit, Renderer2, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { DOCUMENT, Location } from '@angular/common';
import { BreadcrumbService } from 'src/app/breadcrumb.service';

@Component({
  selector: 'app-inspections-c',
  templateUrl: './inspections-c.component.html',
  styleUrls: ['./inspections-c.component.css']
})
export class InspectionsCComponent implements OnInit, AfterViewInit {
  @ViewChild('showQ') showQ: ElementRef;
  inspectionId: string;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private location: Location,
    private breadcrumbService: BreadcrumbService
  ) {
  }

  chargeBreadCrum() {
    this.breadcrumbService.setItems([
      { label: 'Inicio', routerLink: ['/home'] },
      { label: 'Gestión de solicitudes' },
      { label: 'Nueva inspección de vehículo', routerLink: ['/autoInspections'] }
    ]);
  }

  ngOnInit() {
    this.chargeBreadCrum();
    const inspectionId = this.route.snapshot.queryParams.inspectionId;
    this.inspectionId = inspectionId;
    if (inspectionId) {
      const urlT = this.router.createUrlTree([], {
        relativeTo: this.route,
        queryParams: {},
      });
      this.location.go(urlT.toString());
    } else {
      this.router.navigate(['/inspections']);
    }
  }

  goToNewInspection() {
    this.router.navigate(['/inspections']);
  }

  ngAfterViewInit(): void {
    this.showQ.nativeElement.click();
  }
}
