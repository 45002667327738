import { Menu } from "src/app/_services/model-seguridad/models";

export class MenuModel implements Menu {
    docType?: string;
    id?: string;
    url?: string;
    path?: string;
    status?: string;
    name?: string;
    description?: string;
    parentId?: string;
    sort?: number;
    x: string;

    constructor() {
        this.docType = 'menu';
        this.id = null;
        this.url = null;
        this.path = null;
        this.status = null;
        this.name = null;
        this.description = null;
        this.parentId = null;
        this.sort = 0;
        this.x = null;
    }
}