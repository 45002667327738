/**
 * intl-latam-ec-milf-linkingform
 * Manage the information of the linking form.
 *
 * OpenAPI spec version: 2.1.0
 * Contact: kruger@kruger.com.ec
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Catalog } from './catalog';
import { Person } from './person';


export interface RelationshipPep { 
    type?: Catalog;
    otherType?: string;
    personType?: RelationshipPep.PersonTypeEnum;
    person?: Person;
    charge?: string;
    entity?: string;
    dateEntry?: Date;
    dateUnlinked?: Date;
    isSubjectTaxObligationsAnotherCountry?: boolean;
    specifyCountry?: Catalog;
}
export namespace RelationshipPep {
    export type PersonTypeEnum = 'N' | 'J';
    export const PersonTypeEnum = {
        N: 'N' as PersonTypeEnum,
        J: 'J' as PersonTypeEnum
    };
}
