import { Component, OnInit } from '@angular/core';
import { BreadcrumbService } from '../../breadcrumb.service';
import { MenuItem } from 'primeng/api';
import { RulesService } from 'src/app/_services';
import { Constantes } from 'src/app/_shared/var.constant';
import { ApiResponse } from 'src/app/_model/policy/apiResponse';


@Component({
    selector: 'app-negocios',
    templateUrl: './negocios.component.html',
    styleUrls: ['./negocios.css']
})
export class NegociosComponent implements OnInit {
    constantes = new Constantes();
    items: MenuItem[];
    activeItem: MenuItem;
    itemSelect = 0;
    mobile: any;
    userRol: any;
    verAutos = false;
    verHogar = false;
    verSearchPortal = false;
    blockedDocument = false;

    constructor(private breadcrumbService: BreadcrumbService,
        public rulesServices: RulesService) { }

    ngOnInit(): void {
        this.cargarBreadCrum();
        const currentUser = JSON.parse(sessionStorage.getItem('access_token'));
        this.userRol = currentUser.user.roles;
        this.consultSecure();
        this.mobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
        setTimeout(() => {
            this.responsive();

        }, 100);

    }

    cargarBreadCrum() {
        this.breadcrumbService.setItems([
            { label: 'Inicio', routerLink: ['/home'] },
            { label: 'Póliza Express' },
        ]);
    }

    consultSecure() {
        this.seguridadVerAutos();
        this.seguridadVerHogar();
        this.seguridadVerSearchPortal();
    }

    selectApp(item) {
        this.itemSelect = item;
    }
    responsive() {
        const elm1 = <HTMLElement>document.getElementById('negocio');
        const elm2 = <HTMLElement>document.getElementById('labelNeg');
        const elm3 = <HTMLElement>document.getElementById('imgNeg');
        const elm4 = <HTMLElement>document.getElementById('contenedorImg');
        const elm5 = <HTMLElement>document.getElementById('bienvenidos');
        //  const elm6 = <HTMLElement>document.getElementById('cuerpoConsultas');


        if (this.mobile) {
            elm1.style.width = '140px';
            elm1.style.height = '58px';
            elm1.style.marginTop = '98px';
            elm1.style.marginLeft = '20px';
            elm2.style.fontSize = '1.4em';
            elm2.style.padding = '10px';
            elm2.style.paddingTop = '19px';
            elm3.style.height = '255px ! important';
            elm4.style.width = '100%';
            elm4.style.marginTop = '-5%';
            elm5.style.marginTop = '-4%';
            elm5.style.marginLeft = '0%';
            elm5.style.position = 'absolute';
            //    elm6.style.width = '100%';



        }

    }

    seguridadVerSearchPortal() {
        this.blockedDocument = true;
        const viewAuto = {
            rolesId: this.userRol,
            rule: this.constantes.verSearchPortal
        };
        this.rulesServices.intlLatamEcFormSecureVerifyComponentAccess(viewAuto).subscribe((resp: ApiResponse) => {
            const auxcode = parseInt(resp.code + '', 10);
            if (auxcode === 200) {
                this.blockedDocument = false;
                this.verSearchPortal = true;
                this.viewDisplay();
            }
            this.blockedDocument = false;
        },
            (err: any) => console.log(err),
            () => {
                this.blockedDocument = false;
            });
    }

    seguridadVerAutos() {
        this.blockedDocument = true;
        const viewAuto = {
            rolesId: this.userRol,
            rule: this.constantes.verAutos
        };
        this.rulesServices.intlLatamEcFormSecureVerifyComponentAccess(viewAuto).subscribe((resp: ApiResponse) => {
            const auxcode = parseInt(resp.code + '', 10);
            if (auxcode === 200) {
                this.blockedDocument = false;
                this.verAutos = true;
                this.viewDisplay();
            }
            this.blockedDocument = false;
        },
            (err: any) => console.log(err),
            () => {
                this.blockedDocument = false;
            });
    }

    seguridadVerHogar() {
        this.blockedDocument = true;
        const viewHogar = {
            rolesId: this.userRol,
            rule: this.constantes.verHogar
        };
        this.rulesServices.intlLatamEcFormSecureVerifyComponentAccess(viewHogar).subscribe((resp: ApiResponse) => {
            const auxcode = parseInt(resp.code + '', 10);
            if (auxcode === 200) {
                this.blockedDocument = false;
                this.verHogar = true;
                this.viewDisplay();
            }
            this.blockedDocument = false;
        },
            (err: any) => console.log(err),
            () => {
                this.blockedDocument = false;
            });
    }



    viewDisplay() {
        if (this.verAutos && (this.verHogar || this.verSearchPortal)) {
            this.itemSelect = 1;
        } else if (this.verAutos) {
            this.itemSelect = 1;
        } else if (this.verHogar) {
            this.itemSelect = 2;
        } else if (this.verSearchPortal) {
            this.itemSelect = 3;
        }
    }
}