import { Component, OnInit } from '@angular/core';
import { Properties } from '../../general/properties';
import { MenuItem } from 'primeng/primeng';
import { ActivatedRoute } from '@angular/router';
import { Router, Params } from '@angular/router';
import { MessageService } from 'primeng/components/common/messageservice';
import { FormsService } from '../../../_services';
import { DocumentsService } from 'src/app/_services/api_v1/documents.service';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';

@Component({
    selector: 'registro',
    templateUrl: './registro.html',
    styleUrls: ['./registro.css']
})

export class RegistroComponent implements OnInit {

    properties: Properties = new Properties();
    formulario: any;
    items: MenuItem[];
    activeIndex: number = 0;
    step: number = 0;

    // Origen
    origen: number;
    mobile: any;
    style: any;

    // Generales
    pais: any;
    idFormulario: any;
    entityType: any;

    // Tipo Persona
    tipoPersona: any = null;

    //Variables
    cambio: boolean = false;
    verVolver = false
    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private messageService: MessageService,
        public formService: FormsService,
        private _documentsService: DocumentsService
    ) {
    }

    onChange(agreed: boolean) {
        //console.log("Notifico que se hizo un cambio");
        this.cambio = agreed;
        if (this.cambio == true) {
            this.ngOnInit();
            var ua = window.navigator.userAgent;
            var is_ie = ua.indexOf("MSIE ") > -1 || ua.indexOf("Trident/") > -1;
            // Si es IE el navegador recargue la pantalla
            if (is_ie) {
                setTimeout(() => {
                    location.reload();
                }, 1000);
            }

        }
    }


    ngOnInit(): void {
        let user = JSON.parse(sessionStorage.getItem('access_token'));
        if (user && user.user && user.user.brokerId) {
            this.verVolver = true;
        }
        this.mobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
        let id = this.route.snapshot.paramMap.get('idFormulario');
        let country = this.route.snapshot.paramMap.get('idCountry');
        this.pais = country;
        this.idFormulario = id;
        this.tipoPersona = null;
        if (this.pais == "EC") {
            this.origen = 0
        }
        else {
            this.origen = 1
        }
        this.getLinkingForm();
        this.cambio = false;
        setTimeout(() => {
            this.resizeTop();
        }, 1000);
    }

    resizeTop() {
        if (this.mobile) {
            const elm = <HTMLElement>document.getElementById('titleEntidad');
            const pnTop = <HTMLElement>document.getElementById('pnTop');
            const headerStepT = <HTMLElement>document.getElementById('headerStepTitle');
            if (elm != null) {
                elm.style.marginLeft = '0px';
            }
            if (pnTop != null) {
                pnTop.style.marginTop = '100px';
            }
            if (headerStepT != null) {
                headerStepT.style.paddingTop = '0px';
            }
            const titleHeader = <HTMLElement>document.getElementById('headerStepTitle');
            if (titleHeader != null) {
                titleHeader.style.marginLeft = '0px';
            }
            this.style = '0px';
        }
    }

    async getLinkingForm() {
        await this._documentsService.getDocumentsByDateIdByCountry(this.idFormulario, this.pais).subscribe((document: any) => {
            // document = this.testForm;
            if (document && document != null) {
                if (document.data && document.data != null) {
                    this.formulario = document.data;
                }
                if (document.code != "404") {
                    if (document.status == 'REVIEWED' || document.status == 'SIGNED') {
                        if (this.origen === 0)
                            this.router.navigate(['/formulario/finalizado']);
                        else
                            this.router.navigate(['/formularioCo/finalizadosCo/CO']);
                    } else {
                        this.step = this.formulario.step;
                        this.entityType = this.formulario.personType;
                        if (this.entityType == 'N') {
                            this.tipoPersona = 0;
                        }
                        else {
                            this.tipoPersona = 1;
                        }
                        this.activeIndex = (this.step > 0) ? this.step : 0;
                        // Esto para prueba
                        // this.activeIndex = 2
                        //this.tipoPersona = 0

                        if (this.tipoPersona != null) {
                            this.buildStep();
                        }
                    }
                }

            }
        },
            (err: any) => console.log(err),
            () => {
            });
    }

    buildStep() {

        if (this.tipoPersona == 0) {
            this.items = [
                {
                    label: 'Personal',
                    disabled: this.step < 0,
                    command: (event: any) => {
                        this.activeIndex = 0;
                    }

                },
                {
                    label: 'Financiera',
                    disabled: this.step < 1,
                    command: (event: any) => {
                        this.activeIndex = 1;
                    }
                },
                {
                    label: 'Declaraciones',
                    disabled: this.step < 2,
                    command: (event: any) => {
                        this.activeIndex = 2;
                    }
                }
            ];

            if (this.origen == 0) {
                this.style = '0px';
                const stepFour = {
                    label: 'Documentos',
                    disabled: this.step < 3,
                    command: (event: any) => {
                        this.activeIndex = 3;
                    }
                }

                this.items.push(stepFour);

            } else {
                // this.style = '40px';
            }
        }
        else {

            this.items = [
                {
                    label: 'Básica',
                    disabled: this.step < 0,
                    command: (event: any) => {
                        this.activeIndex = 0;
                    }
                },
                {
                    label: 'Financiera',
                    disabled: this.step < 1,
                    command: (event: any) => {
                        this.activeIndex = 1;
                    }
                },
                {
                    label: 'Declaraciones',
                    disabled: this.step < 2,
                    command: (event: any) => {
                        this.activeIndex = 2;
                    }
                }
            ];
            if (this.origen == 0) {
                this.style = '0px';
                const stepFour = {
                    label: 'Documentos',
                    disabled: this.step < 3,
                    command: (event: any) => {
                        this.activeIndex = 3;
                    }
                }

                this.items.push(stepFour);

            } else {
                // this.style = '40px';
            }
        }
    }
    volver() {
        this.router.navigate(['tramites']);
    }

}

