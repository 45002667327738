import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { AppComponent } from './app.component';
import { Subscription } from 'rxjs';
import { TOKE_NAME } from './_shared/var.constant';
import { LoginService } from './_handler/login.service';
import { environment } from '../environments/environment';
import { Router } from '../../node_modules/@angular/router';
import { NotificationService } from './notification.service';
@Component({
  selector: 'app-topbar',
  templateUrl: './app.topbar.component.html',
  styleUrls: ['./topbar.css']
})
export class AppTopBarComponent implements OnInit {
  URL_LOGOUT: string = environment.URL_LOGOUT;
  notifyItems: any[] = [];
  subscription: Subscription;
  @Input() username: string;
  bandera: any = 0;
  baseUrl: any;
  banderaMenu: number = 0;
  mobile: any;
  hrIE: boolean;
  isIEOrEdge = false;
  country: string;

  constructor(public app: AppComponent,
    public loginService: LoginService,
    public notificationService: NotificationService,
    private router: Router) {
    this.subscription = notificationService.itemsHandler.subscribe(response => {
      this.notifyItems = response;
    });
  }

  ngOnInit(): void {
    const url = window.location.href;
    let country = 'EC';
    if (url.endsWith('/CO')) {
      country = 'CO';
    }
    this.country = country;
    this.registrarEvento(null);
    this.mobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    setTimeout(() => {
      this.resizeTop();
      this.isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
      this.responsive();
      this.icono();
    }, 10);

    var ua = window.navigator.userAgent;
    var is_ie = ua.indexOf("MSIE ") > -1 || ua.indexOf("Trident/") > -1;
    // Si es IE el navegador recargue la pantalla
    if (is_ie) {
      this.hrIE = true;
    } else {
      this.hrIE = false;
    }
  }

  openNotify(event, overlay, eventClick) {
    event.onClick();
    overlay.hide(eventClick);
  }

  registrarEvento(event: any) {
    let tk = JSON.parse(sessionStorage.getItem(TOKE_NAME));
    if (tk != null)
      this.username = tk.user != undefined ? tk.user.name : "";
  }

  resizeTop() {
    if (this.mobile) {
      const elm = <HTMLElement>document.getElementById('navbarLiberty');
      if (elm != null) {
        elm.style.height = '90px';
      }
      const contenthr = <HTMLElement>document.getElementById('contenthr');
      if (contenthr != null) {
        contenthr.style.marginTop = '-28px';
      }
      const menuUser = <HTMLElement>document.getElementById('topbar-profile-menu-button');
      if (menuUser != null) {
        menuUser.style.marginTop = '10px';
      }
      const menuUserExit = <HTMLElement>document.getElementById('topbar-exit-menu-button');
      if (menuUserExit != null) {
        // menuUserExit.style.marginTop = '10px';
      }
      const imgLiberty = <HTMLElement>document.getElementById('imgLiberty');
      if (imgLiberty != null) {
        imgLiberty.style.marginLeft = '0%';
        imgLiberty.style.left = '0px';
      }
      const topBarMenu = <HTMLElement>document.getElementById('topbar-usermenu');
      if (topBarMenu != null) {
        topBarMenu.style.top = '90px';
      }
    }
  }

  validarMenu() {
    this.banderaMenu = 0;
    this.banderaMenu = 0;
    if (!this.loginService.estaLogueado()) {
      this.banderaMenu = 1;
    }
  }

  responsive() {

    // const elm5 = <HTMLElement>document.getElementById('bienvenidos');
    //  const elm6 = <HTMLElement>document.getElementById('cuerpoConsultas');


    if (this.mobile) {
      const elm1 = <HTMLElement>document.getElementById('hr');
      const elm2 = <HTMLElement>document.getElementById('sectionTitle');
      const elm3 = <HTMLElement>document.getElementById('imag');
      const elm4 = <HTMLElement>document.getElementById('hrtext');
      const elm5 = <HTMLElement>document.getElementById('imgLiberty');
      const elm6 = <HTMLElement>document.getElementById('navbarLiberty');
      const elm7 = <HTMLElement>document.getElementById('topbar-profile-menu-button');
      const elm8 = <HTMLElement>document.getElementById('topbar-usermenu');
      const elm9 = <HTMLElement>document.getElementById('topbar-exit-menu-button');

      if (elm1 && elm2 && elm3 && elm4 && elm9) {
        elm1.style.display = 'none';
        elm2.style.display = 'flex';
        elm2.style.justifyContent = 'r';
        elm2.style.marginTop = '3px';
        // elm3.style.marginLeft = '6%';
        elm4.style.width = '100%';
        elm4.style.paddingLeft = '-0px';
        elm4.style.marginTop = '-0px';
        elm7.style.marginTop = '-0px !important';
        elm7.style.marginTop = '0px ';
        elm9.style.marginTop = '0px ';
        elm8.style.top = '120px';
        // elm8.style.marginTop = '120px';
      }
      elm5.style.marginLeft = '20px';


    }
  }

  cerrarSesion() {
    this.router.navigate(['landing']);
    this.loginService.cerrarSesion();
    window.location.href = this.URL_LOGOUT;
  }

  icono() {
    this.loginService.estaLogueado();
    const elm1 = <HTMLElement>document.getElementById('imag');
    if (this.loginService.estaLogueado()) {
      elm1.style.marginLeft = '0px';

    } else {
      elm1.style.marginLeft = '20px';
    }
  }
}
