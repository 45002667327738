import { Component, OnInit } from '@angular/core';
import { BreadcrumbService } from 'src/app/breadcrumb.service';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-bpm',
  templateUrl: './bpm.component.html',
  styleUrls: ['./bpm.component.css']
})
export class BpmComponent implements OnInit {

  urlBpm = environment.URL_BPM;

  constructor(
    private router: Router,
    private breadcrumbService: BreadcrumbService,
  ) { }


  ngOnInit() {

    this.irBmp();

  }

  irBmp() {

    var a = document.createElement('a');
    a.target = "_blank";
    a.href = this.urlBpm;
    a.click();
    this.irHome();
  }


  irHome() {
    setTimeout(() => {
      this.router.navigate(['/home']);
    }, 450);

  }

}
