import { Component, OnInit } from '@angular/core';
import { Properties } from '../../general/properties';
import { ConfirmationService } from 'primeng/primeng';
// Servicios
import { BreadcrumbService } from '../../../breadcrumb.service';
import { MessageService } from 'primeng/components/common/messageservice';
import { RulesService, RolesService } from '../../../_services';
// Modelos
import { RuleModel } from 'src/app/_model/security/rule';

@Component({

    selector: 'reglas',
    templateUrl: './reglas.html'
})

export class ReglasComponent implements OnInit {

    properties: Properties = new Properties();
    titulo: string;
    subtitulo: string;
    bandera: number = 0;
    banderaEdit: boolean = false;
    cols: any[];
    rules: any;
    nuevaRegla: RuleModel = new RuleModel;
    loading: boolean = false;
    constructor(
        private breadcrumbService: BreadcrumbService,
        private messageService: MessageService,
        public rulesService: RulesService,
        public rolesService: RolesService,
        private confirmationService: ConfirmationService,
    ) {
    }

    ngOnInit(): void {

        this.cargarBreadCrum();
        this.titulo = 'Gestión de Reglas';
        this.subtitulo = 'Gestiona, visualiza y crea reglas de acuerdo a las necesidades de tu negocio';
        this.armarTabla();
        this.consultar();

    }

    cargarBreadCrum() {
        this.breadcrumbService.setItems([
            { label: 'Inicio', routerLink: ['/home'] },
            {label: "Reglas", routerLink: ['/reglas']}
        ]);
    }

    consultar() {
        this.loading = true
        this.rulesService.intlLatamEcFormSecureGetAllRules()
            .subscribe((resp: any) => {
                this.rules = [];
                resp.data.forEach(element => {
                    element.x = "";
                    this.rules.push(element);
                });
                this.loading = false;
            },
                (err: any) => console.log(err),
                () => {
                });

    }

    armarTabla() {
        this.cols = [
            { field: 'name', header: 'Nombre' },
            { field: 'rule', header: 'Regla' },
            { field: 'description', header: 'Descripción' },
            { field: 'x', header: 'Acciones' },
        ];

    }

    nuevo() {
        this.titulo = "Nueva Regla"
        this.bandera = 2;
        this.banderaEdit = false;
    }

    guardar(form) {
        this.loading = true;
        this.armarGuardar();
        if (this.banderaEdit != true) {
            this.rulesService.intlLatamEcFormSecureCreateRule(this.nuevaRegla).subscribe((result: any) => {
                this.messageService.add({ severity: 'success', summary: '', detail: this.properties.msgSuccessGeneral });
                this.consultar();
                this.cancelar();
                this.loading = false;
            },
                (err: any) => {
                    this.messageService.add({ severity: 'error', detail: this.properties.msgErrorGeneral });
                    this.loading = false;
                });
        }
        else {
            this.rulesService.intlLatamEcFormSecureUpdateRule(this.nuevaRegla).subscribe((result: any) => {
                this.messageService.add({ severity: 'success', summary: '', detail: this.properties.msgSuccessGeneral });
                this.consultar();
                this.cancelar();
                this.loading = false;
            },
                (err: any) => {
                    this.messageService.add({ severity: 'error', detail: this.properties.msgErrorGeneral });
                    this.loading = false;
                });
        }

    }

    cancelar() {
        this.bandera = 0;
        this.banderaEdit = false;
        this.titulo = 'Gestión de Reglas';
        this.subtitulo = 'Gestiona, visualiza y crea reglas de acuerdo a las necesidades de tu negocio';
        this.nuevaRegla = new RuleModel();
    }

    view(rowData) {
        this.bandera = 1;
        this.banderaEdit = true;
        this.nuevaRegla = rowData;
    }

    mostrarEditar() {
        this.bandera = 2;
    }

    armarGuardar() {
        this.nuevaRegla;
        this.nuevaRegla.id = this.nuevaRegla.name;
        delete this.nuevaRegla.x;
    }

    confirmarEliminar() {
        this.properties.confirmarEliminacionMensaje(this.nuevaRegla.description),
            this.confirmationService.confirm({
                key: "eliminar",
                message: this.properties.labelModalEliminar,
                header: this.properties.tituloConfirmacionEliminacion,
                icon: this.properties.eliminar.icono,
                accept: () => {
                    this.messageService.add({ severity: 'success', summary: '', detail: this.properties.mensajeEliminarExito });
                }
            });
    }

    eliminar() {
        this.loading = true;
        this.rulesService.intlLatamEcFormSecureDeleteRule(this.nuevaRegla.name)
            .subscribe((result: any) => {
                this.messageService.add({ severity: 'success', summary: '', detail: this.properties.mensajeEliminarExito });
                this.consultar();
                this.cancelar();
                this.loading = false;

            },
                (err: any) => {
                    this.messageService.add({ severity: 'error', detail: this.properties.mensajeEliminarError });
                    this.loading = false;
                });
    }

    validarDependencias() {
        this.rulesService.intlLatamEcFormSecureGetRolesForRule(this.nuevaRegla.id)
            .subscribe((result: any) => {
                if (result.data == null) {
                    this.eliminar();
                }
                else {
                    this.messageService.add({ severity: 'error', detail: this.properties.mensajeEliminarErrorDependencias });
                    this.consultar();
                    this.cancelar();
                }

            },
                (err: any) => {
                    this.messageService.add({ severity: 'error', detail: this.properties.mensajeEliminarError });
                });
    }
}