/**
 * intl-latam-ec-portal-documents
 * No description
 *
 * OpenAPI spec version: 1.0.0
 * Contact: you@your-company.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { SpousePersonFormDocumentModel } from './spousePersonFormDocument';


export class SpousePersonFormModel { 
    firstName?: string;
    secondName?: string;
    lastName?: string;
    nationality: string;
    secondLastName?: string;
    document?: SpousePersonFormDocumentModel;


    constructor(){
        this.firstName = null;
        this.secondName = null;
        this.lastName = null;
        this.nationality= null;
        this.secondLastName = null;
        this.document = new SpousePersonFormDocumentModel();
    }
}
