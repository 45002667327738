import { Observable } from 'rxjs/Observable';

import { Injectable } from '@angular/core';
import { UrlServices } from '../url.services';
import { GenericService, METHODS } from '../services.util';
@Injectable()
export class InspectionsService {

    urlServices: UrlServices = new UrlServices();

    constructor(public _genericService: GenericService) {
    }

    getInspectionsPlateOrRamv(isPlate: boolean, plateType: string, plateNumber: string, searchBrandModelYearManufacture: string): Observable<any> {
        const {url} = this.urlServices.getUrlInspections(isPlate);
        console.log(url);
        const body = {
            "plateType": plateType,
            "plateNumber": plateNumber,
            "searchBrandModelYearManufacture": searchBrandModelYearManufacture != null ? searchBrandModelYearManufacture : ""
        }
        return this._genericService.genericCallServices(METHODS.POST, url, body, null, null, true, true);
    }

    postInspections(body){
        const {url} = this.urlServices.getUrlInspectionsPost();
        return this._genericService.genericCallServices(METHODS.POST, url, body, null, null, true, true);
    }

}
