import { Component, OnInit } from '@angular/core';
import { Properties } from '../../general/properties';
import { ConfirmationService } from 'primeng/primeng';
// Servicios
import { BreadcrumbService } from '../../../breadcrumb.service';
import { MessageService } from 'primeng/components/common/messageservice';
import { GroupsService } from '../../../_services';

// Modelos
import { GroupModel } from 'src/app/_model/security/group';

//Encriptar
import { CriptoService } from '../../../_handler/cripto.service';

@Component({

    selector: 'grupos',
    templateUrl: './grupos.html'
})

export class GruposComponent implements OnInit {

    properties: Properties = new Properties();
    titulo: string;
    subtitulo: string;
    bandera: number = 0;
    cols: any[];
    groups: any;
    nuevoGrupo: GroupModel = new GroupModel;
    loading: boolean = false;
    banderaEditar: boolean = false;

    // Codificadores
    nuevoGrupoIdCode: any;
    nuevoGrupoIdCodeAux: any;

    constructor(
        private breadcrumbService: BreadcrumbService,
        private messageService: MessageService,
        public groupsService: GroupsService,
        private confirmationService: ConfirmationService,
        private criptoService: CriptoService
    ) {
    }

    ngOnInit(): void {

        this.cargarBreadCrum();
        this.titulo = 'Gestión de Grupos';
        this.subtitulo = 'Gestiona, visualiza y crea grupos de acuerdo a las necesidades de tu negocio';
        this.armarTabla();
        this.consultar();

    }

    cargarBreadCrum() {
        this.breadcrumbService.setItems([
            { label: 'Inicio', routerLink: ['/home'] },
            { label: "Grupos", routerLink: ['/grupos'] }
        ]);
    }

    consultar() {
        this.loading = true;
        this.groupsService.intlLatamEcFormSecureGetGroups()
            .subscribe((resp: any) => {
                this.groups = [];
                resp.data.value.forEach(element => {
                    element.x = "";
                    this.groups.push(element);
                });
                this.loading = false;
            },
                (err: any) => console.log(err),
                () => {
                    this.loading = false;
                });

    }

    armarTabla() {
        this.cols = [
            { field: 'displayName', header: 'Id Bróker' },
            { field: 'description', header: 'Razón social' },
            { field: 'x', header: 'Acciones' },
        ];

    }

    nuevo() {
        this.titulo = "Nuevo Grupo"
        this.bandera = 2;
    }

    guardar(form) {
        this.armarGuardar();
        this.loading = true;
        if (this.nuevoGrupo.id == null) {
            this.groupsService.intlLatamEcFormSecureCreateGroup(this.nuevoGrupo).subscribe((result: any) => {
                if (result.code == '500') {
                    this.messageService.add({ severity: 'error', detail: 'Ya existe un grupo con ese Id de Bróker' });
                }
                if (result.code == '200') {
                    this.messageService.add({ severity: 'success', summary: '', detail: this.properties.msgSuccessSaveGeneral });
                }
                if (result.code == '201') {
                    this.messageService.add({ severity: 'success', summary: '', detail: this.properties.msgSuccessSaveGeneral });
                }
                this.consultar();
                this.cancelar();
                this.loading = false;
            },
                (err: any) => {
                    this.messageService.add({ severity: 'error', detail: this.properties.msgErrorGeneral });
                    this.loading = false;
                });
        }
        else {
            this.groupsService.intlLatamEcFormSecureUpdateGroup(this.nuevoGrupo).subscribe((result: any) => {
                this.messageService.add({ severity: 'success', summary: '', detail: this.properties.msgSuccessGeneral });
                this.consultar();
                this.cancelar();
                this.loading = false;
            },
                (err: any) => {
                    this.messageService.add({ severity: 'error', detail: this.properties.msgErrorGeneral });
                    this.loading = false;
                });
        }

    }

    cancelar() {
        this.bandera = 0;
        this.banderaEditar = false;
        this.titulo = 'Gestión de Grupos';
        this.subtitulo = 'Gestiona, visualiza y crea grupos de acuerdo a las necesidades de tu negocio';
        this.nuevoGrupo = new GroupModel();
    }

    view(rowData) {
        this.bandera = 1;
        this.nuevoGrupo = rowData;
    }

    mostrarEditar() {
        this.bandera = 2;
        this.banderaEditar = true;
    }

    armarGuardar() {
        this.nuevoGrupo;
        delete this.nuevoGrupo.x;
    }

    confirmarEliminar() {
        this.properties.confirmarEliminacionMensaje(this.nuevoGrupo.description),
            this.confirmationService.confirm({
                key: "eliminar",
                message: this.properties.labelModalEliminar,
                header: this.properties.tituloConfirmacionEliminacion,
                icon: this.properties.eliminar.icono,
                accept: () => {
                    this.messageService.add({ severity: 'success', summary: '', detail: this.properties.mensajeEliminarExito });
                }
            });
    }

    eliminar() {
        this.loading = true;
        this.nuevoGrupoIdCodeAux = null;
        this.nuevoGrupoIdCodeAux = this.criptoService.set(this.nuevoGrupo.id);
        this.groupsService.intlLatamEcFormSecureDeleteGroup(this.nuevoGrupoIdCodeAux)
            .subscribe((result: any) => {
                this.messageService.add({ severity: 'success', summary: '', detail: this.properties.mensajeEliminarExito });
                this.consultar();
                this.cancelar();
                this.loading = false;

            },
                (err: any) => {
                    this.messageService.add({ severity: 'error', detail: this.properties.mensajeEliminarError });
                });
    }

    validarDependencias() {
        this.loading = true;
        this.nuevoGrupoIdCode = null;
        this.nuevoGrupoIdCode = this.criptoService.set(this.nuevoGrupo.id);
        this.groupsService.intlLatamEcFormSecureGetGroupMembersById(this.nuevoGrupoIdCode)
            .subscribe((result: any) => {
                if (result.data.value.length > 0) {
                    this.messageService.add({ severity: 'error', detail: this.properties.mensajeEliminarErrorDependencias });
                    this.loading = false;
                    this.consultar();
                    this.cancelar();
                }
                else {
                    this.eliminar();
                    this.loading = false;
                }

            },
                (err: any) => {
                    this.messageService.add({ severity: 'error', detail: this.properties.mensajeEliminarError });
                    this.loading = false;
                });
    }
}