import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-mis-solicitudes',
  templateUrl: './mis-solicitudes.component.html'
})
export class MisSolicitudesComponent implements OnInit {

  url = environment.URL_MY_SOLS;

  constructor(
    private router: Router,
  ) { }


  ngOnInit() {
    this.redir();
  }

  redir() {
    window.open(this.url, '_blank');
    this.irHome();
  }

  irHome() {
    setTimeout(() => {
      this.router.navigate(['/home']);
    }, 450);

  }

}
