import { Group } from "src/app/_services/model-seguridad/group";

export class GroupModel implements Group {
    id?: string;
    description?: string;
    displayName?: string;
    groupTypes?: Array<string>;
    mailEnabled?: string;
    mailNickname?: string;
    securityEnabled?: string;
    x: string;

    constructor() {
        this.id = null;
        this.description = null;
        this.displayName = null;
        this.groupTypes = new Array<string>();;
        this.mailEnabled = "false";
        this.mailNickname = 'ec-latam-form';
        this.securityEnabled = "true";
        this.x = null;
    }
}
