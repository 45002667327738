export * from './address';
export * from './apiResponse';
export * from './catalog';
export * from './claim';
export * from './company';
export * from './contact';
export * from './document';
export * from './entity';
export * from './entityBankReferences';
export * from './entityBranch';
export * from './entityCommercialReferences';
export * from './entityCountries';
export * from './entityLegalRepresentative';
export * from './entityPersonalReferences';
export * from './entityShareholders';
export * from './evicertia';
export * from './form';
export * from './formFigures';
export * from './formUrl';
export * from './geographicLocation';
export * from './incomeExpenses';
export * from './modelFile';
export * from './pep';
export * from './person';
export * from './product';
export * from './relationshipPep';
export * from './report';
export * from './vehicle';
export * from './work';
