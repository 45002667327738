import { Component, OnInit } from '@angular/core';
import { BreadcrumbService } from '../../breadcrumb.service';

@Component({
  selector: 'app-not403',
  templateUrl: './not403.component.html',
  styleUrls: ['./not403.component.css']
})
export class Not403Component implements OnInit {

  constructor(private breadcrumbService: BreadcrumbService) { }

  ngOnInit() {
    this.cargarBreadCrum();
  }

  cargarBreadCrum() {
    this.breadcrumbService.setItems([
        { label: '', routerLink: [''] }
    ]);
}
}
