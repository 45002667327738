import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { GRAN_TYPE, CLIENT_ID } from '../_shared/var.constant';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  urlApiSecureOauth: string = environment.URL_API_SECURE_OAUTH;

  mensajeRegistro = new Subject<string>();

  constructor(private httpClient: HttpClient) { }

  loginOauth() {
    // const body = `grant_type=${GRAN_TYPE}&client_id=${encodeURIComponent(CLIENT_ID)}&client_secret=${encodeURIComponent(environment.CLIENT_SECRET)}`;
    const body = `grant_type=${GRAN_TYPE}&client_id=${(CLIENT_ID)}&client_secret=${(environment.CLIENT_SECRET)}`;
    let headers = new HttpHeaders().set('Content-Type','application/x-www-form-urlencoded');
    // headers = new HttpHeaders().set('grant_type',GRAN_TYPE);
    // headers = new HttpHeaders().set('client_id',CLIENT_ID);
    // headers = new HttpHeaders().set('client_secret',environment.CLIENT_SECRET);
    return this.httpClient.post<any>(`${this.urlApiSecureOauth}`,body, {headers: headers});
  }

}