
import { DOCUMENT } from '@angular/common';
import { Component, ElementRef, Inject, OnInit, Renderer2, ViewChild} from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { SelectItem } from 'primeng/api';
import { InspectionsAuto } from 'src/app/_model/documents/inspectionsAuto';
import { InspectionsService } from 'src/app/_services/api_v1/inspections.service';
import { BreadcrumbService } from 'src/app/breadcrumb.service';


@Component({
    selector: 'app-autoInspections',
    templateUrl: './autoInspections.component.html',
    styleUrls: ['./autoInspections.component.css']
})

export class AutoInspectionsComponent implements OnInit {
    inspectiosnM = new InspectionsAuto();
    typeIdentification: SelectItem[];
    typeAutoIdentification: SelectItem[];
    typeInfoAdd: SelectItem[];
    optionsPredict = [];
    uuid: string;
    serchfind: boolean = false;
    form: NgForm;
    Serial = 'No. F000001'
    ActiveForm = true;
    FinishForm = false;
    disabled = false;
    errorEmail: string = '';
    errorEmailBroker: string = '';
    errorContactTelefonNumber: string = '';
    plateBlocked = false;

    //Disclaimer
    disclaimer = false;
    disclaimer2 = false;
    disclaimer3 = false;
    disclaimer4 = false;
    disabledBtnDis = '';
    valueCheck = false;
    cancelDisclaimer = false;
    returnDisclaimer = false;

    constructor(private inspectionService: InspectionsService, private breadcrumbService: BreadcrumbService, private elementRef: ElementRef, private router: Router, private route: ActivatedRoute) {
        this.inspectiosnM.documentNumber = '';
    }
    ngOnInit() {
        this.chargeBreadCrum();
        this.inspectiosnM.isSameContact = 'true';
        this.typeIdentification = [
            { label: 'Cédula de Identidad', value: 'Cedula' },
            { label: 'RUC', value: 'RUC' },
            { label: 'Pasaporte', value: 'Pasaporte' },

        ];
        this.typeAutoIdentification = [
            { label: 'Particular', value: 'PLACA' },
            // { label: 'RAMv', value: 'RAMV' },

        ]
        this.typeInfoAdd = [
            { label: 'Nueva', value: 'NUEVA' },
            { label: 'Re-inspección de daños', value: 'RE INSPECCION' },
            { label: 'Inclusión de accesorios', value: 'INCLUSIÓN ACCESORIOS' },

        ]
        this.getDataBroker();
        this.inspectiosnM.documentNumber = '';
        this.inspectiosnM.contactTelefonNumber = '';
        this.inspectiosnM.documentType = 'Cedula';
        this.inspectiosnM.plateType = 'PLACA';
    }
    valitatePlate() {
        let regexp = new RegExp(/^[A-Z]{3}-\d{4}/);
        if (regexp.test(this.inspectiosnM.plateNumber) && this.inspectiosnM.plateType != null) {
            this.getPlateOrRamv();
        } else {
            this.gotoDisclaimer()
        }
    }

    chargeBreadCrum() {
        this.breadcrumbService.setItems([
            { label: 'Inicio', routerLink: ['/home'] },
            { label: 'Gestión de solicitudes' },
            { label: 'Nueva inspección de vehículo', routerLink: ['/autoInspections'] }
        ]);
    }


    prepareForm(form: NgForm) {

        if (form.valid) {
            this.inspectionService.postInspections(this.inspectiosnM).subscribe((response) => {
                switch (response.code) {
                    case 4:
                        this.disclaimer3 = true;
                        break;
                    case 3:
                        this.Serial = response.sequencial;
                        window['dataLayer'].push({
                            event: 'button_click',
                            result: 'Success',
                            params: this.inspectiosnM
                        });
                        this.router.navigate(['/autoInspections'], {
                            queryParams: {inspectionId: response.sequencial}
                        })
                        break;
                    case 2:
                        this.goToStep4();
                        break;
                    case 1:
                        this.disclaimer2 = true;
                        break;
                    default:
                        break;
                }
            });
        } else {
            console.log('Error....Form Invalid')
        }
    }
    reInspection() {
        this.inspectiosnM.reInspection = true;
        this.inspectiosnM.inspectionType = "RE INSPECCION";
        this.inspectionService.postInspections(this.inspectiosnM).subscribe((response) => {
            switch (response.code) {
                case 3:
                    this.Serial = response.sequencial;
                    this.gotoFinish();
                    this.disclaimer3 =false
                    break;
                default:
                    break;
            }
        })
    }
    getDataBroker() {
        let data = JSON.parse(sessionStorage.getItem('access_token'));
        this.inspectiosnM.brokerEmail = data.user.email;
        this.inspectiosnM.brokerName = data.user.brokerName;
        this.inspectiosnM.brokerRuc = data.user.brokerId;
    }
    getPlateOrRamv(ramv?) {
        let plate = this.inspectiosnM.plateNumber.replace(/[^a-zA-Z0-9]/g, "-");
        this.inspectionService.getInspectionsPlateOrRamv(true, this.inspectiosnM.plateType, plate, ramv ? ramv.query : '').subscribe((response) => {
            if (response == null || response == "") {
                this.disclaimer = true;
            } else if (response.errorDescripcion) {
                this.disclaimer = true;
            } else if (response == "Blocked") {
                this.plateBlocked = true;
            } else {
                this.plateBlocked = false;
                this.inspectiosnM.use = response.autCodigoTipoServicio
                this.inspectiosnM.vehicleValue = response.autPrecioComercial
                this.inspectiosnM.chassisNumber = response.autSerialVinChasis
                this.inspectiosnM.engineNumber = response.autNumeroMotor
                this.inspectiosnM.vehicleBrand = response.autMarca
                this.inspectiosnM.vehicleModel = response.autModelo
                this.inspectiosnM.vehicleType = response.autClase
                this.inspectiosnM.vehicleCategory = response.autNombreSubClase
                this.inspectiosnM.vehicleGroup = response.autCodigoTipoServicio
                this.inspectiosnM.yearManufacture = response.autAnioModelo
            }
        }, (error) => {
            this.disclaimer = true;
        });
    }

    getPlateOrRamv1(ramv?) {
        this.inspectionService.getInspectionsPlateOrRamv(false, this.inspectiosnM.plateType, this.inspectiosnM.plateNumber, ramv ? ramv.query : '').subscribe((response) => {
            this.optionsPredict = response
        })
    }
    onChangeSelect() {
        this.inspectiosnM.vehicleBrand = ''
        this.inspectiosnM.vehicleModel = ''
        this.inspectiosnM.vehicleType = ''
        this.inspectiosnM.vehicleGroup = ''
        this.inspectiosnM.yearManufacture = ''
    }
    onChangeSelectContact() {
        this.inspectiosnM.contactEmail = ''
        this.inspectiosnM.contactName = ''
        this.inspectiosnM.contactSurName = ''
        this.inspectiosnM.contactTelefonNumber = ''
    }
    onChangeSelectTypeIdentification() {
        this.inspectiosnM.documentNumber = '';
        this.inspectiosnM.name = '';
        this.inspectiosnM.surname = '';
        this.inspectiosnM.middleName = '';
        this.inspectiosnM.middleSurame = '';
        this.inspectiosnM.companyName = '';
    }

    RamvFig() {
        this.inspectiosnM.use = 'personal'
        this.inspectiosnM.vehicleValue = this.inspectiosnM.searchVehicle.Valor
        this.inspectiosnM.chassisNumber = null
        this.inspectiosnM.engineNumber = null
        this.inspectiosnM.vehicleCategory = this.inspectiosnM.searchVehicle.Categotia3
        this.inspectiosnM.vehicleBrand = this.inspectiosnM.searchVehicle.Marca
        this.inspectiosnM.vehicleModel = this.inspectiosnM.searchVehicle.Modelo
        this.inspectiosnM.vehicleType = this.inspectiosnM.searchVehicle.Tipo
        this.inspectiosnM.vehicleGroup = this.inspectiosnM.searchVehicle.Grupo
        this.inspectiosnM.yearManufacture = this.inspectiosnM.searchVehicle.Year
    }

    isEvent(event: KeyboardEvent) {
        if (this.inspectiosnM.contactEmail == null || this.inspectiosnM.contactEmail.trim().length == 0 || this.inspectiosnM.contactEmail == '') {
            this.errorEmail = 'Este campo no puede quedar vacío';
            return false
        } else if( this.inspectiosnM.contactEmail != null || this.inspectiosnM.contactEmail != ''){
            this.errorEmail = 'El correo electrónico ingresado no tiene el formato correcto';
            let regexp = new RegExp(/^[a-zA-Z0-9\._-]{1,64}@([a-zA-Z0-9\.]{1,35})(\.com|\.org|\.net|\.co|\.ec)$/);
            return regexp.test(this.inspectiosnM.contactEmail);
        }
    }
    isEventTel() {
        if (this.inspectiosnM.contactTelefonNumber == '') {
            this.errorContactTelefonNumber = 'Debes ingresar un número de contacto';
            return false
        } else if (this.inspectiosnM.contactTelefonNumber.length < 9 && this.inspectiosnM.contactTelefonNumber.length >= 1) {
            this.errorContactTelefonNumber = 'El numero ingresado no cuenta con el formato correcto';
            return false;
        }
        else {
            return true
        }
    }
    isEventBroker(event: KeyboardEvent) {
        if (this.inspectiosnM.brokerEmail == null || this.inspectiosnM.brokerEmail.trim().length == 0 || this.inspectiosnM.brokerEmail == '') {
            this.errorEmailBroker = 'Este campo no puede quedar vacío';
            return false
        } else if( this.inspectiosnM.brokerEmail != null || this.inspectiosnM.brokerEmail != ''){
            this.errorEmailBroker = 'El correo electrónico ingresado no tiene el formato correcto';
            let regexp = new RegExp(/^[a-zA-Z0-9\._-]{1,64}@([a-zA-Z0-9\.]{1,35})(\.com|\.org|\.net|\.co|\.ec)$/);
            return regexp.test(this.inspectiosnM.brokerEmail);
        }
    }

    validarCedula() {

        const regEx = /^[0-9]{10}$/;
        if (this.inspectiosnM.documentNumber.length == 13 && (this.inspectiosnM.documentType == 'RUC' || this.inspectiosnM.documentType == 'Pasaporte')) {
            // Verificar si el RUC tiene 13 caracteres
            if (this.inspectiosnM.documentNumber.length !== 13) return false;

            // Verificar si los primeros dos dígitos son válidos para Ecuador
            const provincia = parseInt(this.inspectiosnM.documentNumber.substring(0, 2), 10);
            if (provincia < 1 || provincia > 24) return false;

            // Verificar tercer dígito para tipo de contribuyente
            // 6 para entidades públicas, 9 para sociedades privadas y extranjeros sin cédula, y menos de 6 para personas naturales
            const tercerDigito = parseInt(this.inspectiosnM.documentNumber.charAt(2), 10);
            if (tercerDigito !== 6 && tercerDigito !== 9 && tercerDigito >= 6) return false;

            // Aplicar algoritmo de módulo 11 para validar dígito verificador para entidades públicas y privadas
            // Aplicar algoritmo de módulo 10 para personas naturales
            let suma = 0;
            const coeficientesMod11 = [4, 3, 2, 7, 6, 5, 4, 3, 2];
            const coeficientesMod10 = [2, 1, 2, 1, 2, 1, 2, 1, 2];

            for (let i = 0; i < 9; i++) {
                const digito = parseInt(this.inspectiosnM.documentNumber.charAt(i), 10);
                if (tercerDigito === 6 || tercerDigito === 9) {
                    suma += coeficientesMod11[i] * digito;
                } else {
                    let valor = coeficientesMod10[i] * digito;
                    if (valor >= 10) valor -= 9;
                    suma += valor;
                }
            }

            let digitoVerificador;
            if (tercerDigito === 6 || tercerDigito === 9) {
                const modulo = suma % 11;
                digitoVerificador = modulo === 0 ? 0 : 11 - modulo;
            } else {
                const modulo = suma % 10;
                digitoVerificador = modulo === 0 ? 0 : 10 - modulo;
            }

            // El dígito verificador es el décimo dígito del RUC para entidades públicas y privadas
            // y el último dígito para personas naturales
            if (tercerDigito === 6 || tercerDigito === 9) {
                return digitoVerificador === parseInt(this.inspectiosnM.documentNumber.charAt(9), 10);
            } else {
                return digitoVerificador === parseInt(this.inspectiosnM.documentNumber.charAt(9), 10);
            }

        } else if (this.inspectiosnM.documentNumber.length == 10 && this.inspectiosnM.documentType == 'Cedula') {
            if (!regEx.test(this.inspectiosnM.documentNumber)) {
                return false;
            }

            const digitoRegion = parseInt(this.inspectiosnM.documentNumber.substring(0, 2));

            if (digitoRegion < 1 || digitoRegion > 24) {
                return false;
            }

            const ultimoDigito = parseInt(this.inspectiosnM.documentNumber.substring(9, 10));
            const pares = parseInt(this.inspectiosnM.documentNumber.substring(1, 2)) + parseInt(this.inspectiosnM.documentNumber.substring(3, 4)) +
                parseInt(this.inspectiosnM.documentNumber.substring(5, 6)) + parseInt(this.inspectiosnM.documentNumber.substring(7, 8));

            let impares = 0;
            for (let i = 0; i < 9; i += 2) {
                let numero = parseInt(this.inspectiosnM.documentNumber.charAt(i)) * 2;
                if (numero > 9) {
                    numero -= 9;
                }
                impares += numero;
            }

            const sumaTotal = pares + impares;
            const primerDigitoSuma = parseInt(String(sumaTotal).charAt(0));
            const decena = (primerDigitoSuma + 1) * 10;
            let digitoValidador = decena - sumaTotal;

            if (digitoValidador === 10) {
                digitoValidador = 0;
            }

            return digitoValidador === ultimoDigito;
        }

    }
    goToStep4() {
        this.disclaimer4 = true;
    }

    async hidenDisclaimer(form: NgForm) {
        this.disclaimer = false;
        this.disclaimer2 = false;
        this.disclaimer3 = false;
        this.disclaimer4 = false;
        this.goToNewForm(form);
    }
    hidenDisclaimer1() {
        this.disclaimer = false;
    }
    gotoDisclaimer() {
        this.disclaimer = true;
    }

    gotoFinish() {
        this.ActiveForm = false;
        this.FinishForm = true;
    }

    goToNewForm(form? :NgForm) {
        this.inspectiosnM = new InspectionsAuto();
        this.getDataBroker();
        if(form){
            form.resetForm({broke_name: this.inspectiosnM.brokerName, broke_ruc: this.inspectiosnM.brokerRuc, brokerEmail: this.inspectiosnM.brokerEmail, Same_contact: 'true'})
        }
        this.ActiveForm = true;
        this.FinishForm = false;
        this.inspectiosnM.documentNumber = '';
        this.inspectiosnM.isSameContact = 'true';
        this.inspectiosnM.contactTelefonNumber = '';
       
    }
    onChangeTypeDoc() {
        if (this.inspectiosnM.documentType != 'RUC' || this.inspectiosnM.personType != 'J') {
            this.onChangeSelectPersonFalse()
        } else if (this.inspectiosnM.documentType == 'RUC' && this.inspectiosnM.personType == 'J') {
            this.onChangeSelectPersonTrue()
        }
    }

    onChangeSelectPersonTrue() {
        this.disabled = true;
        this.inspectiosnM.isSameContact = 'false';
        this.onChangeSelectTypeIdentification();
    }
    onChangeSelectPersonFalse() {
        this.disabled = false;
        this.inspectiosnM.isSameContact = 'true';
        this.onChangeSelectTypeIdentification();
    }
    goToInit() {
        this.router.navigate(['/home'], { relativeTo: this.route });
    }

    changePlate() {
        this.plateBlocked = false;
    }

    maskInputPlate() {
        let regexp = new RegExp(/^[A-Z]+-+[0-9]{4}$/);
        return regexp.test(this.inspectiosnM.plateNumber);
    }

    onBlur() {
        const value = this.inspectiosnM.contactTelefonNumber;
        if (value.length === 9) {
            this.inspectiosnM.contactTelefonNumber = '0' + value;
        }
    }

}