/**
 * intl-latam-ec-portal-documents
 * No description
 *
 * OpenAPI spec version: 1.0.0
 * Contact: you@your-company.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { FormEntityBankReferencesModel } from './formEntityBankReferences';
import { FormEntityCommercialReferencesModel } from './formEntityCommercialReferences';
import { FormEntityLegalRepresentativeModel } from './formEntityLegalRepresentative';
import { FormEntityLegalRepresentativeRelationshipPepModel } from './formEntityLegalRepresentativeRelationshipPep';
import { FormEntityLegalRepresentativeTypeModel } from './formEntityLegalRepresentativeType';


export class FormEntityModel { 
    legalRepresentative?: FormEntityLegalRepresentativeModel;
    haveAdministratorsPep?: boolean;
    administrators?: Array<FormEntityLegalRepresentativeRelationshipPepModel>;
    shareholdersExceedCapital?: boolean;
    shareholders?: Array<string>;
    mainActivity?: string;
    descriptionMainActivity?: string;
    ciiu?: FormEntityLegalRepresentativeTypeModel;
    sourceResources?: string;
    personalReferences?: Array<string>;
    commercialReferences?: Array<FormEntityCommercialReferencesModel>;
    bankReferences?: Array<FormEntityBankReferencesModel>;
    hasTaxObligationsOtherCountries?: boolean;
    haveRelationshipPep?: boolean;
    relationshipPep?: Array<string>;
    foreignCurrencyTransactions?: boolean;
    operationType?: string;
    otherType?: string;
    haveFinancialProductsAbroad?: boolean;
    products?: Array<string>;
    claim?: Array<string>;
    clauDatosPersonales?: boolean;
    tratamientoDatos?: boolean;
    transferenciaDatos?: boolean;
    tratamientoInformacion?: boolean;

    constructor(){
        this.legalRepresentative = new FormEntityLegalRepresentativeModel();
        this.haveAdministratorsPep = false;
        this.administrators = new Array<FormEntityLegalRepresentativeRelationshipPepModel>();
        this.shareholdersExceedCapital = false;
        this.shareholders = Array<string>();
        this.mainActivity = null;
        this.descriptionMainActivity = null;
        this.ciiu = new FormEntityLegalRepresentativeTypeModel();
        this.sourceResources = null;
        this.personalReferences =  Array<string>();
        this.commercialReferences = Array<FormEntityCommercialReferencesModel>();
        this.bankReferences = Array<FormEntityBankReferencesModel>();
        this.hasTaxObligationsOtherCountries = false;
        this.haveRelationshipPep = false;
        this.relationshipPep = Array<string>();
        this.foreignCurrencyTransactions = false;
        this.operationType = null;
        this.otherType = null;
        this.haveFinancialProductsAbroad = false;
        this.products = Array<string>();
        this.claim = Array<string>();
        this.clauDatosPersonales=false;
        this.tratamientoDatos= false;
        this.transferenciaDatos= false;
        this.tratamientoInformacion = false;
    }
}
