import { Observable } from 'rxjs/Observable';
import { Http, Response, Headers, RequestOptions, URLSearchParams, RequestMethod } from "@angular/http";

import { Injectable } from '@angular/core';
import { UrlServices } from '../url.services';
import { GenericService, METHODS } from '../services.util';
import { TOKE_NAME } from 'src/app/_shared/var.constant';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
//llamar clase Ausencias

/**
 * UserService manages our current user
 */
@Injectable()
export class DocumentsService {
  @BlockUI() blockUI: NgBlockUI;
  urlServices: UrlServices = new UrlServices();
  tokeId: any = "&idToken=52fa1bff326ed4d5e05e7f9f23f1ea50";

  constructor(public _genericService: GenericService) {
  }

  getDocuments(params): Promise<any> {
    let url = this.urlServices.domainDocuments + "/b2b?startPanel=" + params.startPanel
    let newParams = params;
    delete newParams.startPanel;
    url = this.getGeneralFilters(url, newParams);
    let token = JSON.parse(sessionStorage.getItem(TOKE_NAME));
    if (token && token.user && token.user.brokerId)
      url += "&brokerId=" + token.user.brokerId;
    return new Promise((resolve, reject) => {
      this.blockUI.start();
      this._genericService.genericCallServices(METHODS.GET, url, null, null, null, false)
        .subscribe(async (resp) => {
          let data = resp.data;
          if (resp.lastEvaluatedKey) {
            let lastEvKey = resp.lastEvaluatedKey;
            do {
              lastEvKey['currDocs'] = data.length;
              const last = btoa(JSON.stringify(lastEvKey));
              const currResult = await this._genericService.genericCallServices(METHODS.GET, `${url}&finalSearched=${last}`, null, null, null, false).toPromise();
              data = [...data, ...currResult.data]
              lastEvKey = currResult.lastEvaluatedKey;
            } while (lastEvKey);
          }
          this.blockUI.stop();
          resolve(data)
        })
    })
  }

  getGeneralFilters(url, params) {
    const {formType, branch, limit, page, globalFilter, dateType, startDate, endDate, startPanel, status} = params;
    if (limit) url += "&limit=" + limit + "&country=EC";
    if (startPanel) url += "&startPanel=" + startPanel;
    if (page) url += "&page=" + page;
    if (globalFilter) url += "&filter=" + globalFilter
    if (dateType) url += "&dateType=" + dateType
    if (startDate) url += "&startDate=" + encodeURIComponent(startDate.toISOString())
    if (status) url += "&status=" + status
    if (formType) url += "&formType=" + formType
    if (branch) url += "&branch=" + branch
    if (endDate) {
      endDate.setDate(endDate.getDate() + 1);
      url += "&endDate=" + encodeURIComponent(endDate.toISOString())
    }
    return url;
  }

  getCountDocuments(country: string, panel: any = true, params = {}): Promise<number> {
    return new Promise((resolve, reject) => {
      this.blockUI.start();
      let url = `${this.urlServices.getUrlAllDocuments(country)}&countPanels=${panel}`;
      url = this.getGeneralFilters(url, params);
      this._genericService.genericCallServices(METHODS.GET, url, null, null, null, false)
        .subscribe(async (resp) => {
          let accumulated = resp.total;
          if (resp.lastEvaluatedKey) {
            let lastEvKey = resp.lastEvaluatedKey;
            do {
              const last = btoa(JSON.stringify(lastEvKey));
              const currResult = await this._genericService.genericCallServices(METHODS.GET,
                `${this.urlServices.getUrlAllDocuments(country)}&countPanels=true&startPanel=${panel}&finalSearched=${last}`, null, null, null, false
                ).toPromise();
              accumulated += currResult.total;
              lastEvKey = currResult.lastEvaluatedKey;
            } while (lastEvKey);
          }
          this.blockUI.stop();
          resolve(accumulated)
        });
    })
  }

  getDocumentsByDateIdByCountry(dateId, country: string): Observable<any> {
    return this._genericService.genericCallServices(METHODS.GET, this.urlServices.getUrlDocuments(dateId, country), null, null, null);
  }

  updateDocument(document) {
    return this._genericService.genericCallServices(METHODS.PUT, this.urlServices.getUrlDocumentPut(), document);
  }

  events(dateId, country: string, event, body = null): Observable<any> {
    return this._genericService.genericCallServices(METHODS.POST, this.urlServices.getEventUrl(dateId, country, event), body, null, null);
  }

  getPolicyPdf(key: any): Observable<any> {
    return this._genericService.genericCallServices(METHODS.GET, this.urlServices.filesProxy +
      `/${key}`, null, null, 'arraybuffer');
  }


  logingForm(dateId, country: string, code): Observable<any> {
    return this._genericService.genericCallServices(METHODS.POST, this.urlServices.domainDocuments +
      `/${encodeURIComponent(String(dateId))}/login/basic?country=${country}&idToken=52fa1bff326ed4d5e05e7f9f23f1ea50`, code, null, null);
  }



  getReport(parametros): Observable<any> {
    // FORMS
    // POLICY
    let params = new URLSearchParams();
    params.set('dateType', parametros.dateType);
    params.set('startDate', parametros.startDate);
    params.set('endDate', parametros.endDate);
    params.set('status', parametros.status);
    params.set('movementType', parametros.movementType);
    params.set('branch', parametros.branch);
    params.set('branchOffice', parametros.branchOffice);
    params.set('requestType', parametros.requestType);
    params.set('brokerId', parametros.brokerId);
    let options = new RequestOptions({
      method: RequestMethod.Get,
      search: params
    });
    return this._genericService.genericCallServices('get', this.urlServices.domainReports + '?' +
      'country=EC&' +
      'reportName=' + parametros.requestType + '&' +
      'dateType=' + parametros.dateType + '&' +
      'startDate=' + parametros.startDate + '&' +
      'endDate=' + parametros.endDate + '&' +
      'status=' + parametros.status + '&' +
      'movementType=' + parametros.movementType + '&' +
      'branch=' + parametros.branch + '&' +
      'branchOffice=' + parametros.branchOffice + '&' +
      'brokerId=' + parametros.brokerId
      , null, null, null);
  }

}
