import { Component, OnInit } from '@angular/core';
import { Message, SelectItem } from 'primeng/components/common/api';
import * as FileSaver from 'file-saver';
import { Validators, FormControl, FormGroup, FormBuilder } from '@angular/forms';
import { MessageService } from 'primeng/components/common/messageservice';
import { Properties } from '../../general/properties';
import { ReportePoliza } from '../../../_model/policy/reportePoliza';
import { ReporteEstadoPolizaModel } from '../../../_services/model-poliza/modelsPolicy';
import { DefaultService } from '../../../_services';
import { BreadcrumbService } from '../../../breadcrumb.service';
import { ApiResponse } from '../../../_model/policy/apiResponse';
import { DocumentsService } from 'src/app/_services/api_v1/documents.service';
import { ACCOUNT_CONST } from '../../cuenta/cuenta.constants';
import { CatalogService } from 'src/app/_services/api_v1/catalogs.service';

@Component({
  selector: 'app-reporte-polizas',
  templateUrl: './reporte-polizas.component.html',
  styleUrls: ['./reporte-polizas.component.css']
})
export class ReportePolizasComponent implements OnInit {

  properties: Properties = new Properties();
  pais: string = 'EC';
  msgs: Message[];
  filtroFechaInicial: Date = new Date();
  filtroFechaFin: Date = new Date();
  tiposFechas: any;
  tipoFechaSelected: any;
  reportForm: FormGroup;
  estados: any;
  tiposMovimiento: SelectItem[];
  sucursales: SelectItem[];
  ramos: SelectItem[] = [];

  blockedDocument = false;
  numeroItems: number;
  es: any = this.properties.es;
  elementoVacio = { 'id': undefined, 'descripcion': 'TODOS' };

  userRol: any;
  userAux: any;
  brokerIdAux: any;
  brokerId: any;

  // Parametros
  dateType: any;
  startDate: any;
  endDate: any;
  status: any;
  movementType: any;
  branch: any;
  branchOffice: any;

  constructor(private breadcrumbService: BreadcrumbService, private fb: FormBuilder,
    public service: DefaultService,
    private messageService: MessageService,
    private _documentsService: DocumentsService,
    private catalogService: CatalogService
  ) { }


  ngOnInit() {
    this.cargarBreadCrum();
    const currentUser = JSON.parse(sessionStorage.getItem('access_token'));
    if (currentUser != undefined) {
      this.userRol = currentUser.user.roles;
    }
    this.numeroItems = 0;
    this.reportForm = this.fb.group({
      'tipoFecha': new FormControl('', Validators.required),
      'filtroFechaInicial': new FormControl('', Validators.required),
      'filtroFechaFin': new FormControl('', Validators.required),
      'estadoSelected': undefined,
      'tipoMov': undefined,
      'tipoRamo': undefined,
      'tipoSucurcal': undefined
    });
    this.cargarCatalogos();
    this.filtrar();
  }


  filtrar() {
    let user = JSON.parse(sessionStorage.getItem('access_token'));
    this.brokerId = user.user.brokerId;
    // if (user != undefined) {
    //   this.userAux = user.user.roles;
    //   this.brokerIdAux = user.user.brokerId;
    //   if (this.userAux[0] == "operativo_intermediario"
    //     || this.userAux[0] == "consulta_intermediario"
    //     || this.userAux[0] == "admin_intermediario"
    //     || this.userAux[0] == "admin_intermediario_piloto") {
    //     this.brokerId = this.brokerIdAux;
    //   }
    //   else {
    //     this.brokerId = null;
    //   }
    // }
  }

  cargarBreadCrum() {
    this.breadcrumbService.setItems([
      { label: 'Inicio', routerLink: ['/home'] },
      { label: 'Reportes' },
      { label: 'Gestión de póliza ', routerLink: ['/reportes/reportePolizas'] }
    ]);
  }


  cargarCatalogos() {
    this.tiposFechas = this.properties.fechasReportePolicy;
    this.estados = this.properties.estadosResportes;
    this.tiposMovimiento = this.properties.tipoMovimientoReporte;
    this.sucursales = this.properties.sucursalesReporte;
    this.catalogService.getCatalogs(this.properties.ramos, this.pais)
      .subscribe((resp: any) => {
        if (resp) {
          resp.forEach(element => {
            this.ramos.push({ label: element.description, value: element.id });
          });
        }
      },
        (err: any) => console.log(err),
        () => {
        });
  }

  onSubmit(value) {

    const requestReporte: ReportePoliza = new ReportePoliza();
    if (value.filtroFechaFin != null) {
      value.filtroFechaFin.setDate(value.filtroFechaFin.getDate() + 1);
    }
    requestReporte.fecha = {
      tipo: value.tipoFecha,
      fechaInicio: value.filtroFechaInicial,
      fechaFin: value.filtroFechaFin
    };

    requestReporte.codEstado = value.estadoSelected ? value.estadoSelected : undefined;
    requestReporte.codTipoMovimiento = value.tipoMov ? value.tipoMov : undefined;
    requestReporte.codRamo = value.tipoRamo ? value.tipoRamo : undefined;
    requestReporte.codSucursal = value.tipoSucurcal ? value.tipoSucurcal : undefined;

    if (this.brokerId != null) {
      requestReporte.brokerId = this.brokerId;
    }
    if ((value.filtroFechaInicial &&
      value.filtroFechaFin) &&
      (value.filtroFechaInicial > value.filtroFechaFin)) {
      this.messageService.add({ severity: 'error', summary: '', detail: 'La fecha inicial no puede ser mayor a la fecha final' });
    } else {
      this.generarReporte(requestReporte);
    }
  }


  generarReporte(value: ReporteEstadoPolizaModel) {

    this.dateType = value.fecha.tipo;
    this.startDate = value.fecha.fechaInicio;
    this.startDate = this.startDate.toISOString();
    this.endDate = value.fecha.fechaFin;
    this.endDate = this.endDate.toISOString();
    this.status = value.codEstado;
    this.movementType = value.codTipoMovimiento;
    this.branch = value.codRamo;
    this.branchOffice = value.codSucursal;

    let parametros = {
      'dateType': this.dateType ? this.dateType : null,
      'startDate': this.startDate ? this.startDate : null,
      'endDate': this.endDate ? this.endDate : null,
      'status': this.status ? this.status : null,
      'movementType': this.movementType ? this.movementType : null,
      'branch': this.branch ? this.branch : null,
      'branchOffice': this.branchOffice ? this.branchOffice : null,
      'requestType': 'POLICY',
      'brokerId': this.brokerId ? this.brokerId : null
    };

    this.blockedDocument = true;
    this._documentsService.getReport(parametros).subscribe((res: ApiResponse) => {
      const r = new Uint8Array(res.data);
      // this.numeroItems = res.data.itemsNumber;
      this.numeroItems = 1;
      if (res.data) {
        this.toExcel(res.data);
        this.messageService.add({ severity: 'success', summary: '', detail: 'Reporte generado correctamente' });
      } else {
        this.messageService.add({ severity: 'error', summary: '', detail: 'No existen datos para los filtros seleccionados' });
      }
      this.blockedDocument = false;
    });
  }

  toExcel(xlsData: any) {
    const pdfElement = document.createElement('a');
    pdfElement.href = `${ACCOUNT_CONST.REPORTS.EXCEL + xlsData}`;
    pdfElement.download = 'reportePolizaEstados' + '.xls';
    document.body.appendChild(pdfElement);
    pdfElement.click();
    document.body.removeChild(pdfElement);
  }


}
