//Core-angular
import { NgModule } from '@angular/core';
import { MisSolicitudesComponent } from './mis-solicitudes.component';
//Components

@NgModule({
  declarations: [
    MisSolicitudesComponent
  ],
  imports: [

  ],
  providers: [
  ],
  bootstrap: [],
  exports: [
    MisSolicitudesComponent
  ]

})
export class MisSolicitudesModule { }
