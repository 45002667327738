// CORE-ANGULAR

import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { CommonModule } from '@angular/common';
// UTILITARIOS.
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import {
  InputTextModule, DialogModule, DataTableModule, SharedModule, ButtonModule, GrowlModule, DropdownModule,
  MessagesModule, FileUploadModule, ConfirmDialogModule, InputTextareaModule,
  AutoCompleteModule,
  TabViewModule, PanelModule, CalendarModule, ConfirmationService, CheckboxModule, FieldsetModule, RadioButtonModule, MultiSelectModule
} from 'primeng/primeng';
import { KeyFilterModule } from 'primeng/keyfilter';
import { InputMaskModule } from 'primeng/inputmask';
// Import ngx-barcode module
import { TableModule } from 'primeng/table';
import { StepsModule } from 'primeng/steps';
import { SelectButtonModule } from 'primeng/selectbutton';
import { AccordionModule } from 'primeng/accordion';
import { SpinnerModule } from 'primeng/spinner';
// Formulario EC
// Pasos para persona Natural
import { DatosGeneralesComponent as DatosGeneralesEc } from './formulariosEc/personaNatural/datosGenerales/datosGenerales.component';
import { DatosFinancierosComponent as DatosFinancierosEc } from './formulariosEc/personaNatural/datosFinancieros/datosFinancieros.component';
import { DatosDeclaracionesComponent as DatosDeclaracionesEc } from './formulariosEc/personaNatural/datosDeclaraciones/datosDeclaraciones.component';
import { DocumentosComponent as DocumentosEc } from './formulariosEc/personaNatural/documentos/documentos.component';
// Pasos para persona Juridica
import { InfoGeneralComponent as InfoGeneralEc } from './formulariosEc/personaJuridica/infoGeneral/infoGeneral.component';
import { InfoFinancieraComponent as InfoFinancieraEc } from './formulariosEc/personaJuridica/infoFinanciera/infoFinanciera.component';
import { ShareholdeFormComponent as ShareholderEc } from './formulariosEc/personaJuridica/infoFinanciera/shareholder/shareholde-form.component';
import { InfoDeclaracionesComponent as InfoDeclaracionesEc } from './formulariosEc/personaJuridica/infoDeclaraciones/infoDeclaraciones.component';
import { DocumentosPJComponent as DocumentosPJEc } from './formulariosEc/personaJuridica/documentosPJ/documentosPJ.component';
// Formulario CO
import { DatosGeneralesComponent as DatosGeneralesCo } from './formulariosCo/personaNatural/datosGenerales/datosGenerales.component';
import { DatosFinancierosComponent as DatosFinancierosCo } from './formulariosCo/personaNatural/datosFinancieros/datosFinancieros.component';
import { DatosDeclaracionesComponent as DatosDeclaracionesCo } from './formulariosCo/personaNatural/datosDeclaraciones/datosDeclaraciones.component';
// Pasos para persona Juridica
import { InfoGeneralComponent as InfoGeneralCo } from './formulariosCo/personaJuridica/infoGeneral/infoGeneral.component';
import { InfoFinancieraComponent as InfoFinancieraCo } from './formulariosCo/personaJuridica/infoFinanciera/infoFinanciera.component';
import { InfoDeclaracionesComponent as InfoDeclaracionesCo } from './formulariosCo/personaJuridica/infoDeclaraciones/infoDeclaraciones.component';
import { AppFooterComponent } from '../../../app.footer.component';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { BlockUIModule } from 'primeng/blockui';
import { MessageModule } from 'primeng/message';
// charge catalogue service
import { SharedCatalogueService } from './shared-catalogue.service';
// Compress image
import { NgxImageCompressService } from 'ngx-image-compress';

@NgModule({
  declarations: [
    DatosGeneralesEc,
    DatosFinancierosEc,
    DatosDeclaracionesEc,
    DocumentosEc,
    AppFooterComponent,
    InfoGeneralEc,
    InfoFinancieraEc,
    ShareholderEc,
    InfoDeclaracionesEc,
    DocumentosPJEc,
    DatosGeneralesCo,
    DatosFinancierosCo,
    DatosDeclaracionesCo,
    InfoGeneralCo,
    InfoFinancieraCo,
    InfoDeclaracionesCo,
  ],
  imports: [
    TableModule,
    CommonModule,
    FormsModule,
    HttpModule,
    BrowserAnimationsModule,
    InputTextModule,
    DialogModule,
    DataTableModule,
    SharedModule,
    ButtonModule,
    GrowlModule,
    DropdownModule,
    MessagesModule,
    FileUploadModule,
    ConfirmDialogModule,
    InputTextareaModule,
    TabViewModule,
    PanelModule,
    CalendarModule,
    CheckboxModule,
    FieldsetModule,
    RadioButtonModule,
    KeyFilterModule,
    MultiSelectModule,
    AutoCompleteModule,
    InputMaskModule,
    StepsModule,
    AccordionModule,
    SelectButtonModule,
    SpinnerModule,
    ProgressSpinnerModule,
    BlockUIModule,
    MessageModule

  ],
  providers: [
    SharedCatalogueService,
    NgxImageCompressService
  ],
  bootstrap: [],
  exports: [
    DatosGeneralesEc,
    DatosFinancierosEc,
    DatosDeclaracionesEc,
    DocumentosEc,
    InfoGeneralEc,
    InfoFinancieraEc,
    ShareholderEc,
    InfoDeclaracionesEc,
    DocumentosPJEc,
    DatosGeneralesCo,
    DatosFinancierosCo,
    DatosDeclaracionesCo,
    InfoGeneralCo,
    InfoFinancieraCo,
    InfoDeclaracionesCo,
    AppFooterComponent,
  ]

})
export class RegistroModule { }
