/**
 * intl-latam-ec-portal-documents
 * No description
 *
 * OpenAPI spec version: 1.0.0
 * Contact: you@your-company.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { CatalogModel } from './catalog';
import { CompanyContactsModel } from './companyContacts';
import { CompanyDocumentModel } from './companyDocument';
import { PlaceAddressInformationModel } from './placeAddressInformation';
import { ContactModel } from '../form/contact';
import { PersonFormContactsModel } from './personFormContacts';


export class Company {
    type?: CatalogModel;
    /**
     * Otro tipo de compañia.
     */
    otherType?: string;
    /**
     * Fecha de constitución.
     */
    constitutionDate?: Date;
    /**
     * Razón social.
     */
    businessName: string;
    economicActivity: CatalogModel;
    /**
     * Otra actividad economica.
     */
    otherEconomicActivity?: string;
    /**
     * Objeto Social.
     */
    socialObject?: string;
    placeOfIncorporation?: string;
    document?: CompanyDocumentModel;
    ciuu?: CatalogModel;
    societyType?: CatalogModel;
    contacts?: CompanyContactsModel;
    placeAddressCompany?: PlaceAddressInformationModel;
    placeAddressBranch?: PlaceAddressInformationModel;
    branchContacts: PersonFormContactsModel;

    constructor() {
        this.type = null;
        this.otherType = null;
        this.constitutionDate = null;
        this.businessName = null;
        this.economicActivity =null;
        this.otherEconomicActivity = null;
        this.socialObject = null;
        this.placeOfIncorporation=null;
        this.document = new CompanyDocumentModel();
        this.ciuu = null;
        this.societyType = null;
        this.contacts = new CompanyContactsModel;
        this.placeAddressCompany = new PlaceAddressInformationModel;
        this.placeAddressBranch = new PlaceAddressInformationModel;
        this.branchContacts = new PersonFormContactsModel;
    }
}
