
import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({

    selector: 'final',
    templateUrl: './final.html',
    styleUrls: ['./final.css']

})

export class FinalComponent implements OnInit {
mobile: any;
verVolver=false;
    constructor(
        private router: Router
    ) { }

    ngOnInit(): void {
        //console.log("finalizado");
        let user = JSON.parse(sessionStorage.getItem('access_token'));
        if (user && user.user && user.user.brokerId) {
            this.verVolver=true;
        }
        this.mobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
        setTimeout(() => {
            this.responsive();
        }, 1000);
    }

    responsive() {
        const elm2 = <HTMLElement>document.getElementById('ftxt1');
        const elm3 = <HTMLElement>document.getElementById('ftxt2');
        if (this.mobile) {
            elm2.style.paddingLeft = '25px';
            elm3.style.paddingRight = '25px';
            elm3.style.marginTop = '10px';
        } else {
            elm2.style.paddingLeft = '120px';
            elm3.style.paddingRight = '120px';
        }
    }
    volver(){
        this.router.navigate(['tramites']);
    }

}