import { Directive, HostListener, ElementRef } from '@angular/core';

@Directive({
  selector: '[appMask]'
})
export class MaskDirective {

  constructor(private el: ElementRef) { }

  @HostListener('input', ['$event']) 
  onInputChange(event) {
    let initialValue = this.el.nativeElement.value;

    this.el.nativeElement.value = initialValue.toUpperCase().replace(/[^A-Z0-9-]/gi, '');
    if ( this.el.nativeElement.value.length === 3) {
      this.el.nativeElement.value =  this.el.nativeElement.value + '-';
    }

    if (initialValue !== this.el.nativeElement.value) {
      event.stopPropagation();
    }
  }

  @HostListener('keydown.backspace', ['$event'])
  onKeyDownBackspace(event: KeyboardEvent) {
    // Permite borrar incluso después del guion
    const input = event.target as HTMLInputElement;
    if (input.value.endsWith('-')) {
      input.value = input.value.slice(0, -1);
    }
  }
}