/**
 * intl-latam-ec-bg-mibg-api-link-form-bg
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 2.1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import {
    HttpClient, HttpHeaders, HttpParams,
    HttpResponse, HttpEvent
} from '@angular/common/http';
import { CustomHttpUrlEncodingCodec } from '../encoder';

import { Observable } from 'rxjs/Observable';

import { ApiResponse } from '../model-formulario/apiResponse';
import { Form } from '../model-formulario/form';

import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

@Injectable()
export class FormsService {
    @BlockUI() blockUI: NgBlockUI;
    protected basePath = environment.URL_API_FORM;
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional() @Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * 
     * @param countryId 
     * @param brokerId 
     * @param evicertia 
     * @param status 
     * @param filter 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public formsBrokersBrokerIdCountriesCountryIdOPTIONS(countryId: string, brokerId: string, evicertia?: string, status?: string, filter?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public formsBrokersBrokerIdCountriesCountryIdOPTIONS(countryId: string, brokerId: string, evicertia?: string, status?: string, filter?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public formsBrokersBrokerIdCountriesCountryIdOPTIONS(countryId: string, brokerId: string, evicertia?: string, status?: string, filter?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public formsBrokersBrokerIdCountriesCountryIdOPTIONS(countryId: string, brokerId: string, evicertia?: string, status?: string, filter?: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
        this.blockUI.start();
        if (countryId === null || countryId === undefined) {
            throw new Error('Required parameter countryId was null or undefined when calling formsBrokersBrokerIdCountriesCountryIdOPTIONS.');
        }

        if (brokerId === null || brokerId === undefined) {
            throw new Error('Required parameter brokerId was null or undefined when calling formsBrokersBrokerIdCountriesCountryIdOPTIONS.');
        }




        let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (evicertia !== undefined && evicertia !== null) {
            queryParameters = queryParameters.set('evicertia', <any>evicertia);
        }
        if (status !== undefined && status !== null) {
            queryParameters = queryParameters.set('status', <any>status);
        }
        if (filter !== undefined && filter !== null) {
            queryParameters = queryParameters.set('filter', <any>filter);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.options<any>(`${this.basePath}/forms/brokers/${encodeURIComponent(String(brokerId))}/countries/${encodeURIComponent(String(countryId))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map(response => {
                this.blockUI.stop();
                return response
            })
        );
    }

    /**
     * 
     * 
     * @param countryId 
     * @param formId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public formsFormIdCountriesCountryIdOPTIONS(countryId: string, formId: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public formsFormIdCountriesCountryIdOPTIONS(countryId: string, formId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public formsFormIdCountriesCountryIdOPTIONS(countryId: string, formId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public formsFormIdCountriesCountryIdOPTIONS(countryId: string, formId: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
        this.blockUI.start();
        if (countryId === null || countryId === undefined) {
            throw new Error('Required parameter countryId was null or undefined when calling formsFormIdCountriesCountryIdOPTIONS.');
        }

        if (formId === null || formId === undefined) {
            throw new Error('Required parameter formId was null or undefined when calling formsFormIdCountriesCountryIdOPTIONS.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.options<any>(`${this.basePath}/forms/${encodeURIComponent(String(formId))}/countries/${encodeURIComponent(String(countryId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map(response => {
                this.blockUI.stop();
                return response
            })
        );
    }

    /**
     * 
     * 
     * @param formId 
     * @param form 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public formsFormIdOPTIONS(formId: string, form: Form, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public formsFormIdOPTIONS(formId: string, form: Form, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public formsFormIdOPTIONS(formId: string, form: Form, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public formsFormIdOPTIONS(formId: string, form: Form, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
        this.blockUI.start();
        if (formId === null || formId === undefined) {
            throw new Error('Required parameter formId was null or undefined when calling formsFormIdOPTIONS.');
        }

        if (form === null || form === undefined) {
            throw new Error('Required parameter form was null or undefined when calling formsFormIdOPTIONS.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.options<any>(`${this.basePath}/forms/${encodeURIComponent(String(formId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map(response => {
                this.blockUI.stop();
                return response
            })
        );
    }

    /**
     * 
     * 
     * @param form 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public formsOPTIONS(form: Form, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public formsOPTIONS(form: Form, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public formsOPTIONS(form: Form, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public formsOPTIONS(form: Form, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
        this.blockUI.start();
        if (form === null || form === undefined) {
            throw new Error('Required parameter form was null or undefined when calling formsOPTIONS.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.options<any>(`${this.basePath}/forms`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map(response => {
                this.blockUI.stop();
                return response
            })
        );
    }

    /**
     * 
     * 
     * @param form 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public intlLatamEcMilfAddLinkingForm(form: Form, observe?: 'body', reportProgress?: boolean): Observable<ApiResponse>;
    public intlLatamEcMilfAddLinkingForm(form: Form, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ApiResponse>>;
    public intlLatamEcMilfAddLinkingForm(form: Form, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ApiResponse>>;
    public intlLatamEcMilfAddLinkingForm(form: Form, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
        this.blockUI.start();
        if (form === null || form === undefined) {
            throw new Error('Required parameter form was null or undefined when calling intlLatamEcMilfAddLinkingForm.');
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2-jwt-authorizer) required
        // if (this.configuration.apiKeys["Authorization"]) {
        //     headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        // }
        // let token = 'Bearer 9pFsnlCFaxxQQ22ylMtgYMNCQPSdZqugA5AGDlvfUHlbEGBXSM2I2GgUQJD4GTZJFhx5izgn48SPb2wnW046aaElxT52VDV6lfMDasDUpfuamulGv065JDyxRXxc84f1fCqGQTLUoYyPtYI5qjkI26XiEvY9J67bt1Mz1XKQGLb3QKagm8WUOL0wowdKDayqFhTMuvha9TiTNw6ENJ3d4O0IgBvA9ghWshULrR3DkREdLcdOYoBC84MdiiCbequH';
        // headers = headers.set('Authorization', token);

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<ApiResponse>(`${this.basePath}/forms`,
            form,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map(response => {
                this.blockUI.stop();
                return response
            })
        );
    }

    /**
     * 
     * 
     * @param countryId 
     * @param brokerId 
     * @param evicertia 
     * @param status 
     * @param filter 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public intlLatamEcMilfGetLinkingFormByBrokerId(countryId: string, brokerId: string, evicertia?: string, status?: string, filter?: string, observe?: 'body', reportProgress?: boolean): Observable<ApiResponse>;
    public intlLatamEcMilfGetLinkingFormByBrokerId(countryId: string, brokerId: string, evicertia?: string, status?: string, filter?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ApiResponse>>;
    public intlLatamEcMilfGetLinkingFormByBrokerId(countryId: string, brokerId: string, evicertia?: string, status?: string, filter?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ApiResponse>>;
    public intlLatamEcMilfGetLinkingFormByBrokerId(countryId: string, brokerId: string, evicertia?: string, status?: string, filter?: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
        this.blockUI.start();
        if (countryId === null || countryId === undefined) {
            throw new Error('Required parameter countryId was null or undefined when calling intlLatamEcMilfGetLinkingFormByBrokerId.');
        }

        if (brokerId === null || brokerId === undefined) {
            throw new Error('Required parameter brokerId was null or undefined when calling intlLatamEcMilfGetLinkingFormByBrokerId.');
        }

        let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (evicertia !== undefined && evicertia !== null) {
            queryParameters = queryParameters.set('evicertia', <any>evicertia);
        }
        if (status !== undefined && status !== null) {
            queryParameters = queryParameters.set('status', <any>status);
        }
        if (filter !== undefined && filter !== null) {
            queryParameters = queryParameters.set('filter', <any>filter);
        }

        let headers = this.defaultHeaders;

        // authentication (authorize-b2b) required
        // if (this.configuration.apiKeys["Authorization"]) {
        //     headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        // }
        // let token = 'Bearer 9pFsnlCFaxxQQ22ylMtgYMNCQPSdZqugA5AGDlvfUHlbEGBXSM2I2GgUQJD4GTZJFhx5izgn48SPb2wnW046aaElxT52VDV6lfMDasDUpfuamulGv065JDyxRXxc84f1fCqGQTLUoYyPtYI5qjkI26XiEvY9J67bt1Mz1XKQGLb3QKagm8WUOL0wowdKDayqFhTMuvha9TiTNw6ENJ3d4O0IgBvA9ghWshULrR3DkREdLcdOYoBC84MdiiCbequH';
        // headers = headers.set('Authorization', token);

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<ApiResponse>(`${this.basePath}/forms/brokers/${encodeURIComponent(String(brokerId))}/countries/${encodeURIComponent(String(countryId))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map(response => {
                this.blockUI.stop();
                return response
            })
        );
    }

    /**
     * 
     * 
     * @param countryId 
     * @param formId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public intlLatamEcMilfGetLinkingFormById(countryId: string, formId: string, observe?: 'body', reportProgress?: boolean): Observable<ApiResponse>;
    public intlLatamEcMilfGetLinkingFormById(countryId: string, formId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ApiResponse>>;
    public intlLatamEcMilfGetLinkingFormById(countryId: string, formId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ApiResponse>>;
    public intlLatamEcMilfGetLinkingFormById(countryId: string, formId: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
        this.blockUI.start();
        if (countryId === null || countryId === undefined) {
            throw new Error('Required parameter countryId was null or undefined when calling intlLatamEcMilfGetLinkingFormById.');
        }

        if (formId === null || formId === undefined) {
            throw new Error('Required parameter formId was null or undefined when calling intlLatamEcMilfGetLinkingFormById.');
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2-jwt-authorizer) required
        // if (this.configuration.apiKeys["Authorization"]) {
        //     headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        // }
        // let token = 'Bearer 9pFsnlCFaxxQQ22ylMtgYMNCQPSdZqugA5AGDlvfUHlbEGBXSM2I2GgUQJD4GTZJFhx5izgn48SPb2wnW046aaElxT52VDV6lfMDasDUpfuamulGv065JDyxRXxc84f1fCqGQTLUoYyPtYI5qjkI26XiEvY9J67bt1Mz1XKQGLb3QKagm8WUOL0wowdKDayqFhTMuvha9TiTNw6ENJ3d4O0IgBvA9ghWshULrR3DkREdLcdOYoBC84MdiiCbequH';
        // headers = headers.set('Authorization', token);

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<ApiResponse>(`${this.basePath}/forms/${encodeURIComponent(String(formId))}/countries/${encodeURIComponent(String(countryId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map(response => {
                this.blockUI.stop();
                return response
            })
        );
    }

    /**
     * 
     * 
     * @param formId 
     * @param form 
     * @param operation 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public intlLatamEcMilfUpdateLinkingForm(formId: string, form: Form, operation?: string, observe?: 'body', reportProgress?: boolean): Observable<ApiResponse>;
    public intlLatamEcMilfUpdateLinkingForm(formId: string, form: Form, operation?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ApiResponse>>;
    public intlLatamEcMilfUpdateLinkingForm(formId: string, form: Form, operation?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ApiResponse>>;
    public intlLatamEcMilfUpdateLinkingForm(formId: string, form: Form, operation?: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
        this.blockUI.start();
        if (formId === null || formId === undefined) {
            throw new Error('Required parameter formId was null or undefined when calling intlLatamEcMilfUpdateLinkingForm.');
        }

        if (form === null || form === undefined) {
            throw new Error('Required parameter form was null or undefined when calling intlLatamEcMilfUpdateLinkingForm.');
        }


        let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (operation !== undefined && operation !== null) {
            queryParameters = queryParameters.set('operation', <any>operation);
        }

        let headers = this.defaultHeaders;

        // authentication (oauth2-jwt-authorizer) required
        // if (this.configuration.apiKeys["Authorization"]) {
        //     headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        // }
        // let token = 'Bearer 9pFsnlCFaxxQQ22ylMtgYMNCQPSdZqugA5AGDlvfUHlbEGBXSM2I2GgUQJD4GTZJFhx5izgn48SPb2wnW046aaElxT52VDV6lfMDasDUpfuamulGv065JDyxRXxc84f1fCqGQTLUoYyPtYI5qjkI26XiEvY9J67bt1Mz1XKQGLb3QKagm8WUOL0wowdKDayqFhTMuvha9TiTNw6ENJ3d4O0IgBvA9ghWshULrR3DkREdLcdOYoBC84MdiiCbequH';
        // headers = headers.set('Authorization', token);

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<ApiResponse>(`${this.basePath}/forms/${encodeURIComponent(String(formId))}`,
            form,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map(response => {
                this.blockUI.stop();
                return response
            })
        );
    }

}
