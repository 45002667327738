import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-academia',
  templateUrl: './academia.component.html',
  styleUrls: ['./academia.component.css']
})
export class AcademiaComponent implements OnInit {

  urlAcademy = environment.URL_ACADEMY;

  constructor(
    private router: Router,
  ) { }


  ngOnInit() {
    this.irAcademia();
  }

  irAcademia() {
    window.open(this.urlAcademy, '_blank');
    this.irHome();
  }

  irHome() {
    setTimeout(() => {
      this.router.navigate(['/home']);
    }, 450);

  }

}
