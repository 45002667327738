import { Component, OnInit } from '@angular/core';
import { BreadcrumbService } from 'src/app/breadcrumb.service';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-solicitudesEnd',
  templateUrl: './solicitudesEnd.component.html',
  styleUrls: ['./solicitudesEnd.component.css']
})
export class SolicitudesEndComponent implements OnInit {

  titulo: string;
  subtitulo: string;
  idCode: any;
  mensaje: string;
  bandera: any;

  constructor(
    private breadcrumbService: BreadcrumbService,
    private router: Router,
    private route: ActivatedRoute,
  ) { }


  ngOnInit() {
    this.cargarBreadCrum();
    this.mensaje = null;
    this.idCode = this.route.snapshot.paramMap.get('idCode');
    //console.log("Este es el id que llega", this.idCode);
    this.bandera = this.idCode;
    this.mostrarMensaje();
    this.subtitulo = 'Recuerda que el formulario estará habilitado durante los próximos 7 días';

  }

  cargarBreadCrum() {
    this.breadcrumbService.setItems([
      { label: 'Inicio', routerLink: ['/home'] },
      { label: "Crear solicitud de vinculación", routerLink: ['/solicitudes'] }
    ]);
  }


  irSolicitud() {
    this.router.navigate(['/solicitudes']);

  }


  mostrarMensaje() {
    if (this.bandera.length == '0') {
      this.titulo = "Listo, hemos enviado el formulario de conocimiento al correo de tu cliente.";
    }
    if (this.bandera[1] == '0') {
      this.titulo = "Hemos enviado el formulario de conocimiento al correo del contratante.";
    }
    if (this.bandera[0] == '0') {
      this.titulo = "Hemos enviado el formulario de conocimiento al correo del asegurado.";
    }
    if (this.bandera == '0') {
      this.titulo = "Listo, hemos enviado el formulario de conocimiento al correo de tu cliente.";
    }
    if(this.bandera == '00'){
      this.titulo = "Hemos enviado el formulario de conocimiento al correo del asegurado y contratante.";
    }
    if (this.bandera == '11') {
      this.titulo = "El asegurado y contratante ya cuentan con un formulario, no es necesario volverlo a diligenciar.";
    }
    if (this.bandera == '1') {
      this.titulo = "El contratante ya cuenta con el formulario, no es necesario volverlo a diligenciar.";
    }
    if (this.bandera == '22') {
      this.titulo = "El asegurado y contratante ya cuentan con un formulario en proceso.";
    }
    if (this.bandera == '2') {
      this.titulo = "El asegurado ya cuenta con el formulario en proceso.";
    }

  }

}


