import { Gestor } from "./gestor";
import { Ramo } from "./ramo";

export class Comision {
    id:number;
    compania: string;
    nombreBroker: string;
    prima: string;
    porcentajeComision: number;
    gestor : Gestor;
    observacion : string;
    rechazado:boolean;
    agente:any;
    ramoTecnico :Ramo;
    constructor(){
      this.compania='';
      this.nombreBroker='';
      this.prima='';
      this.porcentajeComision=0;
      this.gestor =null;
      this.observacion ='';
      this.rechazado=false;
    }

  }