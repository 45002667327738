import { Observable } from 'rxjs/Observable';

import { Injectable } from '@angular/core';
import { UrlServices } from '../url.services';
import { GenericService, METHODS } from '../services.util';
import { Form } from '../model-formulario/form';
import { DocumentModel } from 'src/app/_model/documents/models';

/**
 * Forms manages services
 */
@Injectable()
export class FormsService {

  urlServices: UrlServices = new UrlServices();


  constructor(public _genericService: GenericService) {
  }
  /**
   * Add new linking form
   * @param form obj that contain data about form
   */
  intlLatamEcMilfAddLinkingForm(document: DocumentModel): Observable<any> {
    return this._genericService.genericCallServices(METHODS.POST, this.urlServices.formContext, document, null, null);
  }
  /**
   * Get form by params
   * @param countryId Country id
   * @param brokerId Broker id
   * @param evicertia data about evicertia
   * @param status status linking form
   * @param filter filter
   */
  intlLatamEcMilfGetLinkingFormByBrokerId(countryId: string, brokerId: string,
    evicertia?: string, status?: string, filter?: string): Observable<any> {
    return this._genericService.genericCallServices(METHODS.GET, this.urlServices.urlFormsBroker + `/${brokerId}/countries/${countryId}`, null, null, null);
  }
  /**
   * Get linkinform by form id and country id
   * @param countryId country id
   * @param formId form id
   */
  intlLatamEcMilfGetLinkingFormById(countryId: string, formId: string): Observable<any> {
    return this._genericService.genericCallServices(METHODS.GET, this.urlServices.formContext + `/${formId}?country=${countryId}`, null, null, null);
  }
  /**
   * Update linking form by id
   * @param formId form id
   * @param form data that linking form
   */
  intlLatamEcMilfUpdateLinkingForm(document: DocumentModel): Observable<any> {
    return this._genericService.genericCallServices(METHODS.PUT, this.urlServices.formContext + `/`, document, null, null);
  }



  getClient(documentType: number, documentNumber: number, isParent: boolean): Observable<any> {
    return this._genericService.genericCallServices(METHODS.GET, this.urlServices.getUrlClient(documentType, documentNumber, isParent));
  }

  postClient(document: any): Observable<any> {
    return this._genericService.genericCallServices(METHODS.POST, this.urlServices.formContext+'/b2b', document, null, null);
  }

  postStateAccount(body: any) {
    return this._genericService.genericCallServices(METHODS.POST, this.urlServices.domainApiAccount+'/states/b2b', body, null, null);
  }

}
