import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '../../../node_modules/@angular/router';
import { LoginService } from './login.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { TOKE_NAME, USER_MENUS, CODE, PATH_PORTAL } from '../_shared/var.constant';


@Injectable({
  providedIn: 'root'
})
export class GuardService implements CanActivate {
 
  constructor(private router: Router, private loginService: LoginService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    
    let count = 0;

    const helper = new JwtHelperService();
    let rpta = this.loginService.estaLogueado();
    // var pantalla = state.url;
    // console.log("La patalla : ", pantalla)
    if (!rpta) {
      // console.log("No esta logueado 1", localStorage.getItem(CODE))
      if(localStorage.getItem(CODE) != null){
        
      }else{
        this.loginService.cerrarSesion();
        this.router.navigate(['landing']);
      }
      return false;
    } else {
      localStorage.clear();
      let token = JSON.parse(sessionStorage.getItem(TOKE_NAME));

      if (token.access_token && !helper.isTokenExpired(token.access_token)) {
        
        let url = state.url;
        var menus = new Array();
        menus = sessionStorage.getItem(PATH_PORTAL) != null ? JSON.parse(sessionStorage.getItem(PATH_PORTAL)) : [];
        menus.push('/home');
        menus.push('/termCondiciones');

        for(let m of menus){
          if (url.includes(m)) {
            count ++;
            break;
          }
        }

        if (count > 0) {
          return true;
        } else {
          this.router.navigate(['not403']);
          return false;
        }

      } else {
        // console.log("Esta en el else")
        this.loginService.cerrarSesion();
        this.router.navigate(['landing']);
        return false;
      }
    }
  }
}
