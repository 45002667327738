import { Invitation } from "src/app/_services/model-seguridad/models";

export class InvitationModel implements Invitation {
    invitedUserDisplayName?: string;
    invitedUserEmailAddress?: string;
    inviteRedirectUrl?: string;
    sendInvitationMessage?: boolean;
    groupId?: string;
    rolesId?: Array<string>;
    userStatus?: number;

    constructor() {
        this.invitedUserDisplayName = null;
        this.invitedUserEmailAddress = null;
        this.inviteRedirectUrl = null;
        this.sendInvitationMessage = true;
        this.groupId = null;
        this.rolesId = new Array<string>();
        this.userStatus = 1;
    }
}