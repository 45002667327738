import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { SelectItem } from 'primeng/components/common/selectitem';
import { ShareholdersOfShareholder } from 'src/app/_model/documents/shareholdersOfshareholder';
import { Properties } from 'src/app/liberty/general/properties';
import { InfoFinancieraComponent } from '../infoFinanciera.component';
import { NgForm } from '@angular/forms';
import { CatalogService } from 'src/app/_services/api_v1/catalogs.service';
import { ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-shareholde-form',
  templateUrl: './shareholde-form.component.html',
  styleUrls: ['./shareholde-form.component.css']
})
export class ShareholdeFormComponent implements OnInit {
  @Input() shareholdersO: any;
  @ViewChild('form') public userFrm: NgForm;
  properties: Properties = new Properties();

  pais: string;
  paisesSelectItem: any[];

  
  tipoPersona: SelectItem[];
  typeInvestmentySelectItem: SelectItem[] = [];
  countriesSelectItem: SelectItem[] = [];
  countriesAdressSelectItem: SelectItem[] = [];
  documentTypeSelectItem: SelectItem[] = [];
  


  constructor(private catalogService: CatalogService, private route: ActivatedRoute,) {
    this.tipoPersona = this.properties.tipoPersona;
    this.countriesSelectItem.push({ label: this.properties.labelSelecioneCombo, value: '' });
    this.countriesAdressSelectItem.push({ label: this.properties.labelSelecioneCombo, value: '' });
    this.documentTypeSelectItem.push({ label: this.properties.labelSelecioneCombo, value: '' });
    this.typeInvestmentySelectItem.push({ label: this.properties.labelSelecioneCombo, value: '' },
      { label: 'Nacional', value: 'Nacional' }, { label: 'Extranjera', value: 'Extranjera' });
  }

  addSubContact(index: number) {
    console.log(index);
    if (!this.shareholdersO[index].shareholders) {
      this.shareholdersO[index].shareholders = [];
    }
    const shareholder = new ShareholdersOfShareholder();
    this.shareholdersO[index].shareholders.push(shareholder);
  }

  eliminarSub(index: number) {
    for (let i = 0; i < this.shareholdersO.length; i++) {
      if (i === index) {
        this.shareholdersO.splice(i, 1);
      }
    }
  }


  ngOnInit(): void {
    const country = this.route.snapshot.paramMap.get('idCountry');
    this.pais = country;
    this.getDocumentType();
    this.getCountries();

  }

  getDocumentType() {
    this.catalogService.getCatalogs(this.properties.documentType, this.pais)
      .subscribe((resp: any) => {
        this.documentTypeSelectItem = [];
        this.documentTypeSelectItem.push({ label: this.properties.labelSelecioneCombo, value: '' });
        resp.forEach(element => {
          const catalog = {
            label: element.description,
            value: element
          };
          this.documentTypeSelectItem.push(catalog);
        });
      },
        (err: any) => console.log(err),
        () => {
        });
  }

  getCountries() {
    this.catalogService.getCatalogs(this.properties.countries, this.pais)
      .subscribe((resp: any) => {
        this.countriesSelectItem = [];
        this.countriesSelectItem.push({ label: this.properties.labelSelecioneCombo, value: '' });
        resp.forEach(element => {
          const catalog = {
            label: element.description,
            value: element
          };
          this.countriesSelectItem.push(catalog);

        });
      },
        (err: any) => console.log(err),
        () => {
        });
  }
}