/**
 * intl-latam-ec-portal-documents
 * No description
 *
 * OpenAPI spec version: 1.0.0
 * Contact: you@your-company.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { CatalogModel } from './catalog';
import { CommissionAgentModel } from './commissionAgent';


export class CommissionModel {
    agent: CommissionAgentModel;
    /**
     * Identificador de la comisión
     */
    id: number;
    /**
     * Porcentaje de comisión.
     */
    commissionPercentage: number;
    /**
     * Prima de la póliza.
     */
    premium: number;
    technicalBouquet?: CatalogModel;

    constructor() {
        this.agent = new CommissionAgentModel();
        this.id = null;
        this.commissionPercentage = null;
        this.premium = null;
        this.technicalBouquet = new CatalogModel();
    }
}
