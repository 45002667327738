import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-cotizador-redirect',
  templateUrl: './cotizador.component.html',
})
export class CotizadorComponent implements OnInit {

  urlRedirect = environment.URL_COTIZADOR_REDIRECT;

  constructor(
    private router: Router,
  ) { }


  ngOnInit() {
    this.redirect();
  }

  redirect() {
    window.open(this.urlRedirect, '_blank');
    this.irHome();
  }

  irHome() {
    setTimeout(() => {
      this.router.navigate(['/home']);
    }, 450);

  }

}
