/**
 * intl-latam-ec-portal
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 2018-12-13T03:57:51Z
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs/Observable';

import { ActualizarEstadoModel } from '../model-poliza/actualizarEstadoModel';

import { BusquedaPolizaModel } from '../model-poliza/busquedaPolizaModel';
import { ComisionActualizacionModel } from '../model-poliza/comisionActualizacionModel';
import { Empty } from '../model-poliza/empty';
import { EnvioAEvicertiaModel } from '../model-poliza/envioAEvicertiaModel';
import { FacturacionElectronicaModel } from '../model-poliza/facturacionElectronicaModel';
import { PolicyElectronicBill } from '../model-poliza/policyElectronicBill';
import { Poliza } from '../model-poliza/poliza';
import { PolizaIdModel } from '../model-poliza/polizaIdModel';
import { RecuperarS3Model } from '../model-poliza/recuperarS3Model';
import { ReporteEstadoPolizaModel } from '../model-poliza/reporteEstadoPolizaModel';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';
import { ApiResponse } from '../../_model/policy/apiResponse';
import { environment } from '../../../environments/environment';


@Injectable()
export class DefaultService {

    protected basePath = environment.URL_API_POLICY;
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (let consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public actualizarComisionOptions(observe?: 'body', reportProgress?: boolean): Observable<Empty>;
    public actualizarComisionOptions(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Empty>>;
    public actualizarComisionOptions(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Empty>>;
    public actualizarComisionOptions(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.options<Empty>(`${this.basePath}/actualizarComision`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param comisionActualizacionModel 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public actualizarComisionPost(comisionActualizacionModel: ComisionActualizacionModel, observe?: 'body', reportProgress?: boolean): Observable<Empty>;
    public actualizarComisionPost(comisionActualizacionModel: ComisionActualizacionModel, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Empty>>;
    public actualizarComisionPost(comisionActualizacionModel: ComisionActualizacionModel, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Empty>>;
    public actualizarComisionPost(comisionActualizacionModel: ComisionActualizacionModel, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (comisionActualizacionModel === null || comisionActualizacionModel === undefined) {
            throw new Error('Required parameter comisionActualizacionModel was null or undefined when calling actualizarComisionPost.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/json'
        ];
        let httpContentTypeSelected:string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set("Content-Type", httpContentTypeSelected);
        }

        return this.httpClient.post<Empty>(`${this.basePath}/actualizarComision`,
            comisionActualizacionModel,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public actualizarEstadoPolizaOptions(observe?: 'body', reportProgress?: boolean): Observable<Empty>;
    public actualizarEstadoPolizaOptions(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Empty>>;
    public actualizarEstadoPolizaOptions(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Empty>>;
    public actualizarEstadoPolizaOptions(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.options<Empty>(`${this.basePath}/actualizarEstadoPoliza`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param actualizarEstadoModel 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public actualizarEstadoPolizaPost(actualizarEstadoModel: ActualizarEstadoModel, observe?: 'body', reportProgress?: boolean): Observable<Empty>;
    public actualizarEstadoPolizaPost(actualizarEstadoModel: ActualizarEstadoModel, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Empty>>;
    public actualizarEstadoPolizaPost(actualizarEstadoModel: ActualizarEstadoModel, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Empty>>;
    public actualizarEstadoPolizaPost(actualizarEstadoModel: ActualizarEstadoModel, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (actualizarEstadoModel === null || actualizarEstadoModel === undefined) {
            throw new Error('Required parameter actualizarEstadoModel was null or undefined when calling actualizarEstadoPolizaPost.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/json'
        ];
        let httpContentTypeSelected:string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set("Content-Type", httpContentTypeSelected);
        }

        return this.httpClient.post<Empty>(`${this.basePath}/actualizarEstadoPoliza`,
            actualizarEstadoModel,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public buscarPolizaByIdOptions(observe?: 'body', reportProgress?: boolean): Observable<Empty>;
    public buscarPolizaByIdOptions(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Empty>>;
    public buscarPolizaByIdOptions(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Empty>>;
    public buscarPolizaByIdOptions(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.options<Empty>(`${this.basePath}/buscarPolizaById`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param polizaIdModel 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public buscarPolizaByIdPost(polizaIdModel: PolizaIdModel, observe?: 'body', reportProgress?: boolean): Observable<ApiResponse>;
    public buscarPolizaByIdPost(polizaIdModel: PolizaIdModel, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ApiResponse>>;
    public buscarPolizaByIdPost(polizaIdModel: PolizaIdModel, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ApiResponse>>;
    public buscarPolizaByIdPost(polizaIdModel: PolizaIdModel, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (polizaIdModel === null || polizaIdModel === undefined) {
            throw new Error('Required parameter polizaIdModel was null or undefined when calling buscarPolizaByIdPost.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/json'
        ];
        let httpContentTypeSelected:string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set("Content-Type", httpContentTypeSelected);
        }

        return this.httpClient.post<ApiResponse>(`${this.basePath}/buscarPolizaById`,
            polizaIdModel,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public buscarPolizaOptions(observe?: 'body', reportProgress?: boolean): Observable<Empty>;
    public buscarPolizaOptions(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Empty>>;
    public buscarPolizaOptions(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Empty>>;
    public buscarPolizaOptions(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.options<Empty>(`${this.basePath}/buscarPoliza`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param busquedaPolizaModel 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public buscarPolizaPost(busquedaPolizaModel: BusquedaPolizaModel, observe?: 'body', reportProgress?: boolean): Observable<Empty>;
    public buscarPolizaPost(busquedaPolizaModel: BusquedaPolizaModel, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Empty>>;
    public buscarPolizaPost(busquedaPolizaModel: BusquedaPolizaModel, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Empty>>;
    public buscarPolizaPost(busquedaPolizaModel: BusquedaPolizaModel, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (busquedaPolizaModel === null || busquedaPolizaModel === undefined) {
            throw new Error('Required parameter busquedaPolizaModel was null or undefined when calling buscarPolizaPost.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/json'
        ];
        let httpContentTypeSelected:string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set("Content-Type", httpContentTypeSelected);
        }

        return this.httpClient.post<Empty>(`${this.basePath}/buscarPoliza`,
            busquedaPolizaModel,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public cargarS3Options(observe?: 'body', reportProgress?: boolean): Observable<Empty>;
    public cargarS3Options(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Empty>>;
    public cargarS3Options(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Empty>>;
    public cargarS3Options(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.options<Empty>(`${this.basePath}/cargarS3`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public cargarS3Post(observe?: 'body', reportProgress?: boolean): Observable<Empty>;
    public cargarS3Post(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Empty>>;
    public cargarS3Post(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Empty>>;
    public cargarS3Post(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
        ];

        return this.httpClient.post<Empty>(`${this.basePath}/cargarS3`,
            null,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public contarPorEstadoOptions(observe?: 'body', reportProgress?: boolean): Observable<Empty>;
    public contarPorEstadoOptions(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Empty>>;
    public contarPorEstadoOptions(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Empty>>;
    public contarPorEstadoOptions(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.options<Empty>(`${this.basePath}/contarPorEstado`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param busquedaPolizaModel 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public contarPorEstadoPost(busquedaPolizaModel: BusquedaPolizaModel, observe?: 'body', reportProgress?: boolean): Observable<Empty>;
    public contarPorEstadoPost(busquedaPolizaModel: BusquedaPolizaModel, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Empty>>;
    public contarPorEstadoPost(busquedaPolizaModel: BusquedaPolizaModel, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Empty>>;
    public contarPorEstadoPost(busquedaPolizaModel: BusquedaPolizaModel, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (busquedaPolizaModel === null || busquedaPolizaModel === undefined) {
            throw new Error('Required parameter busquedaPolizaModel was null or undefined when calling contarPorEstadoPost.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/json'
        ];
        let httpContentTypeSelected:string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set("Content-Type", httpContentTypeSelected);
        }

        return this.httpClient.post<Empty>(`${this.basePath}/contarPorEstado`,
            busquedaPolizaModel,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public crearPolizaOptions(observe?: 'body', reportProgress?: boolean): Observable<Empty>;
    public crearPolizaOptions(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Empty>>;
    public crearPolizaOptions(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Empty>>;
    public crearPolizaOptions(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.options<Empty>(`${this.basePath}/crearPoliza`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param poliza 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public crearPolizaPost(poliza: Poliza, observe?: 'body', reportProgress?: boolean): Observable<Empty>;
    public crearPolizaPost(poliza: Poliza, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Empty>>;
    public crearPolizaPost(poliza: Poliza, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Empty>>;
    public crearPolizaPost(poliza: Poliza, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (poliza === null || poliza === undefined) {
            throw new Error('Required parameter poliza was null or undefined when calling crearPolizaPost.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/json'
        ];
        let httpContentTypeSelected:string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set("Content-Type", httpContentTypeSelected);
        }

        return this.httpClient.post<Empty>(`${this.basePath}/crearPoliza`,
            poliza,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public envioEvicertiaOptions(observe?: 'body', reportProgress?: boolean): Observable<Empty>;
    public envioEvicertiaOptions(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Empty>>;
    public envioEvicertiaOptions(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Empty>>;
    public envioEvicertiaOptions(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.options<Empty>(`${this.basePath}/envioEvicertia`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param envioAEvicertiaModel 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public envioEvicertiaPost(envioAEvicertiaModel: EnvioAEvicertiaModel, observe?: 'body', reportProgress?: boolean): Observable<Empty>;
    public envioEvicertiaPost(envioAEvicertiaModel: EnvioAEvicertiaModel, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Empty>>;
    public envioEvicertiaPost(envioAEvicertiaModel: EnvioAEvicertiaModel, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Empty>>;
    public envioEvicertiaPost(envioAEvicertiaModel: EnvioAEvicertiaModel, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (envioAEvicertiaModel === null || envioAEvicertiaModel === undefined) {
            throw new Error('Required parameter envioAEvicertiaModel was null or undefined when calling envioEvicertiaPost.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/json'
        ];
        let httpContentTypeSelected:string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set("Content-Type", httpContentTypeSelected);
        }

        return this.httpClient.post<Empty>(`${this.basePath}/envioEvicertia`,
            envioAEvicertiaModel,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param date 
     * @param lookupkey 
     * @param uniqueid 
     * @param evidencetype 
     * @param outcome 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public notificacionEvicertiaGet(date?: string, lookupkey?: string, uniqueid?: string, evidencetype?: string, outcome?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public notificacionEvicertiaGet(date?: string, lookupkey?: string, uniqueid?: string, evidencetype?: string, outcome?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public notificacionEvicertiaGet(date?: string, lookupkey?: string, uniqueid?: string, evidencetype?: string, outcome?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public notificacionEvicertiaGet(date?: string, lookupkey?: string, uniqueid?: string, evidencetype?: string, outcome?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (date !== undefined) {
            queryParameters = queryParameters.set('date', <any>date);
        }
        if (lookupkey !== undefined) {
            queryParameters = queryParameters.set('lookupkey', <any>lookupkey);
        }
        if (uniqueid !== undefined) {
            queryParameters = queryParameters.set('uniqueid', <any>uniqueid);
        }
        if (evidencetype !== undefined) {
            queryParameters = queryParameters.set('evidencetype', <any>evidencetype);
        }
        if (outcome !== undefined) {
            queryParameters = queryParameters.set('outcome', <any>outcome);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<any>(`${this.basePath}/notificacionEvicertia`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public notificacionEvicertiaOptions(observe?: 'body', reportProgress?: boolean): Observable<Empty>;
    public notificacionEvicertiaOptions(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Empty>>;
    public notificacionEvicertiaOptions(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Empty>>;
    public notificacionEvicertiaOptions(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.options<Empty>(`${this.basePath}/notificacionEvicertia`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public recuperarS3Options(observe?: 'body', reportProgress?: boolean): Observable<Empty>;
    public recuperarS3Options(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Empty>>;
    public recuperarS3Options(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Empty>>;
    public recuperarS3Options(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.options<Empty>(`${this.basePath}/recuperarS3`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param recuperarS3Model 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public recuperarS3Post(recuperarS3Model: RecuperarS3Model, observe?: 'body', reportProgress?: boolean): Observable<Empty>;
    public recuperarS3Post(recuperarS3Model: RecuperarS3Model, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Empty>>;
    public recuperarS3Post(recuperarS3Model: RecuperarS3Model, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Empty>>;
    public recuperarS3Post(recuperarS3Model: RecuperarS3Model, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (recuperarS3Model === null || recuperarS3Model === undefined) {
            throw new Error('Required parameter recuperarS3Model was null or undefined when calling recuperarS3Post.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/json'
        ];
        let httpContentTypeSelected:string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set("Content-Type", httpContentTypeSelected);
        }

        return this.httpClient.post<Empty>(`${this.basePath}/recuperarS3`,
            recuperarS3Model,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public reporteEstadoPolizaResourceOptions(observe?: 'body', reportProgress?: boolean): Observable<Empty>;
    public reporteEstadoPolizaResourceOptions(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Empty>>;
    public reporteEstadoPolizaResourceOptions(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Empty>>;
    public reporteEstadoPolizaResourceOptions(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.options<Empty>(`${this.basePath}/reporteEstadoPolizaResource`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param reporteEstadoPolizaModel 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public reporteEstadoPolizaResourcePost(reporteEstadoPolizaModel: ReporteEstadoPolizaModel, observe?: 'body', reportProgress?: boolean): Observable<Empty>;
    public reporteEstadoPolizaResourcePost(reporteEstadoPolizaModel: ReporteEstadoPolizaModel, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Empty>>;
    public reporteEstadoPolizaResourcePost(reporteEstadoPolizaModel: ReporteEstadoPolizaModel, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Empty>>;
    public reporteEstadoPolizaResourcePost(reporteEstadoPolizaModel: ReporteEstadoPolizaModel, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (reporteEstadoPolizaModel === null || reporteEstadoPolizaModel === undefined) {
            throw new Error('Required parameter reporteEstadoPolizaModel was null or undefined when calling reporteEstadoPolizaResourcePost.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/json'
        ];
        let httpContentTypeSelected:string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set("Content-Type", httpContentTypeSelected);
        }

        return this.httpClient.post<Empty>(`${this.basePath}/reporteEstadoPolizaResource`,
            reporteEstadoPolizaModel,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public soapToRestFacturaOptions(observe?: 'body', reportProgress?: boolean): Observable<Empty>;
    public soapToRestFacturaOptions(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Empty>>;
    public soapToRestFacturaOptions(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Empty>>;
    public soapToRestFacturaOptions(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.options<Empty>(`${this.basePath}/soapToRestFactura`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param facturacionElectronicaModel 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public soapToRestFacturaPost(facturacionElectronicaModel: FacturacionElectronicaModel, observe?: 'body', reportProgress?: boolean): Observable<Empty>;
    public soapToRestFacturaPost(facturacionElectronicaModel: FacturacionElectronicaModel, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Empty>>;
    public soapToRestFacturaPost(facturacionElectronicaModel: FacturacionElectronicaModel, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Empty>>;
    public soapToRestFacturaPost(facturacionElectronicaModel: FacturacionElectronicaModel, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (facturacionElectronicaModel === null || facturacionElectronicaModel === undefined) {
            throw new Error('Required parameter facturacionElectronicaModel was null or undefined when calling soapToRestFacturaPost.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/json'
        ];
        let httpContentTypeSelected:string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set("Content-Type", httpContentTypeSelected);
        }

        return this.httpClient.post<Empty>(`${this.basePath}/soapToRestFactura`,
            facturacionElectronicaModel,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param policyId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public validateElectronicBillGet(policyId?: string, observe?: 'body', reportProgress?: boolean): Observable<PolicyElectronicBill>;
    public validateElectronicBillGet(policyId?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PolicyElectronicBill>>;
    public validateElectronicBillGet(policyId?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PolicyElectronicBill>>;
    public validateElectronicBillGet(policyId?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (policyId !== undefined) {
            queryParameters = queryParameters.set('policyId', <any>policyId);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<PolicyElectronicBill>(`${this.basePath}/validateElectronicBill`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public validateElectronicBillOptions(observe?: 'body', reportProgress?: boolean): Observable<Empty>;
    public validateElectronicBillOptions(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Empty>>;
    public validateElectronicBillOptions(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Empty>>;
    public validateElectronicBillOptions(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        let httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set("Accept", httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        let consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.options<Empty>(`${this.basePath}/validateElectronicBill`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
