import { Component, OnInit, Input, OnChanges, Output, EventEmitter } from '@angular/core';
import { Properties } from '../../general/properties';
import * as FileSaver from 'file-saver';
import { MessageService } from 'primeng/components/common/messageservice';
import { DefaultService } from 'src/app/_services';
import { DocumentsService } from 'src/app/_services/api_v1/documents.service';
import { StringifyOptions } from 'querystring';
import { ModelFileModel } from 'src/app/_model/documents/models';
import { FilesService } from 'src/app/_services/api_v1/files.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-formulario-revision',
  templateUrl: './formulario-revision.component.html',
  styleUrls: ['./formulario-revision.component.css']
})
export class FormularioRevisionComponent implements OnInit, OnChanges {

  properties: Properties = new Properties();
  @Input() dateId: string;
  @Input() country: string;
  @Output() notificarPadre: EventEmitter<any> = new EventEmitter();

  fechaActualizacion = new Date();
  fechaCreacion = new Date();
  validDate: string;
  result: any;
  doc: any;
  loading: any;
  file: ModelFileModel;
  lblEnvioCliente: string;
  nominaDetails: string = "Nómina actualizada de accionistas o socios, obtenida a través del órgano de control o registro competente; (Para el caso de fundaciones, ONG's u otro tipo de institución, copia de la nómina del comité administrativo o directiva";
  lblDiligenFormulario = 'Diligenciar formulario';
  form: any;

  // first section
  kindProcedure = 'Formulario de vinculación';
  formStatus = 'Estado';
  updateDate: string;
  createDate: string;
  // second section
  names: string;
  surnames: string;
  idType: string;
  identification: string;
  birthdate: Date;
  occupation: string;
  email: string;
  residence: string;
  address: string;
  // third section
  listFiles: any;

  // Desbloquear
  locked: boolean = false;
  mostrarAceptar: boolean;
  correoElectronico: any;
  tituloPopUp: string;
  nuevoCorreo: any;

  // 0 finalizado-rivasado
  // 1 pendiente
  // 2 fallo evicertia, rechazo cliente
  mostrarBoton = 0
  failed = false
  soloDesbloquear: boolean = false;
  personType = 0;

  constructor(
    private messageService: MessageService,
    public policyService: DefaultService,
    private router: Router,
    private fileService: FilesService,
    private _documentsService: DocumentsService
  ) { }

  ngOnChanges(): void {
    this._documentsService.getDocumentsByDateIdByCountry(this.dateId, this.country).subscribe((document: any) => {

      if (document && document != null) {
        if (document.data && document.data != null) {
          // dtk
          this.locked = document.sms.locked;

          // first section
          this.createDate = document.creationDate;
          if (document.evicertia && document.evicertia.date) {
            const validDate = new Date(document.evicertia.date);
            validDate.setDate(validDate.getDate() + 335);
            this.validDate = validDate.toISOString();
          }
          this.updateDate = document.updateDate;
          this.formStatus = document.status;
          //console.log(document);
          if (this.formStatus == this.properties.estadosDocumentos.REVIEWED
            || this.formStatus == this.properties.estadosDocumentos.SIGNED
            || this.formStatus == this.properties.estadosDocumentos.REJECTED_BROKER
            || this.formStatus == this.properties.estadosDocumentos.EXPIRED
            || this.formStatus == this.properties.estadosDocumentos.ANNUAL_EXPIRED
          ) {
            this.mostrarBoton = 0;
          } else if (this.formStatus == this.properties.estadosDocumentos.PENDING) {
            this.mostrarBoton = 1;
          } else if (this.formStatus == this.properties.estadosDocumentos.REJECTED_CLIENT
            || this.formStatus == this.properties.estadosDocumentos.FAILED) {
            this.mostrarBoton = 2;
          }

          if (document.sms && document.sms.locked) {
            this.lblEnvioCliente = 'Desbloquear formulario';
            this.tituloPopUp = 'Desbloquear formulario';
            this.formStatus = "LOCKED";
            this.mostrarBoton = 3;
          } else {

            this.lblEnvioCliente = 'Reenviar formulario';
            this.tituloPopUp = 'Reenviar formulario';
          }
          // second section
          if (document.data.personType == "N") {
            this.personType = 0;
            if (document.data.person && document.data.person != null) {
              this.names = document.data.person.firstName + ' ' + document.data.person.secondName;
              this.surnames = document.data.person.lastName + ' ' + document.data.person.secondLastName;
              this.birthdate = new Date(document.data.person.birthDate);
              if (document.data.person.document && document.data.person.document != null) {
                this.identification = document.data.person.document.documentNumber;
                if (document.data.person.document.type && document.data.person.document.type != null) {
                  this.idType = document.data.person.document.type.description;
                }
              }
              if (document.data.person.placeAddressResidence && document.data.person.placeAddressResidence != null) {
                this.address = document.data.person.placeAddressResidence.mainStreet + ' '
                  + document.data.person.placeAddressResidence.cross + ' ' + document.data.person.placeAddressResidence.lotNumber;
                if (document.data.person.placeAddressResidence.place && document.data.person.placeAddressResidence.place != null) {
                  let countryA = '';
                  let province = '';
                  let city = '';
                  if (document.data.person.placeAddressResidence.place.country &&
                    document.data.person.placeAddressResidence.place.country != null) {
                    countryA = document.data.person.placeAddressResidence.place.country.description;
                  }
                  if (document.data.person.placeAddressResidence.place.province &&
                    document.data.person.placeAddressResidence.place.province != null) {
                    province = document.data.person.placeAddressResidence.place.province.description;
                    if (province == null) {
                      province = '';
                    }
                  }
                  if (document.data.person.placeAddressResidence.place.city &&
                    document.data.person.placeAddressResidence.place.city != null) {
                    city = document.data.person.placeAddressResidence.place.city.description;
                    if (city == null) {
                      city = '';
                    }
                  }
                  this.residence = countryA + ' ' + province + ' ' + city;
                }
              }
              if (document.data.person.contacts && document.data.person.contacts != null) {
                if (document.data.person.contacts.email && document.data.person.contacts.email != null) {
                  if (document.data.person.contacts.email.type && document.data.person.contacts.email.type != null) {
                    this.email = document.data.person.contacts.email.type.description;
                    this.correoElectronico = document.data.person.contacts.email.description;;
                  }
                }
              }
            }
          } else {
            this.personType = 1;
            if (document.data.company && document.data.company != null) {
              this.names = document.data.company.businessName;
              this.birthdate = new Date(document.data.company.constitutionDate);
              if (document.data.company.document && document.data.company.document != null) {
                this.identification = document.data.company.document.documentNumber;
                if (document.data.company.document.type && document.data.company.document.type != null) {
                  this.idType = document.data.company.document.type.description;
                }
              }
              this.occupation = document.data.company.socialObject;
              if (document.data.company.contacts && document.data.company.contacts != null) {
                if (document.data.company.contacts.email && document.data.company.contacts.email != null) {
                  if (document.data.company.contacts.email.type && document.data.company.contacts.email.type != null) {
                    this.email = document.data.company.contacts.email.type.description;
                    this.correoElectronico = document.data.company.contacts.email.description;;
                  }
                }
              }

            }

          }

          if (document.data.work && document.data.work != null) {
            if (document.data.work.profession && document.data.work.profession != null) {
              this.occupation = document.data.work.profession.description;
            }
          }
          if (document.data.files && document.data.files != null) {

            this.getListFiles(document.data.files);
          }
        }
      }
    }, err => {
      console.log('Error', err);
    });
  }
  ngOnInit() {
    // if (this.formulario.files) {
    //   this.formulario.files.forEach(document.data => {
    //     if (document.data) {
    //       const nameFile = document.data.key.split('/');
    //       this.doc[document.data.type] = document.data;
    //       this.doc[document.data.type].name = nameFile[2];
    //     }
    //   });
    // } else {
    //   this.formulario.files = [];
    // }
    // const dateId = '2019-05-03T17:24:25.079Z';
    // const country = 'EC';

    // // this.properties.estadosResportes.forEach(estado => {
    // //   this.estadoFormulario = this.form.data.status;
    // //   if (estado.value !== 0) {
    // //     if (this.form.data.status.toLocaleUpperCase() === estado.value.toLocaleUpperCase()) {
    // //       this.estadoFormulario = estado.label;
    // //     }
    // //   }

    // });
  }

  getListFiles(files: any) {
    if (files && files != null) {
      const sizeList = files.length;
      this.listFiles = [];
      let indexList = 0;
      let indChange = false;
      this.listFiles[indexList] = [];
      for (let index = 0; index < files.length; index++) {
        if ((index % 6) === 0) {
          indexList++;
          indChange = true;
        }
        if (indChange) {
          indChange = false;
          this.listFiles[indexList] = [];
        }
        this.listFiles[indexList].push(files[index]);
      }
      this.listFiles.splice(this.listFiles[0], 1);
    }
  }

  async getFile(file) {
    if (file.key && file.key != null) {
      const dataList = file.key.split("/");
      const result = await this.fileService.downloadFileWithS3Presigned(dataList[3], dataList[1], 'download');
      FileSaver.saveAs(result, file.type);
    }
  }

  seePdf(file) {
    if (file.key && file.key != null && file.type && file.type != null) {
      this.file = new ModelFileModel();
      this.file.type = file.key;
      const path = file.key.split('/');
      this.file.file = path[3];
      this.loading = true;
      this.fileService.recuperarS3Post(file.key).subscribe((resp: any) => {
        var blob = new Blob([resp], { type: 'application/pdf' });
        FileSaver.saveAs(blob, path[3]);
        this.loading = false;
      }, err => {
        console.log(err);
        this.messageService.add({
          severity: 'error', summary: 'Busqueda Pdf',
          detail: 'El archivo no se encuentra disponible, intente mas tarde.'
        });
        this.loading = false;
      });
    } else {
      this.messageService.add({ severity: 'error', summary: 'Busqueda Pdf', detail: 'No se ha encontrado el archivo.' });
    }
  }

  envioCliente() {
    this.mostrarAceptar = true;
  }

  aceptarEnvio() {
    this.armarCorreo();
    this._documentsService.events(this.dateId, this.country, 'RESEND_FORM', this.nuevoCorreo).subscribe((resp: any) => {
      this.mostrarAceptar = false;
      this.notificarPadre.emit(true);
    },
      (err: any) => {
        this.messageService.add({ severity: 'error', summary: '', detail: '¡No se gestionó correctamente' });
      });

  }

  reenviarEvicertia() {
    this._documentsService.events(this.dateId, this.country, 'ACCEPT_EVICERTIA', this.correoElectronico).subscribe((resp: any) => {
      this.mostrarAceptar = false;
      this.notificarPadre.emit(true);
    },
      (err: any) => {
        this.messageService.add({ severity: 'error', summary: '', detail: '¡No se gestionó correctamente' });
      });

  }

  armarCorreo() {
    this.nuevoCorreo = {
      "email": this.correoElectronico + ""
    }

  }

  diligenciarFormulario() {
    // let url = this.router.createUrlTree(['formulario/registro', this.dateId, this.country]);
    // window.open(url.toString(), '_blank');
    this.router.navigate(['formulario/registro', this.dateId, this.country]);
  }

  volver() {
    this.notificarPadre.emit(true);
  }
  fixBinary(bin) {
    var length = bin.length;
    var buf = new ArrayBuffer(length);
    var arr = new Uint8Array(buf);
    for (var i = 0; i < length; i++) {
      arr[i] = bin.charCodeAt(i);
    }
    return buf;
  }
}