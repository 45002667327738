import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RenovationsComponent } from './renovations.component';
import { TableModule } from 'primeng/table';
import { PaginatorModule } from 'primeng/paginator';
import { ButtonModule } from 'primeng/button';
import { DropdownModule } from 'primeng/dropdown';
import { MultiSelectModule } from 'primeng/multiselect';
import { CalendarModule } from 'primeng/calendar';
import { ChipsModule } from 'primeng/chips';
import { StepsModule } from 'primeng/steps';
import {MenuModule} from 'primeng/menu';
import { DialogModule, OverlayPanelModule, MessageModule } from 'primeng/primeng';
import { HttpModule } from '@angular/http';
import { RenovationsService } from 'src/app/_services/api_v1/renovations.service';
import { BlockCopyPasteDirective } from './block-copy-paste.directive';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';



@NgModule({
  imports: [
    CommonModule,
    TableModule,
    PaginatorModule,
    ButtonModule,
    DropdownModule,
    MultiSelectModule,
    CalendarModule, 
    ChipsModule,
    StepsModule,
    DialogModule,
    OverlayPanelModule,
    MessageModule,
    MenuModule,
    FormsModule,
    BrowserModule,
    ReactiveFormsModule,
    HttpModule
  ],
  declarations: [RenovationsComponent, BlockCopyPasteDirective],
  providers: [
  ]
})
export class RenovationsModule { }
