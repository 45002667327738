/**
 * intl-latam-ec-portal-documents
 * No description
 *
 * OpenAPI spec version: 1.0.0
 * Contact: you@your-company.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export class FormUrlModel { 
    generated?: string;
    /**
     * Token SMS generado.
     */
    token?: string;
    /**
     * Fecha inicio de vigencia.
     */
    endEffectiveDate: string;
    dateSendingEmail?: string;
    /**
     * Número de intentos fallidos SMS ingresando al portal.
     */
    attempt?: number;
    /**
     * Fecha inicio de vigencia.
     */
    startEffectiveDate: string;

    constructor (){
        this.generated = null;
        this.token = null;
        this.endEffectiveDate = null;
        this.dateSendingEmail = null;
        this.attempt = null;
        this.startEffectiveDate = null;
    }
}
