import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/throw';
import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';
import { UrlServices } from './url.services';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { throwError } from 'rxjs/internal/observable/throwError';
@Injectable()
export class GenericService {
    urlServices: UrlServices = new UrlServices();
    @BlockUI() blockUI: NgBlockUI;
    constructor(private _http: HttpClient) {
    }

    /**
     * Ete metodo construye genericamente las llamadas a servicios http segun el las necesidades para el proyecto cnm
     * donde & methodoHttpType &
     * 
     * @param methodoHttpType 
     * @param context 
     * @param body 
     * @param parameterRequired 
     * @param additionalParameters 
     */
    public genericCallServices(methodoHttpType, context: string, body: any = null, parameterRequired: string = null, responseType: any = null, withBlock = true, infoText = null): Observable<any> {
        try {
            if (withBlock) {
                this.blockUI.start(infoText);
            }
            let contextoTypeEnd = context;
            if (parameterRequired) {
                contextoTypeEnd = contextoTypeEnd + `/${parameterRequired}`
            }
            return this.switchMethodHttp(methodoHttpType, contextoTypeEnd, body, responseType, withBlock);
        } catch (err) {
            if (withBlock) {
                this.blockUI.stop();
            }
        }
    }

    public switchMethodHttp(methodoHttpType, contextoTypeEnd, body, responseType, withBlock = true): Observable<any> {
        if (methodoHttpType === 'get' || methodoHttpType === 'delete') {
            return this._http[methodoHttpType](contextoTypeEnd, { responseType }).pipe(
                map(response => {
                    if (withBlock) {
                        this.blockUI.stop();
                    }
                    return response
                })
            )
        } else if (methodoHttpType === 'post' || methodoHttpType === 'put') {
            return this._http[methodoHttpType](contextoTypeEnd, body, { responseType }).pipe(
                map(response => {
                    if (withBlock) {
                        this.blockUI.stop();
                    }
                    return response
                }),
                catchError((error) => {
                  if (error.status) {
                    console.error(`Error ${error.status}`);
                  } else {
                    console.error('Error desconocido:', error);
                  }
                  return throwError(error);
                })
            )
        }
    }



}

export enum METHODS {
    POST = 'post',
    GET = 'get',
    UPDATE = 'update',
    PUT = 'put',
    DELETE = 'delete',
    FORMDATA = 'formData',
    FILE = 'file'
}
