import { GroupMember } from "src/app/_services/model-seguridad/models";



export class GroupMemberModel implements GroupMember {
    /**
     * hash id of group id
     */
    groupId?: string;
    /**
     * hash id of user id
     */
    userId?: string;
    constructor() {
        this.groupId = null;
        this.userId = null;
    }
}
