// CORE-ANGULAR
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { CommonModule } from '@angular/common';

import {TreeNode} from 'primeng/api';
// CORE-CNM
import { RolesComponent } from './roles.component';
// UTILITARIOS.
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DatePipe } from '@angular/common';
import {
  InputTextModule, DialogModule, DataTableModule, SharedModule, ButtonModule, GrowlModule, DropdownModule,
  MessagesModule, FileUploadModule, ConfirmDialogModule, InputTextareaModule,
  AutoCompleteModule,
  TabViewModule, PanelModule, CalendarModule, ConfirmationService, CheckboxModule,
  FieldsetModule, RadioButtonModule, MultiSelectModule, BlockUIModule, ProgressSpinnerModule
} from 'primeng/primeng';
import { KeyFilterModule } from 'primeng/keyfilter';

// Import ngx-barcode module
import { TableModule } from 'primeng/table';
import { UsersService } from '../../../_services';

// DTK
import { TreeModule } from 'primeng/tree';
import { TreeTableModule } from 'primeng/treetable';

@NgModule({
  declarations: [
    RolesComponent,

  ],
  imports: [
    TableModule,
    CommonModule,
    FormsModule,
    // ModuloGeneral,
    HttpModule,
    BrowserAnimationsModule,
    InputTextModule,
    DialogModule,
    DataTableModule,
    SharedModule,
    ButtonModule,
    GrowlModule,
    DropdownModule,
    MessagesModule,
    FileUploadModule,
    ConfirmDialogModule,
    InputTextareaModule,
    TabViewModule,
    PanelModule,
    CalendarModule,
    CheckboxModule,
    FieldsetModule,
    RadioButtonModule,
    KeyFilterModule,
    MultiSelectModule,
    AutoCompleteModule,
    BlockUIModule,
    ProgressSpinnerModule,
    TreeModule,
    TreeTableModule

  ],
  providers: [
  ],
  bootstrap: [],
  exports: [
    RolesComponent
  ]

})
export class RolesModule { }
