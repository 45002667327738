import { Observable } from 'rxjs/Observable';

import { Injectable } from '@angular/core';
import { UrlServices } from '../url.services';
import { GenericService, METHODS } from '../services.util';
import { Form } from '../model-formulario/form';
import { DocumentModel, ModelFileModel } from 'src/app/_model/documents/models';
import { ResponseContentType } from '@angular/http';
import { CriptoService } from 'src/app/_handler/cripto.service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';

/**
 * Forms manages services
 */
@Injectable()
export class FilesService {
  @BlockUI() blockUI: NgBlockUI;
  urlServices: UrlServices = new UrlServices();

  constructor(public _genericService: GenericService, private cryptoService: CriptoService) {
  }
  /**
   * Carga el archivo al repositorio S3
   * @param file obj de tipo archivo
   */
  intlLatamEcMilfAddFilesS3(file: ModelFileModel, idForm: string, country: string): Observable<any> {
    return this._genericService.genericCallServices(METHODS.POST, this.urlServices.getUrlFiles(idForm, country), file, null, null);
  }

  async downloadFileWithS3Presigned(documentName, documentNumber, type) {
    this.blockUI.start();
    const presignedUrl = await this.getPresignedS3GetFile(documentName, documentNumber, type).toPromise();
    const response = await this._genericService.genericCallServices(METHODS.GET, presignedUrl, null, null, 'blob', false).toPromise();
    this.blockUI.stop();
    return response
  }

  async uploadFileWithS3Presigned(documentName, documentNumber, type, file) {
    this.blockUI.start();
    const result = await this.getPresignedS3GetFile(documentName, documentNumber, type).toPromise();
    await this._genericService.genericCallServices(METHODS.PUT, result, file, null, null, false).toPromise();
    this.blockUI.stop();
    return result
  }

  getPresignedS3GetFile(documentName, documentNumber, type) {
    let params: any = {
      documentNumber: this.cryptoService.set(documentNumber),
      documentName: this.cryptoService.set(documentName),
      bucketType: "personalDocument",
    };
    let tipo = 1
    let user = JSON.parse(sessionStorage.getItem('access_token'));
    if (user && user.user && user.user.brokerId) {
      tipo = 0
    }
    if (type == 'download') {
      params['typeUrl'] = 'getDocument';
    } else if (type == 'upload') {
      params['typeUrl'] = 'uploadDocument';
    }
    return this._genericService.genericCallServices(METHODS.POST, `${this.urlServices.files}/files/presignedUrl${tipo == 0 ? '/b2b' : ''}`, params, null, null, false);
  }

  recuperarS3Post(key: string, isInvoice?: boolean, country?: string) {
    let invoice = '', countryDes = '';
    if (isInvoice) {
      invoice = 'isInvoice=' + isInvoice;
    }
    if (country) {
      countryDes = 'country=' + country;
    }
    return this._genericService.genericCallServices(METHODS.GET, this.urlServices.filesProxy +
      `/${key}`, null, null, 'arraybuffer');
  }

}
