import { Component, OnInit, Output, EventEmitter, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { SelectItem } from 'primeng/api';
import { InputMask } from 'primeng/inputmask';
import { Properties } from '../../../../../general/properties';
import { Router, ActivatedRoute } from '@angular/router';
import { MessageService } from 'primeng/components/common/messageservice';
import { FormsService, CatalogsService } from '../../../../../../_services';
import { ApiResponse } from '../../../../../../_services/model-formulario/modelsForm';
import { FormModel } from '../../../../../../_model/documents/form';
import { LocationModel } from '../../../../../../_model/documents/location';
import { CompanyContactsModel, DocumentModel } from 'src/app/_model/documents/models';
import { PlaceAddressInformationModel } from '../../../../../../_model/documents/placeAddressInformation';
import { Company } from '../../../../../../_model/documents/company';
// import { EntityBranchModel } from '../../../../../_model/form/entityBranch';
import { ContactModel } from '../../../../../../_model/documents/contact';

import { DocumentsService } from 'src/app/_services/api_v1/documents.service';
import { CatalogService } from 'src/app/_services/api_v1/catalogs.service';

@Component({

    selector: 'app-info-general-ec',
    templateUrl: './infoGeneral.html',
    styleUrls: ['./infoGeneral.css']

})

export class InfoGeneralComponent implements OnInit {

    @Output() changed = new EventEmitter<boolean>();
    @ViewChild('form') public userFrm: NgForm;
    today = new Date();
    yesterday = new Date();

    properties: Properties = new Properties();
    es: any = this.properties.es;
    titulo = 'Comencemos';
    subtitulo = 'Queremos conocerte. Completa la información solicitada en cada paso';
    // Generales
    pais: any;
    idFormulario: any;

    // Catalogos
    tipoActividadSelectItem: any[];
    tipoCompaniaSelectItem: any[];
    countriesSelectItem: SelectItem[] = [];
    provinceSelectItem: SelectItem[] = [];
    citySelectItem: SelectItem[] = [];
    countriesAdressSelectItem: SelectItem[] = [];
    provinceAdressSelectItem: SelectItem[] = [];
    cityAdressSelectItem: SelectItem[] = [];
    tipoPais: any;
    selectCountry = true;
    selectAdressCountry = true;

    // Modelos
    nuevoFormulario = new DocumentModel();
    formulario = new FormModel();
    document = new DocumentModel();
    company = new Company();
    branch = new PlaceAddressInformationModel();

    //Disclaimer
    disclaimer = true;
    disabledBtnDis = '';
    valueCheck = false;
    cancelDisclaimer = false;
    returnDisclaimer = false;


    // Variables
    step: any;
    loading: boolean;
    digitoVerificador: any;
    geographicLocation = new LocationModel();
    form: NgForm;

    // variables conteo campos diligenciados
    listSections = [];
    bndValidHeaderBranch = false;
    listInputsBranch = [];
    bndCamposBranch = false;
    countBranch = 0;
    bndValidHeaderInfo = false;
    listInputsInfo = [];
    bndCamposInfo = false;
    countInfo = 0;
    bndValidHeaderOffice = false;
    listInputsOffice = [];
    bndCamposOffice = false;
    countOffice = 0;
    mobile: any;


    nitColombia: any;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private messageService: MessageService,
        private formService: FormsService,
        private catalogService: CatalogService,
        private _documentsService: DocumentsService
    ) {
        this.countriesSelectItem.push({ label: this.properties.labelSelecioneCombo, value: '' });
        this.countriesAdressSelectItem.push({ label: this.properties.labelSelecioneCombo, value: '' });
        this.provinceSelectItem.push({ label: this.properties.labelSelecioneCombo, value: '' });
        this.provinceAdressSelectItem.push({ label: this.properties.labelSelecioneCombo, value: '' });
        this.citySelectItem.push({ label: this.properties.labelSelecioneCombo, value: '' });
        this.cityAdressSelectItem.push({ label: this.properties.labelSelecioneCombo, value: '' });
        this.yesterday = new Date(this.today.getTime() - 24 * 60 * 60 * 1000);
        this.listSections = ['infoBasica', 'office', 'branch'];
    }

    ngOnInit(): void {
        this.mobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
        let id = this.route.snapshot.paramMap.get('idFormulario');
        let country = this.route.snapshot.paramMap.get('idCountry');
        this.idFormulario = id;
        this.pais = country;
        this.cargarDatos()
    }

    async cargarDatos() {

        await this.cargarTipoActividad();
        await this.cargarTipoCompania();
        await this.getCountries();
        //se movio consultar formulario a la respuesta de consultar paises
        //await this.consultarFormulario();

    }
    onTabOpen(e) {

        const index = e.index;
        switch (index) {
            case 0:
                this.bndValidHeaderInfo = true;
                break;
            case 1:
                this.bndValidHeaderOffice = true;
                break;
            case 2:
                this.bndValidHeaderBranch = true;
                break;
            default:
                break;
        }
    }

    onTabClose(e) {
        this.mobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
        const index = e.index;
        switch (index) {
            case 0:
                this.bndValidHeaderInfo = false;
                break;
            case 1:
                this.bndValidHeaderOffice = false;
                break;
            case 2:
                this.bndValidHeaderBranch = false;
                break;
            default:
                break;
        }
    }

    validacionSection(form: NgForm, listSections: Array<String>) {
        this.countBranch = 0;
        this.listInputsBranch = [];
        this.countInfo = 0;
        this.listInputsInfo = [];
        this.countOffice = 0;
        this.listInputsOffice = [];

        const controls = form.controls;
        Object.keys(controls).forEach(input => {
            const nameInput = input.split('_');
            listSections.forEach(section => {
                if (nameInput[1] === section) {
                    switch (section) {
                        case 'branch':
                            this.listInputsBranch.push(input);
                            if (form.controls[input].value !== '') {
                                if (form.controls[input].value !== 0) {
                                    if (form.controls[input].value !== null) {
                                        this.countBranch++;
                                    }
                                }
                            }
                            break;
                        case 'infoBasica':
                            this.listInputsInfo.push(input);
                            if (form.controls[input].value !== '') {
                                if (form.controls[input].value !== 0) {
                                    if (form.controls[input].value !== null) {
                                        if (form.controls[input].value !== undefined) {
                                            if (form.controls[input].value.id !== null) {
                                                this.countInfo++;
                                            }
                                        }
                                    }
                                }
                            }
                            break;
                        case 'office':
                            this.listInputsOffice.push(input);
                            if (form.controls[input].value !== '') {
                                if (form.controls[input].value !== 0) {
                                    if (form.controls[input].value !== null) {
                                        if (form.controls[input].value !== undefined) {
                                            if (form.controls[input].value.id !== null) {
                                                this.countOffice++;
                                            }
                                        }
                                    }
                                }
                            }
                            break;
                        default:
                            break;
                    }
                }
                if (this.countBranch === this.listInputsBranch.length) {
                    this.bndCamposBranch = true;
                } else {
                    this.bndCamposBranch = false;
                }
                if (this.countInfo === this.listInputsInfo.length) {
                    this.bndCamposInfo = true;
                } else {
                    this.bndCamposInfo = false;
                }
                if (this.countOffice === this.listInputsOffice.length) {
                    this.bndCamposOffice = true;
                } else {
                    this.bndCamposOffice = false;
                }
            });
        });
    }

    notCompleteContact(event: InputMask, dir) {
        if (!event.filled)
            this.company.contacts[dir].description = "";
    }

    validateInput(form: NgForm, key) {
        // console.log(form);
        setTimeout(() => {
            switch (key) {
                case 'branch':
                    this.countBranch = 0;
                    this.listInputsBranch = [];
                    break;
                case 'infoBasica':
                    this.countInfo = 0;
                    this.listInputsInfo = [];
                    break;
                case 'office':
                    this.countOffice = 0;
                    this.listInputsOffice = [];
                    break;
                default:
                    break;
            }

            const controls = form.controls;
            Object.keys(controls).forEach(input => {
                const nameInput = input.split('_');
                if (nameInput[1] === key) {
                    switch (key) {
                        case 'branch':
                            this.listInputsBranch.push(input);
                            if (form.controls[input].value !== '') {
                                if (form.controls[input].value !== 0) {
                                    if (form.controls[input].value !== null) {
                                        this.countBranch++;
                                    }
                                }
                            }
                            break;
                        case 'infoBasica':
                            this.listInputsInfo.push(input);
                            if (form.controls[input].value !== '') {
                                if (form.controls[input].value !== 0) {
                                    if (form.controls[input].value !== null) {
                                        if (form.controls[input].value !== undefined) {
                                            if (form.controls[input].value.id !== null) {
                                                this.countInfo++;
                                            }
                                        }
                                    }
                                }
                            }
                            break;
                        case 'office':
                            this.listInputsOffice.push(input);
                            if (form.controls[input].value !== '') {
                                if (form.controls[input].value !== 0) {
                                    if (form.controls[input].value !== null) {
                                        if (form.controls[input].value !== undefined) {
                                            if (form.controls[input].value.id !== null) {
                                                this.countOffice++;
                                            }
                                        }
                                    }
                                }
                            }
                            break;
                        default:
                            break;
                    }
                }
                if (this.countBranch === this.listInputsBranch.length) {
                    this.bndCamposBranch = true;
                } else {
                    this.bndCamposBranch = false;
                }
                if (this.countInfo === this.listInputsInfo.length) {
                    this.bndCamposInfo = true;
                } else {
                    this.bndCamposInfo = false;
                }
                if (this.countOffice === this.listInputsOffice.length) {
                    this.bndCamposOffice = true;
                } else {
                    this.bndCamposOffice = false;
                }
            });
        }, 1000);
    }

    cargarTipoActividad() {
        let catalogo = "";
        catalogo = "ciuu"
        this.loading = true;
        this.catalogService.getCatalogs(catalogo, this.pais)
            .subscribe((resp: any) => {
                this.tipoActividadSelectItem = [];
                this.tipoActividadSelectItem.push({ 'label': this.properties.labelSelecioneCombo, value: null });
                resp.forEach(element => {
                    this.tipoActividadSelectItem.push({ 'label': element.description, value: element })
                });
                this.loading = false;
            },

                (err: any) => console.log(err),
                () => {
                    this.loading = false;
                });
    }

    cargarTipoCompania() {
        this.catalogService.getCatalogs('companyType', this.pais)
            .subscribe((resp: any) => {
                this.tipoCompaniaSelectItem = [];
                this.tipoCompaniaSelectItem.push({ 'label': this.properties.labelSelecioneCombo, 'value': null });
                resp.forEach(element => {
                    this.tipoCompaniaSelectItem.push({ 'label': element.description, value: element })
                });
            },
                (err: any) => console.log(err),
                () => {
                });
    }

    formatDate(date: string | Date) {
        if (typeof(date) == 'string') {
            const len = date.length;
            return len == 10? new Date(date + 'T05:00:00.000Z'): new Date(date);
        }
        return new Date();
    }

    consultarFormulario() {
        this.loading = true;
        this._documentsService.getDocumentsByDateIdByCountry(this.idFormulario, this.pais)
            .subscribe((resp: any) => {
                this.document = resp;
                this.formulario = resp.data;
                this.step = this.formulario.step;
                if (this.formulario.company == null) {
                    this.company = new Company();
                }
                else {
                    this.company = this.formulario.company;
                    let log = this.company.document.documentNumber.length;
                    let stopper = log - 1;
                    this.digitoVerificador = this.company.document.documentNumber.substring(stopper);
                    this.nitColombia = this.company.document.documentNumber.slice(0, -1);
                }
                if (this.formulario.company.placeAddressCompany == null) {
                    this.company.placeAddressCompany = new PlaceAddressInformationModel();
                } else {
                    this.company.placeAddressCompany = this.formulario.company.placeAddressCompany;
                    if (this.formulario.company.placeAddressCompany.place != null) {
                        this.geographicLocation = this.formulario.company.placeAddressCompany.place;
                    }
                }
                if (this.formulario.company.contacts == null) {
                    this.formulario.company.contacts = new CompanyContactsModel();
                }
                else {
                    this.company.contacts.phoneNumber = this.formulario.company.contacts.phoneNumber;
                }
                /*
                if (this.company.type == null) {
                    this.company.type = new CatalogModel();
                    this.company.type.description = this.properties.labelSelecioneCombo;
                }
                if (this.company.economicActivity == null) {
                    this.company.economicActivity = new CatalogModel();
                    this.company.economicActivity.description = this.properties.labelSelecioneCombo;
                }*/
                if (this.formulario.company.contacts == null) {
                    this.formulario.company.contacts = new CompanyContactsModel();
                }
                else {
                    this.company.contacts.email = this.formulario.company.contacts.email;
                }

                if (this.formulario.company.contacts.cellPhoneNumber == null) {
                    this.formulario.company.contacts.cellPhoneNumber = new ContactModel();
                }
                else {
                    this.company.contacts.cellPhoneNumber = this.formulario.company.contacts.cellPhoneNumber;
                }
                if (this.formulario.company.contacts.email == null) {
                    this.formulario.company.contacts.email = new ContactModel();
                }
                else {
                    this.company.contacts.email = this.formulario.company.contacts.email;
                }
                if (this.formulario.company.placeAddressBranch == null) {
                    this.branch = new PlaceAddressInformationModel();
                }
                else {
                    this.branch = this.formulario.company.placeAddressBranch;
                }
                if (this.company.constitutionDate) {
                    this.company.constitutionDate = this.formatDate(this.company.constitutionDate);
                }
                if (this.company.placeAddressCompany.place.country && (this.company.placeAddressCompany.place.country.id === this.properties.codCatalogCo ||
                    this.company.placeAddressCompany.place.country.id === this.properties.codCatalogEc)) {
                    this.getProvinceByCountry(this.company.placeAddressCompany.place.country, this.properties.birth);
                    this.selectCountry = false;
                }
                if (this.branch.place.country && (this.branch.place.country.id === this.properties.codCatalogCo ||
                    this.branch.place.country.id === this.properties.codCatalogEc)) {
                    this.getProvinceByCountry(this.branch.place.country, this.properties.address);
                    this.selectAdressCountry = false;
                }
                setTimeout(() => {
                    this.validacionSection(this.userFrm, this.listSections);
                    this.loading = false;
                }, 1000);
                if (this.mobile) {
                    const numSections = 3;
                    for (let index = 0; index < numSections; index++) {
                        let elm = <HTMLElement>document.getElementById('countAccordion' + index);
                        elm.style.display = 'none';
                    }
                    let card = <HTMLElement>document.getElementById('cardIntermediarios');
                    card.style.paddingLeft = '10px';
                    card.style.paddingRight = '10px';
                }
            },
                (err: any) => {
                    console.log(err);
                    this.loading = true;
                });
    }

    actualizarFormulario(myForm: NgForm) {
        const listControls = Object.keys(myForm.controls);
        const sizeControls = listControls.length;
        let i = 0;
        let count = 0;
        listControls.forEach(control => {
            if (myForm.valid &&
                (myForm.controls[control].value != null && myForm.controls[control].value !== undefined)) {
                if (myForm.controls[control].value.id === '' || myForm.controls[control].value.id === null) {
                    count++;
                }
                i++;
            } else {
                i++;
                if (!myForm.controls[control].valid) {
                    count++;
                } else if ((myForm.controls[control].value != null && myForm.controls[control].value !== undefined)) {
                    if (myForm.controls[control].value.id === '' || myForm.controls[control].value.id === null) {
                        count++;
                    }
                }
            }
            if (sizeControls === i) {
                if (count === 0) {
                    this.loading = true;
                    this.armarFormulario();
                    this._documentsService.updateDocument(this.nuevoFormulario).
                        subscribe((resp: ApiResponse) => {
                            this.messageService.add({ severity: 'success', summary: '', detail: this.properties.msgSuccessForm });
                            this.loading = false;
                            this.changed.emit(true);
                        },
                            (err: any) => {
                                this.messageService.add({ severity: 'error', summary: '', detail: this.properties.msgErrorForm });
                                this.loading = true;
                            },
                            () => {
                            });
                } else {
                    this.messageService.add({ severity: 'error', summary: '', detail: "El formulario aun tiene " + count + " Error(es) revise las alertas en cada campo" });
                }
            }
        });
    }

    armarFormulario() {
        this.nuevoFormulario = this.document;
        this.nuevoFormulario.data = this.formulario;
        this.nuevoFormulario.data.company = this.company;
        this.nuevoFormulario.data.company.placeAddressBranch = this.branch;
        let paso = 1;
        if (paso <= this.step) {
            paso = this.step;
        } else {
            this.step = paso;
        }
        this.nuevoFormulario.data.step = this.step;
    }

    getCountries() {
        this.catalogService.getCatalogs(this.properties.countries, this.pais)
            .subscribe((resp: any) => {
                this.countriesSelectItem = [];
                this.countriesAdressSelectItem = [];
                this.countriesSelectItem.push({ label: this.properties.labelSelecioneCombo, value: '' });
                this.countriesAdressSelectItem.push({ label: this.properties.labelSelecioneCombo, value: '' });
                resp.forEach(element => {
                    const catalog = {
                        label: element.description,
                        value: element
                    };
                    this.countriesSelectItem.push(catalog);
                    this.countriesAdressSelectItem.push(catalog);

                });
                this.consultarFormulario();
            },
                (err: any) => console.log(err),
                () => {
                });
    }

    getProvinceByCountry(pais, type) {
        if (pais) {
            let idPais = pais.id
            if (idPais === this.properties.codCatalogEc || idPais === this.properties.codCatalogCo) {
                this.catalogService.getCatalogs(this.properties.province, this.pais, idPais)
                    .subscribe((resp: any) => {
                        if (type === this.properties.birth) {
                            this.provinceSelectItem = [];
                            this.provinceSelectItem.push({ label: this.properties.labelSelecioneCombo, value: '' });
                            //this.company.placeAddressCompany.place.country.description = descriptionCountry;
                            resp.forEach(element => {
                                const catalog = {
                                    label: element.description,
                                    value: element
                                };
                                this.provinceSelectItem.push(catalog);
                                this.selectCountry = false;
                            });
                            if (this.company.placeAddressCompany.place.province) {
                                this.getCityByProvince(this.company.placeAddressCompany.place.province, type);
                            }
                        } else {
                            this.provinceAdressSelectItem = [];
                            this.provinceAdressSelectItem.push({ label: this.properties.labelSelecioneCombo, value: '' });

                            resp.forEach(element => {
                                const catalog = {
                                    label: element.description,
                                    value: element
                                };
                                this.provinceAdressSelectItem.push(catalog);
                                this.selectAdressCountry = false;
                            });
                            if (this.branch.place.province) {
                                this.getCityByProvince(this.branch.place.province, type);
                            }
                        }

                    },
                        (err: any) => console.log(err),
                        () => {
                        });
            } else {
                if (type === this.properties.birth) {
                    this.selectCountry = true;
                    this.provinceSelectItem = [];
                    this.citySelectItem = [];
                    this.company.placeAddressCompany.place.province = null;
                    this.company.placeAddressCompany.place.city = null;
                    //this.company.placeAddressCompany.place.country.id = idPais;
                } else {
                    this.selectAdressCountry = true;
                    this.provinceAdressSelectItem = [];
                    this.cityAdressSelectItem = [];
                    this.branch.place.province = null;
                    this.branch.place.city = null;
                    // this.branch.place.country.id = idPais;
                }
            }
            let descriptionCountry = null;
            if (this.countriesSelectItem) {
                this.countriesSelectItem.forEach(country => {
                    if (country.value === idPais) {
                        descriptionCountry = country.label;
                    }
                });
            }
        }
        var typeCtg = '';
        if (type === this.properties.birth || type === this.properties.expedition) {
            typeCtg = 'office';
        } else {
            typeCtg = 'branch';
        }
        this.validateInput(this.userFrm, typeCtg);
    }

    getCityByProvince(province, type) {
        var typeCtg = '';
        if (province) {
            let idProvince = province.id
            this.catalogService.getCatalogs(this.properties.city, this.pais, idProvince).subscribe((resp: any) => {
                if (type === this.properties.birth) {
                    this.citySelectItem = [];
                    this.citySelectItem.push({ label: this.properties.labelSelecioneCombo, value: '' });
                    resp.forEach(element => {
                        const catalog = {
                            label: element.description,
                            value: element
                        };
                        this.citySelectItem.push(catalog);
                        this.provinceSelectItem.forEach(province => {
                            if (province.value === idProvince) {
                                this.company.placeAddressCompany.place.province.description = province.label;
                            }
                        });
                    });
                } else {
                    this.cityAdressSelectItem = [];
                    this.cityAdressSelectItem.push({ label: this.properties.labelSelecioneCombo, value: '' });
                    resp.forEach(element => {
                        const catalog = {
                            label: element.description,
                            value: element
                        };
                        this.cityAdressSelectItem.push(catalog);
                        this.provinceAdressSelectItem.forEach(province => {
                            if (province.value === idProvince) {
                                this.branch.place.province.description = province.label;
                            }
                        });
                    });
                }

            },
                (err: any) => console.log(err),
                () => {
                });
        } else {
            this.citySelectItem = [];
        }
        if (type === this.properties.birth || type === this.properties.expedition) {
            typeCtg = 'office';
        } else {
            typeCtg = 'branch';
        }
        this.validateInput(this.userFrm, typeCtg);
    }

    updateDescriptionCity(idCity, type) {
        var typeCtg = '';
        // if (type === this.properties.birth) {
        //     this.company.placeAddressCompany.place.city.description = this.citySelectItem.find(x => x.value === idCity).label;
        // } else {
        //     this.branch.place.city.description = this.cityAdressSelectItem.find(x => x.value === idCity).label;
        // }
        if (type === this.properties.birth || type === this.properties.expedition) {
            typeCtg = 'office';
        } else {
            typeCtg = 'branch';
        }
        this.validateInput(this.userFrm, typeCtg);
    }

    disclaimerCheck() {
        if (this.valueCheck) {
            this.disclaimer = false;
        }
    }
    hidenDisclaimer() {
        if (this.disclaimer) {
            this.cancelDisclaimer = true;
        }

    }
    gotoDisclaimer() {
        this.cancelDisclaimer = false;
        this.disclaimer = true;
    }

    gotoMainOrCloseWindow() {
       var Item = sessionStorage.getItem('access_token');
       if(Item){
        this.router.navigate(['/tramites'], { relativeTo: this.route } )
       }else{
        location.replace('https://www.liberty.ec/');
       }
    }

}
