//Core-angular
import { NgModule } from '@angular/core';
import { AcademiaComponent } from './academia.component';
//Components

@NgModule({
  declarations: [
    AcademiaComponent

  ],
  imports: [

  ],
  providers: [
  ],
  bootstrap: [],
  exports: [
    AcademiaComponent
  ]

})
export class AcademyModule { }
