/**
 * intl-latam-ec-milf-linkingform
 * Manage the information of the linking form.
 *
 * OpenAPI spec version: 2.1.0
 * Contact: kruger@kruger.com.ec
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Catalog } from './catalog';
import { Company } from './company';
import { Person } from './person';


export interface FormFigures { 
    type?: FormFigures.TypeEnum;
    personType?: string;
    person?: Person;
    company?: Company;
    typeRelationship?: Catalog;
    financialEntity?: string;
    accountType?: string;
    wayPay?: string;
    accountNumber?: string;
}
export namespace FormFigures {
    export type TypeEnum = 'BENEFICIARY' | 'CONTRACTOR' | 'INSURED';
    export const TypeEnum = {
        BENEFICIARY: 'BENEFICIARY' as TypeEnum,
        CONTRACTOR: 'CONTRACTOR' as TypeEnum,
        INSURED: 'INSURED' as TypeEnum
    };
    export type PersonTypeEnum = 'N' | 'J';
    export const PersonTypeEnum = {
        N: 'N' as PersonTypeEnum,
        J: 'J' as PersonTypeEnum
    };
}
