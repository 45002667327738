

export class InspectionsAuto { 
    documentType?: string;
    documentNumber?: string;
    personType?: string;
    companyName?: string;
    name?: string;
    middleName?: string;
    surname?: string;
    middleSurame?: string;
    isSameContact?: string;
    contactName?:string;
    contactSurName?:string;
    contactTelefonNumber?:string;
    contactEmail?:string;
    brokerName?:string;
    brokerRuc?:string;
    brokerEmail?:string;
    plateType?:string;
    plateNumber?:string;
    vehicleBrand?:string;
    vehicleModel?:string;
    vehicleType?:string;
    vehicleGroup?:string;
    searchVehicle?;
    yearManufacture?:string;
    inspectionType?:string;
    quoteNumber?:string;
    remarks?:string;
    typeDoc?:string;
    sourceApplicationId?:string;
    chassisNumber?:string;
    engineNumber?:string;
    use?:string;
    vehicleValue?:string;
    vehicleCategory?:string;
    reInspection?:boolean;

    constructor(){
      this.typeDoc = 'inspection';
      this.sourceApplicationId = 'FORMULARIO';
      this.use = 'personal';
      this.remarks= '';
    }
}


