/**
 * intl-latam-ec-portal-documents
 * No description
 *
 * OpenAPI spec version: 1.0.0
 * Contact: you@your-company.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { LocationModel } from './location';


export class  PlaceAddressInformationModel { 
    place: LocationModel;
    /**
     * Calle principal en Ecuador, dirección para Colombia.
     */
    mainStreet?: string;
    /**
     * Numero de lote, edificio o casa, aplica solo Ecuador.
     */
    lotNumber?: string;
    /**
     * Transversal, aplica solo Ecuador.
     */
    cross?: string;

    constructor(){
        this.place = new LocationModel();
        this.mainStreet = null;
        this.lotNumber = null;
        this.cross = null; 
    }

}
