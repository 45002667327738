//angular
import { Component, OnInit, Input } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { NgForm } from '@angular/forms';
//Liberty-properties
import { Properties } from '../general/properties';
import { ActivatedRoute } from '@angular/router';
import { Router, Params } from '@angular/router';
import { MessageService } from 'primeng/components/common/messageservice';
import { FormsService } from '../../_services';
import { ApiResponse } from '../../_services/model-formulario/modelsForm';
import { LoginService } from 'src/app/_services/login.service';
import { TOKE_NAME_OAUTH } from '../../_shared/var.constant';
import { DocumentsService } from 'src/app/_services/api_v1/documents.service';

@Component({
    selector: 'formulario',
    templateUrl: './formulario.html'
})

export class FormularioComponent implements OnInit {

    properties: Properties = new Properties();
    formulario: any;
    // General
    pais: any;
    idFormulario: any;
    // Variables
    fechaVigencia: any;
    fechaActual = new Date();

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private messageService: MessageService,
        public formService: FormsService,
        private loginService: LoginService,
        private _documentsService: DocumentsService
    ) { }

    ngOnInit(): void {

        this.idFormulario = this.route.snapshot.paramMap.get('idFormulario');
        this.pais = this.route.snapshot.paramMap.get('idCountry');
        this.getLinkingForm();
    }


    getLinkingForm() {
        let user = JSON.parse(sessionStorage.getItem('access_token'));
        this._documentsService.events(this.idFormulario, this.pais, 'GET_PERSON_URL').subscribe((resp: any) => {
            if (user && user.user && user.user.brokerId) {
                this.irRegistro();
            }
            else if (resp != null && resp != undefined && !resp.hasOwnProperty('errorMessage')) {
                this.fechaVigencia = new Date(resp.url.endEffectiveDate)
                if (this.fechaVigencia >= this.fechaActual) {
                    this.irAcceso();
                } else if (resp.status === 'SIGNED'){
                    this.irFinalizado();
                } else {
                    this.irCaducado();
                }
            } else {
                this.irNoExiste();
            }
        },
            (err: any) => {
                console.log('Error', err);
            });

    }

    irAcceso() {
        this.router.navigate(['/formulario/acceso/', this.idFormulario, this.pais]);
    }

    irCaducado() {
        this.router.navigate(['/formulario/caducado']);
    }

    irNoExiste() {
        this.router.navigate(['/formulario/notFound']);
    }

    irRegistro() {
        this.router.navigate(['formulario/registro', this.idFormulario, this.pais]);
    }

    irFinalizado() {
        if (this.pais === 'EC')
            this.router.navigate(['formulario/finalizado']);
        else
            this.router.navigate(['formularioCo/finalizadosCo/CO']);
    }
}