//CORE-ANGULAR
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { CommonModule } from '@angular/common';
//UTILITARIOS.
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  InputTextModule, DialogModule, DataTableModule, SharedModule, ButtonModule, GrowlModule, DropdownModule,
  MessagesModule, FileUploadModule, ConfirmDialogModule, InputTextareaModule,
  AutoCompleteModule, MessageModule,
  TabViewModule, PanelModule, CalendarModule, ConfirmationService, CheckboxModule, FieldsetModule, RadioButtonModule, MultiSelectModule, BlockUIModule
} from 'primeng/primeng';
import { KeyFilterModule } from 'primeng/keyfilter';
import { InputMaskModule } from 'primeng/inputmask';
// Import ngx-barcode module
import { TableModule } from 'primeng/table';
import { StepsModule } from 'primeng/steps';
import { ProgressSpinnerModule } from 'primeng/progressspinner';

//Formulario
import { FormularioComponent } from './formulario.component';
import { FormularioRoutingModule } from './formulario.routing.module';
import { CambiarNumeroComponent } from './cambiarNumero/cambiarNumero.component';
import { BienvenidaComponent } from './bienvenida/bienvenida.component';
import { RegistroComponent } from './registro/registro.component';
import { RegistroModule } from './registro/registro.module';
import { FinalizacionComponent } from './finalizacion/finalizacion.component';
import { FinalComponent } from './finalizacionCo/final.component';



@NgModule({
  declarations: [
    FormularioComponent,
    CambiarNumeroComponent,
    BienvenidaComponent,
    RegistroComponent,
    FinalizacionComponent,
    FinalComponent,
  ],
  imports: [
    TableModule,
    CommonModule,
    FormsModule,
    HttpModule,
    BrowserAnimationsModule,
    InputTextModule,
    DialogModule,
    DataTableModule,
    SharedModule,
    ButtonModule,
    GrowlModule,
    DropdownModule,
    MessagesModule,
    MessageModule,
    FileUploadModule,
    ConfirmDialogModule,
    InputTextareaModule,
    TabViewModule,
    PanelModule,
    CalendarModule,
    CheckboxModule,
    FieldsetModule,
    RadioButtonModule,
    KeyFilterModule,
    MultiSelectModule,
    AutoCompleteModule,
    InputMaskModule,
    StepsModule,
    FormularioRoutingModule,
    RegistroModule,
    ProgressSpinnerModule,
    BlockUIModule,
  ],
  providers: [
  ],
  bootstrap: [],
  exports: [
    FormularioComponent
  ]

})
export class FormularioModule { }
