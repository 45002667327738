/**
 * intl-latam-ec-portal-documents
 * No description
 *
 * OpenAPI spec version: 1.0.0
 * Contact: you@your-company.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { CatalogModel } from './catalog';
import { RelationshipPepPersonDocumentModel } from './relationshipPepPersonDocument';


export class RelationshipPepPersonModel { 
    type?: CatalogModel;
    /**
     * Otro tipo de relación PEP.
     */
    otherType?: string;
    /**
     * Cargo PEP.
     */
    charge?: string;
    /**
     * Entidad.
     */
    entity?: string;
    /**
     * Fecha de ingreso.
     */
    dateEntry?: any;
    typePerson?: string;
    businessName?: string;
    /**
     * Fecha de desvinculación.
     */
    dateUnlinked?: any;
    document?: RelationshipPepPersonDocumentModel;
    /**
     * Nombres completos de la persona.
     */
    name?: string;
    lastName?: string;
    secondName?: string;
    secondLastName?: string;
    

    showOtherType:boolean;
    nationality?: CatalogModel;

    constructor(){
        this.type = null;
        this.otherType = null;
        this.charge = null;
        this.entity = null;
        this.dateEntry = null;
        this.dateUnlinked = null;
        this.document = new RelationshipPepPersonDocumentModel();
        this.name = null;
    }

    
}
