import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery';
import { SelectItem, MenuItem } from 'primeng/api';
import { environment } from '../../../../environments/environment';
import { Router } from '../../../../../node_modules/@angular/router';

@Component({
  selector: 'app-policies-co',
  templateUrl: './policies.component.html',
  styleUrls: ['../policies.component.css']
})
export class PoliciesComponentCo implements OnInit {

  items: SelectItem[];
  itemsUrl: MenuItem[];
  itemSelected: any;
  ruta: any;
  URL_PORTAL: string = environment.URL_PORTAL;

  constructor(private router: Router) { }

  ngOnInit() {
    this.ruta = JSON.parse(sessionStorage.getItem('ruta'));
    this.items =
      [{ label: '¿Que buscas?', value: '' },
      { label: 'Sobre Liberty Seguros S.A.', value: 'term1' },
      { label: 'Usos de contenidos del sitio', value: 'term2' },
      { label: 'Limitaciones del uso de contenidos', value: 'term3' },
      { label: 'Daños y perjuicios', value: 'term4' },
      { label: 'Politicas de privacidad', value: 'term5' },
      { label: 'Derechos de propiedad intelectual', value: 'term6' },
      { label: 'Procedimientos en caso de violación de derechos', value: 'term7' },
      { label: 'Jurisdicción y Ley Aplicable', value: 'term8' },
      { label: 'Link a otros sitios', value: 'term9' },
      { label: 'Información', value: 'term10' }];

    this.itemsUrl = [
      { label: 'Home', routerLink: ['landing'] },
      { label: 'Términos y condiciones' }
    ];
    this.goIn('inicio');
  }

  enviarCodigo() {
    if (this.ruta) {
      if (this.ruta == true) {
        this.router.navigate(['termCondiciones']);
      } else if (this.ruta == false) {
        this.router.navigate(['landing']);
      }
    } else {
      this.router.navigate(['landing']);
    }
  }
  goSection(section) {
    $('html,body').animate({
      scrollTop: $('#' + section).offset().top
    }, 1000).end();
  }

  goInicio() {
    const start = 'inicio';
    this.goSection(start);
  }

  goIn(section) {
    $('html,body').animate({
      scrollTop: $('#' + section).offset().top
    }, 1).end();
  }

  goTopPage() {
    $('.ir-arriba').click(function () {
      $('body, html').animate({
        scrollTop: $('#layout-main').offset().top
      }, 1000);
    }).end();
  }

}
