import { Injectable } from '@angular/core';
import { UrlServices } from '../url.services';
import { GenericService, METHODS } from '../services.util';
import { Observable } from 'rxjs';


/**
 * @author atello-barcia
 */
@Injectable()
export class StateAccountService {

    urlServices: UrlServices = new UrlServices();

    constructor(public _genericService: GenericService) {
    }

    /**
     * Get state account by client and catalogue
     */
    intlLatamEcGetStateAccount(body: any): Observable<any> {
        return this._genericService.genericCallServices(METHODS.POST,
            this.urlServices.getStateAccountUrl(), body);
    }


}
