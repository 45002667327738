import { Component, OnInit } from '@angular/core';
import { SelectItem } from 'primeng/primeng';
import { Properties } from '../general/properties';
import { CatalogService } from 'src/app/_services/api_v1/catalogs.service';
import { FormsService } from 'src/app/_services/api_v1/forms.service';
import { Router } from '@angular/router';

import { BreadcrumbService } from 'src/app/breadcrumb.service';

import { PersonFormModel, FormModel, Company } from 'src/app/_model/documents/models';

//Encriptar
import { CriptoService } from 'src/app/_handler/cripto.service';
import { ContratantePersona } from 'src/app/_model/documents/contratantePersona';


@Component({

    selector: 'solicitudes',
    templateUrl: './solicitudes.html',
    styleUrls: ['./solicitudes.css']
})

export class SolicitudesComponent implements OnInit {

    properties: Properties = new Properties();
    es: any = this.properties.es;
    titulo: string;
    subtitulo: string;
    tituloformulario: string;
    tituloResultado: string;
    tituloContratante: string;
    tituloResultadoContratante: string;

    // Banderas
    bandera: number = 0;
    botonEnviar: any = 0;
    mostrarContratante: any = 0;

    // Catalogos
    tipoDocumentoSelectItem: SelectItem[] = [{ label: this.properties.labelSelecioneCombo, value: null }];
    generos: SelectItem[] = [];
    respuestas: SelectItem[] = [];
    tipoEstadoCivilSelectItem: SelectItem[] = [{ label: this.properties.labelSelecioneCombo, value: null }];
    tipoPersona: any;
    tipoPersonaC: any;

    // Modelos
    // Asegurado
    formulario = new FormModel();
    nuevoFormulario: any;
    person = new PersonFormModel();
    asegurado: any = null;
    company = new Company();

    // Contratante
    figura = new ContratantePersona();
    personC = new PersonFormModel();
    aseguradoC: any = null;
    companyC = new Company();

    tipoDocumento: any;
    found: boolean;
    naturalNotFound: boolean;
    numeroDocumento: any;
    primerNombre: any;
    segundoNombre: any;
    primerApellido: any;
    segundoApellido: any;
    genero: any = null;
    estadoCivil: any;
    fechaNacimiento: any;
    email: any;
    numeroCelular: any;
    // Contratante
    tipoDocumentoC: any;
    numeroDocumentoC: any;
    primerNombreC: any;
    segundoNombreC: any;
    primerApellidoC: any;
    segundoApellidoC: any;
    foundContract: boolean;
    generoC: any;
    estadoCivilC: any;
    fechaNacimientoC: any;
    emailC: any;
    numeroCelularC: any;

    // Compañía
    razonSocial: any;
    fechaConstitucion: Date = new Date();
    emailCompania: any;
    numeroCelularCompania: any;
    aseguradoCompania: any = null;

    // Seguridad
    tipoDocumentoEncriptado: any;
    numeroDocumentoEncriptado: any;

    // Broker
    user: any



    constructor(
        private catalogService: CatalogService,
        private formService: FormsService,
        private breadcrumbService: BreadcrumbService,
        private router: Router,
        private criptoService: CriptoService

    ) {

    }

    ngOnInit(): void {
        this.cargarBreadCrum();
        const currentUser = JSON.parse(sessionStorage.getItem('access_token'));
        this.user = currentUser.user;
        // console.log("Este es el usuario  autenticado", this.user);
        this.cargarBreadCrum();
        this.titulo = "Solicitar formulario de conocimiento de cliente";
        this.subtitulo = "Ingresa la siguiente información para enviar el formulario a tu cliente";
        this.tituloformulario = "Información del asegurado";
        this.tituloContratante = "Información del contratante";
        this.tituloResultado = "Encontramos datos asociados, revisa la siguiente información";
        this.tituloResultadoContratante = "Encontramos datos asociados, revisa la siguiente información";
        this.generos = this.properties.generos;
        this.respuestas = this.properties.respuestas;
        this.cargarTipoDocumento();
        this.cargarTipoEstadoCivil();

    }

    cargarBreadCrum() {
        this.breadcrumbService.setItems([
            { label: 'Inicio', routerLink: ['/home'] },
            { label: "Gestión de solicitudes" },
            { label: "Crear solicitud de vinculación", routerLink: ['/solicitudes'] }
        ]);
    }

    cargarTipoDocumento() {
        this.catalogService.getCatalogs('documentType', 'EC')
            .subscribe((catalogos: any) => {
                catalogos.forEach(element => {
                    this.tipoDocumentoSelectItem.push({ label: element.description, value: element.id })
                });
            },
                (err: any) => console.log(err),
                () => {
                });
    }


    cargarTipoEstadoCivil() {
        this.catalogService.getCatalogs(this.properties.civilStatus, 'EC')
            .subscribe((resp: any) => {
                this.tipoEstadoCivilSelectItem = [];
                this.tipoEstadoCivilSelectItem.push({ label: this.properties.labelSelecioneCombo, value: null });
                resp.forEach(element => {
                    delete element.code;
                    const catalog = {
                        label: element.description,
                        value: element.id
                    };
                    this.tipoEstadoCivilSelectItem.push(catalog);
                });
            },
                (err: any) => console.log(err),
                () => {
                });
    }

    buscarAsegurado() {
        let isParent = true
        this.numeroDocumentoEncriptado = null;
        var encrypted = this.criptoService.set(this.numeroDocumento);
        this.numeroDocumentoEncriptado = encodeURIComponent(encrypted);
        // console.log("este es encriptado", this.numeroDocumento);
        this.formService.getClient(this.tipoDocumento, this.numeroDocumentoEncriptado, isParent)
            .subscribe((resp: any) => {
                if (resp) {
                    this.bandera = 1;
                    this.formulario = resp;
                    this.naturalNotFound = false;
                    if ([1, 3, 4].includes(this.tipoDocumento)) {
                        this.tipoPersona = 0
                        if (resp.data) {
                            this.found = true;
                            this.person = resp.data.person;
                            if (this.person.birthDate) {
                                this.person.birthDate = new Date(this.person.birthDate);
                            }
                        } else {
                            this.found = false;
                        }

                    } else {
                        this.tipoPersona = 1
                        this.company = resp.data.company;
                    }
                } else {
                    this.found = false;
                    if (this.tipoDocumento == 1) {
                        this.naturalNotFound = true;
                        this.tipoDocumento = 0;
                        this.numeroDocumento = '';
                    }
                }
            },
                (err: any) => console.log(err),
                () => {
                });

    }

    buscarContratante() {
        let isParent = false;
        this.mostrarContratante = 1;
        this.botonEnviar = 0;
        this.numeroDocumentoEncriptado = null;
        var encrypted = this.criptoService.set(this.numeroDocumentoC);
        this.numeroDocumentoEncriptado = encodeURIComponent(encrypted);
        if (this.tipoDocumentoC == 1 || this.tipoDocumentoC == 3 || this.tipoDocumentoC == 4) {
            this.tipoPersonaC = 0
        }
        else {
            this.tipoPersonaC = 1
        }
        this.formService.getClient(this.tipoDocumentoC, this.numeroDocumentoEncriptado, isParent)
            .subscribe((resp: any) => {
                if (resp) {
                    this.foundContract = true;
                    this.figura = resp;
                    this.figura.gender = "M";
                    if (this.tipoDocumentoC == 1 || this.tipoDocumentoC == 3 || this.tipoDocumentoC == 4) {

                        if (this.figura.birthDate) {
                            this.figura.birthDate = new Date(this.figura.birthDate);
                        }

                    } else {

                    }
                } else {
                    this.foundContract = false;
                }
            },
                (err: any) => console.log(err),
                () => {
                });

    }

    enviar(form) {
        if (this.botonEnviar == 0) {
            this.armar();
            this.formService.postClient(this.nuevoFormulario)
                .subscribe((resp: any) => {
                    let idCode;
                    if (resp.code) {
                        idCode = resp.code;
                    }
                    else {
                        idCode = 0;
                    }

                    this.router.navigate(['/solicitudesFin/', idCode]);

                },
                    (err: any) => console.log(err),
                    () => {
                    });
        }
        if (this.botonEnviar == 1) {

            this.buscarContratante()

        }

    }

    esContratante(asegurado) {
        // console.log(asegurado)
        if (asegurado == true) {
            this.botonEnviar = 0;
        } else {
            this.botonEnviar = 1;
        }

    }

    armar() {
        this.nuevoFormulario = this.formulario;
        if (this.person.civilStatus.id == 1) {
            this.person.civilStatus.description = "Soltero";
        }
        if (this.person.civilStatus.id == 2) {
            this.person.civilStatus.description = "Casado";
        }
        if (this.person.civilStatus.id == 3) {
            this.person.civilStatus.description = "Unión Libre";
        }
        if (this.person.civilStatus.id == 4) {
            this.person.civilStatus.description = "Divorciado";
        }
        if (this.person.civilStatus.id == 5) {
            this.person.civilStatus.description = "Viudo";
        }
        this.nuevoFormulario.data.person = this.person;
        this.nuevoFormulario.data.broker.document.documentNumber = this.user.brokerId;
        this.nuevoFormulario.data.broker.businessName = this.user.brokerName;
        this.nuevoFormulario.data.broker.contacts.email.description = this.user.email;
        if (this.nuevoFormulario.data.company != undefined) {
            this.nuevoFormulario.data.company = this.company;
        }
        if (this.asegurado == false || this.aseguradoCompania == false) {
            this.nuevoFormulario.data.figures = [];
            this.nuevoFormulario.data.isContractingBeneficiaryInsured = false;
            this.nuevoFormulario.data.figures[0] = this.figura;
        }

    }

    volver() {
        this.bandera = 0;
        this.botonEnviar = 0;
        this.mostrarContratante = 0;
        this.figura = new ContratantePersona();
        this.personC = new PersonFormModel();
        this.aseguradoC = null;
        this.companyC = new Company();
        this.formulario = new FormModel();
        this.nuevoFormulario = null;
        this.person = new PersonFormModel();
        this.asegurado = null;
        this.company = new Company();
        this.tipoDocumentoC = null;
        this.numeroDocumentoC = null;
        this.tipoPersonaC = null;
        this.tipoPersona = null;
    }
}