import { Component, OnInit, Output, EventEmitter, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';

import { SelectItem } from 'primeng/primeng';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/components/common/messageservice';
import { Properties } from '../../../../../general/properties';
import { FormsService } from '../../../../../../_services';

import { Util } from '../../../../../general/util';
import { FormModel } from 'src/app/_model/documents/form';
import { PersonalReferencePersonModel } from 'src/app/_model/documents/personalReferencePerson';
import { BankReferenceModel } from 'src/app/_model/documents/bankReference';
import { CommercialReferenceModel } from 'src/app/_model/documents/commercialReference';
import { EconomicInformationModel, CatalogModel, WorkModel, DocumentModel } from 'src/app/_model/documents/models';
import { CatalogService } from 'src/app/_services/api_v1/catalogs.service';
import { DocumentsService } from 'src/app/_services/api_v1/documents.service';
import { SharedCatalogueService } from '../../../shared-catalogue.service';
import { ComercialInformationModel } from 'src/app/_model/documents/comercialInfo';

@Component({
    selector: 'app-datos-financieros-ec',
    templateUrl: './datosFinancieros.html',
    styleUrls: ['./datosFinancieros.css']
})

export class DatosFinancierosComponent implements OnInit {

    @Output() changed = new EventEmitter<boolean>();
    @ViewChild('form') public userFrm: NgForm;

    properties: Properties = new Properties();
    es: any = this.properties.es;
    titulo: string = "Información Legal y Financiera";
    subtitulo: string = "Diligencia los siguientes campos. Conocer tu información financiera es importante para nosotros."
    // Generales
    pais: any;
    idFormulario: any;

    // Catalogos
    tipoActividadSelectItem: any[];
    tipoActividadIndepentSelectItem: any[];
    tipoEstadoCivilSelectItem: any[];
    tipoCiuuSelectItem: any[];
    tipoOcupacionesSelectItem: any[];
    tipoProfesionSelectItem: any[];
    tipoProductoelectItem: any[];
    tipoRelacionSelectItem: any[];
    tipoCargoSelectItem: any[];
    actividadEco: boolean = false;

    monthlyIcomeSelectItem: any;
    // Modelos
    nuevoFormulario: DocumentModel = new DocumentModel();;
    formulario = new FormModel();
    //formulario.data= new FormModel(); 
    respuestas: SelectItem[];
    countriesSelectItem: SelectItem[] = [];
    provinceSelectItem: SelectItem[] = [];
    citySelectItem: SelectItem[] = [];
    chargesSelectItem: SelectItem[] = [];

    // Referencias
    refPersonales = new Array<PersonalReferencePersonModel>();
    refBancarias = new Array<BankReferenceModel>();

    refComerciales = new Array<CommercialReferenceModel>();

    // Variables
    mostrarSeccion: number;
    sumInsured: number;
    mostrarReferencias: number;
    step: any;
    botonAgregar: boolean;
    botonAgregarBank: boolean;

    loading: boolean;
    mostrarCargo: boolean = false;
    mostrarOtrosTipoLaboral: boolean = false;
    mostrarCargoAux: boolean = false;
    signoMoneda: string;

    // variables para el conteo de campos dilegenciados
    mobile: any;
    bndValidHeaderLabor = false;
    bndValidHeaderEconomic = false;
    bndValidHeaderComercialInfo = false;
    bndValidHeaderReference = false;
    listSections = [];

    listInputsLabor = [];
    bndCamposLabor = false;
    countLabor = 0;
    listInputsEconomic = [];
    bndCamposEconomic = false;
    countEconomic = 0;
    listInputsComercialInformation = [];
    countComercialInformation = 0;
    bndCamposEconomicInfo = false;
    listInputsReference = [];
    bndCamposReference = false;
    countReference = 0;
    mostrarOtraRelacionRef: boolean = false;
    mostrarDescripcion = false;
    util: Util = new Util();
    idMonthlyN: number;
    economicInformation = new EconomicInformationModel();
    comercialInformation = new ComercialInformationModel();

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private messageService: MessageService,
        public formService: FormsService,
        private catalogService: CatalogService,
        private _documentsService: DocumentsService,
        public _sharedCatalogue: SharedCatalogueService,
    ) {
        this.countriesSelectItem.push({ label: this.properties.labelSelecioneCombo, value: '' });
        this.provinceSelectItem.push({ label: this.properties.labelSelecioneCombo, value: '' });
        this.citySelectItem.push({ label: this.properties.labelSelecioneCombo, value: '' });
        this.respuestas = this.properties.respuestas;
        this.listSections = ['labor', 'economic', 'comercialInfo', 'reference'];
    }

    ngOnInit(): void {
        this.mobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
        const id = this.route.snapshot.paramMap.get('idFormulario');
        const country = this.route.snapshot.paramMap.get('idCountry');
        this.pais = country;

        this.idFormulario = id;
        this.signoMoneda = '$';
        this.cargarDatos();
    }

    cargarDatos() {
        this.cargarTipoActividadIndepent();
        this.cargarTipoActividad();
        this.cargarTipoRelacion();
        this.getCountries();
        this.getMonthlyIncomeN();
        // se mueve consultar formulario a la respuesta de pais
        //await this.consultarFormulario();
    }
    onTabOpen(e) {

        const index = e.index;
        switch (index) {
            case 0:
                this.bndValidHeaderLabor = true;
                break;
            case 1:
                this.bndValidHeaderEconomic = true;
                break;
           case 2:
                this.bndValidHeaderComercialInfo = true;
                break;   
            case 3:
                this.bndValidHeaderReference = false;
                break;
            default:
                break;
        }
    }

    onTabClose(e) {
        const index = e.index;
        this.mobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
        switch (index) {
            case 0:
                this.bndValidHeaderLabor = false;
                break;
            case 1:
                this.bndValidHeaderEconomic = false;
                break;
            case 2:
                this.bndValidHeaderComercialInfo = false;
                break;   
            case 3:
                this.bndValidHeaderReference = false;
                break;
            default:
                break;
        }
    }

    ingresosMensFormat() {
        // if (this.origen === 1) {
        this.economicInformation.monthlyIncomeDescription = this.util.formatoMoney(this.economicInformation.monthlyIncomeDescription);
        // }
    }

    egresosMensFormat() {
        // if (this.origen === 1) {
        this.economicInformation.monthlyExpenses = this.util.formatoMoney(this.economicInformation.monthlyExpenses);
        // }
    }

    totalActivosFormat() {
        // if (this.origen === 1) {
        this.economicInformation.actives = this.util.formatoMoney(this.economicInformation.actives);
        // }
    }

    totalPasivosFormat() {
        // if (this.origen === 1) {
        this.economicInformation.pasives = this.util.formatoMoney(this.economicInformation.pasives);
        // }
    }

    otrosIngresosFormat() {
        // if (this.origen === 1) {
        this.economicInformation.otherIncome = this.util.formatoMoney(this.economicInformation.otherIncome);
        // }
    }

    consultarFormulario() {
        this.loading = true;
        this._documentsService.getDocumentsByDateIdByCountry(this.idFormulario, this.pais)
            .subscribe((resp: any) => {
                this.nuevoFormulario = resp;
                this.mostrarCargo = false;
                this.mostrarOtrosTipoLaboral = false;
                this.mostrarOtraRelacionRef = false;
                this.mostrarSeccion = 0;
                this.actividadEco = false;

                this.comercialInformation = resp.data.comercialInformation;
                if (this.comercialInformation == null) {
                    this.comercialInformation = new ComercialInformationModel();
                }
                this.economicInformation = resp.data.economicInformation;
                if (this.economicInformation == null) {
                    this.economicInformation = new EconomicInformationModel();
                }
                if (this.economicInformation.monthlyIncome != null) {
                    this.idMonthlyN = this.economicInformation.monthlyIncome.id;
                } else {
                    this.economicInformation.monthlyIncome = new CatalogModel();
                }


                if (resp.data.work == null) {
                    resp.data.work = new WorkModel();
                    // this.entity.work.phoneNumber.description = '';
                }
                this.step = resp.data.step;
                this.sumInsured = resp.data.sumInsured;
                if (this.sumInsured > 200000) {
                    this.mostrarReferencias = 1;
                }
                else {
                    this.mostrarReferencias = 0;
                }
                if (resp.data.personalReferences == null) {
                    let refPersonalesAux = new PersonalReferencePersonModel();
                    this.refPersonales.push(refPersonalesAux);
                }
                else {
                    this.refPersonales = resp.data.personalReferences;
                    this.refPersonales.forEach(x => {
                        if (x.otherType) {
                            x.showOtherType = true;
                        }
                    })
                }
                if (this.refPersonales.length >= 2) {
                    this.botonAgregar = true;
                } else {
                    this.botonAgregar = false;
                }
                if (resp.data.bankReferences == null) {
                    let bankReferencesAuxA = new BankReferenceModel();
                    // let bankReferencesAuxB = new EntityBankReferencesModel();
                    this.refBancarias.push(bankReferencesAuxA);
                    // this.refBancarias.push(bankReferencesAuxB);
                }
                else {
                    this.refBancarias = resp.data.bankReferences;
                }
                if (resp.data.commercialReferences == null) {
                    let refComercialesAuxA = new CommercialReferenceModel();
                    let refComercialesAuxB = new CommercialReferenceModel();
                    this.refComerciales.push(refComercialesAuxA);
                    this.refComerciales.push(refComercialesAuxB);
                }
                else {
                    this.refComerciales = resp.data.commercialReferences;
                }

                if (resp.data.work.economicActivity && resp.data.work.economicActivity != null) {
                    if (resp.data.work.economicActivity && resp.data.work.economicActivity != null &&
                        resp.data.work.economicActivity.id == 4 || resp.data.work.economicActivity.id == 5 ||
                        resp.data.work.economicActivity.id == 6) {
                        this.mostrarCargo = true
                        resp.data.work.economicActivity.id != 6 ? this.mostrarCargoAux = true  : this.mostrarCargoAux = false;;
                        this.actividadEco = true;
                    }
                    else {
                        this.mostrarSeccion = 0;
                        this.mostrarCargo = false;
                        this.mostrarCargoAux = false;
                    }
                    if (resp.data.work.economicActivity.id == 8) {
                        this.mostrarCargo = false;
                        this.mostrarCargoAux = false;
                        this.mostrarSeccion = 0;
                        this.mostrarOtrosTipoLaboral = true;
                    }

                } else {
                    this.mostrarSeccion = 0;
                }

                this.formulario = resp.data;
                setTimeout(() => {
                    this.validacionSection(this.userFrm, this.listSections);
                    this.loading = false;
                }, 1000);
                if (this.mobile) {
                    for (let index = 0; index < 3; index++) {
                        let elm = <HTMLElement>document.getElementById('countAccordion' + index);
                        elm.style.display = 'none';
                    }
                    let card = <HTMLElement>document.getElementById('cardIntermediarios');
                    card.style.paddingLeft = '10px';
                    card.style.paddingRight = '10px';
                }
            },
                (err: any) => {
                    console.log(err);
                    this.loading = true;
                });
    }

    onChangeMainActivity(eventV) {

        this.validateInput(this.userFrm, 'labor');
        if (eventV) {
            let event = eventV.id
            if (event === 10) {
                this.mostrarDescripcion = true;
            } else {
                this.mostrarDescripcion = false;
            }
        }

    }
    cargarTipoActividadIndepent() {
        let catalogo = "";
        catalogo = "ciuu"
        this.loading = true;
        this.catalogService.getCatalogs(catalogo, this.pais)
            .subscribe((resp: any) => {
                this.tipoActividadIndepentSelectItem = [];
                this.tipoActividadIndepentSelectItem.push({ 'label': this.properties.labelSelecioneCombo, value: null });
                resp.forEach(element => {
                    this.tipoActividadIndepentSelectItem.push({ 'label': element.description, value: element })
                });
                this.loading = false;
            },

                (err: any) => console.log(err),
                () => {
                    this.loading = false;
                });
    }

    validacionSection(form: NgForm, listSections: Array<String>) {
        const catalog = new CatalogModel();
        this.countLabor = 0;
        this.listInputsLabor = [];
        this.countEconomic = 0;
        this.listInputsEconomic = [];
        this.countComercialInformation = 0;
        this.listInputsComercialInformation = [];
        this.countReference = 0;
        this.listInputsReference = [];

        const controls = form.controls;
        Object.keys(controls).forEach(input => {
            const nameInput = input.split('_');
            listSections.forEach(section => {
                if (nameInput[1] === section) {

                    switch (section) {
                        case 'labor':
                            this.listInputsLabor.push(input);
                            if (form.controls[input].value !== '') {
                                if (form.controls[input].value !== 0) {
                                    if (form.controls[input].value !== null && form.controls[input].value !== undefined) {
                                        if (form.controls[input].value.id !== null && form.controls[input].value.id !== '') {
                                            this.countLabor++;
                                        }
                                    }
                                }
                            }
                            break;
                        case 'economic':
                            this.listInputsEconomic.push(input);
                            if (form.controls[input].value !== '' || form.controls[input].status == 'VALID') {
                                if (form.controls[input].value !== 0 || form.controls['ingresosMensuales_economic']) {
                                    if (form.controls[input].value !== null || form.controls[input].status == 'VALID') {
                                        if (form.controls[input].value !== undefined || form.controls[input].status == 'VALID') {
                                            this.countEconomic++;
                                        }
                                    }
                                }
                            }
                            break;
                        case 'comercialInfo':
                            this.listInputsComercialInformation.push(input);
                            if (form.controls[input].value != '') {
                                if (form.controls[input].value !== null) {
                                    if (form.controls[input].value !== undefined) {
                                        if (form.controls[input].value.id !== null) {
                                            this.countComercialInformation++;
                                        }
                                    }
                                }
                            }
                            break;
                        case 'reference':
                            this.listInputsReference.push(input);
                            if (form.controls[input].value !== '') {
                                if (form.controls[input].value !== 0) {
                                    if (form.controls[input].value !== null && form.controls[input].value !== undefined) {
                                        if (form.controls[input].value.id !== null && form.controls[input].value.id !== '') {
                                            this.countReference++;
                                        }
                                    }
                                }
                            }
                            break;
                        default:
                            break;
                    }
                }
                if (this.countLabor === this.listInputsLabor.length) {
                    this.bndCamposLabor = true;
                } else {
                    this.bndCamposLabor = false;
                }
                if (this.countEconomic === this.listInputsEconomic.length) {
                    this.bndCamposEconomic = true;
                } else {
                    this.bndCamposEconomic = false;
                }
                if (this.countComercialInformation === this.listInputsComercialInformation.length) {
                    this.bndCamposEconomicInfo = true;
                } else {
                    this.bndCamposEconomicInfo = false;
                }
                if (this.countReference === this.listInputsReference.length) {
                    this.bndCamposReference = true;
                } else {
                    this.bndCamposReference = false;
                }
            });
        });
    }

    validateInput(form: NgForm, key) {
        setTimeout(() => {
            switch (key) {
                case 'labor':
                    this.countLabor = 0;
                    this.listInputsLabor = [];
                    break;
                case 'economic':
                    this.countEconomic = 0;
                    this.listInputsEconomic = [];
                    break;
                case 'comercialInfo':
                    this.countComercialInformation = 0;
                    this.listInputsComercialInformation = [];
                    break;
                case 'reference':
                    this.countReference = 0;
                    this.listInputsReference = [];
                    break;
                default:
                    break;
            }

            const controls = form.controls;
            Object.keys(controls).forEach(input => {
                const nameInput = input.split('_');
                if (nameInput[1] === key) {
                    switch (key) {
                        case 'labor':
                            this.listInputsLabor.push(input);
                            if (form.controls[input].value !== '') {
                                if (form.controls[input].value !== 0) {
                                    if (form.controls[input].value !== null && form.controls[input].value !== undefined) {
                                        if (form.controls[input].value.id !== null && form.controls[input].value.id !== '') {
                                            this.countLabor++;
                                        }
                                    }
                                }
                            }
                            break;
                        case 'economic':
                            this.listInputsEconomic.push(input);
                            if (form.controls[input].value !== '' || form.controls[input].status == 'VALID') {
                                if (form.controls[input].value !== 0 || form.controls['ingresosMensuales_economic']) {
                                    if (form.controls[input].value !== null || form.controls[input].status == 'VALID') {
                                        if (form.controls[input].value !== undefined || form.controls[input].status == 'VALID') {
                                            this.countEconomic++;
                                        }
                                    }
                                }
                            }
                            break;
                        case 'comercialInfo':
                            this.listInputsComercialInformation.push(input);
                            if (form.controls[input].value != '') {
                                if (form.controls[input].value !== null) {
                                    if (form.controls[input].value !== undefined) {
                                        if (form.controls[input].value.id !== null) {
                                            this.countComercialInformation++;
                                        }
                                    }
                                }
                            }
                            break;
                        case 'reference':
                            this.listInputsReference.push(input);
                            if (form.controls[input].value !== '') {
                                if (form.controls[input].value !== 0) {
                                    if (form.controls[input].value !== null && form.controls[input].value !== undefined) {
                                        if (form.controls[input].value.id !== null && form.controls[input].value.id !== '') {
                                            this.countReference++;
                                        }
                                    }
                                }
                            }
                            break;
                        default:
                            break;
                    }
                }
                if (this.countLabor === this.listInputsLabor.length) {
                    this.bndCamposLabor = true;
                } else {
                    this.bndCamposLabor = false;
                }
                if (this.countEconomic === this.listInputsEconomic.length) {
                    this.bndCamposEconomic = true;
                } else {
                    this.bndCamposEconomic = false;
                }
                if (this.countComercialInformation === this.listInputsComercialInformation.length) {
                    this.bndCamposEconomicInfo = true;
                } else {
                    this.bndCamposEconomicInfo = false;
                }
                if (this.countReference === this.listInputsReference.length) {
                    this.bndCamposReference = true;
                } else {
                    this.bndCamposReference = false;
                }
            });
        }, 1000);
    }

    cargarTipoRelacion() {
        this.catalogService.getCatalogs('relationship', this.pais)
            .subscribe((resp: any) => {
                this.tipoRelacionSelectItem = [];
                this.tipoRelacionSelectItem.push({ 'code': '', 'description': this.properties.labelSelecioneCombo, 'id': '' });
                resp.forEach(element => {
                    this.tipoRelacionSelectItem.push(element)
                });
            },
                (err: any) => console.log(err),
                () => {
                });
    }

    cargarTipoActividad() {
        this.catalogService.getCatalogs(this.properties.economicActivityPN, this.pais)
            .subscribe((resp: any) => {
                this.tipoActividadSelectItem = [];
                this.tipoActividadSelectItem.push({ 'code': '', 'description': this.properties.labelSelecioneCombo, 'id': '' });
                resp.forEach(element => {
                    this.tipoActividadSelectItem.push(element)
                });
            },
                (err: any) => console.log(err),
                () => {
                });
    }

    mostrarOtro(event) {
        let relacion = event;
    }


    verifyForm() {
        let valR = true;
        if (!this.economicInformation.monthlyExpenses.includes('$')) {
            this.economicInformation.monthlyExpenses = null;
            valR = false;
        }
        if (!this.economicInformation.actives.includes('$')) {
            this.economicInformation.actives = null;
            valR = false;
        }
        if (!this.economicInformation.pasives.includes('$')) {
            this.economicInformation.pasives = null;
            valR = false;
        }
        if (this.economicInformation.haveOtherIncome && !this.economicInformation.otherIncome.includes('$')) {
            this.economicInformation.otherIncome = null;
            valR = false;
        }
        return valR
    }

    actualizarFormulario(myForm: NgForm) {
        if (!this.verifyForm()) {
            return
        }    
        if(myForm.valid){
            this.loading = true;
            this.armarFormulario();
            this._documentsService.updateDocument(this.nuevoFormulario).
                subscribe((resp: any) => {
                    this.messageService.add({ severity: 'success', summary: '', detail: this.properties.msgSuccessForm });
                    this.loading = false;
                    this.changed.emit(true);
                },
                    (err: any) => {
                        this.messageService.add({ severity: 'error', summary: '', detail: this.properties.msgErrorForm });
                        this.loading = true;
                    },
                    () => {
                    });
        }else{
            let count = 0;
            Object.keys(myForm.controls).forEach(element => {
                if (!myForm.controls[element].valid) {
                    count++;
                }
            });
            this.messageService.add({
                severity: 'error', summary: '', detail: 'El formulario aun tiene ' + count +
                    ' Error(es) revise las alertas en cada campo'
            }); 
        }
       
    }

    armarFormulario() {

        if (this.idMonthlyN != undefined && this.idMonthlyN != null) {

            this.monthlyIcomeSelectItem.forEach(description => {
                if (description.value === this.idMonthlyN) {
                    const model = {
                        description: description.label,
                        id: description.value,
                        code: null
                    }
                    this.economicInformation.monthlyIncome = model;
                }
            }
            )
        }
        this.formulario.step = this.step

        this.formulario.personalReferences = this.refPersonales;
        this.formulario.bankReferences = this.refBancarias;
        this.formulario.commercialReferences = this.refComerciales;
        this.formulario.economicInformation = this.economicInformation;
        this.formulario.comercialInformation = this.comercialInformation;
        // if (this.formulario.work) {
        //     if (this.formulario.work.economicActivity) {
        //         console.log(this.formulario.work.economicActivity);
        //         let value = this.formulario.work.economicActivity;
        //         let label = null;
        //         this.tipoMainActivitySelectItem.forEach(x => {
        //             if (x.value == value)
        //                 label = x.label;
        //         })
        //         let objetoEconicActivity = new CatalogModel;
        //         objetoEconicActivity.id = +value;
        //         objetoEconicActivity.description = label;
        //        this.formulario.work.economicActivity = objetoEconicActivity;
        //     }
        // }

        this.nuevoFormulario.data = this.formulario;
        let paso = 2;
        if (paso <= this.step) {
            paso = this.step;
        } else {
            this.step = paso;
        }
        this.formulario.step = this.step
    }

    agregar() {
        let referencia = new PersonalReferencePersonModel();
        this.refPersonales.push(referencia);
        if (this.refPersonales.length >= 2) {
            this.botonAgregar = true;
        } else {
            this.botonAgregar = false;
        }
        this.validateInput(this.userFrm, 'reference');
    }
    agregarBanco() {
        let referencia = new BankReferenceModel();
        this.refBancarias.push(referencia);
        if (this.refBancarias.length >= 2) {
            this.botonAgregarBank = true;

        } else {
            this.botonAgregarBank = false;
        }
        this.validateInput(this.userFrm, 'reference');
    }
    getMonthlyIncomeN() {
        this.catalogService.getCatalogs('monthlyIncomeN', this.pais)
            .subscribe((resp: any) => {
                this.monthlyIcomeSelectItem = [];
                this.monthlyIcomeSelectItem.push({ label: this.properties.labelSelecioneCombo, value: null });
                resp.forEach(element => {
                    const catalog = {
                        label: element.description,
                        value: element.id
                    };
                    this.monthlyIcomeSelectItem.push(catalog);
                });
            },
                (err: any) => console.log(err),
                () => {
                });
    }

    getCountries() {
        this.catalogService.getCatalogs(this.properties.countries, this.pais)
            .subscribe((resp: any) => {
                this.countriesSelectItem = [];
                this.countriesSelectItem.push({ label: this.properties.labelSelecioneCombo, value: '' });
                resp.forEach(element => {
                    const catalog = {
                        label: element.description,
                        value: element
                    };
                    this.countriesSelectItem.push(catalog);
                });
                this.consultarFormulario();
            },
                (err: any) => console.log(err),
                () => {
                });
    }


    getProvinceByCountry(pais, index?) {
        if (pais) {
            let idPais = pais.id
            if (idPais === this.properties.codCatalogEc || idPais === this.properties.codCatalogCo) {
                this.catalogService.getCatalogs(this.properties.province, this.pais, idPais)
                    .subscribe((resp: any) => {
                        this.provinceSelectItem = [];
                        this.provinceSelectItem.push({ label: this.properties.labelSelecioneCombo, value: '' });
                        resp.forEach(element => {
                            const catalog = {
                                label: element.description,
                                value: element
                            };
                            this.provinceSelectItem.push(catalog);
                        });
                    },
                        (err: any) => console.log(err),
                        () => {
                        });
            }
            // CONTROL CAMBIOS 
            //entity.work.address.geographicLocation.country.description 
        }
        this.validateInput(this.userFrm, 'labor');
    }

    getCityByProvince(province, index) {

        if (province)
            this.catalogService.getCatalogs(this.properties.city, this.pais, province.id).subscribe((resp: any) => {
                this.citySelectItem = [];
                this.citySelectItem.push({ label: this.properties.labelSelecioneCombo, value: '' });
                resp.forEach(element => {
                    const catalog = {
                        label: element.description,
                        value: element
                    };
                    this.citySelectItem.push(catalog);
                });
            },
                (err: any) => console.log(err),
                () => {
                });

        this.validateInput(this.userFrm, 'labor');
    }

    updateDescriptionCity(idCity, index) {

        this.validateInput(this.userFrm, 'labor');
    }

    eventoTipoActividad(event) {
        this.mostrarOtrosTipoLaboral = false;
        this.mostrarCargo = false;
        this.mostrarCargoAux = false;
        this.actividadEco = false;

        if (event.id == 4 || event.id == 5) {
            this.mostrarCargo = true;
            this.mostrarCargoAux = true;
        }
        else {
            this.mostrarCargo = false;

        }
        if (event.id == 6) {
            this.actividadEco = true;
            this.mostrarCargo = true;
            this.mostrarCargoAux = false;
        }
        if (event.id == 8) {
            this.mostrarOtrosTipoLaboral = true;
        }


        this.validateInput(this.userFrm, 'labor');
    }

    eventoOtraRelacion(event) {

        if (event.relationship.description == "OTROS") {
            event.showOtherType = true;
        }
        else {
            event.showOtherType = false;
        }
        this.validateInput(this.userFrm, 'reference');
    }

    eliminarItem(index) {

        for (var i = 0; i < this.refPersonales.length; i++) {
            if (i == index) {

                this.refPersonales.splice(i, 1)
            }
        }
        if (this.refPersonales.length >= 2) {
            this.botonAgregar = true;
        } else {
            this.botonAgregar = false;
        }
    }

    eliminarItemBank(index) {

        for (var i = 0; i < this.refBancarias.length; i++) {
            if (i == index) {

                this.refBancarias.splice(i, 1)
            }
        }
        if (this.refBancarias.length >= 2) {
            this.botonAgregarBank = true;

        } else {
            this.botonAgregarBank = false;
        }
    }

}
