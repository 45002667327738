/**
 * intl-latam-ec-portal-documents
 * No description
 *
 * OpenAPI spec version: 1.0.0
 * Contact: you@your-company.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { FormEntityLegalRepresentativeTypeModel } from './formEntityLegalRepresentativeType';


export class FormEntityLegalRepresentativeRelationshipPepModel {
    type?: FormEntityLegalRepresentativeTypeModel;
    otherType?: string;
    personType?: string;
    person?: any;
    charge?: string;
    entity?: string;
    dateEntry?: string;
    dateUnlinked?: string;
    isSubjectTaxObligationsAnotherCountry?: boolean;
    specifyCountry?: FormEntityLegalRepresentativeTypeModel;

    constructor() {
        this.type = new  FormEntityLegalRepresentativeTypeModel();
        this.otherType = null;
        this.personType = null;
        this.person = null;
        this.charge = null;
        this.entity = null;
        this.dateEntry = null;
        this.dateUnlinked = null;
        this.isSubjectTaxObligationsAnotherCountry = false;
        this.specifyCountry = new FormEntityLegalRepresentativeTypeModel();
    }
}
