/**
 * intl-latam-ec-portal-documents
 * No description
 *
 * OpenAPI spec version: 1.0.0
 * Contact: you@your-company.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export class FormEntityBankReferencesModel { 
    bankName?: string;
    creditCardName?: string;
    accountType?: string;

    constructor(){
        this.bankName = null;
        this.creditCardName = null;
        this.accountType = null;
    }
}
