/**
 * intl-latam-ec-milf-linkingform
 * Manage the information of the linking form.
 *
 * OpenAPI spec version: 2.1.0
 * Contact: kruger@kruger.com.ec
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


export interface Evicertia { 
    date?: Date;
    signatureStatus?: Evicertia.SignatureStatusEnum;
    uniqueId?: string;
}
export namespace Evicertia {
    export type SignatureStatusEnum = 'CANCELLED' | 'EXPIRED' | 'FAILED' | 'REJECTED' | 'SIGNED' | 'SENT';
    export const SignatureStatusEnum = {
        CANCELLED: 'CANCELLED' as SignatureStatusEnum,
        EXPIRED: 'EXPIRED' as SignatureStatusEnum,
        FAILED: 'FAILED' as SignatureStatusEnum,
        REJECTED: 'REJECTED' as SignatureStatusEnum,
        SIGNED: 'SIGNED' as SignatureStatusEnum,
        SENT: 'SENT' as SignatureStatusEnum
    };
}
