import { Rule } from "src/app/_services/model-seguridad/rule";

export class RuleModel implements Rule {
    docType?: string;
    id?: string;
    rule?: string;
    name?: string;
    description?: string;
    x: string;

    constructor() {
        this.docType = "rule";
        this.id = null;
        this.rule = null;
        this.name = null;
        this.description = null;
        this.x = null;
    }
}
