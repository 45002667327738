export * from './catalogs.service';
import { CatalogsService } from './catalogs.service';
export * from './files.service';
import { FilesService } from './files.service';
export * from './forms.service';
import { FormsService } from './forms.service';
export * from './notifications.service';
import { NotificationsService } from './notifications.service';
export * from './reports.service';
import { ReportsService } from './reports.service';
export * from './default.service';
import { DefaultService } from './default.service';
export * from './groups.service';
import { GroupsService } from './groups.service';
export * from './menus.service';
import { MenusService } from './menus.service';
export * from './roles.service';
import { RolesService } from './roles.service';
export * from './rules.service';
import { RulesService } from './rules.service';
export * from './users.service';
import { UsersService } from './users.service';
export * from '../api_v1/incentivos.service';
import { IncentivosService } from '../api_v1/incentivos.service';
export * from './access.service';
import { AccessService } from './access.service';
import { GenericService } from '../services.util';
import { DocumentsService } from '../api_v1/documents.service';
import { CatalogService } from '../api_v1/catalogs.service';
import { RenovationsService } from '../api_v1/renovations.service';
import { InspectionsService } from '../api_v1/inspections.service';

export const APIS = [CatalogsService,
    CatalogService,
    FilesService,
    FormsService,
    NotificationsService,
    ReportsService,
    DefaultService,
    GroupsService,
    MenusService,
    RolesService,
    RulesService,
    UsersService,
    IncentivosService,
    RenovationsService,
    AccessService,
    DocumentsService,
    InspectionsService,
    GenericService];
