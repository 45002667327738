// angular
import { Component, OnInit, Input, Output, EventEmitter, ViewChild, OnChanges, DoCheck } from '@angular/core';
import { NgForm } from '@angular/forms';
import { SelectItem } from 'primeng/primeng';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/components/common/messageservice';
import { Properties } from '../../../../../general/properties';
import { FormsService, CatalogsService } from '../../../../../../_services';
import { ApiResponse } from '../../../../../../_services/model-formulario/modelsForm';
import { DocumentsService } from 'src/app/_services/api_v1/documents.service';
import { CatalogService } from 'src/app/_services/api_v1/catalogs.service';

import { FormModel } from '../../../../../../_model/documents/form';
import { PersonFormModel } from '../../../../../../_model/documents/personForm';
import { LocationModel } from '../../../../../../_model/documents/location';
import { SpousePersonFormModel, DocumentModel, PlaceAddressInformationModel, PersonFormContactsModel } from 'src/app/_model/documents/models';
import { ContactModel } from 'src/app/_model/form/contact';

@Component({
    selector: 'app-datos-generales-co',
    templateUrl: './datosGenerales.html',
    styleUrls: ['./datosGenerales.css']
})

export class DatosGeneralesComponent implements OnInit, DoCheck {

    @Output() changed = new EventEmitter<boolean>();
    @Output() formAux = new EventEmitter<any>();
    @ViewChild('form') public userFrm: NgForm;

    properties: Properties = new Properties();
    es: any = this.properties.es;
    titulo = 'Comencemos';
    subtitulo = 'Queremos conocerte. Completa la información solicitada en cada paso';

    // Generales
    pais: any;
    idFormulario: any;
    maxBirthDateA = new Date();
    maxDate = new Date(this.maxBirthDateA.setFullYear(this.maxBirthDateA.getFullYear() - 18));

    // Catalogos
    tipoDocumentoSelectItem: any[];
    tipoEstadoCivilSelectItem: any[];
    countriesSelectItem: SelectItem[] = [{ label: this.properties.labelSelecioneCombo, value: null }];
    countriesExpeditionSelectItem: SelectItem[] = [{ label: this.properties.labelSelecioneCombo, value: null }];
    provinceSelectItem: SelectItem[] = [{ label: this.properties.labelSelecioneCombo, value: null }];
    provinceExpeditionSelectItem: SelectItem[] = [{ label: this.properties.labelSelecioneCombo, value: null }];
    citySelectItem: SelectItem[] = [{ label: this.properties.labelSelecioneCombo, value: null }];
    countriesAdressSelectItem: SelectItem[] = [{ label: this.properties.labelSelecioneCombo, value: null }];
    provinceAdressSelectItem: SelectItem[] = [{ label: this.properties.labelSelecioneCombo, value: null }];
    cityAdressSelectItem: SelectItem[] = [{ label: this.properties.labelSelecioneCombo, value: null }];
    cityExpeditionSelectItem: SelectItem[] = [{ label: this.properties.labelSelecioneCombo, value: null }];
    tipoPais: any;
    nacionalidadesSelectItem: any[];

    // Modelos
    nuevoFormulario = new DocumentModel();
    formulario = new FormModel();
    person = new PersonFormModel();
    spouse = new SpousePersonFormModel();
    fecha: Date;
    genero: any;
    estadoCivil: any;
    tipoDocumentoPersona: any;
    step: any;
    // Banderas
    mostrarConyugue = 0;
    generos: SelectItem[] = [];
    selectCountry = true;
    selectAdressCountry = true;
    loading: boolean;
    selectExpeditionCountry = true;
    listSections = [];
    // variables conteo campos diligenciados
    bndValidHeaderContac = false;
    listInputsContac = [];
    bndCamposContact = false;
    countContact = 0;
    bndValidHeaderInfo = false;
    listInputsInfo = [];
    bndCamposInfo = false;
    countInfo = 0;
    bndValidHeaderResidencia = false;
    listInputsResidencia = [];
    bndCamposResidencia = false;
    countResidencia = 0;

    // Variables
    fechaNacimiento: any;
    today = new Date();
    yesterday = new Date();
    mobile: any;
    countryBirth: any;
    expeditionPlaceCountry: any;
    placeAddressResidenceCountry: any;

    // Listas
    listaPaises: any = [];
    idPaisesGuardados = [];
    civilStatusSelectItem = [];
    mostrarNacionalidades: boolean = false;
    selectedNacionalities: string[] = [];

    disabledAgregarNat: boolean = false;

    noconsultar: boolean = false;
    loadFinal: boolean;
    esFormularioPadre: boolean;
    coCatalogo = { "description": "COLOMBIA", "code": null, "id": 170 }

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private messageService: MessageService,
        private formService: FormsService,
        private catalogService: CatalogService,
        private _documentsService: DocumentsService
    ) {
        this.generos = this.properties.generos;
        this.yesterday = new Date(this.today.getTime() - 24 * 60 * 60 * 1000);
        this.listSections = ['infoBasica', 'residencia', 'contacto'];
    }

    ngOnInit(): void {
        this.loadFinal = true;
        this.mobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
        const id = this.route.snapshot.paramMap.get('idFormulario');
        const country = this.route.snapshot.paramMap.get('idCountry');
        this.pais = country;
        this.idFormulario = id;
        this.cargarDatos();
    }
    async cargarDatos() {
        await this.getCountries();
        await this.getNacionalidades();
        await this.cargarTipoDocumento();
        // await this.getCivilStatus();
        // se mueve consultar formulario a la respuesta de consultar paises
        //await  this.consultarFormulario();

    }
    ngDoCheck(): void {
        if (this.loadFinal) {
            this.loadFinal = false;
            setTimeout(() => {
                this.loadPlace();
            }, 1100);
        }
    }



    onTabOpen(e) {
        //console.log(e);
        const index = e.index;
        switch (index) {
            case 0:
                this.bndValidHeaderInfo = true;
                break;
            case 1:
                this.bndValidHeaderResidencia = true;
                break;
            case 2:
                this.bndValidHeaderContac = true;
                break;
            default:
                break;
        }
    }

    onTabClose(e) {
        this.mobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
        const index = e.index;
        switch (index) {
            case 0:
                this.bndValidHeaderInfo = false;
                break;
            case 1:
                this.bndValidHeaderResidencia = false;
                break;
            case 2:
                this.bndValidHeaderContac = false;
                break;
            default:
                break;
        }
    }

    /**
     * test
     */
    loadPlace() {
        this.loading = true;
        if (this.person.birthPlace || this.person.birthPlace != null) {

            this.countryBirth = this.person.birthPlace.country;
            this.expeditionPlaceCountry = this.person.document.expeditionPlace.country;
            this.placeAddressResidenceCountry = this.person.placeAddressResidence.place.country;
            if (this.person.birthPlace.country && (this.person.birthPlace.country.id === this.properties.codCatalogCo ||
                this.person.birthPlace.country.id === this.properties.codCatalogEc)) {
                this.getProvinceByCountry(this.person.birthPlace.country, this.properties.birth);
                this.selectCountry = false;
            }
            if (this.person.placeAddressResidence.place.country && (this.person.placeAddressResidence.place.country.id === this.properties.codCatalogCo ||
                this.person.placeAddressResidence.place.country.id === this.properties.codCatalogEc)) {
                this.getProvinceByCountry(this.person.placeAddressResidence.place.country, this.properties.address);
                this.selectAdressCountry = false;
            }
            if (this.person.document.expeditionPlace.country && (this.person.document.expeditionPlace.country.id === this.properties.codCatalogCo ||
                this.person.document.expeditionPlace.country.id === this.properties.codCatalogEc)) {
                this.getProvinceByCountry(this.person.document.expeditionPlace.country, this.properties.expedition);
                this.selectExpeditionCountry = false;
            }
            if (this.person.birthPlace.country && this.person.birthPlace.country.id === 0) {
                this.person.birthPlace.country.id = this.properties.codCatalogCo;
                this.person.birthPlace.province.id = null;
                this.getProvinceByCountry(this.person.birthPlace.country, this.properties.birth);
                this.selectCountry = false;
            }
            if (this.person.document.expeditionPlace.country && this.person.document.expeditionPlace.country.id === 0) {
                this.person.document.expeditionPlace.country.id = this.properties.codCatalogCo;
                this.getProvinceByCountry(this.person.document.expeditionPlace.country, this.properties.expedition);
                this.selectExpeditionCountry = false;
            }
            if (this.person.placeAddressResidence.place.country && this.person.placeAddressResidence.place.country.id === 0) {
                this.person.placeAddressResidence.place.country.id = this.properties.codCatalogCo;
                this.getProvinceByCountry(this.person.placeAddressResidence.place.country, this.properties.address);
                this.selectAdressCountry = false;
            }
        } else {
            this.person.birthPlace = new LocationModel();
        }


        this.loading = false;
    }

    consultarFormulario() {
        this.loading = true;
        this._documentsService.getDocumentsByDateIdByCountry(this.idFormulario, this.pais)
            .subscribe((resp: any) => {
                this.nuevoFormulario = resp;
                this.formulario = resp.data;

                this.esFormularioPadre = resp.isParentForm;

                this.person = this.formulario.person;
                if (this.formulario.person.placeAddressResidence ||
                    this.formulario.person.placeAddressResidence != null) {
                    this.formulario.person.placeAddressResidence.mainStreet = resp.data.person.placeAddressResidence.mainStreet;

                } else {
                    this.formulario.person.placeAddressResidence = new PlaceAddressInformationModel();
                }


                if (this.formulario.person.document.expeditionPlace === undefined
                    || this.formulario.person.document.expeditionPlace == null) {
                    this.formulario.person.document.expeditionPlace = new LocationModel();
                }
                if (this.person.civilStatus && this.person.civilStatus != null) {
                    this.estadoCivil = this.person.civilStatus;
                } else {
                    this.estadoCivil = null;
                }
                if (this.formulario.spouse === undefined) {
                    this.spouse = new SpousePersonFormModel();
                } else {
                    this.spouse = this.formulario.spouse;
                }
                // this.spouse = this.formulario.entity.spouse;
                if (this.person.birthDate) {
                    this.fechaNacimiento = new Date(this.formulario.person.birthDate);
                }
                if (this.person.document) {
                    if (this.person.document.expeditionDate) {
                        this.person.document.expeditionDate = new Date(this.formulario.person.document.expeditionDate);
                    }
                    else {
                        this.person.document.expeditionDate = null;
                    }

                }
                // if (this.person.document) {
                //     this.person.document.expeditionDate = new Date(this.formulario.person.document.expeditionDate);
                // }

                if (this.person.nationalities == null) {
                    this.listaPaises = [];
                } else {
                    this.listaPaises = this.person.nationalities;
                    this.disabledAgregarNat = true;
                    this.mostrarNacionalidades = true;
                }
                if (!this.formulario.person.birthPlace) {
                    this.countryBirth = this.coCatalogo
                    this.getProvinceByCountry(this.coCatalogo, 'birth')

                }
                this.loadPlace();
                this.step = this.formulario.step;
                if (this.person.civilStatus && this.person.civilStatus != null) {
                    if (this.person.civilStatus.id === 1 || this.person.civilStatus.id === 3) {
                        this.mostrarConyugue = 1;
                    } else {
                        this.mostrarConyugue = 0;
                    }
                } else {
                    this.mostrarConyugue = 0;
                }
                setTimeout(() => {
                    this.validacionSection(this.userFrm, this.listSections);
                    this.loading = false;
                }, 1000);
                if (this.mobile) {
                    const numSections = 3;
                    for (let index = 0; index < numSections; index++) {
                        let elm = <HTMLElement>document.getElementById('countAccordion' + index);
                        elm.style.display = 'none';
                    }
                    let card = <HTMLElement>document.getElementById('cardIntermediarios');
                    card.style.paddingLeft = '10px';
                    card.style.paddingRight = '10px';
                }
            },
                (err: any) => {
                    this.loading = true;
                });

    }

    cargarTipoDocumento() {
        this.catalogService.getCatalogs(this.properties.documentType, this.pais)
            .subscribe((resp: any) => {
                this.tipoDocumentoSelectItem = [];
                this.tipoDocumentoSelectItem.push({ 'value': null, 'label': this.properties.labelSelecioneCombo });
                resp.forEach(element => {
                    this.tipoDocumentoSelectItem.push({ 'value': element, 'label': element.description });
                });
            },
                (err: any) => console.log(err),
                () => {
                });
    }

    getCivilStatus() {
        this.catalogService.getCatalogs(this.properties.civilStatus, this.pais)
            .subscribe((resp: any) => {
                this.civilStatusSelectItem = [];
                this.civilStatusSelectItem.push({ label: this.properties.labelSelecioneCombo, value: null });
                resp.forEach(element => {
                    delete element.code;
                    const catalog = {
                        label: element.description,
                        value: element
                    };
                    this.civilStatusSelectItem.push(catalog);
                    if (this.civilStatusSelectItem.length === 7) {
                        this.estadoCivil = this.person.civilStatus;
                    }

                });
            },
                (err: any) => console.log(err),
                () => {
                });
    }

    getCountries() {
        this.catalogService.getCatalogs(this.properties.countries, this.pais)
            .subscribe((resp: any) => {
                resp.forEach(element => {
                    const catalog = {
                        label: element.description,
                        value: element
                    };
                    this.countriesSelectItem.push(catalog);
                });
                this.consultarFormulario();
            },
                (err: any) => console.log(err),
                () => {
                });
    }

    getNacionalidades() {
        this.catalogService.getCatalogs(this.properties.countries, this.pais)
            .subscribe((resp: any) => {
                this.nacionalidadesSelectItem = [];
                // this.nacionalidadesSelectItem.push({ 'code': '', 'description': this.properties.labelSelecioneCombo, 'id': '' });
                resp.forEach(element => {
                    this.nacionalidadesSelectItem.push(element)
                });
            },
                (err: any) => console.log(err),
                () => {
                });
    }

    getProvinceByCountry(pais, type) {
        if (pais) {
            let idPais = pais.id
            if (idPais === this.properties.codCatalogEc || idPais === this.properties.codCatalogCo) {
                var typeCtg = '';
                this.catalogService.getCatalogs(this.properties.province, this.pais, idPais)
                    .subscribe((resp: any) => {
                        if (type === this.properties.birth) {
                            this.provinceSelectItem = [];
                            this.provinceSelectItem.push({ label: this.properties.labelSelecioneCombo, value: '' });

                            resp.forEach(element => {
                                const catalog = {
                                    label: element.description,
                                    value: element
                                };
                                this.provinceSelectItem.push(catalog);
                                this.selectCountry = false;
                            });
                            if (this.person.birthPlace.province) {
                                this.getCityByProvince(this.person.birthPlace.province, type);
                            }
                        } else if (type === this.properties.address) {
                            this.provinceAdressSelectItem = [];
                            this.provinceAdressSelectItem.push({ label: this.properties.labelSelecioneCombo, value: '' });

                            resp.forEach(element => {
                                const catalog = {
                                    label: element.description,
                                    value: element
                                };
                                this.provinceAdressSelectItem.push(catalog);
                                this.selectAdressCountry = false;
                            });
                            if (this.person.placeAddressResidence.place.province) {
                                this.getCityByProvince(this.person.placeAddressResidence.place.province, type);
                            }
                        } else if (type === this.properties.expedition) {
                            this.provinceExpeditionSelectItem = [];
                            this.provinceExpeditionSelectItem.push({ label: this.properties.labelSelecioneCombo, value: '' });

                            resp.forEach(element => {
                                const catalog = {
                                    label: element.description,
                                    value: element
                                };
                                this.provinceExpeditionSelectItem.push(catalog);
                                this.selectExpeditionCountry = false;
                            });
                            if (this.person.document.expeditionPlace.province) {
                                this.getCityByProvince(this.person.document.expeditionPlace.province, type);
                            }
                        }
                    },
                        (err: any) => console.log(err),
                        () => {
                        });
            } else {
                if (type === this.properties.birth) {
                    this.selectCountry = true;
                    this.provinceSelectItem = [];
                    this.citySelectItem = [];
                    this.person.birthPlace = new LocationModel();
                } else if (type === this.properties.address) {
                    this.selectAdressCountry = true;
                    this.provinceAdressSelectItem = [];
                    this.cityAdressSelectItem = [];
                    this.person.placeAddressResidence.place = new LocationModel();
                } else if (type === this.properties.expedition) {
                    this.selectExpeditionCountry = true;
                    this.provinceExpeditionSelectItem = [];
                    this.cityExpeditionSelectItem = [];
                    this.person.document.expeditionPlace = new LocationModel();
                }
            }
        }
        if (type === this.properties.birth || type === this.properties.expedition) {
            typeCtg = 'infoBasica';
        } else if (type === this.properties.address) {
            typeCtg = 'residencia';
        }
        this.validateInput(this.userFrm, typeCtg);
    }

    getCityByProvince(province, type) {
        if (province) {
            let idProvince = province.id
            var typeCtg = '';
            this.catalogService.getCatalogs(this.properties.city, this.pais, idProvince).subscribe((resp: any) => {
                if (type === this.properties.birth) {
                    this.citySelectItem = [];
                    this.citySelectItem.push({ label: this.properties.labelSelecioneCombo, value: '' });
                    resp.forEach(element => {
                        const catalog = {
                            label: element.description,
                            value: element
                        };
                        this.citySelectItem.push(catalog);
                    });

                } else if (type === this.properties.address) {
                    this.cityAdressSelectItem = [];
                    this.cityAdressSelectItem.push({ label: this.properties.labelSelecioneCombo, value: '' });
                    resp.forEach(element => {
                        const catalog = {
                            label: element.description,
                            value: element
                        };
                        this.cityAdressSelectItem.push(catalog);
                    });

                } else if (type === this.properties.expedition) {
                    this.cityExpeditionSelectItem = [];
                    this.cityExpeditionSelectItem.push({ label: this.properties.labelSelecioneCombo, value: '' });
                    resp.forEach(element => {
                        const catalog = {
                            label: element.description,
                            value: element
                        };
                        this.cityExpeditionSelectItem.push(catalog);
                    });

                }

            },
                (err: any) => console.log(err),
                () => {
                });
        }
        if (type === this.properties.birth || type === this.properties.expedition) {
            typeCtg = 'infoBasica';
        } else if (type === this.properties.address) {
            typeCtg = 'residencia';
        }
        this.validateInput(this.userFrm, typeCtg);
    }

    updateDescriptionCity(idCity, type) {
        let typeCtg, nameCity = '';

        if (type === this.properties.birth || type === this.properties.expedition) {
            typeCtg = 'infoBasica';
        } else if (type === this.properties.address) {
            typeCtg = 'residencia';
        }
        this.validateInput(this.userFrm, typeCtg);
    }

    actualizarFormulario(myForm: NgForm) {
        const listControls = Object.keys(myForm.controls);
        const sizeControls = listControls.length;
        let i = 0;
        let count = 0;
        listControls.forEach(control => {
            if (myForm.valid && count === 0 && myForm.controls[control].value !== 0) {
                i++;
            } else {
                i++;
                if (!myForm.controls[control].valid
                    && control != "correoB_contacto" && control != "celular_contacto"
                    && control != "genero_infoBasica") {
                    count++;
                } else if (myForm.controls[control].value === 0) {
                    count++;
                }
            }
            if (sizeControls === i) {
                if (count === 0) {
                    this.loading = true;
                    this.armarFormulario();
                    this._documentsService.updateDocument(this.nuevoFormulario).
                        subscribe((resp: ApiResponse) => {
                            this.messageService.add({ severity: 'success', summary: '', detail: this.properties.msgSuccessForm });
                            this.loading = false;
                            this.changed.emit(true);
                        },
                            (err: any) => {
                                this.messageService.add({ severity: 'error', summary: '', detail: this.properties.msgErrorForm });
                                this.loading = true;
                            },
                            () => {
                            });
                } else {
                    this.messageService.add({ severity: 'error', summary: '', detail: "El formulario aun tiene " + count + " Error(es) revise las alertas en cada campo" });
                    this.loading = false;
                }
            }
        });
    }

    armarFormulario() {
        this.nuevoFormulario.data = this.formulario;
        this.person.birthPlace.country = this.countryBirth;
        this.person.civilStatus = this.estadoCivil;
        this.person.birthDate = this.fechaNacimiento;
        this.person.document.expeditionPlace.country = this.expeditionPlaceCountry;
        this.person.placeAddressResidence.place.country = this.placeAddressResidenceCountry;
        this.nuevoFormulario.data.person = this.person;
        this.nuevoFormulario.data.spouse = this.spouse;
        this.nuevoFormulario.data.person.nationalities = this.listaPaises;
        let paso = 1;
        if (paso <= this.step) {
            paso = this.step;
        } else {
            this.step = paso;
        }
        this.nuevoFormulario.data.step = this.step;
    }

    seleccionaEstadoCivil(estadoCivil) {
        if (estadoCivil.id === 2 || estadoCivil.id === 3) {
            this.mostrarConyugue = 1;
        } else {
            this.mostrarConyugue = 0;
        }
    }

    agregarNacionalidad() {
        this.mostrarNacionalidades = true;
        this.validateInput(this.userFrm, 'infoBasica');
    }

    validacionSection(form: NgForm, listSections: Array<String>) {
        this.countContact = 0;
        this.listInputsContac = [];
        this.countInfo = 0;
        this.listInputsInfo = [];
        this.countResidencia = 0;
        this.listInputsResidencia = [];
        if (this.person.civilStatus && this.person.civilStatus !== null) {
            if (this.person.civilStatus.id === 2 || this.person.civilStatus.id === 3) {
                this.mostrarConyugue = 1;
            } else {
                this.mostrarConyugue = 0;
            }
        } else {
            this.mostrarConyugue = 0;
        }
        const controls = form.controls;
        Object.keys(controls).forEach(input => {
            const nameInput = input.split('_');
            listSections.forEach(section => {
                if (nameInput[1] === section) {
                    switch (section) {
                        case 'contacto':
                            this.listInputsContac.push(input);
                            if (form.controls[input].value !== '') {
                                this.countContact++;
                            }
                            break;
                        case 'infoBasica':
                            if (this.estadoCivil && this.estadoCivil !== null) {
                                if (this.estadoCivil.id === 2 || this.estadoCivil.id === 3) {
                                    this.mostrarConyugue = 1;
                                } else {
                                    this.mostrarConyugue = 0;
                                }
                            } else {
                                this.mostrarConyugue = 0;
                            }
                            this.listInputsInfo.push(input);
                            if (form.controls[input].value !== '' && form.controls[input].status !== 'INVALID') {
                                if (form.controls[input].value !== 0) {
                                    if (form.controls[input].value !== null) {
                                        if (form.controls[input].value !== undefined) {
                                            this.countInfo++;
                                        }
                                    }
                                }
                            }
                            break;
                        case 'residencia':
                            this.listInputsResidencia.push(input);
                            if (form.controls[input].value !== '') {
                                if (form.controls[input].value !== 0) {
                                    if (form.controls[input].value !== null) {
                                        this.countResidencia++;
                                    }
                                }
                            }
                            break;
                        default:
                            break;
                    }
                }
                if (this.countContact === this.listInputsContac.length) {
                    this.bndCamposContact = true;
                } else {
                    this.bndCamposContact = false;
                }
                if (this.countInfo === this.listInputsInfo.length) {
                    this.bndCamposInfo = true;
                } else {
                    this.bndCamposInfo = false;
                }
                if (this.countResidencia === this.listInputsResidencia.length) {
                    this.bndCamposResidencia = true;
                } else {
                    this.bndCamposResidencia = false;
                }
            });
        });
    }

    validateInput(form: NgForm, key) {
        if (this.estadoCivil && this.estadoCivil !== null) {
            if (this.estadoCivil.id === 2 || this.estadoCivil.id === 3) {
                this.mostrarConyugue = 1;
            } else {
                this.mostrarConyugue = 0;
            }
        } else {
            this.mostrarConyugue = 0;
        }
        setTimeout(() => {
            switch (key) {
                case 'contacto':
                    this.countContact = 0;
                    this.listInputsContac = [];
                    break;
                case 'infoBasica':
                    this.countInfo = 0;
                    this.listInputsInfo = [];
                    break;
                case 'residencia':
                    this.countResidencia = 0;
                    this.listInputsResidencia = [];
                    break;
                default:
                    break;
            }

            const controls = form.controls;
            Object.keys(controls).forEach(input => {
                const nameInput = input.split('_');
                if (nameInput[1] === key) {
                    switch (key) {
                        case 'contacto':
                            this.listInputsContac.push(input);
                            if (form.controls[input].value !== '') {
                                this.countContact++;
                            }
                            break;
                        case 'infoBasica':
                            this.listInputsInfo.push(input);
                            if (form.controls[input].value !== '' && form.controls[input].status !== 'INVALID') {
                                if (form.controls[input].value !== 0) {
                                    if (form.controls[input].value !== null) {
                                        if (form.controls[input].value !== undefined) {
                                            this.countInfo++;
                                        }
                                    }
                                }
                            }
                            break;
                        case 'residencia':
                            this.listInputsResidencia.push(input);
                            if (form.controls[input].value !== '') {
                                if (form.controls[input].value !== 0) {
                                    if (form.controls[input].value !== null) {
                                        this.countResidencia++;
                                    }
                                }
                            }
                            break;
                        default:
                            break;
                    }
                }
                if (this.countContact === this.listInputsContac.length) {
                    this.bndCamposContact = true;
                } else {
                    this.bndCamposContact = false;
                }
                if (this.countInfo === this.listInputsInfo.length) {
                    this.bndCamposInfo = true;
                } else {
                    this.bndCamposInfo = false;
                }
                if (this.countResidencia === this.listInputsResidencia.length) {
                    this.bndCamposResidencia = true;
                } else {
                    this.bndCamposResidencia = false;
                }
            });
        }, 1000);
    }

    armarNacionalidades(event) {
        //console.log(event);
        this.validateInput(this.userFrm, 'infoBasica');
        this.listaPaises = event;
        //console.log("Lista de paises", this.listaPaises);

    }


}
