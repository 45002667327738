export const TOKE_NAME = 'access_token';
export const TOKE_NAME_OAUTH = 'access_token_oauth';
export const CURRENT_FORM_OAUTH = 'current_form_oauth';
export const TOKEN_ID = 'bGliZXJ0eTIwMTk=';
export const USER_SESSION = 'userdata';
export const PATH_PORTAL = 'access_menus';
export const USER_MENUS = 'user_menu';
export const CODE = 'code';
export const CLIENT_ID = 'b5723e8c7bf6f6cc57a781f87c7e0dae';
export const GRAN_TYPE = 'client_credentials';
// export const URL_GET_TOKEN = 'https://login.microsoftonline.com/LibertyMutual.onmicrosoft.com/oauth2/v2.0/token';
// export const URL_LOGIN = 'https://login.microsoftonline.com/08a83339-90e7-49bf-9075-957ccd561bf1/oauth2/authorize?client_id=a428399d-63c2-4220-9576-716492e3db5f&response_type=code&response_mode=query&resource_id=http://graph.microsoft.com/&redirect_uri=http://localhost:4200/login';
// export const URL_LOGOUT = 'https://login.microsoftonline.com/LibertyMutual.onmicrosoft.com/oauth2/v2.0/logout?post_logout_redirect_uri=http://localhost:4200';

// export const URL_GET_TOKEN = 'https://login.microsoftonline.com/LibertyMutual.onmicrosoft.com/oauth2/v2.0/token';
// export const URL_LOGIN = 'https://login.microsoftonline.com/08a83339-90e7-49bf-9075-957ccd561bf1/oauth2/authorize?client_id=a428399d-63c2-4220-9576-716492e3db5f&response_type=code&response_mode=query&resource_id=https://graph.microsoft.com/&redirect_uri=https://intermediarios-dev.liberty.ec/login';
// export const URL_LOGOUT = 'https://login.microsoftonline.com/LibertyMutual.onmicrosoft.com/oauth2/v2.0/logout?post_logout_redirect_uri=https://intermediarios-dev.liberty.ec';

export class Constantes {
    // Roles
    user_admin = 'rol_admin';
    rolAdminIntermediario = 'admin_intermediario';
    rolAdminIntermediarioPiloto = 'admin_intermediario_piloto';
    rolConsultaIntermediario =  'consulta_intermediario';
    // gestor
    rolConsultaLibertyGeneral = 'consulta_libertygeneral';
    // broker
    rolOperativoIntermediario = 'operativo_intermediario';
    rolSuperAdminLiberty = 'super_adminliberty';

    // Reglas
    // Polizas
    gestionarPoliza = 'poliza:btn:gestionar';
    verFactura = 'poliza:link:verFactura';
    verPoliza = 'poliza:link:verPoliza';
    aceptarPoliza = 'poliza:btn:aceptarPoliza';
    rechazarPoliza = 'poliza:btn:rechazarPoliza';
    observacion = 'poliza:btn:enviarObservacion';


    // gestion Formulario
    gestionForm = 'gestionForm:btn:acciones';
    verFormulario = 'gestionForm:btn:verFormulario';
    verFormPadre = 'gestionForm:btn:verFormPadre';

    //Negocios
    verAutos = 'negocios:btn:autos';
    seeActions = 'renovations:btn:actions';
    verHogar = 'negocios:btn:hogar';
    verSearchPortal = 'negocios:btn:searchPortal';
}
