//angular
import { Component, OnInit } from '@angular/core';
//liberty-properties
import { Properties } from '../../general/properties';
//liberty-components
import {
    SelectItem
} from 'primeng/primeng';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/components/common/messageservice';
import { FormsService } from '../../../_services';
import { CatalogService } from 'src/app/_services/api_v1/catalogs.service';
import { DocumentsService } from 'src/app/_services/api_v1/documents.service';

@Component({
    selector: 'cambiarNumero',
    templateUrl: './cambiarNumero.html',
    styleUrls: ['./cambiarNumero.css']
})

export class CambiarNumeroComponent implements OnInit {

    properties: Properties = new Properties();
    tituloCambiarNumero: string = 'Proceso de Actualización número de teléfono';
    subTituloCambiarNumero: string = 'Ingresa tu número y tipo de Indentificación';
    tituloActualizoNumero: string = 'Actualice su número telefónico';
    subTituloActualizaNumero: string = 'Ingrese su nuevo número';
    formFormaSubmitted = false;

    // Generales
    pais: any;
    idFormulario: any;

    // Catalogos
    tipoDocumentoSelectItem: SelectItem[] = [{ label: this.properties.labelSelecioneCombo, value: null }];
    tipoDocumento: any;
    // Banderas
    bandera: number = 0;

    // Modelos
    tipoDocumentoAux: any;
    numeroDocumentoAux: any;
    numeroDocument: number = null;
    numeroCelular: number = null;
    formulario: any;
    nuevoFormulario: any;

    // Tipo Persona
    entityType: any;
    origen: number;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private messageService: MessageService,
        private catalogService: CatalogService,
        public formService: FormsService,
        private _documentsService: DocumentsService
    ) { }

    ngOnInit(): void {
        this.idFormulario = this.route.snapshot.paramMap.get('idFormulario');
        this.pais = this.route.snapshot.paramMap.get('idCountry');
        this.origen = this.pais == 'CO' ? 1 : 0;
        this.cargarTipoDocumento(this.pais);
        this.getLinkingForm();
    }

    cargarTipoDocumento(pais) {
        this.catalogService.getCatalogsBasic('documentType', pais)
            .subscribe((catalogos: any) => {
                catalogos.forEach(element => {
                    this.tipoDocumentoSelectItem.push({ label: element.description, value: element.id })
                });
            },
                (err: any) => console.log(err),
                () => {
                });
    }


    getLinkingForm() {
        this._documentsService.events(this.idFormulario, this.pais, 'GET_PERSON_URL').subscribe((resp: any) => {
            this.formulario = resp.data;
            if (resp.data.personType == "N") {
                this.tipoDocumentoAux = this.formulario.person.document.type.id;
                this.numeroDocumentoAux = this.formulario.person.document.documentNumber;
            } else {
                this.tipoDocumentoAux = this.formulario.company.document.type.id;
                this.numeroDocumentoAux = this.formulario.company.document.documentNumber;
            }

        },
            (err: any) => {
                console.log('Error', err);
            });

    }


    enviar() {
        if (this.tipoDocumento == this.tipoDocumentoAux && this.numeroDocument == this.numeroDocumentoAux) {
            this.bandera = 1;
        } else {
            this.messageService.add({
                severity: 'error', summary: '',
                detail: '¡Su tipo o número de documento no corresponde al registrado!'
            });
        }

    }

    enviarSMS() {
        this.actualizarNumero();
    }

    irAcceso() {
        this.router.navigate(['/formulario/acceso/', this.idFormulario, this.pais]);
        this.messageService.add({ severity: 'success', summary: '', detail: '¡Se actualizó correctamente!' });
    }

    actualizarNumero() {
        this.armarFormulario()
        this._documentsService.events(this.idFormulario, this.pais, 'CHANGE_CELLPHONE_NUMBER', this.nuevoFormulario).subscribe((resp: any) => {
            this.irAcceso();
        },
            (err: any) => {
                this.messageService.add({ severity: 'error', summary: '', detail: '¡No se actualizó correctamente su número!' });
            });
    }

    armarFormulario() {

        this.nuevoFormulario = {
            "cellPhoneNumber": this.numeroCelular + ""
        }

    }


}