/**
 * intl-latam-ec-portal-documents
 * No description
 *
 * OpenAPI spec version: 1.0.0
 * Contact: you@your-company.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { CatalogModel } from './catalog';
import { CommissionModel } from './commission';
import { PersonPolicyModel } from './personPolicy';
import { PolicyElectronicBillModel } from './policyElectronicBill';

/**
 * Póliza
 */
export class PolicyModel { 
    contractor: PersonPolicyModel;
    /**
     * Número de póliza.
     */
    policyNumber: string;
    branchOffice: CatalogModel;
    branch: CatalogModel;
    /**
     * Prima.
     */
    premium: number;
    movementType: CatalogModel;
    /**
     * Fecha inicio de vigencia de la póliza.
     */
    startEffectiveDate: string;
    /**
     * Fecha fin de vigencia de la póliza.
     */
    endEffectiveDate: Date;
    /**
     * Comisiones de la póliza.
     */
    commissions: Array<CommissionModel>;
    /**
     * Datos para obtener el pdf de la póliza.
     */
    files?: Array<string>;
    /**
     * IDPV
     */
    id: string;
    electronicBill?: PolicyElectronicBillModel;
    /**
     * Número de endoso.
     */
    endorsementNumber: string;

    constructor(){
        this.contractor = new  PersonPolicyModel();
        this.policyNumber = null;
        this.branchOffice = new CatalogModel();
        this.branch = new CatalogModel();
        this.premium = null;
        this.movementType = new CatalogModel();
        this.startEffectiveDate = null;
        this.endEffectiveDate = new Date();
        this.commissions= Array<CommissionModel>();
        this.files = Array<string>();
        this.id = null;
        this.electronicBill = new PolicyElectronicBillModel();
        this.endorsementNumber = null;
    }
}
