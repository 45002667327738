import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AutoInspectionsComponent } from './autoInspections.component';
import { TableModule } from 'primeng/table';
import { PaginatorModule } from 'primeng/paginator';
import { ButtonModule } from 'primeng/button';
import { DropdownModule } from 'primeng/dropdown';
import { MultiSelectModule } from 'primeng/multiselect';
import { CalendarModule } from 'primeng/calendar';
import { InputTextModule } from 'primeng/inputtext';
import { ChipsModule } from 'primeng/chips';
import { StepsModule } from 'primeng/steps';
import { AutoCompleteModule, DialogModule, InputMaskModule, InputTextareaModule, OverlayPanelModule } from 'primeng/primeng';
import { HttpModule } from '@angular/http';
import {RadioButtonModule} from 'primeng/radiobutton';
import {SelectButtonModule} from 'primeng/selectbutton';
import { MaskDirective, } from './mask.directive';





@NgModule({
  imports: [
    CommonModule,
    TableModule,
    PaginatorModule,
    ButtonModule,
    DropdownModule,
    MultiSelectModule,
    CalendarModule, 
    ChipsModule,
    StepsModule,
    DialogModule,
    OverlayPanelModule,
    HttpModule,
    InputTextModule,
    InputTextareaModule,
    RadioButtonModule,
    SelectButtonModule,
    AutoCompleteModule,
    InputMaskModule
  ],
  declarations: [AutoInspectionsComponent, MaskDirective],
  providers: [
  ]
})
export class AutoInspectionsModule { }
