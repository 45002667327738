import { AfterViewInit, Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import * as FileSaver from 'file-saver';
import { Calendar, Dialog, MenuItem, MultiSelect, OverlayPanel } from 'primeng/primeng';
import { NotificationService } from 'src/app/notification.service';
import { Renovation, RenovationBody } from 'src/app/_model/documents/renovations';
import { CatalogService } from 'src/app/_services/api_v1/catalogs.service';
import { RenovationsService } from 'src/app/_services/api_v1/renovations.service';
import { BreadcrumbService } from '../../breadcrumb.service';
import { Properties } from '../general/properties';
import { Paginator } from 'primeng/paginator';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CriptoService } from 'src/app/_handler/cripto.service';
import 'moment/locale/es';
import { DatePipe } from '@angular/common';
import { Constantes } from 'src/app/_shared/var.constant';
import { RulesService } from 'src/app/_services';
import { ApiResponse } from 'src/app/_model/policy/apiResponse';
moment.locale('es');

@Component({
  selector: 'app-renovations',
  templateUrl: './renovations.component.html',
  styleUrls: ['./renovations.component.css'],
})
export class RenovationsComponent implements OnInit, AfterViewInit {
  @ViewChild('expiredSelect') expiredSelected: MultiSelect;
  @ViewChild('mainPaginator') mainPaginator: Paginator;
  @ViewChild('calendar') calendar: Calendar;
  @ViewChild('mdlErrGenerateDoc') mdlErrGenerateDoc: Dialog;
  @ViewChild('mdlSendMailError') mdlSendMailError: Dialog;
  @ViewChild('mdlSendMail') mdlSendMail: Dialog;
  @ViewChild('mdlSendMailSuccess') mdlSendMailSuccess: Dialog;

  properties: Properties = new Properties();
  calendarEs = new Properties().calendarEs;
  totalRecords = 0;
  totalRecordsShow = 0;
  limit = 10;
  page = 1;
  branch = '';
  status = '';
  daysCase = '';
  displayRenD = false;
  rangeDates: Date[];
  rangeDatesBack: Date[];
  calendarVigItems: MenuItem[];
  policyNumber = '';
  policyNumberCurrent = '';
  cols = [];
  values = [];
  notifyState = 0;
  renovations: Renovation[] = [];
  optionsExpired = [];
  optionsStatus = [];
  expiredList = [];
  branches = [{
    label: 'None', value: null
  }];
  statusList = [{
    label: 'None', value: null
  }];
  filtrosTabla = {};
  calendarVigState = 0;
  policyEndFh = 0;
  loadingNotify = true;
  expiredFilter = false;
  currentDate = new Date().getTime();
  items: MenuItem[];
  errorSrvCoverage: string;
  titleMdlError = "";
  titleSuccesAction = ""
  msgMdlError = "";
  titleSendMail = "";
  msgSendMail = "";
  showErr = false;
  showErrSendMail = false;
  showSendMailMdl = false;
  titleMdlErrorCoverage = "No se pudo generar el documento";
  titleMdlErrorPolicy = "No se pudo generar el documento de la p\u00F3liza"
  titleMdlErrorSend = "No se pudo enviar el mail"
  titleMdlSendMail = "Ingresa el email para para enviar el PDF de emisi\u00F3n de p\u00F3liza"
  titleMdlSendMailSuccess = "Tu email ha sido enviado con \u00E9xito"
  titleMdlPrinDocSuccess = "El documento PDF fue descargado exitosamente a tu computador"
  msgMdlErrorCoverage = "No podemos generar el documento de Cobertura Provisional en este momento debido a problemas t\u00E9cnicos. Por favor int\u00E9ntalo nuevamente haciendo clic en Reintentar. Si el error persiste, dir\u00EDgete a Consulta de Renovaciones, espera unos minutos y vuelve a intentar.";
  msgMdlErrorCoverageRepeat = "No podemos generar el documento de Cobertura Provisional en este momento debido a problemas t\u00E9cnicos. Por favor reporta la falla a la mesa de ayuda de Liberty, escribiendo al correo: ";
  msgMdlErrorPolicy = "No podemos generar el documento en este momento debido a problemas t\u00E9cnicos. Por favor int\u00E9ntalo nuevamente haciendo clic en Reintentar. Si el error persiste, dir\u00EDgete a Consulta de Renovaciones, espera unos minutos y vuelve a intentar.";
  msgMdlErrorPolicyRepeat = "No podemos generar el documento en este momento debido a problemas t\u00E9cnicos. Por favor reporta la falla a la mesa de ayuda de Liberty, escribiendo al correo: ";
  msgMdlErrorSendMail = "No se pudo enviar el mail en este momento debido a problemas t\u00E9cnicos. Por favor int\u00E9ntalo nuevamente haciendo clic en Reintentar. Si el error persiste, dir\u00EDgete a Consulta de Renovaciones, espera unos minutos y vuelve a intentar. "
  mesgMdlSendMail = "Puedes ingresar hasta 2 emails para enviar."
  isSuccessAction: boolean = false;
  attemptsAllowed = 3;
  objRenovationErr: Renovation;
  objRenovation: Renovation;
  form: FormGroup;
  touchedFields: boolean[] = [];
  recipients: string[] = [];
  btnAction = "";
  actionSuccess = "";
  constantes = new Constantes();
  seeActions = false;

  constructor(
    private breadcrumbService: BreadcrumbService,
    private renovationsService: RenovationsService,
    private catalogService: CatalogService,
    public notify: NotificationService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder, private el: ElementRef,
    private cryptoService: CriptoService,
    private datePipe: DatePipe,
    public rulesServices: RulesService
  ) { this.createForm(); }

  obtenerFechaHoraActual() {
    // Obtén la fecha y hora actual en GMT-5
    const fechaHoraActual = moment().utcOffset('-05:00');
    // Formatea la fecha y hora según tus necesidades
    const formatoDeseado = 'DD/MMM/YYYY HH:mm';
    const fechaFormateada = fechaHoraActual.format(formatoDeseado);
    let formatoFinal = fechaFormateada.replace('.', '');
    return formatoFinal;
  }

  addDaysDate(date: string, daysSum: number): Date {
    const [year, month, day] = date.split('-');
    let dateFrm = new Date(parseInt(year), parseInt(month) - 1, parseInt(day));
    dateFrm.setDate(dateFrm.getDate() + daysSum);
    return dateFrm;
  }

  formatDate(date: string, add = true): string {
    return this.datePipe.transform(this.addDaysDate(date, add? 1: 0), 'dd/MM/yyyy');
  }

  validDateCoverage(effectiveDateEnd: string): boolean {
    let dateStratShow = this.addDaysDate(effectiveDateEnd, 1);
    const actualDate = new Date();
    actualDate.setHours(0, 0, 0, 0);
    let periodDateEnd = this.addDaysDate(effectiveDateEnd, 7);
    let result = false;
    if (actualDate >= dateStratShow && actualDate <= periodDateEnd) {
      result = true;
    }
    return result;
  }

  createForm() {
    this.form = this.formBuilder.group({
      inputs: this.formBuilder.array([
        this.createInput()
      ])
    });
  }

  createInput() {
    return this.formBuilder.group({
      value: ['', [Validators.required, Validators.pattern(/^[^\s]{1,46}@[^\s]{1,51}\.[a-zA-Z]{2,}$/)]]
    });
  }

  addInput() {
    const inputs = this.form.get('inputs') as FormArray;
    inputs.push(this.createInput());
    this.touchedFields.push(false);
  }

  removeInput(index: number) {
    const inputs = this.form.get('inputs') as FormArray;
    inputs.removeAt(index);
    this.touchedFields.splice(index, 1);
  }

  validForm(index: number) {
    this.touchedFields[index] = true;
    this.recipients[index] = (this.form.get('inputs') as FormArray).controls[index].get('value').value;
  }

  resetForm() {
    this.form.reset();
    this.touchedFields = [];
    this.recipients = [];
  }

  getValuePolicy() {
    let policyEnd = 0;
    const renTotal = this.notify.getListByCode('renTotal');
    if (renTotal) {
      // If exist notify and value
      policyEnd = renTotal.value;
    }
    this.policyEndFh = policyEnd;
    return policyEnd
  }

  ngAfterViewInit(): void {
    this.getRenovationsList();
  }

  getControls() {
    return (this.form.get('inputs') as FormArray).controls;
  }

  async ngOnInit() {
    this.objRenovationErr = Object.assign({});
    this.securitySeeActions();
    await this.activatedRoute.queryParams.subscribe(params => {
      if (params.notify) {
        this.notifyState++;
      }
    });
    if (this.notifyState > 0) {
      await this.router.navigate(
        ['.'],
        {
          relativeTo: this.activatedRoute,
          queryParams: {},
        }
      )
    }

    this.chargeBreadCrum();
    this.getBranches();
    this.optionsExpired = [
      { label: '+45 d\u00EDas', value: 1, color: '#1e1045', bkColor: '#CFCCD6' },
      { label: '26-45 d\u00EDas', value: 2, color: '#046074', bkColor: '#CADBDE' },
      { label: '16-25 d\u00EDas', value: 3, color: '#28a3af', bkColor: '#D0E6E8' },
      { label: '6-15 d\u00EDas', value: 4, color: '#ee7000', bkColor: '#F3DDC8' },
      { label: '-5 d\u00EDas', value: 5, color: '#e92243', bkColor: '#F2CFD5' },
      { label: 'Vencidas', value: 6, color: '#e92243', bkColor: '#F2CFD5' },
    ];
    this.getStatusPolicy();
    this.optionsStatus = [
      { label: 'POR RENOVAR', value: 1, color: '#dec75e', bkColor: '#EEA600' },
      { label: 'RENOVADA', value: 2, color: '#046074', bkColor: '#03AC63' },
      { label: 'CANCELADA', value: 3, color: '#9595a9', bkColor: '#565656' },
      { label: 'VENCIDA', value: 4, color: '#e92243', bkColor: '#F3DDC8' },
      { label: 'FV PENDIENTE', value: 5, color: '#ee7000', bkColor: '#E76400' },
      { label: '', value: 5, color: '', bkColor: '' }
    ];
    this.cols = [
      { field: 'policyNumber', header: 'No. p\u00F3liza', width: '9%', align: 'center' },
      { field: 'policyType', header: 'Descripci\u00F3n / Segmento', width: '8%' },
      { field: 'insured', header: 'Asegurado', width: '22%' },
      { field: 'premium', header: 'Prima $USD', width: '9%', align: 'center' },
      { field: 'effectiveDateStart', header: 'Vigencia', width: '9%', align: 'center' },
      { field: 'currentRate', header: 'Tasa actual %', width: '9%', align: 'center' },
      { field: 'proposedRate', header: 'Tasa renovación %', width: '9%', align: 'center' },
      { field: 'status', header: 'Estado', width: '9%', align: 'center' },
      { field: 'x', header: 'Acci\u00F3n', width: '9%', align: 'center' }
    ];
    this.calendarVigItems = [
      { label: 'Desde' },
      { label: 'Hasta' },
    ];
  }

  securitySeeActions() {
    const currentUser = JSON.parse(sessionStorage.getItem('access_token'));
    const viewAuto = {
      rolesId: currentUser.user.roles,
      rule: this.constantes.seeActions
    };
    this.rulesServices.intlLatamEcFormSecureVerifyComponentAccess(viewAuto).subscribe((resp: ApiResponse) => {
      const auxcode = parseInt(resp.code + '', 10);
      if (auxcode === 200) {
        this.seeActions = true;
      }
    });
  }

  getUserContext(): String {
    const infoUser = JSON.parse(sessionStorage.getItem('access_token'));
    const currentUser = infoUser.user.brokerName;
    return currentUser == null ? "" : currentUser;
  }


  openPrintDocument(item: any) {
    item.attemptsError = 0;
    this.printDocument(item);
  }

  printDocument(item: any) {
    const actualDate = this.obtenerFechaHoraActual();
    this.actionSuccess = "PRINT"
    if (item.status == "POR RENOVAR") {
      const objCobProv = {
        "policyNumber": parseInt(item.policyNumber, 10),
        "brokerName": this.getUserContext(),
        "insuredName": item.insured == null ? "" : item.insured.toString(),
        "annex": item.annexEndorsementNumber == null ? "" : item.annexEndorsementNumber.toString(),
        "city": item.city == null ? "" : item.city.toString(),
        "model": item.model == null ? "" : item.model.toString(),
        "yearManufacturing": item.yearManufacturing == null ? "" : item.yearManufacturing.toString(),
        "dateLimit": item.effectiveDateEnd == null ? "" : this.formatDate(item.effectiveDateEnd.toString()),
        "date": this.formatDate(new Date().toISOString(), false),
        "plate": item.branchCode == null ? "" : item.branchCode.toString()
      }
      this.renovationsService.getRenovationCertificate(objCobProv).subscribe((res: any) => {
        this.isSuccessAction = true;
        this.btnAction = "OK";
        this.titleSuccesAction = this.titleMdlPrinDocSuccess;
        this.downloadReport(res, `Cert. cobertura provisional Póliza (${item.policyNumber}).pdf`);
      }, (err) => {
        console.log(err)
        const msgTmpError = `1-${this.getCodeError(err)}-PDF Cobertura Provisional ${actualDate.replace(/(\b[a-z](?!\s))/g, (letra) => letra.toUpperCase())}`
        this.validateActionModalError(item, msgTmpError);
      });
    } else if (item.status == "RENOVADA") {
      const objPolRen = {
        "cod_ramo": parseInt(item.brachCode, 10),    
        "cod_suc": parseInt(item.sucursal, 10),
        "nro_pol": parseInt(item.policyNumber, 10),
        "nro_endo": parseInt(item.endorsementNumberRenov, 10)
      }
      this.renovationsService.getRenovationPDF(objPolRen).subscribe((res: any) => {
        this.isSuccessAction = true;
        this.btnAction = "OK";
        this.titleSuccesAction = this.titleMdlPrinDocSuccess;
        this.downloadReport(res, `Renovación Póliza (${item.policyNumber}).pdf`);
      }, (err) => {
        const msgTmpError = `1-${this.getCodeError(err)}-PDF Póliza ${actualDate.replace(/(\b[a-z](?!\s))/g, (letra) => letra.toUpperCase())}`
        this.validateActionModalError(item, msgTmpError);
      });
    }
  }

  getCodeError(objError: any): string {
    let codError = "";
    const regex = /\[([^\]]+)\]/;
    if (!objError.error.errorMessage) {
      codError = "503";
    } else {
      const resultado = objError.error.errorMessage.match(regex);
      if (resultado && resultado.length > 1) {
        codError = resultado[1];
        console.log(codError);
      } else {
        console.log("No se encontró cod de error");
      }
    }
    return codError;
  }

  validateActionModalError(register: any, msgErr: string) {
    this.objRenovationErr = Object.assign({});
    this.renovations.forEach(curr => {
      curr.showOptions = false;
      if (curr.policyNumber === register.policyNumber && curr.annexEndorsementNumber === register.annexEndorsementNumber) {
        if (register.status == "POR RENOVAR") {
          this.titleMdlError = this.titleMdlErrorCoverage;
          this.msgMdlError = this.msgMdlErrorCoverage;
          if (register.attemptsError >= 2) {
            this.msgMdlError = this.msgMdlErrorCoverageRepeat;
          }
        } else if (register.status == "RENOVADA") {
          this.titleMdlError = this.titleMdlErrorPolicy;
          this.msgMdlError = this.msgMdlErrorPolicy;
          if (register.attemptsError >= 2) {
            this.msgMdlError = this.msgMdlErrorPolicyRepeat;
          }
        }
        curr.attemptsError++;
        this.objRenovationErr = curr;
      }
    });
    this.showErr = true;
    this.errorSrvCoverage = msgErr;
  }


  openSendMail(item: any) {
    this.objRenovation = Object.assign({});
    this.resetForm();
    this.removeInput(1);
    this.titleSendMail = this.titleMdlSendMail;
    this.msgSendMail = this.mesgMdlSendMail;
    this.showSendMailMdl = true;
    this.objRenovation = item;
    this.objRenovation.attemptsErrorSendMail = 0;
  }


  getURLBtnRenovar(item: any) {
    const sucursal = item.sucursal.toString();
    let params: any = {
      policyNumber: this.cryptoService.set(item.policyNumber),
      codBranch: this.cryptoService.set(sucursal)
    };
    const nuevaVentana = window.open(this.renovationsService.callURLBtnRenovar(params), '_blank');
    if (nuevaVentana) {
      nuevaVentana.focus();
    } else {
      console.error('Error al abrir la nueva ventana.');
    }
  }

  getURLBtnConsultar(item: any) {
    const sucursal = item.sucursal.toString();
    let params: any = {
      policyNumber: this.cryptoService.set(item.policyNumber),
      codBranch: this.cryptoService.set(sucursal)
    };
    const nuevaVentana = window.open(this.renovationsService.callURLBtnConsultar(params), '_blank');
    if (nuevaVentana) {
      nuevaVentana.focus();
    } else {
      console.error('Error al abrir la nueva ventana.');
    }
  }

  sendMailPDFCertificate(item: any) {
    this.titleSuccesAction = "";
    if (this.form.valid) {
      const objSendMail = {
        "branchCode": item.brachCode == null ? "" : item.brachCode.toString(),
        "branchOfficeCode": item.sucursal == null ? "" : item.sucursal.toString(),
        "policyNumber": item.policyNumber,
        "insured": item.insured == null ? "" : item.insured.toString(),
        "effectiveDateStart": item.renovationDateStart == null ? "" : item.renovationDateStart.toString(),
        "effectiveDateEnd": item.renovationDateEnd == null ? "" : item.renovationDateEnd.toString(),
        "brokerName": this.getUserContext(),
        "annexEndorsementNumber": item.endorsementNumberRenov == null ? "" : item.endorsementNumberRenov.toString(),
        "recipients": this.recipients
      }
      this.showErrSendMail = false;
      this.renovationsService.sendPDFRenovationCertificate(objSendMail).subscribe((res: any) => {
        this.showSendMailMdl = false;
        this.showErrSendMail = false;
        this.isSuccessAction = true;
        this.btnAction = "Aceptar";
        this.actionSuccess = "MAIL"
        this.titleSuccesAction = this.titleMdlSendMailSuccess;
      }, (err) => {
        this.sendMailError(item);
      })
    } else {
      this.touchedFields = Array(this.touchedFields.length).fill(true);
    }
  }

  sendMailError(register: any) {
    this.showSendMailMdl = false;
    this.objRenovationErr = Object.assign({});
    this.renovations.forEach(curr => {
      curr.showOptions = false;
      if (curr.policyNumber === register.policyNumber && curr.annexEndorsementNumber === register.annexEndorsementNumber) {
        if (register.status == "RENOVADA") {
          this.titleMdlError = this.titleMdlErrorSend;
          this.msgMdlError = this.msgMdlErrorSendMail;
        }
        curr.attemptsErrorSendMail++;
        this.objRenovationErr = curr;
      }
    });
    this.showErrSendMail = true;
  }

  private menuActivoItem: any;

  showOptions(item: any): void {
    event.stopPropagation();
    if (this.menuActivoItem && this.menuActivoItem !== item) {
      this.menuActivoItem.showOptions = false;
    }

    item.showOptions = !item.showOptions;
    this.menuActivoItem = item;
  }

  @HostListener('document:click', ['$event'])
  clickOutside(event: Event): void {
    const clickedInside = this.el.nativeElement.contains(event.target);
    if (this.menuActivoItem) {
      this.menuActivoItem.showOptions = false;
      this.menuActivoItem = null;
    }
  }

  convertPercentage(value: number) {
    return (value * 100).toFixed(2);
  }

  checkPlateRamV(plate, ramv: string) {
    if (!plate || plate == '') {
      if (ramv && ramv != '') {
        return ramv.replace(/.(?=.{4})/g, '\u2022');
      }
      return '-'
    }
    return plate
  }

  capitalizeName(name: string) {
    return name.toLocaleLowerCase().replace(/\b(\w)/g, s => s.toUpperCase());
  }

  filterExpiredDays() {
    let daysCase = '';
    const expiredList = this.expiredList.sort(function (a, b) {
      if (a.value < b.value) return -1;
      if (a.value > b.value) return 1;
      return 0;
    });
    if (expiredList.length > 0) {
      expiredList.forEach((curr, index) => {
        daysCase += `${curr.value}`;
        if (expiredList.length !== index + 1) {
          daysCase += ',';
        }
      })
    }
    this.daysCase = daysCase;
    this.page = 1;
  }



  getBranches() {
    this.catalogService.getCatalogs(this.properties.ramosRenovations, 'EC')
      .subscribe((resp: any) => {
        if (resp) {
          resp.forEach(element => {
            this.branches.push({ label: element.description, value: element.code });
          });
        }
      },
        (err: any) => console.log(err),
        () => {
        });
  }

  getStatusPolicy() {
    this.statusList = [{
      label: 'None', value: null
    },
    {
      label: 'Renovada', value: 'RENOVADA'
    },
    {
      label: 'Cancelada', value: 'CANCELADA'
    },
    {
      label: 'Vencida', value: 'VENCIDA'
    },
    {
      label: 'FV Pendiente', value: 'FV PENDIENTE'
    },
    {
      label: 'Por Renovar', value: 'POR RENOVAR'
    }]
    // this.catalogService.getCatalogs(this.properties.ramosRenovations, 'EC')
    //   .subscribe((resp: any) => {
    //     if (resp) {
    //       resp.forEach(element => {
    //         this.statusList.push({ label: element.description, value: element.code });
    //       });
    //     }
    //   },
    //     (err: any) => console.log(err),
    //     () => {
    //     });
  }

  downloadReport(base64, reportName: string) {
    const type = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    const blob = this.b64toBlob(base64, type);
    FileSaver.saveAs(blob, reportName);

  }

  b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }

  onBlurCalendar() {
    this.rangeDatesBack = this.rangeDates;
  }

  changeCalendar(event) {
    if (event && event[1]) {
      this.calendarVigState = 1;
    } else {
      this.calendarVigState = 0;
    }
  }

  setCalendar() {
    this.page = 1;
    this.calendar.overlayVisible = false;
  }

  closeCalendar() {
    this.calendar.overlayVisible = false;
    this.rangeDates = this.rangeDatesBack;
  }

  removeExpiredItem(index: number) {
    this.expiredList.splice(index, 1);
    this.expiredSelected.ngOnInit();
    this.filterExpiredDays();
  }

  formatDateEc(date: string) {
    const dateN = moment(date);
    return `${('0' + dateN.date()).slice(-2)}/${this.properties.es.monthNamesShort[dateN.month()]}/${dateN.year()}`
  }

  formatDateISO(date: moment.Moment) {
    if (date) {
      return date.format('YYYYMMDD')
    }
  }

  getStatus(status: string) {
    let result;
    result = this.optionsStatus[5];
    if (status == 'POR RENOVAR') {
      result = this.optionsStatus[0];
    } else if (status == 'RENOVADA') {
      result = this.optionsStatus[1];
    } else if (status == 'CANCELADA') {
      result = this.optionsStatus[2];
    } else if (status == 'VENCIDA') {
      result = this.optionsStatus[3];
    } else if (status == 'FV PENDIENTE') {
      result = this.optionsStatus[4];
    }
    return result
  }

  changeBranch() {
    this.page = 1;
  }


  changeStatus() {
    this.page = 1;
  }

  crearFilters() {
    this.page = 1;
    this.notifyState = 0;
    this.policyNumberCurrent = '';
    this.policyNumber = '';
    this.expiredList = [];
    this.daysCase = '';
    this.branch = '';
    this.status = '';
    this.rangeDates = null;
    this.expiredFilter = false;
    this.getRenovationsList(null, true);
  }

  clickSearch() {
    this.page = 1;
    this.notifyState = 0;
    this.policyNumberCurrent = this.policyNumber;
    this.expiredFilter = false;
    this.getRenovationsList(null, true);
  }

  updatePage(event) {
    if (event.page + 1 != this.page) {
      this.policyNumber = this.policyNumberCurrent;
      this.page = event.page + 1;
      this.getRenovationsList();
    }
  }

  filterToExpired() {
    this.page = 1;
    this.notifyState = 1;
    this.policyNumberCurrent = '';
    this.policyNumber = '';
    this.expiredList = [];
    this.daysCase = '';
    this.branch = '';
    this.rangeDates = null;
    this.expiredFilter = true;
    this.getRenovationsList(null, true);
  }

  searchByPolicy() {
    this.page = 1;
    this.notifyState = 0;
    this.policyNumberCurrent = this.policyNumber;
    this.expiredFilter = false;
    this.getRenovationsList(null, true);
  }

  getRenovationsList(download = false, resetPage = false) {
    let notify;
    const limit = this.limit;
    const page = this.page || 1;
    const policy = this.policyNumberCurrent;
    const daysCase = this.daysCase;
    const branch = this.branch;
    const rangeDates = this.rangeDates;
    const startDate = rangeDates && rangeDates[0] ? this.formatDateISO(moment(rangeDates[0])) : '';
    let endDate = rangeDates && rangeDates[1] ? this.formatDateISO(moment(rangeDates[1])) || '' : '';
    const status = this.status;
    if (this.expiredFilter) {
      endDate = this.formatDateISO(moment().add('days', 7));
    }
    if (this.notifyState > 0) {
      notify = this.notifyState;
    }
    // this.renovations = [
    //   {
    //     "policyNumber": "588006",
    //     "branchName": "VEHICULOS",
    //     "policyType": "Individual",
    //     "insuredIdentificationNumber": "1717146780",
    //     "effectiveDateStart": "2023-01-16",
    //     "effectiveDateEnd": "2024-01-16",
    //     "insured": "  PARRALES MORALES",
    //     "premium": 1020,
    //     "insuredIdentificationType": "REGISTRO UNICO DE CONTRIBUYENTE",
    //     "currentRate": 0.06,
    //     "proposedRate": 0.069,
    //     "branchCode": "PBQ-1957",
    //     "ramv": "KMJWA37HABU291959",
    //     "status": "RENOVADA",
    //     "showOptions": false,
    //     "attemptsError": 0,
    //     "attemptsErrorSendMail": 0,
    //     "insuredIdentificationAbr": "",
    //     "sucursal": 8,
    //     "annexEndorsementNumber": "109230902",
    //     "city": "Quito",
    //     "model": "Ford 4",
    //     "yearManufacturing": "1998",
    //     "renewalApply": 1,
    //     "showCoverageOption": false

    //   },
    //   {
    //     "policyNumber": "588006",
    //     "branchName": "VEHICULOS",
    //     "policyType": "Individual",
    //     "insuredIdentificationNumber": "1717146780",
    //     "effectiveDateStart": "2023-02-02",
    //     "effectiveDateEnd": "2024-02-02",
    //     "insured": "  RONQUILLO VILLAMAR ",
    //     "premium": 534,
    //     "insuredIdentificationType": "REGISTRO UNICO DE CONTRIBUYENTE",
    //     "currentRate": 0.03,
    //     "proposedRate": 0.0309,
    //     "branchCode": "PDU-8374",
    //     "ramv": "9BWAL5BZ2NP028830",
    //     "status": "CANCELADA",
    //     "showOptions": false,
    //     "attemptsError": 0,
    //     "attemptsErrorSendMail": 0,
    //     "insuredIdentificationAbr": "",
    //     "sucursal": 8,
    //     "annexEndorsementNumber": "109230900",
    //     "city": "Quito",
    //     "model": "Ford 4",
    //     "yearManufacturing": "1998",
    //     "renewalApply": 1,
    //     "showCoverageOption": false

    //   },
    //   {
    //     "policyNumber": "588006",
    //     "branchName": "VEHICULOS",
    //     "policyType": "Individual",
    //     "insuredIdentificationNumber": "1717146780",
    //     "effectiveDateStart": "2024-02-23",
    //     "effectiveDateEnd": "2025-02-23",
    //     "insured": "  PASPUEL ",
    //     "premium": 925,
    //     "insuredIdentificationType": "REGISTRO UNICO DE CONTRIBUYENTE",
    //     "currentRate": 0.025,
    //     "proposedRate": 0.029,
    //     "branchCode": "GCB-5925",
    //     "ramv": "WBAJG1106KEJ06878",
    //     "status": "POR RENOVAR",
    //     "showOptions": false,
    //     "attemptsError": 0,
    //     "attemptsErrorSendMail": 0,
    //     "insuredIdentificationAbr": "",
    //     "sucursal": 8,
    //     "annexEndorsementNumber": "109230905",
    //     "city": "Quito",
    //     "model": "Ford 4",
    //     "yearManufacturing": "1998",
    //     "renewalApply": 1,
    //     "showCoverageOption": false

    //   },
    //   {
    //     "policyNumber": "584259",
    //     "branchName": "VEHICULOS",
    //     "policyType": "Individual",
    //     "insuredIdentificationNumber": "1717146780",
    //     "effectiveDateStart": "2023-01-03",
    //     "effectiveDateEnd": "2024-01-03",
    //     "insured": "  VASQUEZ  ",
    //     "premium": 375.44,
    //     "insuredIdentificationType": "REGISTRO UNICO DE CONTRIBUYENTE",
    //     "currentRate": 0.0309,
    //     "proposedRate": 0.0354,
    //     "branchCode": "PBM-4619",
    //     "ramv": "8LGJE5522AE000174",
    //     "status": "FV PENDIENTE",
    //     "showOptions": false,
    //     "attemptsError": 0,
    //     "attemptsErrorSendMail": 0,
    //     "insuredIdentificationAbr": "",
    //     "sucursal": 8,
    //     "annexEndorsementNumber": "109230901",
    //     "city": "Quito",
    //     "model": "Ford 4",
    //     "yearManufacturing": "1998",
    //     "renewalApply": 1,
    //     "showCoverageOption": false

    //   },
    //   {
    //     "policyNumber": "584061",
    //     "branchName": "VEHICULOS",
    //     "policyType": "Individual",
    //     "insuredIdentificationNumber": "1717567246",
    //     "effectiveDateStart": "2023-01-10",
    //     "effectiveDateEnd": "2024-01-10",
    //     "insured": "JOSE ANDRES CHURUCHUMBI MARCALLA EDWIN EMILIO  ",
    //     "premium": 917.69,
    //     "insuredIdentificationType": "PASAPORTE",
    //     "currentRate": 0.034,
    //     "proposedRate": 0.039,
    //     "branchCode": "GTA-948",
    //     "ramv": "LVAV2MAB3NC001390",
    //     "status": "VENCIDA",
    //     "showOptions": false,
    //     "attemptsError": 0,
    //     "attemptsErrorSendMail": 0,
    //     "insuredIdentificationAbr": "",
    //     "sucursal": 8,
    //     "annexEndorsementNumber": "109230901",
    //     "city": "Quito",
    //     "model": "Ford 4",
    //     "yearManufacturing": "1998",
    //     "renewalApply": 1,
    //     "showCoverageOption": false

    //   },
    //   {
    //     "policyNumber": "577838",
    //     "branchName": "VEHICULOS",
    //     "policyType": "Individual",
    //     "insuredIdentificationNumber": "0808032915",
    //     "effectiveDateStart": "2023-01-15",
    //     "effectiveDateEnd": "2024-01-15",
    //     "insured": "RODRIGO FERNANDO QUEZADA QUEZADA  ",
    //     "premium": 793.52,
    //     "insuredIdentificationType": "CEDULA DE IDENTIDAD",
    //     "currentRate": 0.035,
    //     "proposedRate": 0.0405,
    //     "branchCode": "GBO-9162",
    //     "ramv": "LVCB2NBA4MS220045",
    //     "status": "otr",
    //     "showOptions": false,
    //     "attemptsError": 0,
    //     "attemptsErrorSendMail": 0,
    //     "insuredIdentificationAbr": "",
    //     "sucursal": 8,
    //     "annexEndorsementNumber": "109230901",
    //     "city": "Quito",
    //     "model": "Ford 4",
    //     "yearManufacturing": "1998",
    //     "renewalApply": 1,
    //     "showCoverageOption": false

    //   },
    //   {
    //     "policyNumber": "577576",
    //     "branchName": "VEHICULOS",
    //     "policyType": "Individual",
    //     "insuredIdentificationNumber": "1717146780",
    //     "effectiveDateStart": "2023-01-28",
    //     "effectiveDateEnd": "2024-01-28",
    //     "insured": "  OJEDA TAPIA  ",
    //     "premium": 484.38,
    //     "insuredIdentificationType": "REGISTRO UNICO DE CONTRIBUYENTE",
    //     "currentRate": 0.026,
    //     "proposedRate": 0.03,
    //     "branchCode": "PDQ-9120",
    //     "ramv": "3N8CP5HD7ML460444",
    //     "status": "POR RENOVAR",
    //     "showOptions": false,
    //     "attemptsError": 0,
    //     "attemptsErrorSendMail": 0,
    //     "insuredIdentificationAbr": "",
    //     "sucursal": 8,
    //     "annexEndorsementNumber": "109230901",
    //     "city": "Quito",
    //     "model": "Ford 4",
    //     "yearManufacturing": "1998",
    //     "renewalApply": 1,
    //     "showCoverageOption": false

    //   },
    //   {
    //     "policyNumber": "577564",
    //     "branchName": "VEHICULOS",
    //     "policyType": "Individual",
    //     "insuredIdentificationNumber": "1717146780",
    //     "effectiveDateStart": "2024-02-15",
    //     "effectiveDateEnd": "2025-02-15",
    //     "insured": "  MONAGA PRECIADO WILLIAN OTON  ",
    //     "premium": 449.45,
    //     "insuredIdentificationType": "REGISTRO UNICO DE CONTRIBUYENTE",
    //     "currentRate": 0.0297,
    //     "proposedRate": 0.0342,
    //     "branchCode": "PBX-1074",
    //     "ramv": "8LGJE552XCE008881",
    //     "status": "POR RENOVAR",
    //     "showOptions": false,
    //     "attemptsError": 0,
    //     "attemptsErrorSendMail": 0,
    //     "insuredIdentificationAbr": "",
    //     "sucursal": 8,
    //     "annexEndorsementNumber": "109230901",
    //     "city": "Quito",
    //     "model": "Ford 4",
    //     "yearManufacturing": "1998",
    //     "renewalApply": 1,
    //     "showCoverageOption": false

    //   },
    //   {
    //     "policyNumber": "577519",
    //     "branchName": "VEHICULOS",
    //     "policyType": "Individual",
    //     "insuredIdentificationNumber": "1717146780",
    //     "effectiveDateStart": "2024-02-16",
    //     "effectiveDateEnd": "2025-02-16",
    //     "insured": "EFRAIN INDACOCHEA  ",
    //     "premium": 388.37,
    //     "insuredIdentificationType": "REGISTRO UNICO DE CONTRIBUYENTE",
    //     "currentRate": 0.0241,
    //     "proposedRate": 0.0276,
    //     "branchCode": "PDF-6730",
    //     "ramv": "TSMYA22S3KM589164",
    //     "status": "POR RENOVAR",
    //     "showOptions": false,
    //     "attemptsError": 0,
    //     "attemptsErrorSendMail": 0,
    //     "insuredIdentificationAbr": "",
    //     "sucursal": 8,
    //     "annexEndorsementNumber": "109230901",
    //     "city": "Quito",
    //     "model": "Ford 4",
    //     "yearManufacturing": "1998",
    //     "renewalApply": 1,
    //     "showCoverageOption": false

    //   },
    //   {
    //     "policyNumber": "577089",
    //     "branchName": "VEHICULOS",
    //     "policyType": "Individual",
    //     "insuredIdentificationNumber": "1818010669",
    //     "effectiveDateStart": "2024-02-22",
    //     "effectiveDateEnd": "2025-02-22",
    //     "insured": "MARIO FEDERICO QUEZADA GALARZA  ",
    //     "premium": 991.97,
    //     "insuredIdentificationType": "CEDULA DE IDENTIDAD",
    //     "currentRate": 0.035,
    //     "proposedRate": 0.0405,
    //     "branchCode": "GBO-9995",
    //     "ramv": "LVCB2NBA1MS210069",
    //     "status": "POR RENOVAR",
    //     "showOptions": false,
    //     "attemptsError": 0,
    //     "attemptsErrorSendMail": 0,
    //     "insuredIdentificationAbr": "",
    //     "sucursal": 8,
    //     "annexEndorsementNumber": "109230901",
    //     "city": "Quito",
    //     "model": "Ford 4",
    //     "yearManufacturing": "1998",
    //     "renewalApply": 1,
    //     "showCoverageOption": false

    //   },
    //   {
    //     "policyNumber": "576897",
    //     "branchName": "VEHICULOS",
    //     "policyType": "Individual",
    //     "insuredIdentificationNumber": "1919001550",
    //     "effectiveDateStart": "2023-01-02",
    //     "effectiveDateEnd": "2024-01-02",
    //     "insured": "  MERO VELEZ  ",
    //     "premium": 1211.2,
    //     "insuredIdentificationType": "CEDULA DE IDENTIDAD",
    //     "currentRate": 0.034,
    //     "proposedRate": 0.039,
    //     "branchCode": "GCT-281",
    //     "ramv": "LVAV2MBB4KC001232",
    //     "status": "RENOVADA",
    //     "showOptions": false,
    //     "attemptsError": 0,
    //     "attemptsErrorSendMail": 0,
    //     "insuredIdentificationAbr": "",
    //     "sucursal": 8,
    //     "annexEndorsementNumber": "109230901",
    //     "city": "Quito",
    //     "model": "Ford 4",
    //     "yearManufacturing": "1998",
    //     "renewalApply": 1,
    //     "showCoverageOption": false

    //   },
    //   {
    //     "policyNumber": "571026",
    //     "branchName": "VEHICULOS",
    //     "policyType": "Individual",
    //     "insuredIdentificationNumber": "1717146780",
    //     "effectiveDateStart": "2023-01-29",
    //     "effectiveDateEnd": "2024-01-29",
    //     "insured": "CRISTHIAN DAVID MONCAYO GUAJALA ",
    //     "premium": 825,
    //     "insuredIdentificationType": "REGISTRO UNICO DE CONTRIBUYENTE",
    //     "currentRate": 0.03,
    //     "proposedRate": 0.0345,
    //     "branchCode": "PCP-9187",
    //     "ramv": "JN1JBAT32FW003878",
    //     "status": "RENOVADA",
    //     "showOptions": false,
    //     "attemptsError": 0,
    //     "attemptsErrorSendMail": 0,
    //     "insuredIdentificationAbr": "",
    //     "sucursal": 8,
    //     "annexEndorsementNumber": "109230901",
    //     "city": "Quito",
    //     "model": "Ford 4",
    //     "yearManufacturing": "1998",
    //     "renewalApply": 1,
    //     "showCoverageOption": false

    //   },
    //   {
    //     "policyNumber": "571010",
    //     "branchName": "VEHICULOS",
    //     "policyType": "Individual",
    //     "insuredIdentificationNumber": "1111017029",
    //     "effectiveDateStart": "2023-01-25",
    //     "effectiveDateEnd": "2024-01-25",
    //     "insured": "CARLOS GUILLERMO COBEÑA PAZ CRISTHIAN ANTONIO  ",
    //     "premium": 765.19,
    //     "insuredIdentificationType": "CEDULA DE IDENTIDAD",
    //     "currentRate": 0.035,
    //     "proposedRate": 0.0405,
    //     "branchCode": "GSY-3986",
    //     "ramv": "LVCB2NBA8KS230011",
    //     "status": "RENOVADA",
    //     "showOptions": false,
    //     "attemptsError": 0,
    //     "attemptsErrorSendMail": 0,
    //     "insuredIdentificationAbr": "",
    //     "sucursal": 8,
    //     "annexEndorsementNumber": "109230901",
    //     "city": "Quito",
    //     "model": "Ford 4",
    //     "yearManufacturing": "1998",
    //     "renewalApply": 1,
    //     "showCoverageOption": false

    //   },
    //   {
    //     "policyNumber": "570877",
    //     "branchName": "VEHICULOS",
    //     "policyType": "Individual",
    //     "insuredIdentificationNumber": "1717146780",
    //     "effectiveDateStart": "2023-01-18",
    //     "effectiveDateEnd": "2024-01-18",
    //     "insured": "CARLOS EDUARDO LI ",
    //     "premium": 366.28,
    //     "insuredIdentificationType": "REGISTRO UNICO DE CONTRIBUYENTE",
    //     "currentRate": 0.0266,
    //     "proposedRate": 0.0306,
    //     "branchCode": "PCG-1273",
    //     "ramv": "JN1TAAT31EW751027",
    //     "status": "RENOVADA",
    //     "showOptions": false,
    //     "attemptsError": 0,
    //     "attemptsErrorSendMail": 0,
    //     "insuredIdentificationAbr": "",
    //     "sucursal": 8,
    //     "annexEndorsementNumber": "109230901",
    //     "city": "Quito",
    //     "model": "Ford 4",
    //     "yearManufacturing": "1998",
    //     "renewalApply": 1,
    //     "showCoverageOption": false

    //   },
    //   {
    //     "policyNumber": "570718",
    //     "branchName": "VEHICULOS",
    //     "policyType": "Individual",
    //     "insuredIdentificationNumber": "0101059490",
    //     "effectiveDateStart": "2023-01-07",
    //     "effectiveDateEnd": "2024-01-07",
    //     "insured": "  GUAMAN  ",
    //     "premium": 404.89,
    //     "insuredIdentificationType": "CEDULA DE IDENTIDAD",
    //     "currentRate": 0.0397,
    //     "proposedRate": 0.0457,
    //     "branchCode": "MBE-3185",
    //     "ramv": "LVCP2DVA3KU920118",
    //     "status": "RENOVADA",
    //     "showOptions": false,
    //     "attemptsError": 0,
    //     "attemptsErrorSendMail": 0,
    //     "insuredIdentificationAbr": "",
    //     "sucursal": 8,
    //     "annexEndorsementNumber": "109230901",
    //     "city": "Quito",
    //     "model": "Ford 4",
    //     "yearManufacturing": "1998",
    //     "renewalApply": 1,
    //     "showCoverageOption": false

    //   },
    //   {
    //     "policyNumber": "570712",
    //     "branchName": "VEHICULOS",
    //     "policyType": "Individual",
    //     "insuredIdentificationNumber": "0505025116",
    //     "effectiveDateStart": "2023-01-03",
    //     "effectiveDateEnd": "2024-01-03",
    //     "insured": "  VALENCIA ARCILA ",
    //     "premium": 371.96,
    //     "insuredIdentificationType": "CEDULA DE IDENTIDAD",
    //     "currentRate": 0.037,
    //     "proposedRate": 0.0425,
    //     "branchCode": "GCB-8795",
    //     "ramv": "LVCP2DVA3KU920152",
    //     "status": "RENOVADA",
    //     "showOptions": false,
    //     "attemptsError": 0,
    //     "attemptsErrorSendMail": 0,
    //     "insuredIdentificationAbr": "",
    //     "sucursal": 8,
    //     "annexEndorsementNumber": "109230901",
    //     "city": "Quito",
    //     "model": "Ford 4",
    //     "yearManufacturing": "1998",
    //     "renewalApply": 1,
    //     "showCoverageOption": false

    //   },
    //   {
    //     "policyNumber": "570656",
    //     "branchName": "VEHICULOS",
    //     "policyType": "Individual",
    //     "insuredIdentificationNumber": "1717146780",
    //     "effectiveDateStart": "2023-01-08",
    //     "effectiveDateEnd": "2024-01-08",
    //     "insured": "CARLOS PATRICIO BARREZUETA VINCES KEVIN EDUARDO MORA",
    //     "premium": 369.36,
    //     "insuredIdentificationType": "REGISTRO UNICO DE CONTRIBUYENTE",
    //     "currentRate": 0.024,
    //     "proposedRate": 0.0275,
    //     "branchCode": "PCY-3943",
    //     "ramv": "TSMYA22S9JM561478",
    //     "status": "RENOVADA",
    //     "showOptions": false,
    //     "attemptsError": 0,
    //     "attemptsErrorSendMail": 0,
    //     "insuredIdentificationAbr": "",
    //     "sucursal": 8,
    //     "annexEndorsementNumber": "109230901",
    //     "city": "Quito",
    //     "model": "Ford 4",
    //     "yearManufacturing": "1998",
    //     "renewalApply": 1,
    //     "showCoverageOption": false

    //   },
    //   {
    //     "policyNumber": "565314",
    //     "branchName": "VEHICULOS",
    //     "policyType": "Individual",
    //     "insuredIdentificationNumber": "1717146780",
    //     "effectiveDateStart": "2023-01-18",
    //     "effectiveDateEnd": "2024-01-18",
    //     "insured": "  TABARA NUÑEZ VICKY ALICIA ",
    //     "premium": 371.07,
    //     "insuredIdentificationType": "REGISTRO UNICO DE CONTRIBUYENTE",
    //     "currentRate": 0.0266,
    //     "proposedRate": 0.0306,
    //     "branchCode": "PCR-2336",
    //     "ramv": "8LDCB5359G0384941",
    //     "status": "RENOVADA",
    //     "showOptions": false,
    //     "attemptsError": 0,
    //     "attemptsErrorSendMail": 0,
    //     "insuredIdentificationAbr": "",
    //     "sucursal": 8,
    //     "annexEndorsementNumber": "109230901",
    //     "city": "Quito",
    //     "model": "Ford 4",
    //     "yearManufacturing": "1998",
    //     "renewalApply": 1,
    //     "showCoverageOption": false

    //   },
    //   {
    //     "policyNumber": "560481",
    //     "branchName": "VEHICULOS",
    //     "policyType": "Individual",
    //     "insuredIdentificationNumber": "1717146780",
    //     "effectiveDateStart": "2023-01-10",
    //     "effectiveDateEnd": "2024-01-10",
    //     "insured": "MYRIAN MERCEDES COBA MAYORGA DANNY GABRIEL  ",
    //     "premium": 763.19,
    //     "insuredIdentificationType": "REGISTRO UNICO DE CONTRIBUYENTE",
    //     "currentRate": 0.0379,
    //     "proposedRate": 0.0434,
    //     "branchCode": "PCI-7460",
    //     "ramv": "8LDCB0375E0236318",
    //     "status": "RENOVADA",
    //     "showOptions": false,
    //     "attemptsError": 0,
    //     "attemptsErrorSendMail": 0,
    //     "insuredIdentificationAbr": "",
    //     "sucursal": 8,
    //     "annexEndorsementNumber": "109230901",
    //     "city": "Quito",
    //     "model": "Ford 4",
    //     "yearManufacturing": "1998",
    //     "renewalApply": 1,
    //     "showCoverageOption": false

    //   },
    //   {
    //     "policyNumber": "545050",
    //     "branchName": "VEHICULOS",
    //     "policyType": "Individual",
    //     "insuredIdentificationNumber": "1717146780246",
    //     "effectiveDateStart": "2023-01-01",
    //     "effectiveDateEnd": "2024-01-01",
    //     "insured": "  Empresa Juridica  94646  ",
    //     "premium": 1625.34,
    //     "insuredIdentificationType": "REGISTRO UNICO DE CONTRIBUYENTE",
    //     "currentRate": 0.0263,
    //     "proposedRate": 0.0303,
    //     "branchCode": "PCN-2056",
    //     "ramv": "8LDCB0370F03000976",
    //     "status": "RENOVADA",
    //     "showOptions": false,
    //     "attemptsError": 0,
    //     "attemptsErrorSendMail": 0,
    //     "insuredIdentificationAbr": "",
    //     "sucursal": 8,
    //     "annexEndorsementNumber": "109230901",
    //     "city": "Quito",
    //     "model": "Ford 4",
    //     "yearManufacturing": "1998",
    //     "renewalApply": 1,
    //     "showCoverageOption": false

    //   },
    //   {
    //     "policyNumber": "541534",
    //     "branchName": "VEHICULOS",
    //     "policyType": "Individual",
    //     "insuredIdentificationNumber": "1717146780",
    //     "effectiveDateStart": "2023-02-26",
    //     "effectiveDateEnd": "2024-02-26",
    //     "insured": "  CARVAJAL SERRANO  ",
    //     "premium": 375.1,
    //     "insuredIdentificationType": "REGISTRO UNICO DE CONTRIBUYENTE",
    //     "currentRate": 0.0251,
    //     "proposedRate": 0.0259,
    //     "branchCode": "PBO-8591",
    //     "ramv": "WVGZZZ5NZ9W522690",
    //     "status": "RENOVADA",
    //     "showOptions": false,
    //     "attemptsError": 0,
    //     "attemptsErrorSendMail": 0,
    //     "insuredIdentificationAbr": "",
    //     "sucursal": 8,
    //     "annexEndorsementNumber": "109230901",
    //     "city": "Quito",
    //     "model": "Ford 4",
    //     "yearManufacturing": "1998",
    //     "renewalApply": 1,
    //     "showCoverageOption": false

    //   },
    //   {
    //     "policyNumber": "541521",
    //     "branchName": "VEHICULOS",
    //     "policyType": "Individual",
    //     "insuredIdentificationNumber": "1717146780",
    //     "effectiveDateStart": "2023-01-23",
    //     "effectiveDateEnd": "2024-01-23",
    //     "insured": "  PARRALES PACHAY CESAR SIMON  ",
    //     "premium": 568.13,
    //     "insuredIdentificationType": "REGISTRO UNICO DE CONTRIBUYENTE",
    //     "currentRate": 0.0334,
    //     "proposedRate": 0.0384,
    //     "branchCode": "PCM-3128",
    //     "ramv": "WVWMF83C0EE092614",
    //     "status": "RENOVADA",
    //     "showOptions": false,
    //     "attemptsError": 0,
    //     "attemptsErrorSendMail": 0,
    //     "insuredIdentificationAbr": "",
    //     "sucursal": 8,
    //     "annexEndorsementNumber": "109230901",
    //     "city": "Quito",
    //     "model": "Ford 4",
    //     "yearManufacturing": "1998",
    //     "renewalApply": 1,
    //     "showCoverageOption": false

    //   },
    //   {
    //     "policyNumber": "534083",
    //     "branchName": "VEHICULOS",
    //     "policyType": "Individual",
    //     "insuredIdentificationNumber": "1717146780",
    //     "effectiveDateStart": "2023-01-10",
    //     "effectiveDateEnd": "2024-01-10",
    //     "insured": "  CORDOVA CAPA JORGE LUIS ",
    //     "premium": 459.27,
    //     "insuredIdentificationType": "REGISTRO UNICO DE CONTRIBUYENTE",
    //     "currentRate": 0.0378,
    //     "proposedRate": 0.0433,
    //     "branchCode": "XBA-7372",
    //     "ramv": "8LDCSV366C0115816",
    //     "status": "RENOVADA",
    //     "showOptions": false,
    //     "attemptsError": 0,
    //     "attemptsErrorSendMail": 0,
    //     "insuredIdentificationAbr": "",
    //     "sucursal": 8,
    //     "annexEndorsementNumber": "109230901",
    //     "city": "Quito",
    //     "model": "Ford 4",
    //     "yearManufacturing": "1998",
    //     "renewalApply": 1,
    //     "showCoverageOption": false

    //   },
    //   {
    //     "policyNumber": "524952",
    //     "branchName": "VEHICULOS",
    //     "policyType": "Individual",
    //     "insuredIdentificationNumber": "0992280778001",
    //     "effectiveDateStart": "2023-01-19",
    //     "effectiveDateEnd": "2024-01-19",
    //     "insured": "  Empresa Juridica  15560  ",
    //     "premium": 1074.83,
    //     "insuredIdentificationType": "REGISTRO UNICO DE CONTRIBUYENTE",
    //     "currentRate": 0.0422,
    //     "proposedRate": 0.0487,
    //     "branchCode": "GTG-5303",
    //     "ramv": "LGWDBE372MB611540",
    //     "status": "RENOVADA",
    //     "showOptions": false,
    //     "attemptsError": 0,
    //     "attemptsErrorSendMail": 0,
    //     "insuredIdentificationAbr": "",
    //     "sucursal": 8,
    //     "annexEndorsementNumber": "109230901",
    //     "city": "Quito",
    //     "model": "Ford 4",
    //     "yearManufacturing": "1998",
    //     "renewalApply": 1,
    //     "showCoverageOption": false

    //   },
    //   {
    //     "policyNumber": "520423",
    //     "branchName": "VEHICULOS",
    //     "policyType": "Individual",
    //     "insuredIdentificationNumber": "1717146780",
    //     "effectiveDateStart": "2023-01-18",
    //     "effectiveDateEnd": "2024-01-18",
    //     "insured": "ALEXANDRA SUSANA CORONEL FLORES ",
    //     "premium": 1223.74,
    //     "insuredIdentificationType": "REGISTRO UNICO DE CONTRIBUYENTE",
    //     "currentRate": 0.0255,
    //     "proposedRate": 0.0295,
    //     "branchCode": "GSX-1786",
    //     "ramv": "8AJKB8CD0J1672795",
    //     "status": "RENOVADA",
    //     "showOptions": false,
    //     "attemptsError": 0,
    //     "attemptsErrorSendMail": 0,
    //     "insuredIdentificationAbr": "",
    //     "sucursal": 8,
    //     "annexEndorsementNumber": "109230901",
    //     "city": "Quito",
    //     "model": "Ford 4",
    //     "yearManufacturing": "1998",
    //     "renewalApply": 1,
    //     "showCoverageOption": false

    //   },
    //   {
    //     "policyNumber": "513700",
    //     "branchName": "VEHICULOS",
    //     "policyType": "Individual",
    //     "insuredIdentificationNumber": "1212065188035",
    //     "effectiveDateStart": "2023-01-30",
    //     "effectiveDateEnd": "2024-01-30",
    //     "insured": "  Empresa Juridica  25150  ",
    //     "premium": 974.93,
    //     "insuredIdentificationType": "REGISTRO UNICO DE CONTRIBUYENTE",
    //     "currentRate": 0.0271,
    //     "proposedRate": 0.0311,
    //     "branchCode": "GSO-3282",
    //     "ramv": "JN1JBAT32FW003109",
    //     "status": "RENOVADA",
    //     "showOptions": false,
    //     "attemptsError": 0,
    //     "attemptsErrorSendMail": 0,
    //     "insuredIdentificationAbr": "",
    //     "sucursal": 8,
    //     "annexEndorsementNumber": "109230901",
    //     "city": "Quito",
    //     "model": "Ford 4",
    //     "yearManufacturing": "1998",
    //     "renewalApply": 1,
    //     "showCoverageOption": false

    //   }
    // ]
    // this.renovations.forEach(curr => {
    //   const finalDate = moment(curr.effectiveDateEnd);
    //   const currentDate = moment();
    //   curr.expiresIn = finalDate.diff(currentDate, 'days');
    //   if (curr.insuredIdentificationType == "CEDULA DE IDENTIDAD") {
    //     curr.insuredIdentificationAbr = "CI"
    //   } else if (curr.insuredIdentificationType == "REGISTRO UNICO DE CONTRIBUYENTE") {
    //     curr.insuredIdentificationAbr = "RUC"
    //   } else {
    //     curr.insuredIdentificationAbr = curr.insuredIdentificationType;
    //   }
    //   curr.showCoverageOption = this.validDateCoverage(curr.effectiveDateStart);
    // });
    this.renovationsService.getListRenovations({ limit, page, policy, branch, startDate, endDate, status, daysCase, download }).subscribe((res: RenovationBody) => {
      if (res) {
        if (download) {
          this.downloadReport(res, 'listado_renovaciones');
        } else {
          res.data.forEach(curr => {
            const finalDate = moment(curr.effectiveDateEnd);
            const currentDate = moment();
            curr.expiresIn = finalDate.diff(currentDate, 'days');
            if (curr.insuredIdentificationType == "CEDULA DE IDENTIDAD") {
              curr.insuredIdentificationAbr = "CI"
            } else if (curr.insuredIdentificationType == "REGISTRO UNICO DE CONTRIBUYENTE") {
              curr.insuredIdentificationAbr = "RUC"
            } else {
              curr.insuredIdentificationAbr = curr.insuredIdentificationType;
            }
            curr.showCoverageOption = this.validDateCoverage(curr.effectiveDateEnd);
          });
          this.renovations = res.data;
          this.totalRecords = res.totalRows;
          this.totalRecordsShow = this.renovations.length;
          if (resetPage) {
            this.mainPaginator.changePage(0);
          }
        }
      }
    }, (err: any) => {
      console.log(err);
      this.getCodeError(err);
      this.totalRecords = 0;
      this.totalRecordsShow = 0;
      this.renovations = [];
    });
  }

  chargeBreadCrum() {
    this.breadcrumbService.setItems([
      { label: 'Inicio', routerLink: ['/home'] },
      { label: 'Gestión de solicitudes' },
      { label: 'Consulta de renovaciones', routerLink: ['/renovations'] }
    ]);
  }

}
