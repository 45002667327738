//Core-angular
import { NgModule } from '@angular/core';
//Components
import { BpmComponent } from './bpm.component';

@NgModule({
  declarations: [
    BpmComponent

  ],
  imports: [

  ],
  providers: [
  ],
  bootstrap: [],
  exports: [
    BpmComponent
  ]

})
export class BpmModule { }
