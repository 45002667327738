/**
 * intl-latam-ec-form-secure
 * Api to secure Application
 *
 * OpenAPI spec version: 1.2.1
 * Contact: cgudino@kruger.com.ec
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import {
    HttpClient, HttpHeaders, HttpParams,
    HttpResponse, HttpEvent
} from '@angular/common/http';
import { CustomHttpUrlEncodingCodec } from '../encoder';

import { Observable } from 'rxjs/Observable';

import { ModelApiResponse } from '../model-seguridad/modelApiResponse';
import { Rule } from '../model-seguridad/rule';
import { RulesQuery } from '../model-seguridad/rulesQuery';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { map } from 'rxjs/operators';
import { BASE_PATH, COLLECTION_FORMATS } from '../variables';
import { Configuration } from '../configuration';
import { environment } from '../../../environments/environment';


@Injectable()
export class RulesService {
    @BlockUI() blockUI: NgBlockUI;
    protected basePath = environment.URL_API_SECURE;
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional() @Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Post rule
     * Create new rule structure
     * @param body Request object for creation rule
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public intlLatamEcFormSecureCreateRule(body: Rule, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public intlLatamEcFormSecureCreateRule(body: Rule, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public intlLatamEcFormSecureCreateRule(body: Rule, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public intlLatamEcFormSecureCreateRule(body: Rule, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
        this.blockUI.start();
        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling intlLatamEcFormSecureCreateRule.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.basePath}/rules`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map(response => {
                this.blockUI.stop();
                return response
            })
        );
    }

    /**
     * Delete rule
     * delete existing rule structure
     * @param name 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public intlLatamEcFormSecureDeleteRule(name: string, observe?: 'body', reportProgress?: boolean): Observable<ModelApiResponse>;
    public intlLatamEcFormSecureDeleteRule(name: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ModelApiResponse>>;
    public intlLatamEcFormSecureDeleteRule(name: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ModelApiResponse>>;
    public intlLatamEcFormSecureDeleteRule(name: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
        this.blockUI.start();
        if (name === null || name === undefined) {
            throw new Error('Required parameter name was null or undefined when calling intlLatamEcFormSecureDeleteRule.');
        }

        let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (name !== undefined && name !== null) {
            queryParameters = queryParameters.set('name', <any>name);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.delete<ModelApiResponse>(`${this.basePath}/rules`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map(response => {
                this.blockUI.stop();
                return response
            })
        );
    }

    /**
     * get rule
     * get existing rule structure
     * @param authorization 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public intlLatamEcFormSecureGetAllRules(authorization?: string, observe?: 'body', reportProgress?: boolean): Observable<ModelApiResponse>;
    public intlLatamEcFormSecureGetAllRules(authorization?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ModelApiResponse>>;
    public intlLatamEcFormSecureGetAllRules(authorization?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ModelApiResponse>>;
    public intlLatamEcFormSecureGetAllRules(authorization?: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
        this.blockUI.start();
        // if (authorization === null || authorization === undefined) {
        //     throw new Error('Required parameter authorization was null or undefined when calling intlLatamEcFormSecureGetAllRules.');
        // }

        let headers = this.defaultHeaders;
        // if (authorization !== undefined && authorization !== null) {
        //     headers = headers.set('Authorization', String(authorization));
        // }

        // // authentication (authorize-b2b) required
        // if (this.configuration.apiKeys["Authorization"]) {
        //     headers = headers.set('Authorization', this.configuration.apiKeys["Authorization"]);
        // }

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<ModelApiResponse>(`${this.basePath}/rules/list`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map(response => {
                this.blockUI.stop();
                return response
            })
        );
    }

    /**
     * get roles names
     * get array roles names
     * @param ruleId Rule id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public intlLatamEcFormSecureGetRolesForRule(ruleId: string, observe?: 'body', reportProgress?: boolean): Observable<ModelApiResponse>;
    public intlLatamEcFormSecureGetRolesForRule(ruleId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ModelApiResponse>>;
    public intlLatamEcFormSecureGetRolesForRule(ruleId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ModelApiResponse>>;
    public intlLatamEcFormSecureGetRolesForRule(ruleId: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
        this.blockUI.start();
        if (ruleId === null || ruleId === undefined) {
            throw new Error('Required parameter ruleId was null or undefined when calling intlLatamEcFormSecureGetRolesForRule.');
        }

        let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (ruleId !== undefined && ruleId !== null) {
            queryParameters = queryParameters.set('ruleId', <any>ruleId);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        return this.httpClient.get<ModelApiResponse>(`${this.basePath}/rules/rol`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * get rule
     * get existing rule structure
     * @param name get rule by name
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public intlLatamEcFormSecureGetRule(name: string, observe?: 'body', reportProgress?: boolean): Observable<ModelApiResponse>;
    public intlLatamEcFormSecureGetRule(name: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ModelApiResponse>>;
    public intlLatamEcFormSecureGetRule(name: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ModelApiResponse>>;
    public intlLatamEcFormSecureGetRule(name: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
        this.blockUI.start();
        if (name === null || name === undefined) {
            throw new Error('Required parameter name was null or undefined when calling intlLatamEcFormSecureGetRule.');
        }

        let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (name !== undefined && name !== null) {
            queryParameters = queryParameters.set('name', <any>name);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];

        return this.httpClient.get<ModelApiResponse>(`${this.basePath}/rules`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map(response => {
                this.blockUI.stop();
                return response
            })
        );
    }

    /**
     * Put rule
     * Update existing rule structure
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public intlLatamEcFormSecureUpdateRule(body: Rule, observe?: 'body', reportProgress?: boolean): Observable<ModelApiResponse>;
    public intlLatamEcFormSecureUpdateRule(body: Rule, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ModelApiResponse>>;
    public intlLatamEcFormSecureUpdateRule(body: Rule, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ModelApiResponse>>;
    public intlLatamEcFormSecureUpdateRule(body: Rule, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
        this.blockUI.start();
        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling intlLatamEcFormSecureUpdateRule.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.put<ModelApiResponse>(`${this.basePath}/rules`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map(response => {
                this.blockUI.stop();
                return response
            })
        );
    }

    /**
     * query access
     * query access control
     * @param body Request component access by rol
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public intlLatamEcFormSecureVerifyComponentAccess(body: RulesQuery, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public intlLatamEcFormSecureVerifyComponentAccess(body: RulesQuery, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public intlLatamEcFormSecureVerifyComponentAccess(body: RulesQuery, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public intlLatamEcFormSecureVerifyComponentAccess(body: RulesQuery, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
        this.blockUI.start();
        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling intlLatamEcFormSecureVerifyComponentAccess.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.basePath}/security`,
            body,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        ).pipe(
            map(response => {
                this.blockUI.stop();
                return response
            })
        );
    }

}
