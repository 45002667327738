import { Component, OnInit, Output } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup } from '@angular/forms';
import { ConfirmationService } from 'primeng/api';
import { Message } from 'primeng/components/common/api';
import { MessageService } from 'primeng/components/common/messageservice';
import { BreadcrumbService } from '../../../breadcrumb.service';
import { Http } from '@angular/http';
import 'rxjs/add/operator/map';
import { Comision } from '../../../_model/policy/comision';

import { Poliza } from '../../../_model/policy/poliza';
import { Properties } from '../../general/properties';
import { RecuperarModel } from '../../../_model/policy/recuperar';
import {
  RecuperarS3Model, FacturacionElectronicaModel, ActualizarEstadoModel, EnvioAEvicertiaModel,
  PolizaIdModel, ComisionActualizacionModel, BusquedaPolizaModel, PolicyElectronicBill
} from '../../../_services/model-poliza/modelsPolicy';
import { ApiResponse } from '../../../_model/policy/apiResponse';
import { DefaultService, RulesService } from '../../../_services';
import { Constantes, TOKE_NAME } from '../../../_shared/var.constant';
import { PolizaComponent } from '../poliza.component';
import { LoginService } from '../../../_handler/login.service';

import { DomSanitizer } from '@angular/platform-browser';
import * as FileSaver from 'file-saver';

@Component({
  selector: 'app-poliza-revision',
  templateUrl: './poliza-revision.component.html',
  styleUrls: ['./poliza-revision.component.css']
})
export class PolizaRevisionComponent implements OnInit {
  constantes = new Constantes();
  gestionaPolizaDisplay = 1;
  recuperars3Model: RecuperarS3Model;
  facturaElectronicaModel: FacturacionElectronicaModel;
  updateStatusModel: ActualizarEstadoModel;
  envioAEvicertiaModel: EnvioAEvicertiaModel;
  properties = new Properties();
  tituloPopUp: string;
  public fGaceptarPoliza: FormGroup;
  polizaSeleccionada: Poliza = new Poliza();
  polizas: Poliza[] = [];
  polizaId: PolizaIdModel;
  checked = false;
  valorComision: number;
  selectedType: string;
  msgs: Message[] = [];
  mostrarAceptar: boolean;
  mostrarRechazar: boolean;
  estadosFinalizados: any;
  estadosParaGestionSinAcciones: any;
  blockedDocument = true;
  evicertia: any = {};
  doc: any;
  llaveDocumento: any = {};
  comisionPrincipal: Comision = new Comision();
  archivoPDF: string;
  archivoXML: string;
  numeroPoliza: any;
  fechaEmisionEs: any;
  monthNames = [];
  fechaFinVigenciaEs: string;
  fechaInicioVigenciaEs: string;
  usuario: any;
  bandera: any;
  botonRechazarPoliza: any;
  botonAceptarPoliza: any;
  idpv: number;
  userRol: any;
  polizaSeguridad: any;
  verFactura = false;
  verPoliza = false;
  aceptarPolza = false;
  rechazarPolza = false;
  verObservacion = false;

  motivo: boolean = false;
  motivoAux: any;
  estadoPoliza: String;


  recuperarModel: RecuperarModel;
  documentoUrl: any = null
  busquedaPolizaModel: BusquedaPolizaModel;



  constructor(private activatedRoute: ActivatedRoute,
    public policyService: DefaultService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private router: Router,
    public rulesServices: RulesService,
    public polizaPadre: PolizaComponent,
    private http: Http,
    private loginService: LoginService,
    private breadcrumbService: BreadcrumbService,
    private domSanitizer: DomSanitizer, ) {

  }

  ngOnInit() {
    this.cargarBreadCrum();
    this.userRol = this.loginService.getRoles();
    this.consultarSeguridad();
    this.monthNames = this.properties.monthNames;
    this.estadosFinalizados = this.properties.estadosFinalizados;
    this.estadosParaGestionSinAcciones = this.properties.estadosParaGestionSinAcciones;
    this.idpv = this.activatedRoute.snapshot.params['idpv'];
    const body = { 'idpv': Number(this.idpv) };
    this.polizaSeleccionada = new Poliza();
    this.buscarPoliza(body);
    this.mostrarAceptar = false;
    this.mostrarRechazar = false;
    this.comisionPrincipal = new Comision();
    this.blockedDocument = true;
    this.bandera = false;
  }


  consultarSeguridad() {
    this.seguridadAceptarPoliza();
    this.seguridadRechazarPoliza();
    this.seguridadVerFactura();
    this.seguridadVerPoliza();
    this.seguridadEnviarObservacion();
  }

  seguridadVerFactura() {
    this.blockedDocument = true;
    const verFactura = {
      rolesId: this.userRol,
      rule: this.constantes.verFactura
    };
    this.rulesServices.intlLatamEcFormSecureVerifyComponentAccess(verFactura).subscribe((resp: ApiResponse) => {
      const auxcode = parseInt(resp.code + '', 10);
      if (auxcode === 200) {
        this.blockedDocument = false;
        this.verFactura = true;
      }
      this.blockedDocument = false;
    },
      (err: any) => console.log(err),
      () => {
        this.blockedDocument = false;
      });
  }

  seguridadVerPoliza() {
    this.blockedDocument = true;
    const verPolivaPdf = {
      rolesId: this.userRol,
      rule: this.constantes.verPoliza
    };
    this.rulesServices.intlLatamEcFormSecureVerifyComponentAccess(verPolivaPdf).subscribe((resp: ApiResponse) => {
      const auxcode = parseInt(resp.code + '', 10);
      if (auxcode === 200) {
        this.blockedDocument = false;
        this.verPoliza = true;
      }
      this.blockedDocument = false;
    },
      (err: any) => console.log(err),
      () => {
        this.blockedDocument = false;
      });
  }

  seguridadAceptarPoliza() {
    this.blockedDocument = true;
    const aceptarPolza = {
      rolesId: this.userRol,
      rule: this.constantes.aceptarPoliza
    };
    this.rulesServices.intlLatamEcFormSecureVerifyComponentAccess(aceptarPolza).subscribe((resp: ApiResponse) => {
      const auxcode = parseInt(resp.code + '', 10);
      if (auxcode === 200) {
        this.blockedDocument = false;
        this.aceptarPolza = true;
      }
      this.blockedDocument = false;
    },
      (err: any) => console.log(err),
      () => {
        this.blockedDocument = false;
      });
  }

  seguridadRechazarPoliza() {
    this.blockedDocument = true;
    const rechazarPolza = {
      rolesId: this.userRol,
      rule: this.constantes.rechazarPoliza
    };
    this.rulesServices.intlLatamEcFormSecureVerifyComponentAccess(rechazarPolza).subscribe((resp: ApiResponse) => {
      const auxcode = parseInt(resp.code + '', 10);
      if (auxcode === 200) {
        this.blockedDocument = false;
        this.rechazarPolza = true;
      }
      this.blockedDocument = false;
    },
      (err: any) => console.log(err),
      () => {
        this.blockedDocument = false;
      });
  }

  seguridadEnviarObservacion() {
    this.blockedDocument = true;
    const observacion = {
      rolesId: this.userRol,
      rule: this.constantes.observacion
    };
    this.rulesServices.intlLatamEcFormSecureVerifyComponentAccess(observacion).subscribe((resp: ApiResponse) => {
      const auxcode = parseInt(resp.code + '', 10);
      if (auxcode === 200) {
        this.blockedDocument = false;
        this.verObservacion = true;
      }
      this.blockedDocument = false;
    },
      (err: any) => console.log(err),
      () => {
        this.blockedDocument = false;
      });
  }


  cargarBreadCrum() {

    this.breadcrumbService.itemsSource.next([
      { label: 'Mis solicitudes', routerLink: ['polizas'] },
      { label: 'Póliza' + ' ' + this.numeroPoliza }
    ]);
  }


  volver() {
    this.router.navigate(['polizas']);
  }

  buscarPoliza(body: PolizaIdModel) {

    this.bandera = false;
    this.blockedDocument = true;
    this.policyService.buscarPolizaByIdPost(body).subscribe((resp: ApiResponse) => {
      this.polizaSeleccionada = resp.data[0];
      this.estadoPoliza = this.polizaSeleccionada.estado.descripcion;
      if (this.polizaSeleccionada.estado.codigo === 'Rejected') {
        this.botonRechazarPoliza = 'Confirmar Rechazo';
        this.botonAceptarPoliza = 'Reenviar Póliza';
      } else {
        this.botonRechazarPoliza = 'Rechazar Póliza';
        this.botonAceptarPoliza = 'Aceptar Póliza';
      }
      if (this.polizaSeleccionada.comisiones[0]) {
        if (this.polizaSeleccionada.comisiones[0].observacion) {
          this.bandera = true;
        }
      } else {
        this.bandera = false;
      }
      if (this.polizaSeleccionada.motivoRechazo != undefined) {
        this.motivo = true;
        this.motivoAux = this.polizaSeleccionada.motivoRechazo;
      }
      const fechaTmp = new Date(this.polizaSeleccionada.fechaEmision);
      const fechaInicioTmp = new Date(this.polizaSeleccionada.fechaInicioVigencia);
      const fechaFinTmp = new Date(this.polizaSeleccionada.fechaFinVigencia);
      // let dia, mes, anio, diaB, mesB, anioB, diaC, mesC, anioC;
      // dia = fechaTmp.getDate();
      // mes = this.monthNames[fechaTmp.getMonth()]
      // anio = fechaTmp.getFullYear()
      // diaB = fechaInicioTmp.getDate();
      // mesB = this.monthNames[fechaInicioTmp.getMonth()];
      // anioB = fechaInicioTmp.getFullYear();
      // diaC = fechaFinTmp.getDate();
      // mesC = this.monthNames[fechaFinTmp.getMonth()];
      // anioC = fechaFinTmp.getFullYear();
      // this.fechaEmisionEs = dia + ' ' + mes + ' ' + anio;
      // this.fechaInicioVigenciaEs = diaB + ' ' + mesB + ' ' + anioB;
      // this.fechaFinVigenciaEs = diaC + ' ' + mesC + ' ' + anioC;
      this.fechaEmisionEs = fechaTmp.getDate() + ' ' + this.monthNames[fechaTmp.getMonth()] + ' ' + fechaTmp.getFullYear();
      this.fechaInicioVigenciaEs = fechaInicioTmp.getDate() + ' ' + this.monthNames[fechaInicioTmp.getMonth()] + ' ' +
        fechaInicioTmp.getFullYear();
      this.fechaFinVigenciaEs = fechaFinTmp.getDate() + ' ' + this.monthNames[fechaFinTmp.getMonth()] + ' ' + fechaFinTmp.getFullYear();

      this.buscarComisionPrincial();
      this.valorComision = Number(this.comisionPrincipal.prima) * Number(this.comisionPrincipal.porcentajeComision / 100);
      this.valorComision = Number(this.valorComision.toFixed(2));
      this.blockedDocument = false;
      if (this.polizaSeleccionada.numeroPoliza) {
        this.numeroPoliza = this.polizaSeleccionada.numeroPoliza;
      } else {
        this.numeroPoliza = '';
      }
    });
  }

  verPdf() {
    if (this.polizaSeleccionada.pdf.key && this.polizaSeleccionada.pdf.Bucket) {
      this.recuperarModel = new RecuperarModel();
      this.recuperarModel.key = this.polizaSeleccionada.pdf.key;
      this.recuperarModel.bucket = this.polizaSeleccionada.pdf.Bucket;
      this.policyService.recuperarS3Post(this.recuperarModel).subscribe((resp: any) => {
        let byteArray = new Uint8Array(resp.data.data);
        let blob = new Blob([byteArray], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8'
        });
        let pdfNames = [];
        let name = this.polizaSeleccionada.pdf.key;
        pdfNames = name.split('/')
        FileSaver.saveAs(blob, pdfNames[1]);
        // let documento = new Blob([resp.data], { type: 'application/pdf;base64' });
        // this.documentoUrl = null;
        // this.documentoUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(blob));
        // console.log(this.documentoUrl);
      });
      // window.open(this.polizaSeleccionada.pdf.Location, '_blank');
    } else {
      this.messageService.add({ severity: 'error', summary: 'Busqueda Pdf', detail: 'No se ha encontrado el archivo.' });
    }
  }

  descargarArchivos() {
    this.facturaElectronicaModel = {
      'rucEmpresa': '1790093808001',
      'codigoSAP': this.polizaSeleccionada.facturaElectronica.codigoSAP,
      'numeroAutorizacion': this.polizaSeleccionada.facturaElectronica.numeroAutorizacion
    };
    this.policyService.soapToRestFacturaPost(this.facturaElectronicaModel).subscribe((resp: ApiResponse) => {
      if (resp.data.pdf) {
        const a = document.createElement('a');
        a.href = 'data:application/octet-stream;base64,' + resp.data.pdf;
        a.download = 'Factura - ' + this.polizaSeleccionada.contratante.nombresApellidos + '.pdf';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        const b = document.createElement('a');
        b.href = 'data:application/octet-stream;base64,' + resp.data.xml;
        b.download = 'Factura - ' + this.polizaSeleccionada.contratante.nombresApellidos + '.xml';
        document.body.appendChild(b);
        b.click();
        document.body.removeChild(b);
        this.blockedDocument = false;
      } else {
        this.messageService.add({ severity: 'error', summary: 'Busqueda Factura', detail: 'No se ha encontrado el archivo.' });
        this.blockedDocument = false;
      }
    }, (err: any) => {
      this.blockedDocument = false;
    });

  }

  descargarFacturaPDF() {
    this.blockedDocument = true;
    this.descargarArchivos();
  }


  sapValidate() {
    if (this.polizaSeleccionada.facturaElectronica.codigoSAP != undefined && this.polizaSeleccionada.facturaElectronica.codigoSAP != null
      && eval(this.polizaSeleccionada.facturaElectronica.codigoSAP) > 0
      && this.polizaSeleccionada.facturaElectronica.numeroAutorizacion != undefined &&
      this.polizaSeleccionada.facturaElectronica.numeroAutorizacion != null) {
      this.descargarFacturaPDF();
    } else {
      this.blockedDocument = true;
      this.policyService.validateElectronicBillGet(this.polizaSeleccionada.idpv.toString()).subscribe((resp: PolicyElectronicBill) => {
        if (resp.sap > 0) {
          this.polizaSeleccionada.facturaElectronica.codigoSAP = resp.sap.toString();
          this.polizaSeleccionada.facturaElectronica.numeroAutorizacion = resp.authorizationCode;
          this.descargarFacturaPDF();
        } else {
          this.messageService.add({
            severity: 'error', summary: 'Error', detail:
              'La factura no esta disponible, por favor intetelo mas tarde'
          });
          this.blockedDocument = false;
        }
      });
    }
  }


  confirmRechazoComision() {
    this.confirmationService.confirm({
      message: '¿Estás seguro de que desea continuar?',
      header: 'Observación',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.msgs = [{ severity: 'info', summary: 'Confirmed', detail: 'Se ha guardado la observación' }];
        this.actualizarComision();
      },
      reject: () => {
        this.msgs = [{ severity: 'info', summary: 'Rejected', detail: 'No se ha guardo la observación' }];
      }
    });
  }

  inicializarAceptarPoliza() {
    this.mostrarAceptar = true;
    this.tituloPopUp = 'Aceptar Póliza';
  }

  aceptarPoliza() {
    this.blockedDocument = true;
    this.mostrarAceptar = false;
    this.envioAEvicertiaModel = {
      'idpv': this.polizaSeleccionada.idpv,
      'key': this.polizaSeleccionada.pdf.key,
      'bucket': this.polizaSeleccionada.pdf.Bucket,
      'nombresApellidos': this.polizaSeleccionada.contratante.nombresApellidos,
      'correoElectronico': this.polizaSeleccionada.contratante.correoElectronico,
      'numeroPoliza': this.polizaSeleccionada.numeroPoliza
    };

    this.policyService.envioEvicertiaPost(this.envioAEvicertiaModel).subscribe((resp: ApiResponse) => {
      if (resp != null && (Number(resp.code) === 200)) {
        this.router.navigate(['/polizas']);
        this.polizaPadre.cargarPolizas();
        this.polizaPadre.contarPolizasEstados();
        this.messageService.add({ severity: 'success', summary: 'Exito', detail: 'Póliza revisada' });

      } else {
        this.blockedDocument = false;
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'No se pudo actualizar la póliza' });
      }
    });
  }

  inicializarRechazarPoliza() {
    this.mostrarRechazar = true;
    this.tituloPopUp = 'Rechazar Póliza';
  }

  rechazarPoliza() {
    this.blockedDocument = true;
    this.mostrarRechazar = false;
    this.updateStatusModel = {
      'idpv': this.polizaSeleccionada.idpv,
      'numeroPoliza': this.polizaSeleccionada.numeroPoliza,
      'numeroEndoso': this.polizaSeleccionada.numeroEndoso,
      'ramo': this.polizaSeleccionada.ramo,
      'sucursal': this.polizaSeleccionada.sucursal,
      'estado': { 'codigo': 'rejectedb', 'descripcion': 'Rechazado broker' },
      'descripcionRechazo': this.polizaSeleccionada.descripcionRechazo,
      'gestor': this.polizaSeleccionada.gestor,
      'movimiento': this.polizaSeleccionada.tipoMovimiento.descripcion,
      'broker': this.polizaSeleccionada.broker
    };

    this.policyService.actualizarEstadoPolizaPost(this.updateStatusModel).subscribe((resp: ApiResponse) => {
      this.blockedDocument = true;
      if (resp != null && (Number(resp.code) === 200)) {
        this.router.navigate(['/polizas']);
        this.polizaPadre.cargarPolizas();
        this.polizaPadre.contarPolizasEstados();
        this.messageService.add({ severity: 'success', summary: 'Exito', detail: 'Póliza revisada' });
        this.blockedDocument = false;
      } else {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'No se pudo rechazar la poliza' });
        this.blockedDocument = false;
      }
    });
  }

  verificarContenidoComision() {
    if (!this.checked) {
      this.comisionPrincipal.observacion = '';
    }
  }

  validarMostrar() {
    let result = true;
    this.estadosParaGestionSinAcciones.forEach(element => {
      if (element == this.polizaSeleccionada.estado.codigo) {
        result = false;
      }
    });
    return result;
  }

  actualizarComision(): any {
    this.blockedDocument = true;
    const comision: ComisionActualizacionModel = {
      idpv: this.polizaSeleccionada.idpv,
      idComision: this.comisionPrincipal.id,
      porcentajeComision: this.comisionPrincipal.porcentajeComision,
      observacion: this.comisionPrincipal.observacion,
      broker: this.polizaSeleccionada.contratante.nombresApellidos,
      agente: this.polizaSeleccionada.gestor.gestor,
      movimiento: this.polizaSeleccionada.tipoMovimiento.descripcion
    };
    this.policyService.actualizarComisionPost(comision).subscribe((resp: ApiResponse) => {
      const result = resp;
      if (result.code = 200) {
        this.checked = false;
        this.bandera = true;
        this.messageService.add({ severity: 'success', summary: 'Exito', detail: 'Observación enviada' });
      } else {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'No se pudo enviar observación' });
      }
      this.blockedDocument = false;
    });
  }

  buscarComisionPrincial() {
    if (this.polizaSeleccionada.comisiones && this.polizaSeleccionada.comisiones.length === 1) {
      this.comisionPrincipal = this.polizaSeleccionada.comisiones[0];
    } else {
      if (this.polizaSeleccionada.comisiones && this.polizaSeleccionada.comisiones.length > 0) {
        this.polizaSeleccionada.comisiones.forEach(element => {
          if (element.agente.id === this.polizaSeleccionada.gestor.id && element.ramoTecnico && element.ramoTecnico.id ===
            this.polizaSeleccionada.ramo.id) {
            this.comisionPrincipal = element;
          }

        });
      }

    }
  }
}

